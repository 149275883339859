import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { getBrandData } from '../../utils'

const CourseName = styled.div`padding-left: 10px; font-weight: bold; `
const CourseListItem = styled(Grid)`padding-left: 15px; margin-top: 5px; cursor: pointer; border-bottom: solid 1px gainsboro;`
const CourseCardImage = styled.img`height: 30px; max-width: 100%; border-radius: 6px 6px 6px 6px; margin-top: 3px; margin-bottom: 3px;`

function StaffCourseListItem(props) {
	const [coverImage, setCoverImage] = useState('https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3')

	useEffect(() => {
		if (props.course) {
			let tmpCoverImage = 'https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3'

			if (props.course.meta && props.course.meta.intro) {
				tmpCoverImage = props.course.meta.intro.coverImageThumb300Url
			} else {
				tmpCoverImage = 'https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3'
			}
			setCoverImage(tmpCoverImage)
		}
	}, [props.course])

	return (		
		<CourseListItem item xs={12}>
			<Grid container style={{fontSize: '13px'}}>
				<Grid item xs={12} sm={1}>
					<a href={`${getBrandData().publicWebsite}/${props.course.meta.intro.language}/course/${props.course.meta.settings.slug}`} target="_blank" rel="noopener noreferrer">
						<CourseCardImage src={coverImage} />
					</a>
				</Grid>
				<Grid item xs={12} sm={4}>
					<CourseName>{props.course.meta.settings.courseSpecificName ? props.course.meta.settings.courseSpecificName : (props.course.meta.intro.title ? props.course.meta.intro.title : props.course.title)}</CourseName>
				</Grid>
				<Grid item xs={12} sm={3} style={{textAlign: 'right'}}>
					<b>{props.course.meta.stats.firstLessonDate ? moment(props.course.meta.stats.firstLessonDate.toDate()).format('YYYY-MM-DD') : ''}</b>
					<br />
					{props.course.meta.stats.lessonCount} ders, {(props.course.meta.pricing && props.course.meta.pricing.basePrice) ? props.course.meta.stats.lessonCount * props.course.meta.pricing.basePrice : 'No price'} {(props.course.meta.pricing && props.course.meta.pricing.currency) ? props.course.meta.pricing.currency : 'No currency'}
				</Grid>
				<Grid item xs={12} sm={4} style={{paddingLeft: '20px'}}>
					<div>{props.course.students.registered.length} Registered</div>
					<div>{props.course.students.inShoppingCart.length} In Cart</div>
				</Grid>
			</Grid>
		</CourseListItem>
	)
}

export { StaffCourseListItem }
