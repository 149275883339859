import React, { useMemo, useRef } from 'react'
import { composeDecorators } from './utils/composeDecorators'
import { extractPlainText } from './utils/extractPlainText'
import { useField } from 'usetheform'
import { Editor, EditorState } from 'draft-js'


import './Styles.css'

export const DraftEditor = (props) => {
	const initialState = useMemo(
		() => EditorState.createEmpty(composeDecorators(props.maxChars)),
		[props.maxChars]
	)

	const { value, setValue } = useField({
		type: 'custom',
		name: 'editorState',
		value: initialState
	})

	const onEditorChange = async (editorState) => {
		let extractedData = await extractPlainText(editorState)
		props.onChange(extractedData)
		setValue(editorState)
	}

	/* 
		field used to hold the draft's ref 
		within the form state in order to be easly
		accessible from external form's fields (Input, Collection etc..) 
	*/
	const refEditor = useRef(null);
	useField({
		type: 'custom',
		name: 'refEditor',
		value: refEditor
	})

	return (
		<div className="Editor">
			<Editor
				ref={refEditor}
				editorState={value}
				onChange={(e) => onEditorChange(e)}
				placeholder="Bugün eğitici ne paylaşmak istersiniz?"
			/>
		</div>
	)
}
