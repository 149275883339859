import React, { useState, useEffect } from 'react'
import styled  from 'styled-components'
import { motion } from 'framer-motion'
import Grid from '@material-ui/core/Grid'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

const PlayersList = styled.div`border: solid 1px gainsboro; border-radius: 8px; width: calc(100% - 20px); margin-left: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(30, 30, 30, 0.25);
	@media (max-width: 767px) {
		width: calc(100% - 4px); margin-left: 2px;
	}
`
const ListHeader = styled(Grid)`width: 100%; border-bottom: solid 1px gainsboro;`
const ListTitle = styled.div`float: left; font-weight: bold; font-size: 30px; margin-left: 10px; text-align: left; line-height: 60px;
	@media (max-width: 767px) {
		font-size: 24px; line-height: 40px;
	}
`
const ListCount = styled.div`float: left; font-weight: bold; font-size: 60px; color: #1a53ff; margin-right: 10px; text-align: left;
	@media (max-width: 767px) {
		font-size: 40px;
	}
`
const PlayerListContent = styled.div`overflow: auto; width: 100%;
	height: ${(props) => props.height ? props.height : 'calc(100vh - 150px)'}; 
	@media (max-width: 767px) {
		font-size: calc(100vh - 165px);
	}
`
const BackgroundDiv = styled.div`z-index: -1; min-height: 100%;
	background-image: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.3)), url('/images/backgrounds/back-1.png'); 
	background-repeat: no-repeat; background-attachment: fixed;
`
const PlayerNameItem = styled.div`float: left; width: calc(100% - 12px); height: 50px; text-align: center; border-radius: 18px; overflow: hidden; position: relative; margin-left: 6px; margin-top: 8px; margin-bottom: 8px; opacity: 1;`
const PlayerNameText = styled.div`font-weight: bold; font-size: 18px; margin: 5px; text-align: center; overflow: hidden; color: white; opacity: 1;
	@media (max-width: 767px) {
		font-size: 12px;
	}
` 

const backColorList = ['#3366FF', '#001f3f', '#9966FF', '#08AEEA', '#FF4136', '#FF851B', '#85144b', '#3D9970', '#A01A7D', '#E09F7D', '#EC4067', '#07020D', '#716A5C', '#26547C', '#EF475F']

function ConnectedUsers(props) {
	const [players, setPlayers] = useState([])
	const [playersUI, setPlayersUI] = useState([])

	useEffect(() => {
		let tempPlayers = props.game.players
		if (!tempPlayers) {
			tempPlayers = [];
		} else {
			tempPlayers = Object.values(tempPlayers);
		}
		setPlayers(tempPlayers)
	}, [props.game.players])

	useEffect(() => {
		let tempList = []
		players.map((player, index) => {			
			tempList.push(
				<Grid item xs={6} sm={3} md={2} key={index}>
					<motion.div initial={{ scale: 0 }} animate={{ rotate: 360, scale: 1 }} transition={{ type: "spring", stiffness: 260, damping: 20 }} >
						<motion.div
							animate={props.animate ? { scale: [1, 1.03, 1], x: [0, 3, 0], y: [0, 3, 0], } : {}}
    						transition={props.animate ? { duration: 0.8, loop: Infinity, repeatDelay: 0, times: [0, 0.4, 0.8]}: {}}
						>
							<PlayerNameItem style={{backgroundColor: backColorList[index%15] }} id={`p_${index}`}>
								<PlayerNameText>
									{player.email && player.email.length > 0 ? <VerifiedUserIcon style={{color: '#00fc04', fontSize: '16px'}} /> : ''}&nbsp;
									{player.name}
								</PlayerNameText>									
							</PlayerNameItem>
						</motion.div>
					</motion.div>
				</Grid>
			)
			return null
		})
		setPlayersUI(tempList)
	}, [players])

	useEffect(() => {
		if (playersUI && playersUI.length > 10) {
			let element = document.getElementById(`p_${players.length - 4}`)
			if (element){
				element.scrollIntoView()
			}
		}
	}, [playersUI])

	return (
		<PlayersList>
			<ListHeader container>
				<Grid item xs={9} sm={6}>
					<ListTitle>Katılımcılar</ListTitle>
				</Grid>
				<Grid item xs={3} sm={6}>
					<ListCount>{players.length}</ListCount>
				</Grid>
			</ListHeader>	
				
			<PlayerListContent height={props.height}>
				<BackgroundDiv>
					<Grid container>
						<Grid item xs={12}>
							<Grid container style={{overflow: 'hidden'}}>
								{playersUI}
							</Grid>
						</Grid>
					</Grid>
				</BackgroundDiv>
			</PlayerListContent>
		</PlayersList>
	)
}

export { ConnectedUsers }