import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import Input from '@material-ui/core/Input'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DeleteIcon from '@mui/icons-material/Delete'
import { H1 } from '../../components'
import { ExcludedPartItem } from './ExcludedPartItem'

const VideoLine = styled.div`
	border:  ${(props) => props.showAdvanced ? 'solid 1px gainsboro' : '0px'};
	border-radius:  ${(props) => props.showAdvanced ? '6px' : '0px'};
	margin-top:  ${(props) => props.showAdvanced ? '20px' : ''};
	margin-bottom:  ${(props) => props.showAdvanced ? '20px' : ''};
	background-color:  ${(props) => props.showAdvanced ? 'white' : ''};
`

function PlaylistVideoItem(props) {
	const [video, setVideo] = useState(props.video)
	const [showAdvanced, setShowAdvanced] = useState(false)

	useEffect(async () => {
		if (props.video) {
			setVideo(props.video)
		}
	}, [props.video])

	const onVideoURLChange = (newValue) => {
		if (newValue !== video.url) {
			let tmpVideo = {...video}
			tmpVideo.url = newValue
			setVideo(tmpVideo)
		}
	}

	const onVideoNameChange = (newValue) => {
		if (newValue !== video.name) {
			let tmpVideo = {...video}
			tmpVideo.name = newValue
			setVideo(tmpVideo)
		}
	}

	const addToPlaylist = () => {
		if (video && video.url && video.url.length > 0 && video.name && video.name.length > 0 && props.onVideoUpdate) {
			props.onVideoUpdate(video)
		}
	}

	const updateExcludedData = (newData, index) => {
		if (newData !== video.excludedParts[index]) {
			let tmpVideo = {...video}
			tmpVideo.excludedParts[index] = newData
			setVideo(tmpVideo)
			props.onVideoUpdate(tmpVideo)
		}
	}

	const removeExcludedPart = (_data) => {
		let tmpVideo = {...video}		
		let tempExcludedParts = tmpVideo.excludedParts.filter(x => x.id !== _data.id)
		tmpVideo.excludedParts = (tempExcludedParts && tempExcludedParts.length > 0) ? tempExcludedParts : [{ id: 0, start: 0, end: 0 }]
		console.log('tmpVideo: ', tmpVideo)
		setVideo(tmpVideo)
		props.onVideoUpdate(tmpVideo)
	}

	const addExcludedPart = (newData) => {
		let tmpVideo = {...video}
		if (tmpVideo.excludedParts && tmpVideo.excludedParts.length > 0) {
			tmpVideo.excludedParts.push(newData)
		} else {
			tmpVideo.excludedParts = [newData]
		}
		setVideo(tmpVideo)
		props.onVideoUpdate(tmpVideo)
	}

	return (
		<div>
			{
				props.firstLine &&
					<div style={{borderBottom: 'solid 2px black'}}>
						<div style={{float: 'left', width: '28px', fontSize: '12px', fontWeight: 'bold', paddingTop: '10px' }}>
							
						</div>
						<div style={{float: 'left', width: 'calc(49% - 22px)', fontWeight: 'bold' }}>
							Video Adı
						</div>
						<div style={{float: 'left', width: 'calc(49% - 22px)', marginLeft: '4px', fontWeight: 'bold'}}>
							URL
						</div>
						<div style={{float: 'right', width: '24px', marginLeft: '4px', paddingTop: '12px'}}>
							
						</div>
						<div style={{clear: 'both'}}></div>
					</div>
			}
			{
				props.newAddition &&
					<div>
						<div style={{float: 'left', width: '28px', fontSize: '12px', fontWeight: 'bold', paddingTop: '10px' }}></div>
						<div style={{float: 'left', width: 'calc(49% - 14px)' }}>
							<Input type="text" onChange={(e) => onVideoNameChange(e.target.value)} value={video.name} onBlur={() => { addToPlaylist() } }
								style={{width: '100%', fontFamily: 'Varela Round' }} 
							/>
						</div>
						<div style={{float: 'left', width: 'calc(49% - 14px)', marginLeft: '4px'}}>
							<Input type="text" onChange={(e) => onVideoURLChange(e.target.value)} value={video.url} onBlur={() => { addToPlaylist() } }
								style={{width: '100%', fontFamily: 'Varela Round' }} 
							/>
						</div>
						<div style={{clear: 'both'}}></div>
					</div>
			}
			{
				video && !props.newAddition &&
					<VideoLine showAdvanced={showAdvanced}>
						<div style={{float: 'left', width: '28px', fontSize: '12px', fontWeight: 'bold', paddingTop: '10px', paddingLeft: '10px' }}>
							
							{video.id + 1}- &nbsp;
						</div>
						<div style={{float: 'left', width: 'calc(49% - 26px)' }}>
							<Input type="text" onChange={(e) => onVideoNameChange(e.target.value)} value={video.name} onBlur={() => { props.onVideoUpdate && props.onVideoUpdate(video) } }
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
						</div>
						<div style={{float: 'left', width: 'calc(49% - 26px)', marginLeft: '4px'}}>
							<Input type="text" onChange={(e) => onVideoURLChange(e.target.value)} value={video.url} onBlur={() => { props.onVideoUpdate && props.onVideoUpdate(video) } }
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
						</div>
						<div style={{float: 'right', width: '24px', marginLeft: '4px', marginRight: '4px', paddingTop: '12px', textAlign: 'center'}}>
							<KeyboardArrowDownIcon onClick={() => setShowAdvanced(true) } style={{display: (showAdvanced ? 'none' : 'block'), cursor: 'pointer' }} />
							<KeyboardArrowUpIcon onClick={() => setShowAdvanced(false) } style={{display: (showAdvanced ? 'block' : 'none'), cursor: 'pointer' }} />
						</div>
						<div style={{clear: 'both'}}></div>
						<div style={{display: (showAdvanced ? 'block' : 'none') }}>
							<Grid container>
								<Grid item xs={12} sm={6}>
									<H1>Excluded Seconds</H1>
									{
										video.excludedParts && video.excludedParts.length > 0 ?
											video.excludedParts.map((excludedPart, index) =>
												<ExcludedPartItem key={index} excludedPart={excludedPart} onUpdate={(newData) => updateExcludedData(newData, index) } onDelete={(newData) => {removeExcludedPart(newData)} } firstLine={index === 0 ? true : false} />												
											)
										:
											<div>No excluded parts.</div>
									}
									<ExcludedPartItem excludedPart={{id: (video.excludedParts ? video.excludedParts.length : 0), start: 0, end: 0}} onUpdate={(newData) => addExcludedPart(newData) } newAddition={true} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<div style={{float: 'right', marginTop: '30px'}}>
										Delete Video ? <DeleteIcon onClick={() => { props.onDeleteVideo && props.onDeleteVideo(video)} } style={{fontSize: '14px', color: 'red', cursor: 'pointer'}} />
									</div>
									<div style={{clear: 'both'}}></div>
								</Grid>
							</Grid>
						</div>
					</VideoLine>
			}
		</div>
	)
}

export { PlaylistVideoItem }
