import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@mui/material/TextField'
import ClearIcon from '@material-ui/icons/Clear'
import { H1, SimpleTextListManager, FormElementLabel, FormElementDesc } from '../../components'
import { AuthContext } from '../../data/context/AuthProvider'
import FileUpload from '../../data/firebase/fileUploadFirebase'
// import { storage } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const emptyFileObj = {url: '', fileName: ''}

function PageSettings(props) {
	const [authData] = useContext(AuthContext)

	const [page, setPage] = useState(props.page)

	useEffect(() => {
		setPage(props.page)
	}, [props.page.id])

	const updatePageData = (fieldName, fieldValue) => {
		let tmpPage = {...page}
		tmpPage[fieldName] = fieldValue
		setPage(tmpPage)
		if (props.onUpdatePageData) {
			props.onUpdatePageData(tmpPage)
		}
	}
	const updatePageCategoryData = (fieldName, fieldValue) => {
		let tmpPage = {...page}
		if (!tmpPage.category) {tmpPage.category = {}}
		tmpPage.category[fieldName] = fieldValue
		setPage(tmpPage)
		if (props.onUpdatePageData) {
			props.onUpdatePageData(tmpPage)
		}
	}
	const updatePageIntroData = (fieldName, fieldValue) => {
		let tmpPage = {...page}
		if (!tmpPage.introDetails) {tmpPage.introDetails = {}}
		tmpPage.introDetails[fieldName] = fieldValue
		setPage(tmpPage)
		if (props.onUpdatePageData) {
			props.onUpdatePageData(tmpPage)
		}
	}
	const updatePageIntroImageData = (fieldName, fieldValue) => {
		let tmpPage = {...page}
		if (!tmpPage.introDetails) { tmpPage.introDetails = {} }
		if (!tmpPage.introDetails.images) { tmpPage.introDetails.images = {} }
		tmpPage.introDetails.images[fieldName] = fieldValue
		setPage(tmpPage)
		console.log('tmpPage: ', tmpPage)
		if (props.onUpdatePageData) {
			props.onUpdatePageData(tmpPage)
		}
	}

	const updatePageUrlsData = (fieldName, fieldValue) => {
		let tmpPage = {...page}
		if (!tmpPage.urls) { tmpPage.urls = {} }
		tmpPage.urls[fieldName] = fieldValue
		setPage(tmpPage)
		if (props.onUpdatePageData) {
			props.onUpdatePageData(tmpPage)
		}
	}

	const deleteFile = async (fileName) => {
		let firebaseObj = await getFirebaseObj('PageSettings fbo_PageBlocks')
		let storage = firebaseObj.storage
	
		const fileRef = storage.ref(`/PAGES/${page.id}/INTRO/${fileName}`)
		fileRef.delete().then(() => {

		}).catch((error) => {
			console.log('File could not be deleted.')
		})
	}

	

	return (		
		<div>
			{
				authData.email === page.owner &&
					<Grid container>											
						<Grid item xs={12} style={{paddingTop: '5px'}}>
							<TextField type="text" onChange={(e) => updatePageData('slug', e.target.value)} value={page.slug} label="Slug" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın yayınlanacağı, url eki </FormElementDesc>
						</Grid>
						
						<Grid item xs={12} style={{paddingTop: '20px'}}>
							<FormElementLabel>Tanıtım Bilgileri</FormElementLabel>
						</Grid>
						<Grid item xs={12} style={{paddingTop: '5px'}}>
							<TextField type="text" onChange={(e) => updatePageIntroData('catchPhrase', e.target.value)} value={(page.introDetails && page.introDetails) ? page.introDetails.catchPhrase : ''} label="Slogan" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın sloganı</FormElementDesc>
						</Grid>
						
						<Grid item xs={12} style={{paddingTop: '20px'}}>
							<FormElementLabel>Sayfa Resimleri</FormElementLabel>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '5px'}}>
							{
								page.introDetails && page.introDetails.images && page.introDetails.images.cover1 && page.introDetails.images.cover1.url && page.introDetails.images.cover1.url.length > 0 ?
									<div>
										<img src={page.introDetails.images.cover1.url} style={{width: '250px'}} alt="Cover 1" />
										<ClearIcon color="action" onClick={() => { deleteFile(page.introDetails.images.cover1.fileName); updatePageIntroImageData('cover1', emptyFileObj);  }} title="Sil" style={{float: 'right'}} />
									</div>
								:
									<FileUpload folder={`/PAGES/${page.id}/INTRO`} fileType="image" containerType="dropzone" type="page-cover-1"
										height="300px" margin="auto" 
										onUploadComplete={(e) => { updatePageIntroImageData('cover1', { url: e.url, fileName: e.fileName} )} }
									/>
							}
							<FormElementDesc>Bu sayfanın üst tanıtım resmi 1</FormElementDesc>
						</Grid>						

						<Grid item xs={12} sm={6} style={{paddingTop: '5px'}}>
							{
								page.introDetails && page.introDetails.images && page.introDetails.images.cover2 && page.introDetails.images.cover2.url && page.introDetails.images.cover2.url.length > 0 ?
									<div>
										<img src={page.introDetails.images.cover2.url} style={{width: '250px'}} alt="Cover 2" />
										<ClearIcon color="action" onClick={() => { deleteFile(page.introDetails.images.cover2.fileName); updatePageIntroImageData('cover2', emptyFileObj);  }} title="Sil" style={{float: 'right'}} />
									</div>
								:
									<FileUpload folder={`/PAGES/${page.id}/INTRO`} fileType="image" containerType="dropzone" type="page-cover-2"
										height="300px" margin="auto" 
										onUploadComplete={(e) => { updatePageIntroImageData('cover2', { url: e.url, fileName: e.fileName} )} }
									/>
							}
							<FormElementDesc>Bu sayfanın üst tanıtım resmi 2</FormElementDesc>
						</Grid>

						<Grid item xs={12} sm={6} style={{paddingTop: '5px'}}>
							{
								page.introDetails && page.introDetails.images && page.introDetails.images.fullLogo && page.introDetails.images.fullLogo.url && page.introDetails.images.fullLogo.url.length > 0 ?
									<div>
										<img src={page.introDetails.images.fullLogo.url} style={{width: '250px'}} alt="Full Logo" />
										<ClearIcon color="action" onClick={() => { deleteFile(page.introDetails.images.fullLogo.fileName); updatePageIntroImageData('fullLogo', emptyFileObj);  }} title="Sil" style={{float: 'right'}} />
									</div>
								:
									<FileUpload folder={`/PAGES/${page.id}/INTRO`} fileType="image" containerType="dropzone" type="fullLogo"
										height="300px" margin="auto" 
										onUploadComplete={(e) => { updatePageIntroImageData('fullLogo', { url: e.url, fileName: e.fileName} )} }
									/>
							}
							<FormElementDesc>Bu sayfanın üst tanıtım tam logosu</FormElementDesc>
						</Grid>

						<Grid item xs={12} sm={6} style={{paddingTop: '5px'}}>
							{
								page.introDetails && page.introDetails.images && page.introDetails.images.profile && page.introDetails.images.profile.url && page.introDetails.images.profile.url.length > 0 ?
									<div>
										<img src={page.introDetails.images.profile.url} style={{width: '250px'}} alt="Profile" />
										<ClearIcon color="action" onClick={() => { deleteFile(page.introDetails.images.profile.fileName); updatePageIntroImageData('profile', emptyFileObj);  }} title="Sil" style={{float: 'right'}} />
									</div>
								:
									<FileUpload folder={`/PAGES/${page.id}/INTRO`} fileType="image" containerType="dropzone" type="profile"
										height="300px" margin="auto" 
										onUploadComplete={(e) => { updatePageIntroImageData('profile', { url: e.url, fileName: e.fileName} )} }
									/>
							}
							<FormElementDesc>Bu sayfanın profil resmi</FormElementDesc>
						</Grid>


						<Grid item xs={12} style={{paddingTop: '20px'}}>
							<FormElementLabel>Sosyal Medya ve Web</FormElementLabel>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '10px'}}>
							<TextField type="text" onChange={(e) => updatePageUrlsData('whatsapp', e.target.value)} value={(page.urls && page.urls.whatsapp) ? page.urls.whatsapp : ''} label="Whatsapp" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın Whatsapp grubu</FormElementDesc>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '10px'}}>
							<TextField type="text" onChange={(e) => updatePageUrlsData('telegram', e.target.value)} value={(page.urls && page.urls.telegram) ? page.urls.telegram : ''} label="Telegram" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın Telegram grubu</FormElementDesc>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '10px'}}>
							<TextField type="text" onChange={(e) => updatePageUrlsData('domain', e.target.value)} value={(page.urls && page.urls.domain) ? page.urls.domain : ''} label="Domain" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın domain adı</FormElementDesc>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '10px'}}>
							<TextField type="text" onChange={(e) => updatePageUrlsData('website', e.target.value)} value={(page.urls && page.urls.website) ? page.urls.website : ''} label="Website" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın web sitesi</FormElementDesc>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '10px'}}>
							<TextField type="text" onChange={(e) => updatePageUrlsData('facebook', e.target.value)} value={(page.urls && page.urls.facebook) ? page.urls.facebook : ''} label="Facebook" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın Facebook adresi</FormElementDesc>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '10px'}}>
							<TextField type="text" onChange={(e) => updatePageUrlsData('twitter', e.target.value)} value={(page.urls && page.urls.twitter) ? page.urls.twitter : ''} label="Twitter" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın Twitter adresi</FormElementDesc>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '10px'}}>
							<TextField type="text" onChange={(e) => updatePageUrlsData('instagram', e.target.value)} value={(page.urls && page.urls.instagram) ? page.urls.instagram : ''} label="Instagram" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın Instagram adresi</FormElementDesc>
						</Grid>

						<Grid item xs={12} style={{paddingTop: '20px'}}>
							<FormElementLabel>Sayfa Kategorisi</FormElementLabel>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '5px'}}>
							<TextField type="text" onChange={(e) => updatePageCategoryData('main', e.target.value)} value={(page.category && page.category.main) ? page.category.main : ''} label="Ana Kategori" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın ana kategorisi</FormElementDesc>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '5px'}}>
							<TextField type="text" onChange={(e) => updatePageCategoryData('sub', e.target.value)} value={(page.category && page.category.sub) ? page.category.sub : ''} label="Alt Kategori" variant="standard"
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
							<FormElementDesc>Bu sayfanın alt kategorisi</FormElementDesc>
						</Grid>


						<Grid item xs={12} style={{paddingTop: '25px'}}></Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '5px'}}>
							<FormElementLabel>Yayına İzin Verilen Domainler</FormElementLabel>
							<FormElementDesc>Bu kursun hangi web sitelerinde yayınlanmasını istiyorsanız, domain adreslerini ekleyiniz.</FormElementDesc>
							<SimpleTextListManager itemList={(page.allowedHostNames) ? page.allowedHostNames : []} editable={true} onePerLine={true} showNumbers={true}
								onChange={(items) => { updatePageData('allowedHostNames', items) } }
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '5px', paddingLeft: '15px'}}>
							<FormElementLabel>Kategoriler</FormElementLabel>
							<FormElementDesc>Listelerin birlikte görüntülenmesini sağlayacak kategoriler belirleyebilirsiniz.</FormElementDesc>
							<SimpleTextListManager itemList={(page.categories) ? page.categories : []} editable={true} onePerLine={true} showNumbers={true}
								onChange={(items) => { updatePageData('categories', items) } }
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{paddingTop: '5px'}}>
							<FormElementLabel>Editorler</FormElementLabel>
							<FormElementDesc>Listeyi düzenleme yetkisine sahip kullanıcıların email adreslerini ekleyebilirsiniz.</FormElementDesc>
							<SimpleTextListManager itemList={(page.editors) ? page.editors : []} editable={true} onePerLine={true} showNumbers={true}
								onChange={(items) => { updatePageData('editors', items) } }
							/>
						</Grid>
					</Grid>
			}
			{
				page.editors && (page.editors.length > 0) && (page.editors.indexOf(authData.email) >= 0) && (authData.email !== page.owner) &&
					<div>
						<div>
							<H1>Kategoriler: </H1>
							{
								page.categories.map((_category, index) => 
									<div key={index} style={{borderRadius: '6px', backgroundColor: '#5e54df', marginLeft: '10px', float: 'left', padding: '10px', color: 'white', marginTop: '10px'}}>
										{_category}
									</div>
								)
							}
						</div>
						<div style={{clear: 'both'}}></div>
					</div>
			}
		</div>
	)
}

export { PageSettings}
