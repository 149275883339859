import axios from 'axios'
import moment from 'moment'
import Resizer from 'react-image-file-resizer'
import urlMetadata from 'url-metadata'

const getGeoInfo = async (_callback) => {
	try {
		let response = await axios.get('https://ipapi.co/json/')
		return response.data
	} catch (error) {
		console.log(error)
		return(null)
	}
}

const getDateFromUnixSeconds = (seconds) => {
	let tempDate = moment(new Date('1/1/1970 00:00:00 UTC').setSeconds(seconds))
	return tempDate
}


function random(min, max) { // min and max included 
	return Math.floor(Math.random() * (max - min + 1) + min);
}

function removeNonEnglishChars(text) {
	let newText = text.toLowerCase()
	const charsToRemove = ['ı', 'İ', 'ğ', 'Ğ', 'ö', 'Ö', 'ç', 'Ç', 'ü', 'Ü', 'ş', 'Ş']
	const charsToReplace = ['i', 'i', 'g', 'g', 'o', 'o', 'c', 'c', 'u', 'u', 's', 's']
	
	for (let i = 0; i < newText.length; i++) {
		let charIndex = charsToRemove.indexOf(newText[i])
		if (charIndex > -1) {
			let re = new RegExp(newText[i], "g")
			newText = newText.replace(re, charsToReplace[charIndex])
		}
	}
	return newText
}

const createSlug = (text) => {
	let newText = text.replace('İ', 'i').toLowerCase()
	newText = removeNonEnglishChars(newText)

	var invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_üğışç ö]+/g;
	newText = newText.replace(invalid, "-");
	
	return newText
}

const convertImgURLToDataURLviaCanvas = (url, callback) => {
	var xhr = new XMLHttpRequest()
	xhr.onload = function() {
		var reader = new FileReader()
		reader.onloadend = function() {
			callback(reader.result)
			// return reader.result
		}
		reader.readAsDataURL(xhr.response)
	}
	xhr.open('GET', url)
	xhr.responseType = 'blob'
	xhr.send()
}

const base64toBlob = (b64Data, contentType='', sliceSize=512) => {
	const byteCharacters = atob(b64Data)
	const byteArrays = []
  
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize)
			
		const byteNumbers = new Array(slice.length)
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}	
		const byteArray = new Uint8Array(byteNumbers)
		byteArrays.push(byteArray)
	}
  
	const blob = new Blob(byteArrays, {type: contentType})
	return blob
}

const resizeImage = (file, width, height) => new Promise(resolve => {
	Resizer.imageFileResizer(file, width, height, 'JPEG', 100, 0, uri => { resolve(uri) }, 'base64')
})

const getFirstLinkInsideText = async (plainText) => {
	const detectURL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
	const urlsList = plainText ? plainText.match(detectURL) : []
	const firstURL = urlsList && urlsList.slice && urlsList.slice(-1)[0]
	return firstURL
}

const getFirstLinkMetaInsideText = async (plainText) => {
	const detectURL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
	const cacheURLs = {}

	const urlsList = plainText ? plainText.match(detectURL) : []
	const previewURL = urlsList && urlsList.slice && urlsList.slice(-1)[0]
	
	if (!previewURL) return null
	
	if (!cacheURLs[previewURL]) {
		cacheURLs[previewURL] = await getMetaDataOfUrl(previewURL)
	}
	return cacheURLs[previewURL]
}

const getMetaDataOfUrl = async (url) => {
	const urlObj = new URL(url)
	let newURL = `https://us-central1-yesil-okul-web-app.cloudfunctions.net/prx/${urlObj.hostname}${urlObj.protocol === 'https:' ? ':443' : ''}${urlObj.pathname}${urlObj.search ? ('?' + urlObj.search) : ''}`
	let result = await urlMetadata(newURL)
	console.log('Url meta result: ', result)
	return result
}

export { getGeoInfo, getDateFromUnixSeconds, random, removeNonEnglishChars, createSlug, convertImgURLToDataURLviaCanvas, base64toBlob, resizeImage, getFirstLinkInsideText, getFirstLinkMetaInsideText, getMetaDataOfUrl }
