import React from 'react'
import styled from 'styled-components'
import { CountdownAnimation } from '../../../components'

const StartMessage = styled.div`font-size: 60px; margin-top: 80px; font-weight: bold; width: 100%; text-align: center;`

function PhaseStarting(props) {    
    return (
        <div>
            <StartMessage>Başlıyoruz...</StartMessage>
            <CountdownAnimation speed="slow" />
        </div>
    )
}

export default PhaseStarting
