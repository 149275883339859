// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const getAllStaffStats = async (email) => {
	let firebaseObj = await getFirebaseObj('getAllStaffStats')
	let firestore = firebaseObj.firestore

	let tempStaffStatsList = []
	if (email && email.length > 0 && email === 'yesilokulcom@gmail.com') {
		let sStaffStats = await firestore.collection('stats').where('object', '==', 'staff').get()
		sStaffStats.docs.map(doc => {
			tempStaffStatsList.push(doc.data())
			return null
		})
	}
	return tempStaffStatsList
}

const getStaffStats = async (email) => {
	let firebaseObj = await getFirebaseObj('getStaffStats')
	let firestore = firebaseObj.firestore

	let tempStaffStats = []
	if (email && email.length > 0) {
		let sStaffStats = await firestore.collection('stats').where('object', '==', 'staff').where('email', '==', email).get()
		sStaffStats.docs.map(doc => {
			tempStaffStats.push(doc.data())
			return null
		})
	}
	return tempStaffStats
}

const updateStaffStats = async (updatedStats) => {
	let firebaseObj = await getFirebaseObj('updateStaffStats')
	let firestore = firebaseObj.firestore

	let userDocRef = await firestore.collection('stats').doc(updatedStats.email)
	await userDocRef.set(updatedStats)
}

export { getAllStaffStats, getStaffStats, updateStaffStats }
