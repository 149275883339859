import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import AnswerOption from './AnswerOption'
import FileUpload from '../../../data/firebase/fileUploadFirebase'
import { BaseInput } from '../../../components'

function AnswerOptionList(props) {
	const [freeTextAnswer, setFreeTextAnswer] = useState('')

	return (
		<Grid container className={props.containerClassName ? props.containerClassName : 'align-bottom' }>
		{
			(props.question.aType === 'multiple' || props.question.aType === 'boolean' || props.question.aType === 'order-puzzle') && props.question.answerOptions && (
				props.question.answerOptions.map((answerOption, index) => {
					if (index < 10) {
						let answerItem
						
						if (answerOption && ((answerOption.optionText && answerOption.optionText.length > 0) || ( answerOption.optionImage && answerOption.optionImage.url.length > 0 ) )) {
							if (props.question.answerOptions.length >= 5) {
								let l = props.question.answerOptions.length
								let widthPercent = (l === 5 ? '20%' : (l === 6 ? '33%' : (l === 7  ? '25%' : (l === 8 ? '25%' : (l === 9 ? '20%' : '20%') ))))
								
								answerItem = (
									<div style={{float: 'left', width: widthPercent}} key={index}>
										<AnswerOption answer={answerOption} aType={props.question.aType} index={index} answerCount={props.question.answerOptions.length}
											answerQuestion={(a) => { if(props.answerQuestion) { props.answerQuestion(a)} } }
										/>
									</div>
								)
							} else {
								answerItem = (
									<Grid key={index} item xs={(props.question.answerOptions.length > 4) ? 6 : 12} sm={6}>
										<AnswerOption answer={answerOption} aType={props.question.aType} index={index} answerCount={props.question.answerOptions.length}
											answerQuestion={(a) => { if(props.answerQuestion) { props.answerQuestion(a)} } }
										/>
									</Grid>
								)
							}
						}
						return answerItem
					} else {
						return null
					}
				})
			)
		}

		{
			(props.question.aType === 'free-text') &&
				<Grid item xs={12}>
					{
						props.answerQuestion &&
							<div>
								<BaseInput height="100px" width="calc(100% - 80px)" fontSize="36px" onChange={(a) => { setFreeTextAnswer(a) } } />
								<Fab color="primary" aria-label="add" onClick={() =>  { if (props.answerQuestion) { props.answerQuestion({ freeText: freeTextAnswer } )} } }>
									<NavigateNextIcon />
								</Fab>
							</div>
					}
				</Grid>
		}
		{
			(props.question.aType === 'image-upload') && (
				props.answerQuestion ? ( // If there is answer function it is player and has to show file upload
					(props.player.email && props.player.email.length > 0) ? // If user logged in show file upload
						<Grid item xs={12}>
							<FileUpload folder={`/STUDENT-FILES/IN-LESSON-UPLOADS/${props.player.email}`} fileType="image" containerType="dropzone" type="student-answer-file"
								height="calc(100vh - 650px)" margin="auto" 
								onUploadComplete={(e) => { props.answerQuestion({uploadedImage: e} ) } }
							/>
						</Grid>
						: // User not logged in, feature unavailable
						<Grid item xs={12}>
							<span>Dosya gönderme özelliği sadece kayıtlı kullanıcılar için çalışmaktadır. Sonraki derslerde sisteme kullanıcı adı ve şifreniz ile girmenizi öneririz </span>
						</Grid>
				)
				: // Host only see a message about players
					<Grid item xs={12}>
						<span>Öğrencilerin dosya yüklemeleri bekleniyor. </span>
					</Grid>
			)
				
		}		
		</Grid>
	)
}

export default AnswerOptionList
