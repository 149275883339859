import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { ReactSortable } from 'react-sortablejs'
import { BaseButton, TextBoxListItem, H1 } from '../../../components'

const NewButton = styled(BaseButton)`width: 100px; height: 20px; font-size: 12px; color: white;`

let changeFromInside = false
function MetaList(props) {
	const [itemList, setItemList] = useState(props.items)

	useEffect(() => {
		if (changeFromInside) {
			props.onUpdateList(props.itemType, itemList)
		}
	}, [itemList])

	const updateItem = (value, index) => {
		if (itemList[index].value !== value) {
			let tempList = [...itemList]
			tempList[index] = {value: value}
			changeFromInside = true
			setItemList(tempList)
		}
	}

	const addNewItem = () => {
		let tempList = [...itemList]
		tempList.push({value: ''})
		changeFromInside = true
		setItemList(tempList)
	}

	const deleteItem = (index) => {
		let tempList = [...itemList]
		tempList.splice(index, 1)

		changeFromInside = true
		setItemList([...tempList])
	}

	const reOrderItems = (newOrderedList) => {
		let changed = false
		for (let i = 0; i < newOrderedList.length; i++) {
			if (newOrderedList[i].value !== itemList[i].value) {
				changed = true
			}
		}
		if (changed) {
			let tempItems = []
			for (let i = 0; i < newOrderedList.length; i++ ) {
				let tempItem = { value: newOrderedList[i].value }
				if (tempItem) {
					tempItems.push(tempItem)
				}
			}
			changeFromInside = true
			setItemList([...tempItems])
		}
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<p style={{margin: '20px'}}>Burada yazdığınız açıklamalar, öğrencilerin kursunuzun onlara uygun olup olmadığına karar vermesine yardımcı olur.</p>
			</Grid>
			<Grid item xs={12}>
				<H1>{props.text1}</H1>
			</Grid>
			
			<Grid item xs={12}>
				{
					(itemList && itemList.length > 0 && props.editable) ?
						<ReactSortable list={itemList} setList={(e) => {reOrderItems(e)}} delayOnTouchStart={true} delay={10} filter={"input"} preventOnFilter={false}>
							{
								itemList.map((item, index) => (
									<TextBoxListItem itemValue={item.value} itemIndex={index} onUpdateItem={(value, index) => {updateItem(value, index)}} onDeleteItem={(index) => deleteItem(index)} key={index} updated={item.updated} />
								))
							}
						</ReactSortable>
					:
						<div>
							{
								itemList.map((item, index) => (
									<div>{item.value}</div>
								))
							}
						</div>
				}
			</Grid>
			{
				props.editable &&
					<Grid item xs={12} style={{textAlign: 'right'}}>
						<NewButton type="button" onClick={() => addNewItem()} value="Cevap Ekleyin" />&nbsp;&nbsp;
					</Grid>
			}
		</Grid>
	)
}

export default MetaList
