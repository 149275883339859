// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'
import { createSlug } from '../../utils/utilityFunctions'

const getPages = async (userEmail) => {
	let firebaseObj = await getFirebaseObj('getPages')
	let firestore = firebaseObj.firestore

	let tempPages = []
	if (userEmail === 'yesilokulcom@gmail.com') {
		let sPages = await firestore.collection('pages').get()
		sPages.docs.map(doc => {
			tempPages.push(doc.data())
			return null
		})
	} else {
		let sPages = await firestore.collection('pages').where('owner', '==', userEmail).get()
		sPages.docs.map(doc => {
			tempPages.push(doc.data())
			return null
		})

		sPages = await firestore.collection('pages').where('editors', 'array-contains-any', [userEmail]).get()
		sPages.docs.map(doc => {
			tempPages.push(doc.data())
			return null
		})
	}

	tempPages.sort(function(a,b) {
		if (a.title < b.title) return 1
		if (a.title > b.title) return -1
		return 0
	})

	return (tempPages)
}

const getPageBySlug = async (slug) => {
	let firebaseObj = await getFirebaseObj('getPageBySlug')
	let firestore = firebaseObj.firestore

	let tempPages = []
	let sPages = await firestore.collection('pages').where('slug', '==', slug).get()
	sPages.docs.map(doc => {
		tempPages.push(doc.data())
		return null
	})

	let tmpPage
	if (tempPages.length > 0) {
		tmpPage = tempPages[0]
	}

	return tmpPage
}

const addPage = async (pageName, ownerUser) => {
	let firebaseObj = await getFirebaseObj('addPage')
	let firestore = firebaseObj.firestore

	let newDocRef = await firestore.collection('pages').doc()
	const newDocId = newDocRef.id

	let newPage = {
		id: newDocId,
		owner: ownerUser,
		title: pageName,
		slug: createSlug(pageName),
		introDetails: {
			catchPhrase: '',
		}
	}
	newDocRef.set(newPage)

	return newPage
}

const updatePage = async (pageData) => {
	let firebaseObj = await getFirebaseObj('updatePage')
	let firestore = firebaseObj.firestore

	let docRef = await firestore.collection('pages').doc(pageData.id)
	await docRef.set(pageData)
}

export { getPages, getPageBySlug, addPage, updatePage}
