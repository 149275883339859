import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { getStaffStats } from '../../data/firebase/statsFirebase'
import { getUserInfoByEmail, getMyMenteeStudentList } from '../../data/firebase/userFirebase'
import { getCoursesOfStaff } from '../../data/firebase/courseFirebase'
import { H1 } from '../../components'
import { getStudentCoursesOfStudentEmailList } from '../../data/firebase/studentCoursesFirebase'
import { getUniqueStudentEmailsFromStudentList,
	addStudentCoursesToStudents } from '../../data/utils/studentUtils'
import { LessonListTimeTable } from '../timetable/LessonListTimeTable'
import { StaffStats } from './StaffStats'
import { StaffCourseListItem } from './StaffCourseListItem'
import { StudentList } from '../student/StudentList'
import { AuthContext } from '../../data/context/AuthProvider'
import { Toggle } from '../../components'

const tabs = [
	{ id: 'stats', label: 'İstatistik' },
	{ id: 'courses', label: 'Kurslarım' },
	{ id: 'mentees', label: 'Rehberlik Öğrencilerim' },
	{ id: 'lesson-list-time-table', label: 'Ders Takvimi' },
]


function StaffDetails(props) {
	const [authData] = useContext(AuthContext)

	const [tab, setTab] = useState('stats')
	const [userData, setUserData] = useState()
	const [staffStats, setStaffStats] = useState()
	const [staffCourses, setStaffCourses] = useState([])
	const [staffMentees, setStaffMentees] = useState([])

	useEffect(async () => {
		if (props.email && props.email.length > 0) {
			let staffUserData = await getUserInfoByEmail(props.email)
			setUserData(staffUserData)
		
			let tempStaffStats = await getStaffStats(props.email)
			if (tempStaffStats && tempStaffStats.length > 0) {
				setStaffStats(tempStaffStats[0])
			} else {
				setStaffStats(null)
			}
			
			console.log('tempStaffStats: ', tempStaffStats)
			
			let tempStaffCourses = await getCoursesOfStaff(props.email)
			setStaffCourses(tempStaffCourses)
			// console.log('tempStaffCourses: ', tempStaffCourses)

			let tempStaffMentees = await getMyMenteeStudentList(props.email)
			let menteeStudentsEmailList = await getUniqueStudentEmailsFromStudentList(tempStaffMentees)
			let tmpMenteeStudentCourses = await getStudentCoursesOfStudentEmailList(menteeStudentsEmailList)
			// setAllStudentCourses(tmpMenteeStudentCourses)
			tempStaffMentees = await addStudentCoursesToStudents(tempStaffMentees, tmpMenteeStudentCourses)
			setStaffMentees(tempStaffMentees)
		}
	}, [props.email])


	return (
		<Grid container>
			<Grid item xs={12}>
				{
					userData &&
						<Grid container>							
							<Grid item xs={12}>
								<H1 style={{fontSize: '32px'}}>
									{userData.name} &nbsp;&nbsp;
									{
										authData.email && authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0) &&
											<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fusers~2F${userData.email}`} target="_blank" rel="noopener noreferrer" style={{fontSize: '11px'}}>
												{userData.email}
											</a>
									}
								</H1>
							</Grid>
							<Grid item xs={12} style={{border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
								<Grid container>
									<Grid item xs={12} sm={4}>
										<b>Ülke/Şehir:</b> {userData.location ? userData.location.country_name + ' > ' + userData.location.region + ' > ' + userData.location.city : ''}
									</Grid>
									<Grid item xs={12} sm={4}>
										<b>Email:</b> {userData.email}
									</Grid>
									<Grid item xs={12} sm={4}>
										<b>Telefon:</b> {userData.phone}
									</Grid>
									<Grid item xs={12} sm={4}>
										<b>Kayıt Tarihi: </b> {moment(userData.created).format('YYYY-MM-DD hh:mm a')}
									</Grid>
									<Grid item xs={12} sm={4}>
										<b>Last Login: </b> {moment(userData.lastLogin).format('YYYY-MM-DD hh:mm a')}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
							</Grid>
							{
								tab === 'stats' &&
								<Grid item xs={12} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
									<Grid container>										
										{
											staffStats ?
												<StaffStats staffStats={staffStats} />
											:
												<div>No stats.</div>
										}
									</Grid>
								</Grid>
							}
							{
								tab === 'courses' &&
									<Grid item xs={12} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
										<Grid container>
											<Grid item xs={12}>
												<H1 style={{borderBottom: 'solid 1px gainsboro', marginBottom: '10px'}}>
													Açılan Kurslar ({(staffCourses && staffCourses.length > 0 ) ? <span>{staffCourses.length}</span> : <span>0</span>})
												</H1>
											</Grid>
											{
												staffCourses && staffCourses.length > 0 ?
													staffCourses.map((_course, index) => (
															<StaffCourseListItem course={_course} key={index} />
														)
													)
												:
													<div>Açılmış kurs yok.</div>
											}
										</Grid>
									</Grid>
							}
							{
								tab === 'mentees' &&
									<Grid item xs={12} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
										<Grid container>
											<Grid item xs={12}>
												<H1 style={{borderBottom: 'solid 1px gainsboro', marginBottom: '10px'}}>
													Rehberliğimdeki Öğrenciler ({(staffMentees && staffMentees.length > 0) ? <span>{staffMentees.length}</span> : <span>0</span> })
												</H1>
											</Grid>
											{
												staffMentees && staffMentees.length > 0 ?
													<StudentList students={staffMentees} />
												:
													<div>Rehberliğinizde öğrenci yok.</div>
											}
										</Grid>
									</Grid>
							}

							

							{
								tab === 'lesson-list-time-table' &&
								staffCourses && staffCourses.length > 0 &&
									<Grid container style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
										<Grid item xs={12}>
											<H1 style={{marginTop: '25px'}}>
												DERS TAKVIMI
											</H1>
											<LessonListTimeTable courses={staffCourses} />
										</Grid>
									</Grid>
							}
						</Grid>
				}
			</Grid>
		</Grid>
	)
}

export { StaffDetails }