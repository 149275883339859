import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import AnswerOptionEntry from './AnswerOptionEntry'
import AddCircleIcon from '@material-ui/icons/AddCircle'


const AddIcon = styled(AddCircleIcon)`color: blue; float: right; cursor: pointer;
	&:hover { color: red; }
`

function AnswerOptionEntryList(props) {
	const [answerOptions, setAnswerOptions] = useState(props.answerOptions)

	useEffect(() => {
		setAnswerOptions(props.answerOptions)
	}, [props.answerOptions])

	return (
		<Grid container>
			{
				answerOptions && answerOptions.length > 0 && (						
					answerOptions.map((answer, index) => {
						if (index < 2 || (!(props.aType === 'boolean'))) {
							let answerItem
							answerItem = (
								<Grid key={index} item xs={12} sm={6}>
									<AnswerOptionEntry value={answerOptions[index]} gameTemplateKey={props.gameTemplateKey} aType={props.aType} cType={props.cType} index={index}
										onUpdate={(optionData) => {if (props.onUpdate) {props.onUpdate(optionData, index)}} }
										onDelete = {() => { props.onDelete(index) }}
									/>
								</Grid>
							)
							return answerItem
						}
						return null
					})
				)
			}
			{
				((props.aType === 'multiple' && props.answerOptions.length < 10) || props.aType === 'free-text' || (props.aType === 'order-puzzle' && props.answerOptions.length < 10) ) &&
					<Grid item xs={12}>
						<AddIcon onClick={() => props.addAnswerOption()} />
					</Grid>
			}
			
		</Grid>
	)
}

export default AnswerOptionEntryList