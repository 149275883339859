import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { getBrandData } from '../../utils/appUtil'

const GroupHeader = styled.div`width: 100%; text-align: left; font-size: 24px; font-weight: bold; border-bottom: solid 1px gainsboro; padding-left: 20px;`

function LessonListTimeTable(props) {
	const [courses, setCourses] = useState(props.courses ? props.courses : [])
	const [lessons, setLessons] = useState()

	useEffect(() => {
		if (props.courses && props.courses.length > 0) {
			setCourses(props.courses ? props.courses : [])

			let tempLessons = []
			props.courses.map(_course => {
				_course.sections.map(_section => {
					_section.lessons.map(_lesson => {
						let newLesson = {..._lesson}
						newLesson.courseName = _course.courseName
						newLesson.courseId = _course.id
						newLesson.owner = _course.owner
						newLesson.courseMeta = _course.meta
	
						tempLessons.push(newLesson)
						return null
					})
					return null
				})
				return null
			})
			tempLessons = tempLessons.filter(_lesson => _lesson.courseSpecificData && _lesson.courseSpecificData.lessonDateTime)
			tempLessons.sort(function (a, b) { return (a.courseSpecificData && a.courseSpecificData.lessonDateTime && b.courseSpecificData && b.courseSpecificData.lessonDateTime) ? (a.courseSpecificData.lessonDateTime - b.courseSpecificData.lessonDateTime) : 0 })
			setLessons(tempLessons)
		}
	}, [props.courses])

	return (		
		<Grid container>
			{
				props.withHeader && courses && courses.length > 0 &&
					<Grid item xs={12} style={{marginBottom: '20px'}}>
						{ props.type === 'course' && <GroupHeader>Kurs Saat Tablosu</GroupHeader> }
						{ props.type === 'seminar' && <GroupHeader>Seminer Saat Tablosu</GroupHeader> }
						{ props.type === 'contest' && <GroupHeader>Yarışma Saat Tablosu</GroupHeader> }
						{ props.type === 'event' && <GroupHeader>Etkinlik Saat Tablosu</GroupHeader> }
					</Grid>
			}

			{
				lessons && lessons.length > 0 &&
					<Grid item xs={12} style={{marginTop: '5px', borderBottom: 'solid 1px gainsboro', fontWeight: 'bold'}}>
						<Grid container>
							<Grid item xs={4}>
								DERS ADI
							</Grid>
							<Grid item xs={4}>
								KURS ADI
							</Grid>
							<Grid item xs={4} style={{textAlign: 'right'}}>
								<Grid container>
									<Grid item xs={12} sm={4} style={{textAlign: 'right'}}>
										Tarih
									</Grid>
									<Grid item xs={12} sm={4} style={{textAlign: 'right'}}>
										Gün
									</Grid>
									<Grid item xs={12} sm={4} style={{textAlign: 'right'}}>
										Saat
									</Grid>
								</Grid>	
							</Grid>
						</Grid>
					</Grid>
			}
			
			{
				lessons && lessons.length > 0 && lessons.map((_lesson, index) => {
					let lessonFullName = _lesson.title ? _lesson.title  : ''
					let lessonShortName = _lesson.title ? (_lesson.title.length > 60 ? (_lesson.title.substring(0, 60) + '...') : _lesson.title) : ''
					let courseFullName = _lesson.courseMeta.settings.courseSpecificName ? _lesson.courseMeta.settings.courseSpecificName : _lesson.courseMeta.intro.title
					let courseShortName = ''
					if (_lesson.courseMeta.settings.courseSpecificName) {
						if (_lesson.courseMeta.settings.courseSpecificName.length > 60) {
							courseShortName = _lesson.courseMeta.settings.courseSpecificName.substring(0, 60) + '...'
						} else {
							courseShortName = _lesson.courseMeta.settings.courseSpecificName
						}
					} else {
						courseShortName = _lesson.courseMeta.intro.title
					}
					return (
						<Grid item xs={12} key={index} style={{marginTop: '5px', borderBottom: 'solid 1px gainsboro'}}>
							<Grid container>
								<Grid item xs={4} style={{fontSize: '12px'}}>
										<Tooltip title={lessonFullName}>
											<a href={`${getBrandData().publicWebsite}/${_lesson.courseMeta.intro.language}/course/${_lesson.courseMeta.settings.slug}/${_lesson.id}/${_lesson.title.replace(/_/g, ' ')}`} 
												target="_blank" rel="noopener noreferrer">													
												{lessonShortName}
											</a>
										</Tooltip>
								</Grid>
								<Grid item xs={4} style={{fontSize: '12px', paddingLeft: '10px', paddingRight: '10px'}}>
									<Tooltip title={courseFullName}>
										<a href={`${getBrandData().publicWebsite}/${_lesson.courseMeta.intro.language}/course/${_lesson.courseMeta.settings.slug}`}
											target="_blank" rel="noopener noreferrer">
											{courseShortName}
										</a>
									</Tooltip>
								</Grid>
								<Grid item xs={4} style={{textAlign: 'right'}}>
									{
										_lesson && _lesson.courseSpecificData && _lesson.courseSpecificData.lessonDateTime &&
											<Grid container>
												<Grid item xs={12} sm={4} style={{textAlign: 'right'}}>
													{_lesson.courseSpecificData.lessonDateTime ? moment(_lesson.courseSpecificData.lessonDateTime.toDate()).format('DD MMMM') : ''}
												</Grid>
												<Grid item xs={12} sm={4} style={{textAlign: 'right'}}>
													{_lesson.courseSpecificData.lessonDateTime ? moment(_lesson.courseSpecificData.lessonDateTime.toDate()).format('dddd') : ''}
												</Grid>
												<Grid item xs={12} sm={4} style={{textAlign: 'right'}}>
													{_lesson.courseSpecificData.lessonDateTime ? moment(_lesson.courseSpecificData.lessonDateTime.toDate()).format('HH:mm') : ''}
												</Grid>
											</Grid>
									}
								</Grid>
							</Grid>
						</Grid>
					)
				})
			}
		</Grid>
	)
}

export { LessonListTimeTable }
