import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import {unstable_batchedUpdates} from 'react-dom'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Switch from '@material-ui/core/Switch'
import { CardDiv, H1, Toggle } from '../../../../components'
import { AuthContext } from '../../../../data/context/AuthProvider'
import AnswersBarChart from '../../shared/AnswersBarChart'
import { OneQuestionResult } from '../report/question/OneQuestionResult'
import { LessonItemSettings } from './LessonItemSettings'
import { StudentAnswerListItem } from '../report/question/StudentAnswerListItem'
import { getLessonResults } from '../../../../data/firebase/lessonResultFirebase'
import { getBrandData } from '../../../../utils/appUtil'

const LessonDetailsComp = styled.div``

const tabs = [
	{ id: 'scoreboard', label: 'Puan Tablosu' },
	{ id: 'student-answer-table', label: 'Öğrenci Cevapları' },
	{ id: 'question-based-answers', label: 'Soru Bazlı Cevaplar' },
	{ id: 'settings', label: 'Ayarlar' },
]

function LessonItemDetails(props) {
	const [authData] = useContext(AuthContext)
	const [studentResults, setStudentResults] = useState([])
	const [tab, setTab] = useState('scoreboard')
	const [studentNames, setStudentNames] = useState()
	const [studentPoints, setStudentPoints] = useState()
	const [studentMissedPoints, setStudentMissedPoints] = useState()
	const [studentNonSeenPoints, setStudentNonSeenPoints] = useState()
	
	const [showStudentListOneQuestionResult, setShowStudentListOneQuestionResult] = useState(true)

	useEffect(async () => {
		if (props.lessonItem) {
			let tempLessonResults = await getLessonResults(props.lessonItem.key)
			let tmpStudentNames = []
			let tmpStudentPoints = []
			let tmpStudentMissedPoints = []
			let tmpStudentNonSeenPoints = []
			for (let i = 0; i < tempLessonResults.length; i++) {
				tmpStudentNames.push(`${i+1}. ${tempLessonResults[i].user.name ? tempLessonResults[i].user.name : ''}`)
				tmpStudentPoints.push(tempLessonResults[i].stats.totalScore ? tempLessonResults[i].stats.totalScore : 0)
				tmpStudentMissedPoints.push((tempLessonResults[i].stats.totalAvailPoint) ? (tempLessonResults[i].stats.totalScore ? tempLessonResults[i].stats.totalAvailPoint - tempLessonResults[i].stats.totalScore : tempLessonResults[i].stats.totalAvailPoint) : 0)
				tmpStudentNonSeenPoints.push((tempLessonResults[i].stats.totalAvailPoint && props.lessonItem.stats && props.lessonItem.stats.totalPoint ) ? props.lessonItem.stats.totalPoint - tempLessonResults[i].stats.totalAvailPoint : 0 )

				tempLessonResults[i].email = tempLessonResults[i].user.email // For compatibility with live lesson data - Component needs these
				tempLessonResults[i].name = tempLessonResults[i].user.name // For compatibility with live lesson data - Component needs these
			}
			unstable_batchedUpdates(() => {
				setStudentNames(tmpStudentNames)
				setStudentPoints(tmpStudentPoints)
				setStudentMissedPoints(tmpStudentMissedPoints)
				setStudentNonSeenPoints(tmpStudentNonSeenPoints)
				setStudentResults(tempLessonResults)
			})
		}
		
	}, [props])

    return (
		<LessonDetailsComp>
			<CardDiv>
				<Grid container>
					<Grid item xs={9}>
						<div style={{textAlign: 'left', fontSize: '14px', fontWeight: 'bold'}}>
							<a href={`${getBrandData().publicWebsite}/lesson/${props.lessonItem.key}`} target="_blank" rel="noopener noreferrer">
								<PlayCircleFilledIcon style={{fontSize: '11px'}} /> {props.lessonItem.title}
							</a>
						</div>
					</Grid>
					<Grid item xs={1}>
						{
							authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) &&
								<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2FlessonItems~2F${props.lessonItem.key}`} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a>
						}
					</Grid>
					<Grid item xs={2}>
						<b style={{fontSize: '12px'}}>({studentResults ?  studentResults.length : 0})</b>
					</Grid>
				</Grid>
			</CardDiv>
			
			<Grid container>
				<Grid item xs={12} style={{marginTop: '20px'}}>
					<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
				</Grid>				
				{
					tab === 'scoreboard' &&
						<Grid item xs={12}>
							<H1>PUAN TABLOSU</H1>
							<CardDiv >								
								{
									studentNames && studentNames.length > 0 && studentPoints && studentPoints.length > 0 && props.lessonItem && props.lessonItem.stats &&
										<AnswersBarChart studentNames={studentNames} points={studentPoints} missedPoints={studentMissedPoints} nonSeenPoints={studentNonSeenPoints} maxPoint={props.lessonItem.stats.totalPoint} />
								}
							</CardDiv>
						</Grid>
				}
				
				{
					tab === 'student-answer-table' &&
						<Grid item xs={12}>
							<CardDiv>
								<H1>ÖĞRENCİ CEVAPLARI ({studentResults ? studentResults.length : 0})</H1>
								{
									studentResults.map((studentResult, index) =>
										<StudentAnswerListItem studentIndex={index} student={studentResult} key={index} lessonInstance={props.lessonItem} currentQuestionIndex={studentResult.lesson.activeState.currentQuestionIndex} answerIndexOffset={0} />
									)
								}						
							</CardDiv>
						</Grid>
				}
				{
					tab === 'question-based-answers' &&
						<Grid item xs={12}>
							<CardDiv>
								<H1>SORU BAZLI SONUCLAR</H1>
									<div>
										<Switch color="primary"
											checked={showStudentListOneQuestionResult}
											style={{color: showStudentListOneQuestionResult ? 'green' : 'red'}}
											onChange={(e) => { setShowStudentListOneQuestionResult(!showStudentListOneQuestionResult) } }
										/>
										{
											showStudentListOneQuestionResult ?
												<span style={{color: 'green'}}>Öğrenciler Gösteriliyor</span>
											:
												<span style={{color: 'red'}}>Öğrenciler Gizlendi</span>
										}
									</div>
									{
										props.lessonItem.quiz && props.lessonItem.quiz.questions && props.lessonItem.quiz.questions.map((question, index) =>
											<OneQuestionResult question={question} key={index} lessonInstance={props.lessonItem} lessonResults={studentResults} questionIndex={index} showStudentListDetails={showStudentListOneQuestionResult} answerIndexOffset={0} />
										)
									}
							</CardDiv>
						</Grid>
				}
				{
					tab === 'settings' &&
						<Grid item xs={12}>
							<CardDiv>
								<H1>AYARLAR</H1>
								<LessonItemSettings lessonItem={props.lessonItem} 
									onSettingsChange={(newSettings) => { if (props.onSettingsChange) { props.onSettingsChange(newSettings) } }}
								/>
							</CardDiv>
						</Grid>
				}
			</Grid>
			<div style={{height: '80px'}}>&nbsp;</div>
		</LessonDetailsComp>
    )
}

export default LessonItemDetails
