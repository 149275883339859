// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'
import moment from 'moment'

const getUserInfoByEmail = async (email) => {
	let firebaseObj = await getFirebaseObj('getUserInfoByEmail')
	let firestore = firebaseObj.firestore

	let userData
	let tempUsers = []
	if (email && email.length > 0) {
		let sUsers = await firestore.collection('users').where('email', '==', email).get()
		sUsers.docs.map(doc => {
			tempUsers.push(doc.data())
			return null
		})
		userData = tempUsers && tempUsers.length > 0 ? tempUsers[0] : null
	}
	return userData
}

const updateUserInfo = async (email, updatedUserData) => {
	let firebaseObj = await getFirebaseObj('updateUserInfo')
	let firestore = firebaseObj.firestore

	let userDocRef = await firestore.collection('users').doc(email)
	await userDocRef.set(updatedUserData)
}

const getAllStaff = async (email) => {
	let firebaseObj = await getFirebaseObj('getAllStaff')
	let firestore = firebaseObj.firestore

	let tempStaffList = []
	if (email && email.length > 0 && email === 'yesilokulcom@gmail.com') {
		let sStaff = await firestore.collection('users').where('roles', 'array-contains-any', ['teacher', 'mentor']).get()
		sStaff.docs.map(doc => {
			tempStaffList.push(doc.data())
			return null
		})
	}
	tempStaffList = tempStaffList.sort((a,b) => !a.lastLogin ? 1 :  a.lastLogin < b.lastLogin ? 1 : -1)
	return tempStaffList
}

const getMyMenteeStudentList = async (email) => {
	let firebaseObj = await getFirebaseObj('getMyMenteeStudentList')
	let firestore = firebaseObj.firestore

	let tempMenteeStudents = []
	if (email && email.length > 0) {
		let sMenteeStudents = await firestore.collection('users').where('mentorship.mentor', '==', email).get()
		sMenteeStudents.docs.map(doc => {
			tempMenteeStudents.push(doc.data())
			return null
		})
	}
	return tempMenteeStudents
}

const getAllMentors = async () => {
	let firebaseObj = await getFirebaseObj('getAllMentors')
	let firestore = firebaseObj.firestore
	
	let tempMentors = []
	let sMentors = await firestore.collection('users').where('roles', 'array-contains', 'mentor').get()
	sMentors.docs.map(doc => {
		tempMentors.push(doc.data())
		return null
	})
	return tempMentors
}

const getAllStudents = async (email, days) => {
	let firebaseObj = await getFirebaseObj('getAllStudents')
	let firestore = firebaseObj.firestore

	let tempStudents = []
	if (email && email.length > 0 && email === 'yesilokulcom@gmail.com') {
		let sStudents
		if (days) {
			sStudents = await firestore.collection('users').where('roles', 'array-contains', 'student').where('created', '>', new Date(moment().add(days, 'days').toDate()).getTime() ).orderBy('created', 'desc').get()
		} else {
			sStudents = await  firestore.collection('users').where('roles', 'array-contains', 'student').get()
		}
		sStudents.docs.map(doc => {
			tempStudents.push(doc.data())
			return null
		})
	}
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const getStudentsForEmailList = async (studentEmails, email) => {
	let firebaseObj = await getFirebaseObj('getStudentsForEmailList')
	let firestore = firebaseObj.firestore

	let tempStudents = []
	
	if (email && email.length > 0 && email === 'yesilokulcom@gmail.com') {
		let tmpStudentEmails = []
		for (let i = 0; i < studentEmails.length; i++ ) {
			tmpStudentEmails.push(studentEmails[i])
			if (tmpStudentEmails.length === 10 || i === studentEmails.length -1 ) {
				if (tmpStudentEmails.length <= 10) {
					let sStudents = await firestore.collection('users').where('email', 'in', tmpStudentEmails).get()
					sStudents.docs.map(doc => {
						tempStudents.push(doc.data())
						return null
					})
				}
				tmpStudentEmails = []
			}
		}
	}
	return tempStudents
}

export { getUserInfoByEmail, updateUserInfo, getAllStaff, getMyMenteeStudentList, getAllMentors, getAllStudents, getStudentsForEmailList }
