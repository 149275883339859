import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
// import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import StopIcon from '@material-ui/icons/Stop'
// import { updateVideoPlayStatus } from '../../../data/firebase/live/firebaseGameHostAndPlayUtils'

const QuestionVideoArea = styled.div`width: 100%; text-align: center; position: relative;`
const AllVideoArea = styled.div`border-radius: 6px; width: 98%; padding-top: 5px;`
const VideoPlayer = styled(ReactPlayer)`max-width: 100vw; border-radius: 6px; margin: auto;`
const PlayerButton = styled.div`cursor: pointer; margin-top: 5px; font-weight: bold; float: left; padding-left: 20px;`

function QuestionVideo(props) {
	const qPlayer = useRef(0)

	const [videoVolume, setVideoVolume] = useState(0.5)
	const [playing, setPlaying] = useState(false)
	const [videoInfo, setVideoInfo] = useState()
	const [activeSecond, setActiveSecond] = useState(props.videoInfo && props.videoInfo.startSecond ? props.videoInfo.startSecond : 0)
	const [videoDuration, setVideoDuration] = useState(0)

	useEffect(() => {
		if (props.videoVolume) {
			setVideoVolume(props.videoVolume)
		}
	}, [props.videoVolume, props.videoInfo])

	useEffect(() => {
		if (props.videoInfo) {
			setVideoInfo(props.videoInfo)
		}
	}, [props.videoInfo])

    return (
        <QuestionVideoArea>
			{
				videoInfo &&
					<AllVideoArea>
						{
							videoInfo && videoInfo.soundOption === 3 &&
								<div>
									<img src="https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fsound-effect.png?alt=media&token=8640d0f7-6b4e-4137-a310-13ba5e455084"
										alt="Listen sound" style={{maxWidth: '90%', maxHeight: '10vh'}} />
								</div>
						}
						<div style={{display: (videoInfo.soundOption === 3 ? 'none' : 'block') }}>
							<VideoPlayer url={videoInfo.url} playing={playing} loop={false} volume={videoInfo.soundOption === 2 ? 0 : videoVolume} ref={qPlayer} muted={false}
								controls={props.controlType && props.controlType === 'host' ? true : false}
								style={{ 
									pointerEvents: (props.controlType && props.controlType === 'host' ? '' : 'none'),
									borderRadius: '6px'
								}}
								height={props.useMaxScreen ? (props.controlType === 'host' ? 'calc(100vh - 230px)' : 'calc(100vh - 200px)') : '320px'}
								width="100%"
								onVolumeChange={(e) => { setVideoVolume(e.target.volume) } }
								onDuration={(duration) => setVideoDuration(Math.round(duration))}
								progressInterval={1000}
								onProgress={(e) => {
									setActiveSecond(Math.round(e.playedSeconds))
									if( (videoInfo.startSecond && videoInfo.startSecond > 0) || (videoInfo.endSecond && videoInfo.endSecond > 0) ) {
										if (e.playedSeconds > (videoInfo.endSecond ? videoInfo.endSecond : 100000000 ) || 
											e.playedSeconds < (videoInfo.startSecond ? videoInfo.startSecond : 0 ) ) {
											setPlaying(false)
											if (props.onEnd) { // Call on end
												props.onEnd()
											} else { // or just rewind
												qPlayer.current.seekTo(videoInfo.startSecond, true)
											}
										} 
									}
								}}
								onReady={(e) => { 
									if( (videoInfo.startSecond && videoInfo.startSecond > 0)) {
										qPlayer.current.seekTo(videoInfo.startSecond, true)
									}
								}}
								onError={(e) => { console.log('onError ', e, ' url:', videoInfo.url) } }
								onPlay={() => { setPlaying(true) }}
								onStart={() => { setPlaying(true) }}
								onPause={() => { setPlaying(false) }}
								onEnded={() =>  { setPlaying(false) }}
							/>
						</div>
						<div>
							{
								(props.controlType === 'host' || props.mode === 'active') &&
										<PlayerButton onClick={() => { videoInfo ? setPlaying(!playing) : setPlaying(false) }}>
											{
												playing ?
													<StopIcon style={{fontSize: '30px', color: 'red'}} />
												:
													<PlayCircleFilledIcon style={{fontSize: '30px', color: 'green'}} />
											}
										</PlayerButton>
										
							}
							<div style={{float: 'right'}}>
								{activeSecond - videoInfo.startSecond}
								{
									videoInfo.endSecond && videoInfo.endSecond !== 1000000 ?
										<span> / {videoInfo.endSecond - videoInfo.startSecond}</span>
										:
										<span> / {videoDuration}</span>
								}
							</div>
						</div>
					</AllVideoArea>
			}
		</QuestionVideoArea>
    )
}

export default QuestionVideo
