import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@mui/material/Button'
import { PlaylistManager } from './PlaylistManager'
import { AuthContext } from '../../data/context/AuthProvider'
import { addPlaylist } from '../../data/firebase/playlistFirebase'
import { BaseInput } from '../../components'

const BlockTitle = styled.div`font-size: 16px; font-weight: bold; padding-left: 10px; margin-top: 10px; border-bottom: solid 1px black;`
const ListItem = styled.div`margin-left: 10px; margin-right: 5px; margin-top: 5px; cursor: pointer; border-bottom: solid 1px gainsboro; border-radius: 6px; padding: 5px;
	:hover {
		background-color: gainsboro;
	}
`

function PlaylistList(props) {
	const [authData] = useContext(AuthContext)
	const [selectedPlaylist ,setSelectedPlaylist] = useState()
	const [selectedPlaylistIndex ,setSelectedPlaylistIndex] = useState()
	const [newPlaylistName, setNewPlaylistName] = useState('')
	const [openNewDialog, setOpenNewDialog] = useState(false)

	useEffect(() => {
		if (props.playlists && props.playlists.length > 0) {
			if (!selectedPlaylist) {
				setSelectedPlaylist(props.playlists[0])
				setSelectedPlaylistIndex(0)
			}
		} else {
			setSelectedPlaylist(null)
			setSelectedPlaylistIndex(null)
		}
	}, [props.playlists])

	const addNew = async () => {
		if (newPlaylistName && newPlaylistName.length > 0 && authData && authData.email)  {
			let newPlaylist = await addPlaylist(newPlaylistName, authData.email)
			if (props.onNewPlaylist) {
				props.onNewPlaylist(newPlaylist)
			}
			setOpenNewDialog(false)
		}
	}
	
	return (
		<div>
			<Grid container style={{textAlign: 'left'}}>
				<Grid item xs={6} sm={3}>
					<Grid container>
						<Grid item xs={9}>
							<BlockTitle>
								PLAYLISTS ({(props.playlists && props.playlists.length > 0 ) ? <span>{props.playlists.length}</span> : <span>0</span>})
							</BlockTitle>
						</Grid>
						<Grid item xs={3} style={{paddingLeft: '10px'}}>
							<Button variant="outlined" onClick={() => setOpenNewDialog(true) }>Yeni</Button>
						</Grid>
						{
							(props.playlists && props.playlists.length > 0 && authData.roles && authData.roles.some(role => ['owner', 'mentor', 'teacher'].indexOf(role) >= 0 )) ? 
								<Grid item xs={12}>
									<Grid container style={{maxHeight: 'calc(100vh - 160px)', borderRight: 'solid 1px gainsboro', overflow: 'auto'}}>
										{
											props.playlists && props.playlists.length > 0 && props.playlists.map((playlist, index) => 
												<Grid item xs={12} key={index}>
													<ListItem onClick={() => { setSelectedPlaylist(playlist); setSelectedPlaylistIndex(index) } }
														style={{
															backgroundColor: (selectedPlaylist === playlist ? 'navy' : ''),
															color: (selectedPlaylist === playlist ? '#ffffff' : '#000000')
														}}
													>
														<span>{index + 1}) </span>
														<span style={{fontWeight: 'bold'}}>{playlist.title}</span>
														<span style={{fontWeight: 'bold', fontSize: '12px'}}> ({playlist.videos && playlist.videos.length})</span>
														<span style={{fontSize: '11px'}}> {playlist.owner}</span>
													</ListItem>
												</Grid>
											)
										}
									</Grid>
								</Grid>
							:
								<span>No playlist defined yet.</span>
						}
					</Grid>
				</Grid>
				<Grid item xs={6} sm={9} style={{padding: '10px'}}>
					{
						selectedPlaylist &&
							<Grid container>
								<Grid item xs={12}>
									<PlaylistManager playlist={selectedPlaylist} onUpdatePlaylist={(updatedPlaylist) => {if (props.onUpdatePlaylist) {	props.onUpdatePlaylist(updatedPlaylist, selectedPlaylistIndex) } } } />
								</Grid>
							</Grid>
					}
				</Grid>				
			</Grid>
			<Dialog open={openNewDialog} onClose={() => setOpenNewDialog(false)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Yeni Video Listesi</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Video Listesinin Ismi
					</DialogContentText>
					<BaseInput autoFocus id="name" value={newPlaylistName} onChange={(newValue) => setNewPlaylistName(newValue)} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenNewDialog(false)} color="primary">
						IPTAL
					</Button>
					<Button onClick={() => addNew()} color="primary">
						EKLE
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)	
}

export { PlaylistList }
