import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { AuthContext } from '../../data/context/AuthProvider'
import { CardDiv } from '../../components'

const GameCard = styled(CardDiv)`float: left; border: solid 1px gainsboro; height: 220px; border-radius: 6px; width: 95%; margin: 10px; text-align: center; `
const GameCardImageArea = styled.div`height: 120px; width: 100%;`
const GameCardImage = styled.img`height: 116px; max-width: 100%; border-radius: 6px 6px 6px 6px; margin-top: 3px; margin-bottom: 3px;`
const GameIDLink = styled(Link)`height: 30px; font-size: 16px; font-weight: bold; overflow: hidden;`
const CreateDate = styled.div`float: right; font-size: 12px;`
const OwnerEmail = styled.div`float: right; font-size: 9px; font-weight: bold;`


function CurriculumListItem(props) {
    const [authData] = useContext(AuthContext)

    const [coverImage, setCoverImage] = useState('https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3')

    useEffect(() => {
        if (props.curriculum) {
            if (props.curriculum.meta && props.curriculum.meta.intro && props.curriculum.meta.intro.coverImageUrl) {
                setCoverImage(props.curriculum.meta.intro.coverImageUrl)
            } else {
                setCoverImage('https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3')
            }
        }
    }, [props.curriculum])

    return (
        <GameCard key={props.curriculum.id}>
            <div>
                <GameCardImageArea>
                    <GameCardImage src={coverImage}  />
                </GameCardImageArea>

                <div>
                    <GameIDLink to={`/curriculum-designer/${props.curriculum.id}`}>{props.curriculum.title && props.curriculum.title.length > 0 ? props.curriculum.title : 'Isimsiz Ders'}</GameIDLink>
                </div>
                
                <CreateDate>{moment(props.curriculum.meta.dates.curriculumCreated).format('DD MMMM YYYY hh:mm')}</CreateDate>
                
                {
                    (authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 )) && 
                        <OwnerEmail>
                            {props.curriculum.owner}&nbsp;
                            <a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data~2Fcurriculums~2F${props.curriculum.id}`} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a>
                        </OwnerEmail>
                }
            </div>
        </GameCard>
	)
}

export default CurriculumListItem
