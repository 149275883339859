import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import LanguageIcon from '@material-ui/icons/Language'
import Tooltip from '@material-ui/core/Tooltip'
import { ReactSortable } from 'react-sortablejs'
import { getPlaylistBySlug, updatePlaylist } from '../../data/firebase/playlistFirebase'
import { AuthContext } from '../../data/context/AuthProvider'
import { H1, Toggle, SimpleTextListManager, FormElementLabel, FormElementDesc } from '../../components'
import { PlaylistVideoItem } from './PlaylistVideoItem'
import { getBrandData } from '../../utils'

const tabsPlaylistDataOptions = [
	{ id: 'videos', label: 'VIDEOLAR' },
	{ id: 'details', label: 'AYARLAR' },
]

function PlaylistManager(props) {
	const [authData] = useContext(AuthContext)

	const [playlist, setPlaylist] = useState()
	const [tabPlaylistData, setTabPlaylistData] = useState('videos')

	useEffect(async () => {
		if (props.playlist) {
			let playlistData = await getPlaylistBySlug(props.playlist.slug)
			setPlaylist(playlistData)
		}
	}, [props.playlist])

	const updateVideoData = (newVideoData, index) => {
		if (newVideoData !== playlist.videos[index]) {
			let tmpPlaylist = {...playlist}
			tmpPlaylist.videos[index] = newVideoData
			setPlaylist(tmpPlaylist)
		}
	}

	const removeVideo = (videoData) => {
		let tmpPlaylist = {...playlist}		
		let tempVideos = tmpPlaylist.videos.filter(x => x.id !== videoData.id)
		tmpPlaylist.videos = (tempVideos && tempVideos.length > 0) ? tempVideos : [{ id: 0, name: '', url: '' }]
		setPlaylist(tmpPlaylist)
	}

	const addVideo = (newVideoData) => {
		let tmpPlaylist = {...playlist}
		tmpPlaylist.videos.push(newVideoData)
		setPlaylist(tmpPlaylist)
	}

	const reOrderVideos = (newOrderedVideos) => {
		let changed = false
		for (let i = 0; i < newOrderedVideos.length; i++) {
			if (newOrderedVideos[i].id !== playlist.videos[i].id) {
				changed = true
			}
		}
		if (changed) {
			let tempVideos = []
			let newIndex = 0
			for (let i = 0; i < newOrderedVideos.length; i++ ) {
				let tempVideo = newOrderedVideos[i]
				if (tempVideo) {
					newIndex++
					tempVideo.id = newIndex
					tempVideos.push(tempVideo)
				}
			}
			let tmpPlaylist = {...playlist}
			tmpPlaylist.videos = tempVideos
			setPlaylist(tmpPlaylist)
		}
	}

	const updatePlaylistData = (fieldName, fieldValue) => {
		let tmpPlaylist = {...playlist}
		tmpPlaylist[fieldName] = fieldValue
		setPlaylist(tmpPlaylist)
	}

	const savePlaylist = () => {
		updatePlaylist(playlist)
		if (props.onUpdatePlaylist) {
			props.onUpdatePlaylist(playlist)
		}
	}

	return (
		<div>
			{
				playlist &&
					<Grid container>
						<>
							<Grid item xs={9} style={{paddingLeft: '10px'}}>
								<TextField type="text" onChange={(e) => updatePlaylistData('title', e.target.value)} value={playlist.title} label="Title" variant="standard" color="success"
									style={{width: '100%', fontFamily: 'Varela Round !important', fontSize: '24px !important' }} 
								/>
							</Grid>
							<Grid item xs={1}>
								{
									authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) &&
										<Tooltip title="Goto playlist data on firestore">
											<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fplaylists~2F${playlist.id}`} target="_blank" rel="noopener noreferrer"><WhatshotIcon /></a>											
										</Tooltip>
								}
								<Tooltip title="Goto playlist on website">
									<a href={`${getBrandData().publicWebsite}/music/${playlist.slug}/0`} target="_blank" rel="noopener noreferrer"><LanguageIcon /></a>										
								</Tooltip>
							</Grid>
							<Grid item xs={2}>
								<Button variant="outlined" onClick={() => savePlaylist() }>Kaydet</Button>
							</Grid>
							<Grid item xs={12} style={{margin: '10px'}}>
								<Toggle options={tabsPlaylistDataOptions} onChange={(newValue) => setTabPlaylistData(newValue) } value={tabPlaylistData} />
							</Grid>

							{
								tabPlaylistData === 'details' && 
									<Grid item xs={12}>
										{
											authData.email === playlist.owner &&
												<Grid container>											
													<Grid item xs={12} style={{paddingTop: '5px'}}>
														<TextField type="text" onChange={(e) => updatePlaylistData('slug', e.target.value)} value={playlist.slug} label="Slug" variant="standard"
															style={{width: '100%', fontFamily: 'Varela Round' }}
														/>
													</Grid>
													<Grid item xs={6} style={{paddingTop: '5px'}}>
														<FormElementLabel>Yayına İzin Verilen Domainler</FormElementLabel>
														<FormElementDesc>Bu kursun hangi web sitelerinde yayınlanmasını istiyorsanız, domain adreslerini ekleyiniz.</FormElementDesc>
														<SimpleTextListManager itemList={(playlist.allowedHostNames) ? playlist.allowedHostNames : []} editable={true} onePerLine={true} showNumbers={true}
															onChange={(items) => { updatePlaylistData('allowedHostNames', items) } }
														/>
													</Grid>
													<Grid item xs={6} style={{paddingTop: '5px', paddingLeft: '15px'}}>
														<FormElementLabel>Kategoriler</FormElementLabel>
														<FormElementDesc>Listelerin birlikte görüntülenmesini sağlayacak kategoriler belirleyebilirsiniz.</FormElementDesc>
														<SimpleTextListManager itemList={(playlist.categories) ? playlist.categories : []} editable={true} onePerLine={true} showNumbers={true}
															onChange={(items) => { updatePlaylistData('categories', items) } }
														/>
													</Grid>
													<Grid item xs={6} style={{paddingTop: '5px'}}>
														<FormElementLabel>Editorler</FormElementLabel>
														<FormElementDesc>Listeyi düzenleme yetkisine sahip kullanıcıların email adreslerini ekleyebilirsiniz.</FormElementDesc>
														<SimpleTextListManager itemList={(playlist.editors) ? playlist.editors : []} editable={true} onePerLine={true} showNumbers={true}
															onChange={(items) => { updatePlaylistData('editors', items) } }
														/>
													</Grid>
												</Grid>
										}
										{
											playlist.editors && playlist.editors.length > 0 && playlist.editors.indexOf(authData.email) >= 0 &&
												<div>
													<div>
														<H1>Kategoriler: </H1>
														{
															playlist.categories.map((_category, index) => 
																<div key={index} style={{borderRadius: '6px', backgroundColor: '#5e54df', marginLeft: '10px', float: 'left', padding: '10px', color: 'white', marginTop: '10px'}}>
																	{_category}
																</div>
															)
														}
													</div>
													<div style={{clear: 'both'}}></div>
												</div>
										}
										
									</Grid>
							}
							{
								tabPlaylistData === 'videos' &&
									<Grid item xs={12} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
										<Grid container>
											<Grid item xs={11}>												
												<H1 style={{marginBottom: '5px'}}>
													Videolar ({(playlist.videos && playlist.videos.length > 0 ) ? <span>{playlist.videos.length}</span> : <span>0</span>})
												</H1>
											</Grid>
											<Grid item xs={12}>
												<div style={{maxHeight: 'calc(100vh - 280px)', overflow: 'auto'}}>
													<ReactSortable list={playlist.videos} setList={(e) => {reOrderVideos(e)}} delayOnTouchStart={true} delay={10} filter={"input"} preventOnFilter={false}>
														{
															playlist.videos && playlist.videos.length > 0 ?
																playlist.videos.map((video, index) =>
																	<PlaylistVideoItem key={index} video={video} onVideoUpdate={(newVideoData) => updateVideoData(newVideoData, index) } onDeleteVideo={(videoData) => {removeVideo(videoData)} } firstLine={index === 0 ? true : false} />												
																)
															:
																<div>Video yok.</div>
														}
													</ReactSortable>
													<PlaylistVideoItem video={{id: playlist.videos.length, name:'', url:''}} onVideoUpdate={(newVideoData) => addVideo(newVideoData) } newAddition={true} />
													<PlaylistVideoItem video={{id: (playlist.videos.length + 1), name:'', url:''}} onVideoUpdate={(newVideoData) => addVideo(newVideoData) } newAddition={true} />
												</div>
											</Grid>
										</Grid>
									</Grid>	
							}
						</>
					</Grid>
			}
		</div>
	)
}

export { PlaylistManager }