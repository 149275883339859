import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { addStaffStatsToStaff,
	filterStaffHaveCourses, filterStaffHaveNoCourses, filterStaffHaveMentorRole, filterStaffNoMentorRole
} from '../data/utils/staffUtils'
import { createStaffListStats } from '../data/utils/statsUtils'
import { getAllStaff } from '../data/firebase/userFirebase'
import { AuthContext } from '../data/context/AuthProvider'
import { Layout2 } from '../components'
import StaffList from '../PageBlocks/staff/StaffList'
import { getAllStaffStats } from '../data/firebase/statsFirebase'

function Staff(props) {
	let history = useHistory()

	const [authData] = useContext(AuthContext)
	const [filteredStaff, setFilteredStaff] = useState()
	const [allStaff, setAllStaff] = useState()
	const [courseOwnershipFilter, setCourseOwnershipFilter] = useState('all')
	const [mentorshipStatusFilter, setMentorshipStatusFilter] = useState('all')
	
	useEffect(async () => {
		if (authData && authData.email) { // Only owner
			if (authData.roles && authData.roles.length > 0 && authData.roles.some(role => ['owner'].indexOf(role) >= 0)) { // Only owner
				if (!allStaff) {
					let tmpStaffList = await getAllStaff(authData.email)				
					let tmpStaffStats = await getAllStaffStats(authData.email)
					tmpStaffList = await addStaffStatsToStaff(tmpStaffList, tmpStaffStats)
					setAllStaff(tmpStaffList)
				}
			} else {
				history.push(`/`)
			}
		}
	}, [authData])

	useEffect(async () => {
		if (allStaff && allStaff.length > 0){
			let tmpStaffFiltered = await applyCourseOwnershipFilter(allStaff)
			tmpStaffFiltered = await applyMentorshipFilter(tmpStaffFiltered)
			setFilteredStaff(tmpStaffFiltered)
		}
	}, [allStaff, courseOwnershipFilter, mentorshipStatusFilter])

	const applyCourseOwnershipFilter = async (staffList) => {
		let tempFilteredStaff = []
		if (courseOwnershipFilter === 'all') {
			tempFilteredStaff = staffList
		} else if (courseOwnershipFilter === 'at-least-one-course') {
			tempFilteredStaff = await filterStaffHaveCourses(staffList)
		} else if (courseOwnershipFilter === 'no-course') {
			tempFilteredStaff = await filterStaffHaveNoCourses(staffList)
		}
		return tempFilteredStaff
	}

	const applyMentorshipFilter = async (staffList) => {
		let tempFilteredStaff = []
		if (mentorshipStatusFilter === 'all') {
			tempFilteredStaff = staffList
		} else if (mentorshipStatusFilter === 'have-mentor-role') {
			tempFilteredStaff = await filterStaffHaveMentorRole(staffList)
		} else if (mentorshipStatusFilter === 'no-mentor-role') {
			tempFilteredStaff = await filterStaffNoMentorRole(staffList)
		} 
		return tempFilteredStaff
	}

	return (
		<Layout2 withAuth={true}>
			{
				allStaff ?
					<Grid container style={{marginTop: '10px'}}>
						<Grid item xs={12} sm={4}>
							<FormControl style={{width: '50%'}}>
								<InputLabel htmlFor="mentor">Course Ownership</InputLabel>
								<Select value={courseOwnershipFilter} onChange={(e) =>  { setCourseOwnershipFilter(e.target.value)} }>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="at-least-one-course">Have At Least One Course</MenuItem>
									<MenuItem value="no-course">No Course Yet</MenuItem>
								</Select>
							</FormControl>
							<FormControl style={{width: '50%'}}>
								<InputLabel htmlFor="mentor">Mentorship Status</InputLabel>
								<Select value={mentorshipStatusFilter} onChange={(e) =>  { setMentorshipStatusFilter(e.target.value)} }>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="have-mentor-role">Have Mentor Role</MenuItem>
									<MenuItem value="no-mentor-role">Does Not Have Mentor Role</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={false} sm={2}></Grid>
						<Grid item xs={12} sm={4}>
							
						</Grid>
						<Grid item xs={false} sm={2}>
							<Button onClick={() => createStaffListStats(authData.email, filteredStaff)} color="primary">CREATE STATS</Button>
						</Grid>
						<Grid item xs={12}>
							{
								filteredStaff &&
									<StaffList staffList={filteredStaff} />
							}
						</Grid>
					</Grid>
				:
					<div></div>
			}
		</Layout2>
	)
}

export default Staff
