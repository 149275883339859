import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getGameInstanceData, getGameInstanceRef, addPlayerAndReturnRef } from '../../data/firebase/live/firebaseGameHostAndPlayUtils'
import Quiz from '../../PageBlocks/quiz/play/Quiz'
import { Layout2, H1, FormLine, BaseInput, BaseButton } from '../../components'

const PlayPage = styled.div`margin: 10px; max-width: 400px; width: 90%; margin-left: auto; margin-right: auto;`
const GameJoinBlock = styled.div`margin-top: 100px;`
const SubmitButton = styled(BaseButton)`float: right; width: 200px;`

let createPlayerInProgress = false
function Play(props) {
	const [game, setGame] = useState({})
	const [gameId, setGameId] = useState((props && props.match && props.match.params && props.match.params.pincode) ? props.match.params.pincode : '')
	const [playerKey, setPlayerKey] = useState('')
	const [player, setPlayer] = useState({})

	useEffect(() => {
		if (gameId) {
			joinGame(gameId)
		}
	}, [])

	const joinGame = (gameId) => {
		getGameInstanceData(gameId, (gameData) => {
			if (gameData) {
				if (gameData.phase === 'setup') {
					const snack = { variant: 'error', message: 'Henüz başlatılmadı' }
					props.showSnackbar(snack)
				} else {
					const storedPlayerKey = localStorage.getItem('RecentPlayerKey')
					
					if (storedPlayerKey && gameData.players && gameData.players[storedPlayerKey]) {
						setPlayerKey(storedPlayerKey)
						setPlayer(gameData.players[storedPlayerKey])
					}
					initGameListener(gameData)
				}
			} else {
				const snack = { variant: 'info', message: 'Bu PIN için oyun bulunamadı' }
				props.showSnackbar(snack)
			}
		})
	}

	const initGameListener = async (_game) => {
		let gameRef = await getGameInstanceRef(_game.key)

		gameRef.on('value', (snapshot) => {
			const game = snapshot.val()
			if (game) {
				setGame(game)
			} else {
				setGame('')
			}
		})
	}

	const createPlayer = (player) => {
		if (createPlayerInProgress === false) {
			createPlayerInProgress = true
			if(!game.players) {
				addNewPlayer(player)
			} else {
				let nameInUse = false
				for (let i = 0; i < Object.keys(game.players).length; i++) {
					if (game.players[Object.keys(game.players)[i]].name === player.name) {
						nameInUse = true
						const snack = { variant: 'error', message: 'Bu kullanıcı ismi bu ders için aktif olarak kullanımda, lütfen başka bir kullanıcı adı belirleyin.' }
						props.showSnackbar(snack)
					} else if (player.email.length > 0 && game.players[Object.keys(game.players)[i]].email === player.email) {
						nameInUse = true
						const snack = { variant: 'error', message: 'Bu email ile derse zaten oturum açıldı.' }
						props.showSnackbar(snack)
					}
				}
				if (!nameInUse) {
					addNewPlayer(player)
				}
			}
			createPlayerInProgress = false
		}
	}

	const addNewPlayer = async (player) => {
		let playerRef = await addPlayerAndReturnRef(game.key)
		const newPlayer = Object.assign({ key: playerRef.key }, player)		
		playerRef.set(newPlayer, (error) => {
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
			} else {
				setPlayerKey(newPlayer.key)
				setPlayer(newPlayer)
				localStorage.setItem('RecentPlayerKey', newPlayer.key)
			}
		})
	}

	return (
		<Layout2 withAuth={false} headerMode="player" game={game}>
			{
				(!game || !game.phase) &&
					<PlayPage>
						<GameJoinBlock>
							<H1>PIN KODU</H1>
							<FormLine>							
								<BaseInput name="gameID" value={gameId} onChange={(newValue) => setGameId(newValue)} color="red" fontSize="48px" textAlign="center" height="60px" />
							</FormLine>
							<FormLine>
								<SubmitButton type="button" onClick={() => joinGame(gameId)} value="OYUNA KATIL" />
							</FormLine>
						</GameJoinBlock>
					</PlayPage>
			}
			{
				(game && game.phase) &&
					<div className="page-container play-page">
						<Quiz game={game} createPlayer={(player) => createPlayer(player)} playerKey={playerKey} player={player} showSnackbar={props.showSnackbar} />
					</div>
			}
		</Layout2>
	)
}

export default Play
