import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@mui/material/Button'
import HelpIcon from '@material-ui/icons/Help'
import { useHistory } from 'react-router-dom'
import LessonTemplateListItem from '../../PageBlocks/quiz/create/LessonTemplate/LessonTemplateListItem'
import { addGameTemplateAndReturnRef, updateGameTemplateData } from '../../data/firebase/live/firebaseGameCreationUtils'
import { getLessonTemplatesOfStaff, updateLessonTemplateBaseData } from '../../data/firebase/lessonTemplateFirebase'
import { Layout2, BaseInput, H1 } from '../../components'
import { AuthContext } from '../../data/context/AuthProvider'

const ListPage = styled.div`margin: 10px; width: 100%; margin-left: auto; margin-right: auto; padding: 5px;`

function LessonTemplateList(props) {
	const [authData] = useContext(AuthContext)
	let history = useHistory()

	const MAX_TIME = 99999999999999999
	const [lessonTemplates, setLessonTemplates] = useState([])
	const [filteredlessonTemplates, setFilteredLessonTemplates] = useState([])
	const [startIndex, setStartIndex] = useState(MAX_TIME) // This is a time index for created and goes descending
	const [pagingCount] = useState(20)
	const [searchText, setSearchText] = useState('')
	
	useEffect(() => {
		if (authData && authData.email) {
			getListFromStart()
		}
	}, [authData.email])

	const getListFromStart = async () => {
		if (authData && authData.email) {
			let tempLessonTemplates = await getLessonTemplatesOfStaff(authData.email, startIndex, pagingCount)
			if (tempLessonTemplates.length > 0) {
				setStartIndex(tempLessonTemplates[tempLessonTemplates.length - 1].created - 1) // Get smallest created date and make it smaller by -1
			} else {
				setStartIndex(MAX_TIME)
			}

			let allLessonTemplates = [...lessonTemplates, ...tempLessonTemplates]
			setLessonTemplates(allLessonTemplates)
			setFilteredLessonTemplates(allLessonTemplates)
		}
	}

	useEffect(async () => {
		if (searchText.length > 2) {
			let tmpLessonTemplates = await applySearchTextFilter(lessonTemplates)
			setFilteredLessonTemplates(tmpLessonTemplates)
		} else {
			setFilteredLessonTemplates(lessonTemplates)
		}
	}, [searchText])

	const applySearchTextFilter = async (_lessonTemplates) => {
		let tempFilteredLessonTemplates = [..._lessonTemplates]
		if (searchText && searchText.length > 0) {
			tempFilteredLessonTemplates = tempFilteredLessonTemplates.filter(_lessonTemplate => 
				(_lessonTemplate.title && _lessonTemplate.title.toLowerCase().indexOf(searchText) >= 0)				
				|| (_lessonTemplate.owner && _lessonTemplate.owner.toLowerCase().indexOf(searchText) >= 0)
				|| (_lessonTemplate.metaData && _lessonTemplate.metaData.tags && _lessonTemplate.metaData.tags.length > 0 && _lessonTemplate.metaData.tags.filter(_tag => _tag.indexOf(searchText) >= 0).length > 0)
				|| (
					_lessonTemplate.quiz && _lessonTemplate.quiz.questions && _lessonTemplate.quiz.questions.length > 0 &&
					(_lessonTemplate.quiz.questions.map(_question => {
						if (_question.questionContent && _question.questionContent.texts && _question.questionContent.texts.length > 0) {
							if (_question.questionContent.texts.map(_qText => {
								let result = false
								if (_qText.text && _qText.text.toLowerCase().indexOf(searchText) >= 0) {
									result = true
								}
								return result
							}).filter(_result => _result === true).length > 0) {
								return true
							} else {
								return false
							}
						} else {
							return false
						}
					}).filter(_result => _result === true).length > 0)
				)
			)
		}
		return tempFilteredLessonTemplates
	}
	
    const createGame = async () => {
		const tempGame = {}

		tempGame.created = Date.now()
		tempGame.status = 'CREATED'
		tempGame.phase = 'setup'

		tempGame.title = ''
		tempGame.owner = authData.email

		let gameRef = await addGameTemplateAndReturnRef()

		tempGame.key = gameRef.key
		tempGame.templateKey = gameRef.key
		
		updateLessonTemplateBaseData(tempGame.key, tempGame) // FIRESTORE
		updateGameTemplateData(tempGame.key, tempGame, (error) => {
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
			}
		})

		history.push('/lesson-designer/' + tempGame.key)
	}
	return (
		<Layout2 withAuth={true}>
			<ListPage>
				<H1>DERSLERİM</H1>
				{
					authData.roles && authData.roles.some(role => ['owner', 'admin', 'teacher'].indexOf(role) >= 0 ) && (
						<Grid container>
							<Grid item xs={6}>
								<BaseInput value={searchText} onChange={(newValue) => setSearchText(newValue.toLowerCase())} width="calc(100% - 80px)" /> <Tooltip title="Filter lesson templates by; Name, owner, question texts and tags."><HelpIcon /></Tooltip>
							</Grid>
							<Grid item xs={6}>
								<div style={{textAlign: 'right', marginRight: '20px'}}>
									<Button variant="outlined" onClick={() => createGame()}>YENİ DERS</Button>
								</div>
							</Grid>
						</Grid>
					)
				}

				<Grid container>
					{
						filteredlessonTemplates && filteredlessonTemplates.length > 0 &&
						filteredlessonTemplates.map((template) => template &&
								<Grid item xs={12} sm={4} md={3} key={template.key}>
									<LessonTemplateListItem gameTemplate={template} />
								</Grid>									
							)
					}
				</Grid>
				<div style={{textAlign: 'center', marginRight: '20px', paddingBottom: '50px'}}>
					<Button variant="outlined" onClick={() => getListFromStart()}>MORE</Button>
				</div>				
			</ListPage>
		</Layout2>
	)
}

export default LessonTemplateList
