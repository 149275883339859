import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { PlaylistList } from '../PageBlocks/playlist/PlaylistList'
import { getPlaylists } from '../data/firebase/playlistFirebase'
import { AuthContext } from '../data/context/AuthProvider'
import { Layout2 } from '../components'

function Playlists(props) {	const [authData] = useContext(AuthContext)
	const [allPlaylists, setAllPlaylists] = useState()
	const [filteredPlaylists, setFilteredPlaylists] = useState()
	// const [selectedTeacher, setSelectedTeacher] = useState()
	// const [userMode, setUserMode] = useState('')

	useEffect(async () => {
		if (authData && authData.email && authData.roles && authData.roles.length > 0 && (authData.roles.some(role => ['teacher'].indexOf(role) >= 0) || authData.roles.some(role => ['owner'].indexOf(role) >= 0)) ) {
			if (authData.roles.some(role => ['teacher'].indexOf(role) >= 0) && authData.roles.some(role => ['owner'].indexOf(role) === -1)) { // Only teacher not owner
				// setSelectedTeacher(authData.email)

				await updatePlaylistsFromDB()
				// setUserMode('teacher')
			}
			if (authData.roles.some(role => ['owner'].indexOf(role) >= 0)) { // Only owner
				await updatePlaylistsFromDB()				
				// setUserMode('owner')
			}
		}
	}, [authData])

	const updatePlaylistsFromDB = async () => {
		let tmpPlaylists = await getPlaylists(authData.email)
		setAllPlaylists(tmpPlaylists)
	}

	const addPlaylistToAll = (newPlaylist) => {
		let tempPlaylists = [...allPlaylists]
		tempPlaylists.push(newPlaylist)
		tempPlaylists.sort(function(a,b) {
			if (a.name < b.name) return 1
			if (a.name > b.name) return -1
			return 0
		})
		setAllPlaylists(tempPlaylists)
	}

	const updatePlaylistInAll = (updatedPlaylist, index) => {
		let tempPlaylists = [...allPlaylists]
		tempPlaylists[index] = updatedPlaylist
		tempPlaylists.sort(function(a,b) {
			if (a.name < b.name) return 1
			if (a.name > b.name) return -1
			return 0
		})
		setAllPlaylists(tempPlaylists)
	}

	useEffect(() => {
		setFilteredPlaylists(allPlaylists)
	}, [allPlaylists])

	return (
		<Layout2 withAuth={true}>
			<Grid container style={{marginTop: '10px'}}>
				<Grid item xs={12}>
					<PlaylistList playlists={filteredPlaylists} onNewPlaylist={(newPlaylist) => addPlaylistToAll(newPlaylist)} onUpdatePlaylist={(updatedPlaylist, index) => { updatePlaylistInAll(updatedPlaylist, index) } } />
				</Grid>
			</Grid>
		</Layout2>
	)
}

export default Playlists
