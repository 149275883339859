import React from 'react'
import styled from 'styled-components'

const ImageArea = styled.div`float: left; margin: 5px;`
const Image = styled.img`max-width: 300px; border-radius: 6px; border: solid 1px #f0f0f0; `

function ImageListPreview(props) {

	return (
		<div>
			{
				props.images && props.images.length > 0 && props.images.map((_image, index) => 
					<ImageArea key={index}>
						<Image src={_image.thumb_url ? _image.thumb_url : _image.url} />
					</ImageArea>
				)
			}
		</div>
	)
}

export { ImageListPreview }
