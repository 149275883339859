import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { isMobile } from 'react-device-detect'
import Drawer from '@material-ui/core/Drawer'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import AssessmentIcon from '@material-ui/icons/Assessment'
import GroupIcon from '@material-ui/icons/Group'
import InfoIcon from '@material-ui/icons/Info'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import PrevQuestionList from './PrevQuestionList'
import LessonInfo from './LessonInfo'
import Leaderboard from './Leaderboard'
import { ConnectedUsers } from './ConnectedUsers'
import QuestionPlayerAnswers from './QuestionPlayerAnswers'

const DrawerContentArea = styled.div`padding: 10px; height: 100%; width: 100vw;`
const DrawerBottomLine = styled.div` padding-left: 10px; background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%); color: #ffffff; text-align: center; height: 60px; padding-top: 10px; border-radius: 10px 10px 0 0;
	width: 100%; min-width: 280px;
`

const QuestionListIcon = styled(VideoLabelIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px; z-index: 10;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`
const LessonInfoIcon = styled(InfoIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px; z-index: 10;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`
const UsersIcon = styled(AccountBoxIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px; z-index: 10;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`
const ReportIcon = styled(AssessmentIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px; z-index: 10;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`
const AnswerIcon = styled(GroupIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px; z-index: 10;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`
const CloseIcon = styled.div`font-size: 18px; float: left; border: solid 2px white; color: white;  border-radius: 24px; width: 28px; text-align: center; cursor: pointer; font-weight: bold; margin-left: 5px;
	:hover {background-color: red; color: white; border: solid 2px red;}
`

function MainDrawer(props) {
	const [drawerState, setDrawerState] = useState('closed')
	const [firstLoad, setFirstLoad] = useState(true)

	useEffect(() => {
		if (props.onStateChange) {
			props.onStateChange(drawerState)
		}
	}, [drawerState])

	useEffect(() => {
		if (firstLoad) {
			setFirstLoad(false)
			setDrawerState('closed')
		} else {
			if (props.content === 'question-list' && props.gameInstance.uiState.drawerQuestionList) { setDrawerState(props.gameInstance.uiState.drawerQuestionList) }
			if (props.content === 'lesson-info' && props.gameInstance.uiState.drawerLessonInfo) { setDrawerState(props.gameInstance.uiState.drawerLessonInfo) }
			if (props.content === 'user-list' && props.gameInstance.uiState.drawerUserList) { setDrawerState(props.gameInstance.uiState.drawerUserList) }
			if (props.content === 'report' && props.gameInstance.uiState.drawerReport) { setDrawerState(props.gameInstance.uiState.drawerReport) }
			if (props.content === 'question-player-answers' && props.gameInstance.uiState.drawerAnswerList ) { setDrawerState(props.gameInstance.uiState.drawerAnswerList) }
		}
	}, [ (props.gameInstance.uiState && props.gameInstance.uiState.drawerQuestionList), (props.gameInstance.uiState && props.gameInstance.uiState.drawerLessonInfo), (props.gameInstance.uiState && props.gameInstance.uiState.drawerUserList), 
		(props.gameInstance.uiState && props.gameInstance.uiState.drawerReport),  (props.gameInstance.uiState && props.gameInstance.uiState.drawerAnswerList) ])

	return (
		<div>
			<div style={{float: props.float}}>
				{props.content === 'question-list' && props.showIcon && <QuestionListIcon onClick={() => setDrawerState('open')}  style={{fontSize: props.iconSize ? props.iconSize : (isMobile ?  '28px' : '40px') }} stylecolor={props.iconColor} />}
				{props.content === 'lesson-info' && props.showIcon && <LessonInfoIcon onClick={() => setDrawerState('open')}  style={{fontSize: props.iconSize ? props.iconSize : (isMobile ?  '28px' : '40px') }} stylecolor={props.iconColor} />}
				{props.content === 'user-list' && props.showIcon && <UsersIcon onClick={() => setDrawerState('open')}  style={{fontSize: props.iconSize ? props.iconSize : (isMobile ?  '28px' : '40px') }} stylecolor={props.iconColor} />}
				{props.content === 'report' && props.showIcon && <ReportIcon onClick={() => setDrawerState('open')}  style={{fontSize: props.iconSize ? props.iconSize : (isMobile ?  '28px' : '40px') }} stylecolor={props.iconColor} />}
				{props.content === 'question-player-answers' && props.showIcon && <AnswerIcon onClick={() => setDrawerState('open')}  style={{fontSize: props.iconSize ? props.iconSize : (isMobile ?  '28px' : '40px') }} stylecolor={props.iconColor} /> }
			</div>

			<Drawer anchor="bottom" open={drawerState === 'open' ? true : false} onClose={() => { if (props.mode === 'active') { setDrawerState('closed') } } } >
				<DrawerContentArea>
					{props.content === 'question-list' && <PrevQuestionList gameInstance={props.gameInstance} mode={props.mode} videoControlType={props.videoControlType} content={props.content} onActiveQuestionChange={(activeQuestion) => { if (props.onActiveQuestionChange) { props.onActiveQuestionChange(activeQuestion)} } } /> }
					{props.content === 'lesson-info' && <LessonInfo gameInstance={props.gameInstance} />}
					{props.content === 'user-list' && <ConnectedUsers game={props.gameInstance} animate={false} />}
					{props.content === 'report' && <Leaderboard game={props.gameInstance} listCount="250" tableHeight="calc(100vh - 110px)" selectTab={(newValue) => {if (props.selectTab) { props.selectTab(newValue) } }} />}
					{props.content === 'question-player-answers' && <QuestionPlayerAnswers gameInstance={props.gameInstance} question={props.gameInstance.quiz.questions[props.gameInstance.quiz.currentQuestion]} mode={props.mode}
						onAnswerSelect={(selectedAnswerKey) => { if (props.onAnswerSelect) { props.onAnswerSelect(selectedAnswerKey)} } } />
					}
				</DrawerContentArea>
				<DrawerBottomLine>
					<Grid item xs={12}>
						{props.content === 'question-list' && <span>DERS - ONIZLEME</span>}
						{props.content === 'lesson-info' && <span>DERS HAKKINDA</span>}
						{props.content === 'user-list' && <span>KATILIMCILAR</span>}
						{props.content === 'report' && <span>PUAN SIRALAMASI</span>}
						{props.content === 'question-player-answers' && <span>CEVAPLAR</span> }
						
						{props.mode === 'active' && <CloseIcon onClick={() => setDrawerState('closed') }>X</CloseIcon>}
					</Grid>					
				</DrawerBottomLine>
			</Drawer>			
		</div>
	)
}

export default MainDrawer
