// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const getLessonTemplate = async (key) => {
	let firebaseObj = await getFirebaseObj('getLessonTemplate')
	let firestore = firebaseObj.firestore

	let sLessonTemplates = await firestore.collection('lessonTemplates').where('key', '==', key).get()
	let tempLessonTemplates = []

	if (sLessonTemplates) {
		sLessonTemplates.docs.map(doc => {
			tempLessonTemplates.push(doc.data())
			return null
		})
	}
	
	let tmpLessonTemplate
	if (tempLessonTemplates && tempLessonTemplates.length > 0) {
		tmpLessonTemplate = tempLessonTemplates[0]
	}

	return tmpLessonTemplate
}

const getLessonTemplatesOfStaff = async (ownerEmail, startIndex, pagingCount) => {
	let firebaseObj = await getFirebaseObj('getLessonTemplatesOfStaff')
	let firestore = firebaseObj.firestore

	let tempLessonTemplates = []
	if (ownerEmail === 'yesilokulcom@gmail.com') {
		let sLessonTemplates = await firestore.collection('lessonTemplates').orderBy('created', 'desc').startAt(startIndex).limit(pagingCount).get()
		sLessonTemplates.docs.map(doc => {
			tempLessonTemplates.push(doc.data())
			return null
		})
	} else {
		let sLessonTemplates = await firestore.collection('lessonTemplates').orderBy('created', 'desc').startAt(startIndex).limit(pagingCount).where('owner', '==', ownerEmail).get()
		sLessonTemplates.docs.map(doc => {
			tempLessonTemplates.push(doc.data())
			return null
		})
	}
	tempLessonTemplates.sort((a,b) => a.created > b.created ? -1 : 1)
	return tempLessonTemplates
}

const searchLessonTemplates = async (searchText, email) => {
	let firebaseObj = await getFirebaseObj('searchLessonTemplates')
	let firestore = firebaseObj.firestore

	let sLessonTemplates = []
	if (email === 'yesilokulcom@gmail.com') {
		sLessonTemplates = await firestore.collection('lessonTemplates').get()
	} else {
		sLessonTemplates = await firestore.collection('lessonTemplates').where('owner', '==', email).get()
	}
	// sLessonTemplates = await firestore.collection('lessonTemplates').where('metaData.tags', 'array-contains', searchText).get()
	let tempLessonTemplates = []

	if (sLessonTemplates) {
		sLessonTemplates.docs.map(doc => {
			tempLessonTemplates.push(doc.data())
			return null
		})
	}
	tempLessonTemplates = tempLessonTemplates.filter(_lessonTemplate => _lessonTemplate.title.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 || (_lessonTemplate.metaData && _lessonTemplate.metaData.tags && _lessonTemplate.metaData.tags.indexOf(searchText.toLowerCase()) >= 0))
	return tempLessonTemplates
}

const getAllLessonTemplatesOfCurriculum = async (curriculumId) => {
	let firebaseObj = await getFirebaseObj('getAllLessonTemplatesOfCurriculum')
	let firestore = firebaseObj.firestore

	let lessonTemplateKeys = []
	let crr = await firestore.collection('curriculums').where('id', '==', curriculumId).get()
	const curriculum = crr.docs[0].data()
	if (curriculum.sections && curriculum.sections.length > 0) {
		curriculum.sections.map(section => {
			if (section.lessons) {
				section.lessons.map(lesson => {
					if (lesson.lessonTemplateKey) {
						lessonTemplateKeys.push(lesson.lessonTemplateKey)
					}
					return null
				})
			}
			return null
		})
		if (lessonTemplateKeys && lessonTemplateKeys.length > 0) {
			let tempLessonTemplates = []
			for (let i = 0; i < lessonTemplateKeys.length / 10; i++) {
				let slicedTemplateKeys = lessonTemplateKeys.slice(i * 10, (i+1) * 10 ) // FIRESTORE DOES NOT ALLOW MORE THAN 10 ITEMS FOR "IN" QUERUIES - SO IT IS 10 BY 10
				if (slicedTemplateKeys.length > 0) {
					let sLessonTemplates = await firestore.collection('lessonTemplates').where('key', 'in', slicedTemplateKeys).get()
					sLessonTemplates.docs.map(doc => {
						tempLessonTemplates.push(doc.data())
						return null
					})
				}
			}
			return(tempLessonTemplates)
		} else {
			return([])
		}			
	} else {
		return([])
	}
}

const updateLessonTemplateBaseData = async (lessonTemplateKey, lessonTemplateUpdatedData) => {
	let firebaseObj = await getFirebaseObj('updateLessonTemplateBaseData')
	let firestore = firebaseObj.firestore

	firestore.collection('lessonTemplates').doc(lessonTemplateKey).set(lessonTemplateUpdatedData, {merge: true})
}

const updateQuestionListAndStatsInTemplate = async (lessonTemplateKey, newSlideList) => {
	let firebaseObj = await getFirebaseObj('updateQuestionListAndStatsInTemplate')
	let firestore = firebaseObj.firestore

	// const batch = firestore.batch()
	// newSlideList.map((newQuestion, index) => {
	// 	batch.set(firestore.collection(`lessonTemplates/${lessonTemplateKey}/questions`).doc(`q-${index}`), newQuestion)
	// 	return null
	// })
	// batch.commit()

	let totalPoint = 0, totalAnsweringTime = 0, quizCount = 0
	newSlideList.map(_slide => {
		if (_slide.cType === 'quiz') {
			totalPoint += (_slide.qOptions && _slide.qOptions.point ? _slide.qOptions.point : 0)
			totalAnsweringTime += (_slide.qOptions && _slide.qOptions.timeLimit ? _slide.qOptions.timeLimit : 0)
			quizCount++
		}
		return null
	})
	let avgAnsweringTime = Math.round(totalAnsweringTime / quizCount)
	let stats = {
		allSlidesCount: newSlideList.length,
		totalPoint: totalPoint,
		totalAnsweringTime: totalAnsweringTime,
		avgAnsweringTime: avgAnsweringTime,
		cType: {
			contentCount: newSlideList.filter(slide => (slide.cType && slide.cType === 'content')).length,
			quizCount: quizCount, // newSlideList.filter(slide => (slide.cType && slide.cType === 'quiz')).length,
			pollCount: newSlideList.filter(slide => (slide.cType && slide.cType === 'poll')).length,
			formCount: newSlideList.filter(slide => (slide.cType && slide.cType === 'form')).length,
		},
		qType: {
			textCount: newSlideList.filter(slide => (slide.qType && slide.qType === 'text')).length,
			imageCount: newSlideList.filter(slide => (slide.qType && slide.qType === 'image_text')).length,
			videoCount: newSlideList.filter(slide => (slide.qType && slide.qType === 'video_text')).length,
		},
		aType: {
			multipleCount: newSlideList.filter(slide => (slide.aType && slide.aType === 'multiple' && (slide.cType === 'quiz' || slide.cType === 'poll' || slide.cType === 'form'))).length,
			booleanCount: newSlideList.filter(slide => (slide.aType && slide.aType === 'boolean' && (slide.cType === 'quiz' || slide.cType === 'poll' || slide.cType === 'form'))).length,
			freeTextCount: newSlideList.filter(slide => (slide.aType && slide.aType === 'free-text' && (slide.cType === 'quiz' || slide.cType === 'poll' || slide.cType === 'form'))).length,
			imageUploadCount: newSlideList.filter(slide => (slide.aType && slide.aType === 'image-upload' && (slide.cType === 'quiz' || slide.cType === 'poll' || slide.cType === 'form'))).length,
		}
	}

	await firestore.collection('lessonTemplates').doc(lessonTemplateKey).set({stats: stats, quiz: {questions: newSlideList} }, {merge: true})
}

const updateLessonTemplateCoverImage = async (lessonTemplateKey, coverImageData) => {
	let firebaseObj = await getFirebaseObj('updateLessonTemplateCoverImage')
	let firestore = firebaseObj.firestore

	firestore.collection('lessonTemplates').doc(lessonTemplateKey).set({metaData: {coverImage: coverImageData }}, {merge: true})
}
const updateLessonTemplateTags = async (lessonTemplateKey, tags) => {
	let firebaseObj = await getFirebaseObj('updateLessonTemplateTags')
	let firestore = firebaseObj.firestore

	firestore.collection('lessonTemplates').doc(lessonTemplateKey).set({metaData: {tags: tags }}, {merge: true})
}

const updateLessonTemplateSettings = async (lessonTemplateKey, settings) => {
	let firebaseObj = await getFirebaseObj('updateLessonTemplateSettings')
	let firestore = firebaseObj.firestore

	firestore.collection('lessonTemplates').doc(lessonTemplateKey).set({metaData: {settings: settings }}, {merge: true})
}

const updateLessonTemplateDescription = async (lessonTemplateKey, description) => {
	let firebaseObj = await getFirebaseObj('updateLessonTemplateDescription')
	let firestore = firebaseObj.firestore

	firestore.collection('lessonTemplates').doc(lessonTemplateKey).set({metaData: {description: description }}, {merge: true})
}

const getTemplateMetaData = async (lessonTemplateKey) => {
	let firebaseObj = await getFirebaseObj('getTemplateMetaData')
	let firestore = firebaseObj.firestore

	let lessonTemplates = await firestore.collection('lessonTemplates').where('key', '==', lessonTemplateKey).get()
	if (lessonTemplates && lessonTemplates.docs && lessonTemplates.docs.length > 0) {
		return lessonTemplates.docs[0].data().metaData
	} else {
		return null
	}
}

const updateFullLessonTemplateByImport = async (lessonTemplateKey, importedLessonTemplateData) => {
	let firebaseObj = await getFirebaseObj('updateFullLessonTemplateByImport')
	let firestore = firebaseObj.firestore

	firestore.collection('lessonTemplates').doc(lessonTemplateKey).set(importedLessonTemplateData, {merge: true})
}


export {
	getLessonTemplate, getLessonTemplatesOfStaff, searchLessonTemplates, getAllLessonTemplatesOfCurriculum, // GET TEMPLATE DERIVATIVES
	updateLessonTemplateBaseData, updateQuestionListAndStatsInTemplate, // UPDATES
	updateLessonTemplateCoverImage,	getTemplateMetaData, updateLessonTemplateTags, updateLessonTemplateSettings, updateLessonTemplateDescription, // SETTINGS and META
	updateFullLessonTemplateByImport,
}
