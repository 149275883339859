import React, { useState, useEffect, useContext } from 'react'
import CourseDesigner from '../../PageBlocks/course/CourseDesigner'
import { getCourseById } from '../../data/firebase/courseFirebase'
import { Layout2 } from '../../components'
import { AuthContext } from '../../data/context/AuthProvider'

function Course(props) {	
	const [authData] = useContext(AuthContext)

	const [courseId] = useState((props && props.match && props.match.params && props.match.params.id) ? props.match.params.id :  null)
	const [selectedCourse, setSelectedCourse] = useState({})

	useEffect(async () => {
		if ( courseId && authData.email) {
			const tempSelected = await getCourseById(authData.email, courseId)
			setSelectedCourse(tempSelected)
		}
	}, [courseId, authData.email])

	return (
		<Layout2 withAuth={true}>
			{
				selectedCourse && selectedCourse.id && 
					<div>
						<CourseDesigner course={selectedCourse} urlParams={props.match.params} />
					</div>
			}
		</Layout2>
	)
}

export default Course
