import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input  from '@material-ui/core/Input'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import QuestionList from './LessonTemplate/QuestionList'
import LessonInstanceList from './LessonInstance/LessonInstanceList'
import MainQuestionForm from './LessonTemplate/QuestionForm/MainQuestionForm'
import MetaDataForm from './LessonTemplate/MetaDataForm'
import LessonDetails from './LessonInstance/LessonDetails'
import LessonItemDetails from './LessonInstance/LessonItemDetails'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { AuthContext } from '../../../data/context/AuthProvider'
import { updateGameTemplateData } from '../../../data/firebase/live/firebaseGameCreationUtils'
import { updateLessonTemplateBaseData } from '../../../data/firebase/lessonTemplateFirebase'
import { BaseButton, Toggle, DrawerBottomLine, CloseIcon } from '../../../components'


const QuestionListArea = styled.div`height: calc(100vh - 150px); overflow: auto; overflow: auto; border-right: solid 1px gainsboro; 
	/* width */ ::-webkit-scrollbar {  width: 6px; } /* Track */ ::-webkit-scrollbar-track { box-shadow: inset 0 0 3px grey; border-radius: 10px; } /* Handle */ ::-webkit-scrollbar-thumb { background: gray; border-radius: 10px; } /* Handle on hover */ ::-webkit-scrollbar-thumb:hover { background: black; width: 6px; }
	@media (max-width: 960px) {
		height: calc(100vh - 230px);
	}
	
`
const QuestionFormArea = styled.div`height: calc(100vh - 150px); overflow: auto; margin: 0 auto; max-width: 1000px; padding-left: 5px; text-align: center;
	/* width */ ::-webkit-scrollbar {  width: 6px; } /* Track */ ::-webkit-scrollbar-track { box-shadow: inset 0 0 3px grey; border-radius: 10px; } /* Handle */ ::-webkit-scrollbar-thumb { background: gray; border-radius: 10px; } /* Handle on hover */ ::-webkit-scrollbar-thumb:hover { background: black; width: 6px; }
	@media (max-width: 960px) {
		height: calc(100vh - 180px);
	}
`

const tabs = [
	{ id: 'design', label: 'Tasarım' },
	{ id: 'lessons', label: 'Dersler' },
	{ id: 'meta', label: 'Ayarlar' },
]

function LessonTemplateDesigner(props) {
	const [authData] = useContext(AuthContext)

	const [gameKey, setGameKey] = useState('')
	const [title, setTitle] = useState('')

	const [activeQuestion, setActiveQuestion] = useState()
	const [questions] = useState([])

	const [drawerId, setDrawerId] = useState('')
	const [drawerState, setDrawerState] = useState(false)
	
	const [selectedInstance, setSelectedInstance] = useState()
	const [tab, setTab] = useState('design')

	useEffect(() => {
		if (props.game) {
			setGameKey(props.game.key)
			setTitle(props.game.title)
			
			if (props.game.quiz && props.game.quiz.questions && props.game.quiz.questions.length > 0) {
				if (activeQuestion) {
					let activeQuestionID = activeQuestion ? (activeQuestion.id === 0 ? 0 : activeQuestion.id - 1) : 0
					setActiveQuestion(props.game.quiz.questions[activeQuestionID])
				}
			}
		}
	}, [props.game])
	
	const updateLessonTemplateBase = () => {
		const game = {
			title: title,
		}

		updateLessonTemplateBaseData(gameKey, game) // FIRESTORE
		updateGameTemplateData(gameKey, game, (error) => { // FIREBASE
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
			}
		})
	}
	
	const selectDrawer = (_drawerId) => {
		setDrawerId(_drawerId)
		setDrawerState(true)
	}

	const updateLessonInstanceSettings = (newSettings) => {
		let tmpSelectedLessonInstance = {...selectedInstance}
		tmpSelectedLessonInstance.publish = newSettings.publish
		setSelectedInstance(tmpSelectedLessonInstance)
	}

	return (
		<div>
			<Grid container>
				<Grid item xs={12} style={{borderBottom: 'solid 1px gainsboro', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginTop: '20px', marginBottom: '5px'}}>
					<Grid container>
						<Grid item xs={10} md={6}>
							<FormControl style={{width: '100%'}}>
								<InputLabel>Ders Adı</InputLabel>
								<Input value={title} maxLength="80" onChange={(e) => setTitle(e.target.value) } onBlur={() => updateLessonTemplateBase()} />
							</FormControl>
						</Grid>
						<Grid item xs={2} md={1}>							
							{
								authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) &&
									<span>
										<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/database/yesil-okul-web-app-default-rtdb/data/~2Flesson_templates~2F${props.game.key}`} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a>
										<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2FlessonTemplates~2F${props.game.key}`} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a>
									</span>
							}
						</Grid>
						<Grid item xs={12} md={4}>
							<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
						</Grid>
					</Grid>
				</Grid>

				{
					tab === 'design' &&
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={2}>
									<QuestionListArea>
										<QuestionList game={props.game} setActive={(q) => { setActiveQuestion(q); }}  activeQuestion={activeQuestion} />								
									</QuestionListArea>						
								</Grid>
								<Grid item xs={10}>
									<QuestionFormArea>
										{
											activeQuestion &&											
												<MainQuestionForm showSnackbar={props.showSnackbar} questions={questions} activeQuestion={activeQuestion} gameKey={gameKey} />
										}
									</QuestionFormArea>
								</Grid>
							</Grid>
						</Grid>
				}
				{
					tab === 'lessons' &&
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12} md={2} style={{ height: isMobile ? '60px' : 'calc(100vh - 150px)', overflow: 'auto', borderRight: 'solid 1px gainsboro'}}>
									<MobileView>
										<BaseButton onClick={() => selectDrawer('lesson-list')} value="Dersler" type="button" style={{width: '100px'}} />
									</MobileView>
									<BrowserView>
										<LessonInstanceList game={props.game} selectedInstance={selectedInstance} setActiveLesson={(l) => setSelectedInstance(selectedInstance ? null : l)} />
									</BrowserView>
									<MobileView>
										<Drawer anchor="left" open={drawerState} onClose={() => setDrawerState(false)}>
											<Grid container>
												{
													drawerId === 'lesson-list' && (
														<LessonInstanceList game={props.game} selectedInstance={selectedInstance} setActiveLesson={(l) => setSelectedInstance(selectedInstance ? null : l)} />
													)
												}
												
												<DrawerBottomLine style={{width: '100%'}}>
													<Grid item xs={12}>
														<span>LESSONS</span>
														<CloseIcon onClick={() => setDrawerState(false)}>X</CloseIcon>
													</Grid>
												</DrawerBottomLine>											
											</Grid>
										</Drawer>
									</MobileView>
								</Grid>
								<Grid item xs={12} md={1}></Grid>
								<Grid item xs={12} md={8}>
									{
										selectedInstance &&
											<div>
												{
													selectedInstance.userMode && selectedInstance.userMode === 'SelfPaced' ?
														<LessonItemDetails lessonItem={selectedInstance} onSettingsChange={(newSettings) => { updateLessonInstanceSettings(newSettings) }} />
													:
														<LessonDetails gameInstance={selectedInstance} />
												}
											</div>
									}
								</Grid>
								<Grid item xs={12} md={1}></Grid>
							</Grid>
						</Grid>
				}
				{
					tab === 'meta' &&
						<Grid item xs={12}>
							<MetaDataForm lessonTemplate={props.game} />
						</Grid>
				}
			</Grid>
		</div>
	)
}

export default LessonTemplateDesigner
