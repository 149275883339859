import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {unstable_batchedUpdates} from 'react-dom'
import Grid from '@material-ui/core/Grid'
import ClearIcon from '@material-ui/icons/Clear'
// import { storage } from '../../../../../utils/firebaseSettings'
import { getFirebaseObj } from '../../../../../utils/firebaseSettings'
import { BaseInput } from '../../../../../components'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import FileUpload from '../../../../../data/firebase/fileUploadFirebase'

const AnswerOptionItem = styled.div`border-radius: 6px; margin: 3px; padding: 3px; height: 80px;
	-webkit-box-shadow: inset 0 0 4px  ${(props) => props.backgroundColor ? props.backgroundColor : 'blue'}; 
`
const Image = styled.img`height: 70px; max-width: 95%; border-radius: 6px; 
	-webkit-box-shadow: inset 0 0 4px  ${(props) => props.backgroundColor ? props.backgroundColor : 'blue'}; 
`
const CheckCorrect = styled(CheckCircleOutlineIcon)`width: 48px !important; height: 48px !important; cursor: pointer; margin-top: 10px;`
const ImageSelectArea = styled.div`padding-top: 16px; height: 72px; width: 100$; `
const DeleteAnswerIcon = styled(DeleteIcon)`cursor: pointer; float: right;` 

const emptyFileObj = {url: '', fileName: ''}
let changeFromInside = false

function AnswerOptionEntry(props) {
	const [optionText, setOptionText] = useState('')
	const [optionImage, setOptionImage] = useState({url: ''})
	const [optionSound, setOptionSound] = useState({url: ''})
	const [optionVideo, setOptionVideo] = useState({url: ''})
	const [aType, setAType] = useState(props.aType)
	
	const [isCorrect, setIsCorrect] = useState(false)

	const [firstLoadDone, setFirstLoadDone] = useState(false)

	useEffect(() => {
		changeFromInside = false
		unstable_batchedUpdates(() => {
			setOptionText(props.value.optionText ? props.value.optionText : '')
			setOptionImage(props.value.optionImage ? props.value.optionImage : {url: ''})
			setOptionSound(props.value.optionSound ? props.value.optionSound :  {url: ''})
			setOptionVideo(props.value.optionVideo ? props.value.optionVideo : {url: ''})
			setIsCorrect(props.value.isCorrect ? props.value.isCorrect : false)
		})
		setFirstLoadDone(true)
	}, [props.value])

	useEffect(() => {
		setAType(props.aType)
	}, [props.aType])
	
	useEffect(() => {
		if (firstLoadDone && changeFromInside) {
			let optionData = {}
			// To reduce the amount of data only necessary parts adding to option data. This data will tranfers to many clients many times. All below conditions is for reducing the data amount
			if (optionText && optionText.length > 0) { optionData.optionText = optionText }
			if (optionImage && optionImage.url && optionImage.url.length > 0) { optionData.optionImage = optionImage }
			if (optionSound && optionSound.url && optionSound.url.length > 0) { optionData.optionSound = optionSound }
			if (optionVideo && optionVideo.url && optionVideo.url.length > 0) { optionData.optionVideo = optionVideo }
			optionData.isCorrect = isCorrect
			
			if (props.onUpdate) {
				props.onUpdate(optionData)
			}
		}
	}, [optionText, optionImage, optionSound, optionVideo, isCorrect])

	const deleteFile = async (fileName) => {
		let firebaseObj = await getFirebaseObj('AnswerOptionEntry fbo_PageBlocks')
		let storage = firebaseObj.storage

		const fileRef = storage.ref(`/MICRO-LESSON-IMAGES/${props.gameTemplateKey}/${fileName}`)
		fileRef.delete().then(() => {

		}).catch((error) => {
			console.log('File could not be deleted.')
		})
	}

	return (
		<AnswerOptionItem backgroundColor={isCorrect ? 'rgba(0, 150, 0, 0.75)' : 'rgba(150, 150, 150, 0.75)'}>
			<Grid container>
				<Grid item xs={props.cType === 'quiz' ? 2 : 1}>
					{
						props.cType === 'quiz' &&
							<CheckCorrect
								onClick={() => { changeFromInside = true; setIsCorrect(!isCorrect)}	}
								style={{color: isCorrect ? 'green' : 'gainsboro' }}
							/>
					}
				</Grid>

				{
					(!optionImage || !optionImage.url || !optionImage.url.length === 0) && // No Image
					(!optionSound || !optionSound.url || !optionSound.url.length === 0) && // No Sound
					(!optionVideo || !optionVideo.url || !optionVideo.url.length === 0) && // No Video
						<Grid item xs={props.cType === 'quiz' ? 8 : 9}>
							<BaseInput onChange={(newValue) => { changeFromInside = true; setOptionText(newValue) }} value={optionText} height="70px" fontSize="20px" maxLength="60" />
						</Grid>
				}
				
				{
					(aType === 'multiple' || aType === 'boolean' || aType === 'order-puzzle') &&
					<>
						{
							optionImage && optionImage.url && optionImage.url.length > 0 ?
								<Grid item xs={9}>							
									<Image src={optionImage.url} alt="answer" backgroundColor={isCorrect ? 'rgba(0, 204, 0, 0.75)' : 'rgba(204, 0, 0, 0.75)'} />
									<ClearIcon color="action" onClick={() => { deleteFile(optionImage.fileName); changeFromInside = true;  setOptionImage(emptyFileObj);  }} title="Kaldır" style={{float: 'right'}} />
								</Grid>
							:
								<Grid item xs={1}>
									<ImageSelectArea>
										{/* <FileUpload onUploadComplete={(e) => { changeFromInside = true;  setOptionImage({ url: e.url, fileName: e.fileName}) } } gameTemplateKey={props.gameTemplateKey} type="question-image" width="30px" height="30px" padding="2px" margin="auto" /> */}
										<FileUpload folder={`/MICRO-LESSON-IMAGES/${props.gameTemplateKey}`} fileType="image" containerType="icon" gameTemplateKey={props.gameTemplateKey} type="answer-option-image"
											width="30px" height="30px" padding="2px" margin="auto" pasteItemId={`answerImageArea${props.index}`}
											onUploadComplete={(e) => { changeFromInside = true; setOptionImage({ url: e.url, fileName: e.fileName}); } }
										/>
									</ImageSelectArea>
								</Grid>		
						}			
					</>
				}
				<Grid item xs={1}>
					<DeleteAnswerIcon style={{fontSize: '14px', color: 'maroon'}}
						onClick={() => {if (optionImage.fileName && optionImage.fileName.length > 0) { deleteFile(optionImage.fileName) }; props.onDelete()}}
					/>
				</Grid>
			</Grid>
		</AnswerOptionItem>
	)
}

export default AnswerOptionEntry