import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {unstable_batchedUpdates} from 'react-dom'
import Grid from '@material-ui/core/Grid'
import ClearIcon from '@material-ui/icons/Clear'
// import { storage } from '../../../../../utils/firebaseSettings'
import { getFirebaseObj } from '../../../../../utils/firebaseSettings'
import { BaseInput, Toggle, FormElementLabel } from '../../../../../components'
import QuestionVideo from '../../../shared/QuestionVideo'
import FileUpload from '../../../../../data/firebase/fileUploadFirebase'

const QuestionContent = styled.div`width: 100%;`
let changeFromInside = false

const videoSoundOptions = [
	{ id: 1, label: 'Video + Ses' },
	{ id: 2, label: 'Sessiz' },
	{ id: 3, label: 'Sadece Ses' },
]

function QuestionEntry(props) {
	const [texts, setTexts] = useState([{text: ''}]) // Screen position etc will be added later
	const [text1, setText1] = useState({text: ''}) // Screen position etc will be added later
		
	const [images, setImages] = useState([])
	const [imageUrl1, setImageUrl1] = useState('')
	const [imageFileName1, setImageFileName1] = useState('')
	
	const [videos, setVideos] = useState([])
	const [videoUrl1, setVideoUrl1] = useState('')
	const [videoStartSecond1, setVideoStartSecond1] = useState(0)
	const [videoEndSecond1, setVideoEndSecond1] = useState(1000000)
	const [videoSoundOption, setVideoSoundOption] = useState(1)

	const [embedUrl, setEmbedUrl] = useState('')

	const [firstLoadDone, setFirstLoadDone] = useState(false)

	useEffect(() => {
		changeFromInside = false
		unstable_batchedUpdates(() => {
			setTexts(props.value.texts ? props.value.texts : [{ text:  '' }] )
			setImages(props.value.images ? props.value.images :  [] )
			setVideos(props.value.videos ? props.value.videos :  [] )
			setEmbedUrl(props.value.embedUrl ? props.value.embedUrl : '')

			setText1((props.value.texts && props.value.texts.length > 0) ? props.value.texts[0] : {text: ''} )

			setImageUrl1((props.value.images && props.value.images.length > 0) ? props.value.images[0].url : '')
			setImageFileName1((props.value.images && props.value.images.length > 0) ? props.value.images[0].fileName : '')

			setVideoUrl1((props.value.videos && props.value.videos.length > 0) ? props.value.videos[0].url : '' )
			setVideoStartSecond1((props.value.videos && props.value.videos.length > 0) ? props.value.videos[0].startSecond : 0 )
			setVideoEndSecond1((props.value.videos && props.value.videos.length > 0) ? props.value.videos[0].endSecond : 1000000 )
			setVideoSoundOption((props.value.videos && props.value.videos.length > 0) ? props.value.videos[0].soundOption : '' )
		})
		setFirstLoadDone(true)
	}, [props.value])
	
	useEffect(() => {
		if (firstLoadDone && changeFromInside) {
			updateQuestionContent()	
		}
	}, [texts, images, videos, imageUrl1, imageFileName1, videoUrl1, videoStartSecond1, videoEndSecond1, videoSoundOption, embedUrl])

	const updateQuestionContent = () => {
		let questionContentData = {}

		// TEXT
		let tempTexts = [...texts]
		if (text1) {
			if (tempTexts.length === 0) { tempTexts.push(text1) } else { tempTexts[0] = text1 }
		} else {
			if (tempTexts.length > 0) { tempTexts[0] = text1 }
		}
		setTexts(tempTexts)
		questionContentData.texts = tempTexts

		// IMAGE
		let tempImages = [...images]
		if (imageUrl1.length > 0) {
			if (tempImages.length === 0) { tempImages.push({url: imageUrl1, fileName: imageFileName1}) } else { tempImages[0] = {url: imageUrl1, fileName: imageFileName1} }
		} else {
			if (tempImages.length > 0) { tempImages.splice(0, 1)}				
		}
		setImages(tempImages)
		questionContentData.images = tempImages

		// VIDEO
		let tempVideos = [...videos]
		if (videoUrl1.length > 0) {
			if (tempVideos.length === 0) { 
				tempVideos.push({url: videoUrl1, startSecond: videoStartSecond1, endSecond: videoEndSecond1, soundOption: videoSoundOption})
			} else {
				tempVideos[0] = {url: videoUrl1, startSecond: videoStartSecond1, endSecond: videoEndSecond1, soundOption: videoSoundOption}
			}
		} else {
			if (tempVideos.length > 0) { tempVideos.splice(0, 1) }
		}			
		setVideos(tempVideos)
		questionContentData.videos = tempVideos

		questionContentData.embedUrl = embedUrl

		if (props.onUpdate) {
			props.onUpdate(questionContentData)
		}
	}

	const deleteFile = async (fileName) => {
		let firebaseObj = await getFirebaseObj('QuestionEntry fbo_PageBlocks')
		let storage = firebaseObj.storage
	
		const fileRef = storage.ref(`/MICRO-LESSON-IMAGES/${props.gameTemplateKey}/${fileName}`)
		fileRef.delete().then(() => {
			setImageUrl1('')
			setImageFileName1('')
			// updateQuestionContent()
		}).catch((error) => {
			console.log('File could not be deleted. error:', error)
		})
	}

	const updateImageData = (url, fileName) => {
		unstable_batchedUpdates(() => {
			setImageUrl1(url)
			setImageFileName1(fileName)
		})
	}

	return (
		<QuestionContent>
			<Grid container>
				<Grid item xs={12} style={{paddingTop: '10px'}}>
					<BaseInput onChange={(newValue) => { setText1({text: newValue} )} } onBlur={() => {changeFromInside = true; updateQuestionContent(); } } value={text1.text} height="40px" fontSize="20px" maxLength="300" />
				</Grid>
				<Grid item xs={12} style={{marginTop: '10px'}}>
					{
						(props.qType === 'image_text') && // Types contain image content
							<div>
								{
									(images && images.length > 0 && images[0].url.length > 0) ?								
										<Grid container>
											<Grid item xs={1}></Grid>
											<Grid item xs={10}>
												<img src={images[0].url} style={{maxHeight: '400px', maxWidth: '100%', marginBottom: '10px', borderRadius: '8px'}} alt="Question Preview" />
											</Grid>
											<Grid item xs={1}>
												<ClearIcon color="action" onClick={() => { changeFromInside = true; deleteFile(images[0].fileName);  }} title="Kaldır" style={{float: 'right', cursor: 'pointer'}} />
											</Grid>
										</Grid>
									:
										<div>
											<FileUpload folder={`/MICRO-LESSON-IMAGES/${props.gameTemplateKey}`} fileType="image" containerType="dropzone" gameTemplateKey={props.gameTemplateKey} type="question-image"
												height="calc(100vh - 650px)" margin="auto" pasteItemId="questionImageArea"
												onUploadComplete={(e) => { changeFromInside = true; updateImageData(e.url, e.fileName) } }
											/>
										</div>
								}
							</div>						
					}

					{ 
						(props.qType === 'video_text') && // Types contain video content
							<Grid container style={{margin: '10px'}}>
								<Grid item xs={12}>
									<FormElementLabel>Video URL</FormElementLabel>
									<BaseInput value={(videos && videos.length > 0 && videos[0].url) ? videos[0].url : ''} onChange={(newValue) => { changeFromInside = true; setVideoUrl1(newValue) } } width="98%" />
								</Grid>
								
								{
									(videoUrl1 && videoUrl1.length > 0) &&
										(
											<Grid item xs={12}>
												<Grid container>
													<Grid item xs={12} md={2}>
														<FormElementLabel>Başlangıç (sn)</FormElementLabel>
														<BaseInput value={(videos && videos.length > 0 && videos[0].startSecond) ? videos[0].startSecond : 0} onChange={(newValue) => { changeFromInside = true; setVideoStartSecond1(newValue) } } type="number" width="98%" />
													</Grid>
													<Grid item xs={false} md={1}></Grid>
													<Grid item xs={12} md={2}>
														<FormElementLabel>Bitiş (sn)</FormElementLabel>
														<BaseInput value={(videos && videos.length > 0 && videos[0].endSecond) ? videos[0].endSecond : 100000} onChange={(newValue) => { changeFromInside = true; setVideoEndSecond1(newValue); } } type="number" width="98%" />
													</Grid>
													<Grid item xs={12} md={1}></Grid>
													<Grid item xs={12} md={6}>
														<FormElementLabel>Ses - Video</FormElementLabel>
														<Toggle options={videoSoundOptions} onChange={(newValue) => {changeFromInside = true; setVideoSoundOption(newValue) } } value={videoSoundOption} />
													</Grid>
												</Grid>
											</Grid>
										)
								}

								<Grid item xs={12} style={{marginTop: '10px'}}>
									{
										(videoUrl1 && videoUrl1.length > 0) &&
											<QuestionVideo playing={false} videoInfo={videos[0]} useMaxScreen={false} mode="active" controlType="host" videoVolume={1} />
									}
								</Grid>
							</Grid>
					}
					{
						(props.qType === 'embed_url') && // Types contain image content
							<div>
								<div>
									<FormElementLabel>URL</FormElementLabel>
									<BaseInput value={(embedUrl) ? embedUrl : ''} onChange={(newValue) => { changeFromInside = true; setEmbedUrl(newValue) } } width="98%" />
								</div>
								{
									(embedUrl && embedUrl.length > 0) ?								
										<div>
											<iframe src={embedUrl} style={{width: '100%', height: '300px'}} title="Embedded URL" frameBorder="0" border="0" cellSpacing="0" />
										</div>
									:
										<div style={{width: '100%', height: '300px'}}>Waiting URL</div>
								}
							</div>						
					}
				</Grid>
			</Grid>
		</QuestionContent>
	)
}

export default QuestionEntry