import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { H1 } from '../../components'
import { AuthContext } from '../../data/context/AuthProvider'

function StudentInfo(props) {
	const [authData] = useContext(AuthContext)
	const [userData, setUserData] = useState()
	
	useEffect(async () => {
		if (props.userData) {
			setUserData(props.userData)
		}
	}, [props.userData])

	return (
		<div>
			{
				userData &&
					<Grid container>							
						<Grid item xs={12}>
							<H1 style={{fontSize: '32px'}}>
								{userData.name} &nbsp;&nbsp;
								{
									authData.email && authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0) &&
										<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fusers~2F${userData.email}`} target="_blank" rel="noopener noreferrer" style={{fontSize: '11px'}}>
											{userData.email}
										</a>
								}
							</H1>
						</Grid>
						<Grid item xs={12} style={{border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
							<Grid container>
								<Grid item xs={12} sm={4}>
									<b>Veli Adı:</b> {userData.parentName}
								</Grid>
								<Grid item xs={12} sm={4}>
									<b>Ülke/Şehir:</b> {userData.location ? userData.location.country_name + ' > ' + userData.location.region + ' > ' + userData.location.city : ''}
								</Grid>
								<Grid item xs={12} sm={4} style={{overflow: 'hidden'}}>
									<Tooltip title={userData.phone}>
										<span>
											<b>Email:</b> {userData.email}
										</span>
									</Tooltip>	
								</Grid>
								<Grid item xs={12} sm={3} style={{overflow: 'hidden'}}>
									<Tooltip title={userData.phone}>
										<span>
											<b>Telefon:</b> {userData.phone}
										</span>
									</Tooltip>
								</Grid>
								<Grid item xs={12} sm={1}>
									<b>Yaş:</b> {userData.age ? userData.age.label : ''}
								</Grid>

								<Grid item xs={12} sm={4} style={{overflow: 'hidden'}}>
									<Tooltip title={(userData.mentorship && userData.mentorship.mentor) ? userData.mentorship.mentor : 'Rehber Atanmamış' }>
										<span>
											<b>Rehber:</b>
											{(userData.mentorship && userData.mentorship.mentor) ? userData.mentorship.mentor : 'Rehber Atanmamış' }
										</span>
									</Tooltip>
								</Grid>

								<Grid item xs={12} sm={4}>
									<b>Kayıt Tarihi:</b> {moment(userData.created).format('YYYY-MM-DD hh:mm a')}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
			}
		</div>
	)
}

export { StudentInfo }
