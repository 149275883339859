import React, { useState, useEffect, useContext } from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import Input from '@material-ui/core/Input'
import styled from 'styled-components'
import { AuthContext } from '../../data/context/AuthProvider'
import { addNewCommentForUser, deleteCommentForUser } from '../../data/firebase/commentsForUsersFirebase'

const ListItem = styled.div`float: left; margin: 2px; border: solid 1px gray; border-radius: 4px; min-height: 30px; cursor: pointer;`
const ListItemNumber = styled.b`border-right: solid 1px gray; font-weight: bold; padding-right: 6px;`
const ListItemText = styled.span`padding-left: 6px;`
const RemoveListItemArea = styled.div`background-color: #c27272; width: 20px; float: right; border: solid 1px #c27272; border-radius: 4px; height: 100%; margin-left: 10px; min-height: 30px; padding-top: 5px; cursor: pointer;
	&:hover { background-color: #d10404; }
`
const NewListItemInput = styled(Input)`font-family: Nunito; margin: 2px; padding: 4px; font-size: 12px; float: left; width: 150px; heigth: 24px; border-radius: 4px; max-width: 95%; overflow: auto; text-overflow: ellipsis; border: solid 1px #ad0085;`

function CommentsAboutStudent(props) {
	const [authData] = useContext(AuthContext)

	const [listItem, setListItem] = useState('')
	const [listItems, setListItems] = useState([])

	useEffect(() => {
		if (props.itemList !== listItems) {
			setListItems(props.itemList)
		}
	}, [props.itemList])


	const onNewListKeyPress = async (event) => {
		if (event.charCode  === 13  && listItem && listItem.length > 0 && listItems.indexOf(listItem) === -1) {
			let newCommentResult = await addNewCommentForUser(props.studentUserData, authData, {  content: listItem, category: 'teacherToStudent', level: 'teacher' })
			setListItems([...listItems, newCommentResult.newCommentForUser])
			setListItem('')
		}
	}

	const onNewListItemChange = async (e) => {
		setListItem(e.target.value)
	}

	const removeListItem = async (commentDocId, i) => {
		await deleteCommentForUser(commentDocId, authData.email)
		setListItems(listItems.filter((_listItem, index) => index !== i))
	}

	return(
		<div style={{width: '100%'}}>		
			{
				(listItems && listItems.length > 0) && listItems.map((_listItem, index) => 
					<ListItem key={index} style={{width: (props.onePerLine ? '100%' : '')}} >
						<div style={{float: 'left', paddingLeft: '5px', marginTop: '5px'}}>
							{
								props.showNumbers ?
									<ListItemNumber>{index + 1}</ListItemNumber>
									:
									<ListItemNumber> </ListItemNumber>
							}
							<ListItemText>{_listItem.comment.content}</ListItemText>
						</div>
						{
							props.editable && authData.email === 'yesilokulcom@gmail.com' &&
								<RemoveListItemArea>
									<ClearIcon onClick={() => {removeListItem(_listItem.id, index)}} style={{fontSize: '18px', color: 'white'}} />
								</RemoveListItemArea>
						}
					</ListItem>
				)
			}
			
			{
				props.editable &&
					<NewListItemInput type="text" onKeyPress={(e) => onNewListKeyPress(e)} onChange={(e) => onNewListItemChange(e)} value={listItem} maxLength="50"
						style={{width: (props.onePerLine ? '100%' : '')}} 
					/>
			}
			<div style={{clear: 'both'}}></div>
		</div>
	)
}

export { CommentsAboutStudent }
