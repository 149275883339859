import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import QuestionVideo from '../../../shared/QuestionVideo'
import { H2 } from '../../../../../components'

const QuestionObject = styled.div`margin-top: 30px; border: solid 1px black; border-radius: 6px; padding: 5px; margin-top: 15px;`
const StudentListPart = styled(Grid)`border: solid 1px gainsboro; border-radius: 4px; margin-top: 10px;`

function OneQuestionResult(props) {
	const [playerResults, setPlayerResults] = useState([])
	const [nonAnsweredPlayers, setNonAnsweredPlayers] = useState([])
	const [stats, setStats] = useState()
	const [texts, setTexts] = useState([''])
	const [images, setImages] = useState([])
	const [videos, setVideos] = useState([])

	useEffect(() => {
		if (props.question) {
			getPlayerResultsForQuestion(props.questionIndex + props.answerIndexOffset)
			if (props.question.questionContent) {
				if (props.question.questionContent.texts && props.question.questionContent.texts.length > 0) { setTexts(props.question.questionContent.texts) }
				if (props.question.questionContent.images && props.question.questionContent.images.length > 0) { setImages(props.question.questionContent.images) }
				if (props.question.questionContent.videos && props.question.questionContent.videos.length > 0) { setVideos(props.question.questionContent.videos) }
			}
		}
	}, [props.question])

	const getPlayerResultsForQuestion = (questionId) => {
		var tempAllPlayers = props.lessonResults //  getPlayerList(props.lessonInstance.players)
		var tempPlayerAnswers = []
		let tmpNonAnsweredPlayers = []
		let correctCount = 0
		tempAllPlayers.map((player, index) => {
			if (player.answers && player.answers[questionId]) {
				let tempPlayer = {
					email: player.email,				
					name: player.name,
					score: player.answers[questionId].score,
					answer: player.answers[questionId]
				}
				tempPlayerAnswers.push(tempPlayer)

				if (player.answers[questionId] && player.answers[questionId].answer && player.answers[questionId].answer.isCorrect) {
					correctCount++
				}
			} else {
				let tempPlayer = {
					email: player.email,				
					name: player.name
				}
				tmpNonAnsweredPlayers.push(tempPlayer)
			}
			return null
		})
		tempPlayerAnswers = tempPlayerAnswers.sort((a, b) => ((b.score < a.score) ? -1 : (b.score > a.score) ? 1 : 0))
		setPlayerResults(tempPlayerAnswers)
		setNonAnsweredPlayers(tmpNonAnsweredPlayers)
		setStats({
			studentCount: tempAllPlayers.length,
			answerCount: tempPlayerAnswers.length,
			correctAnswerCount: correctCount,
			correctPercentageInAllPlayers: ((correctCount*100)/tempAllPlayers.length),
			correctPercentageInAnswers: ((correctCount*100)/tempPlayerAnswers.length)
		})
	}
    return (
		<QuestionObject>
			<Grid container>
				<Grid item xs={12} style={{fontSize: '16px', borderBottom: 'solid 1px gainsboro', fontWeight: 'bold'}}>
					{
						(texts[0].text && texts[0].text.length > 0) ?
							<span>{props.questionIndex + 1} - {texts[0].text}</span>
						:
							<span>{props.questionIndex + 1} - </span>
					}
				</Grid>
				<Grid item xs={6} style={{padding: '10px'}}>
					{
						(props.question.qType === 'video_text') && videos && videos.length > 0 &&
							<QuestionVideo playing={props.videoPlaying} question={props.question} videoInfo={videos[0]} onEnd={props.onVideoEnd}
								useMaxScreen={props.videoUseMaxScreen} controlType={props.videoControlType} mode={props.mode} videoVolume={props.videoVolume}
							/>
					}
					{
						(props.question.qType === 'image_text') && images && images.length > 0 &&
							<img style={{maxWidth: '60%', maxHeight: '150px', border: 'solid 1px gainsboro', borderRadius: '4px' }} src={images[0].url} alt={texts[0].text} />
					}
				</Grid>
				
				<Grid item xs={6}>
					{
						stats && (props.question.cType === 'quiz') &&
							<span>{stats.correctAnswerCount} Doğru / {stats.answerCount} Cevaplayan - Toplam Öğrenci Sayısı: {stats.studentCount}</span>
					}
				</Grid>
				
				{
					props.showStudentListDetails === true && (props.question.cType === 'quiz' || props.question.cType === 'poll' || props.question.cType === 'form') &&
						<StudentListPart item xs={12}>
							{
								playerResults && playerResults.length > 0 &&
									<div style={{paddingLeft: '5px'}}>
										<H2 style={{color: 'green'}}>CEVAPLAR</H2>
										{
											playerResults.map((player, pindex) =>
												<div key={pindex} style={{marginLeft: '5px'}}>
													{pindex+1}- {player.email && player.email.length > 0 ? <VerifiedUserIcon style={{color: '#3dc706', fontSize: '14px'}} /> : <RemoveCircleIcon style={{color: 'red', fontSize: '14px'}} />} {player.name} ({player.email})
													<span>
														&nbsp;(
														<span style={{fontWeight :'bold', fontSize: '16px', color: (player.answer.score > 0 ? 'green' : 'red')}}>
															{player.answer.score}
														</span>&nbsp;-&nbsp;
														{player.answer && player.answer.answer && 
															player.answer.answer.optionText ? 
																player.answer.answer.optionText
															:
																(
																	player.answer && player.answer.answer && player.answer.answer.freeText ?
																		player.answer.answer.freeText
																	:
																		player.answer.answer.uploadedImage ?
																			<a href={player.answer.answer.uploadedImage.url} target="_blank" rel="noopener noreferrer" >
																				<img src={player.answer.answer.uploadedImage.thumb_url} alt="Uploaded answer" />
																			</a>
																		:
																			''
																)
														}
														)
														&nbsp;
													</span>
												</div>
											)
										}
									</div>
							}
							{
								nonAnsweredPlayers && nonAnsweredPlayers.length > 0 &&
									<div style={{paddingLeft: '5px'}}>
										<H2 style={{color: 'red'}}>CEVAP VERMEYENLER</H2>
										{
											nonAnsweredPlayers.map((player, pindex) =>
												<div key={pindex} style={{marginLeft: '5px'}}>
													{pindex+1}- {player.email && player.email.length > 0 ? <VerifiedUserIcon style={{color: '#3dc706', fontSize: '14px'}} /> : <RemoveCircleIcon style={{color: 'red', fontSize: '14px'}} />} {player.name} ({player.email})
													<span>
														&nbsp;(<span style={{fontWeight :'bold', fontSize: '16px', color: 'red'}}>0</span> - '---')&nbsp;
													</span>
												</div>
											)
										}
									</div>
							}
						</StudentListPart>
				}
				
			</Grid>
		</QuestionObject>
    )
}

export { OneQuestionResult }
