import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const QuestionComp = styled.div`text-align: left; cursor: pointer;
    border: ${(props) => props.selected ? 'solid 2px #99bbff' : 'solid 1px gray'}; 
    background-color: ${(props) => props.selected ? '#e3f3ff' : '#ffffff'};
    border-radius: 4px;
    width: 99%;
`
const Content = styled.div`height: 110px; `
const Footer = styled.div`height: 22px; overflow: hidden; padding-right: 10px; padding-bottom: 1px;
    border-top: ${(props) => props.selected ? 'solid 2px #99bbff' : 'solid 1px gray'}; 
`

const QuestionText = styled.div`font-size: 12px; text-align: center; margin: 2px; height: 16px; overflow: hidden; margin-left: 5px; margin-right: 5px; margin-top: 5px;
    font-weight: ${(props) => props.selected ? 'bold' : ''}; 
`
const QuestionTime = styled.div`font-size: 9px; color: red; text-align: center; width: 20px; border: solid 1px blue; border-radius: 10px; margin 0 auto;
    @media (max-width: 960px) { font-size: 8px; }
`
const Point = styled.div`font-size: 9px; color: green; text-align: center; max-width: 100%; width: 34px; border: solid 1px green; border-radius: 10px; margin-right: 5px; padding-top: 1px; overflow: hidden; margin 0 auto;
    @media (max-width: 960px) { font-size: 8px; }
`
const QuestionImageArea = styled.div`height: 50px; width: 100%; text-align: center; justify-content: center; overflow: hidden;
    border: ${(props) => props.selected ? 'solid 1px #ffffff' : 'solid 1px gainsboro'}; 
`
const QuestionImage = styled.img`max-width: 100%; max-height: 100%; margin: auto`
const AnswerOption = styled.div`margin-left: 3px; margin-right: 3px; font-size: 10px; height: 14px; overflow: hidden; border-radius: 4px; margin-top: 2px; padding-left: 3px; padding-top: 1px;
    border: ${(props) => `solid 1px ${props.color}` }; 
    color:  ${(props) => props.color}; 
`
const EmbeddedItemInfo = styled.div`height: 50px; width: 100%; text-align: center; justify-content: center; overflow: hidden; font-size: 11px;
    border: ${(props) => props.selected ? 'solid 1px #ffffff' : 'solid 1px gainsboro'};`

const Info = styled.div`float: left; margin-left: 4px;`
const Number = styled.span`border-radius: 60px; text-align: center; padding: 1px; margin-bottom: 2px;
    border: ${(props) => props.selected ? '' : 'solid 1px #2b4db3'};
    background-color: ${(props) => props.selected ? '#2b4db3' : '#ffffff'};
    color: ${(props) => props.selected ? '#ffffff' : '#2b4db3'};
    font-size: ${(props) => props.selected ? '12px' : '11px'};
`
const Buttons = styled.div`text-align: right; width: 100%; padding: 0px; `
const IconButton = styled.span`cursor: pointer; margin-left: 10px; margin-right: 10px;
    display: ${(props) => props.selected ? 'block' : 'none'};
`

function QuestionListItem(props) {
    const [openDialog, setOpenDialog] = useState(false)
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        setIsSelected(props.activeQuestion && props.question.id === props.activeQuestion.id)
    }, [props.activeQuestion, props.question.id])

    const cType = props.question.cType
    const qType = props.question.qType
    const aType = props.question.aType
    
    return (
        <QuestionComp selected={isSelected} id={props.id}>
            <Content onClick={() => { props.selectQuestion(props.question) }}>
                <Grid container>
                    <Grid item xs={12}>
                        <QuestionText style={{height: ((qType === 'text') ? '50px' : '18px')  }} selected={isSelected}>
                            {(props.question.questionContent && props.question.questionContent.texts) ? (props.question.questionContent.texts[0].text.length > 30 ? props.question.questionContent.texts[0].text.substring(0, 30) + '...' : props.question.questionContent.texts[0].text) : ''}
                        </QuestionText>
                    </Grid>
                    <Grid item xs={3}>
                        {
                            (cType === 'quiz' || cType === 'poll' || cType === 'form') && props.showMeta !== false &&
                                <QuestionTime>
                                    {(props.question.qOptions && props.question.qOptions.timeLimit) ? props.question.qOptions.timeLimit + '' : '-'}
                                </QuestionTime>
                        }
                    </Grid>

                    <Grid item xs={6}>
                    {
                        (qType === 'image_text') &&                    
                            <QuestionImageArea selected={isSelected}>
                                {
                                    props.question.questionContent && props.question.questionContent.images && props.question.questionContent.images.length > 0 && props.question.questionContent.images[0].url && props.question.questionContent.images[0].url.length > 0 &&
                                        <QuestionImage src={props.question.questionContent.images[0].url} alt="Question Preview" />
                                }
                            </QuestionImageArea>
                    }
                    {
                        (qType === 'video_text') &&
                            <QuestionImageArea selected={isSelected}>
                                {
                                    props.question.questionContent && props.question.questionContent.videos && props.question.questionContent.videos.length > 0 && props.question.questionContent.videos[0].url && props.question.questionContent.videos[0].url.length > 0 &&
                                        props.question.questionContent.videos[0].url.includes("youtube.com/watch") ?
                                            <img src={ props.question.questionContent.videos[0].url.replace('https://www.youtube.com/watch?v=', 'https://i.ytimg.com/vi/') + '/hqdefault.jpg' } style={{width: 80}} alt="Video" />
                                        :
                                            <div>VIDEO</div>
                                }
                            </QuestionImageArea>
                    }
                    {
                        (qType === 'embed_url') &&
                            <EmbeddedItemInfo selected={isSelected}>
                                {props.question.questionContent && props.question.questionContent.embedUrl}
                            </EmbeddedItemInfo>
                    }
                    </Grid>

                    <Grid item xs={3}>
                        {
                            (cType === 'quiz' || cType === 'poll' || cType === 'form') && props.showMeta !== false &&
                                <Point>
                                    {(props.question.qOptions && props.question.qOptions.point) ? props.question.qOptions.point + '' : '0'}
                                </Point>
                        }
                    </Grid>
                    {
                        (cType === 'quiz' || cType === 'poll' || cType === 'form') && (aType !== 'image-upload') && props.showAnswers !== false &&
                            <Grid item xs={12}>
                                <Grid container>
                                    {
                                        props.question.answerOptions && props.question.answerOptions.map((answer, index) =>
                                            (
                                                (index < 4) &&
                                                    <Grid item xs={6} key={index}>
                                                        <AnswerOption color={cType === 'quiz' ? (answer.isCorrect ? 'green' : 'red') : 'black' }>
                                                            {
                                                                answer.optionImage && answer.optionImage.url ?
                                                                    answer.optionImage.url.length > 0 ?
                                                                        <img src={answer.optionImage.url} style={{ height: '15px' }} alt="Correct Answer" />
                                                                    :
                                                                        <span>-</span>
                                                                : 
                                                                    <span>{answer.optionText ? answer.optionText : ''}</span>
                                                            }
                                                        </AnswerOption>
                                                    </Grid>
                                            )
                                        )
                                    }
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Content>
            <Footer selected={isSelected}>
                <Info>
                    <Number selected={isSelected}>
                        &nbsp;{parseInt(props.question.id)}&nbsp;
                    </Number>
                </Info>
                <Buttons>
                    {
                        props.showDelete !== false &&
                            <IconButton onClick={() => setOpenDialog(true) } selected={isSelected} >
                                <DeleteIcon style={{fontSize: '12px', marginTop: '0px', color: 'maroon'}}/>
                            </IconButton>
                    }
                </Buttons>
            </Footer>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"Silmek istediğinizden emin misiniz?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Slayt içeriğini silmek istediğinizden emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Iptal
                    </Button>
                    <Button onClick={() => { props.deleteQuestion(props.question); setOpenDialog(false)}} color="primary" autoFocus>
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        </QuestionComp>
    )
}

export default QuestionListItem
