import styled from 'styled-components'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const MainAppView = styled.div`font-family: Nunito; width: 100%;`

const Clearfix =  styled.div`clear:both`
const EmptySpace =  styled.div`content: ' '; white-space: pre;
	margin-top: ${(props) => props.marginTop ? props.marginTop : '10px'};
	margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '10px'};`
const Divider =  styled.div`margin-top:10px; margin-bottom:10px;border-bottom: solid 1px gray; width: 100%;`
const BlockDivider1 = styled.div`height: 65px;  
-webkit-box-shadow: 6px 6px 25px 0px rgba(194,155,219,0.75); -moz-box-shadow: 6px 6px 25px 0px rgba(194,155,219,0.75); box-shadow: 6px 6px 25px 0px rgba(194,155,219,0.75);`
const Centered = styled.div`margin-top: 10px; text-align: center; width: 100%;`


const PageContent = styled.div`justify-content: center; align-items: center;`
const Block = styled.div`width: 100; text-align: center; margin-top: 20px; text-align: center; width: 100%;`

const H1 = styled.div`font-size: 22px; font-weight: bold; width: 100%; text-align: center;`
const H2 = styled.div`font-size: 18px; font-weight: bold; width: 100%;`
const LogoImg = styled.img`max-height: 48px; text-decoration: none;
	float: ${(props) => props.float ? props.float : 'right'};
`

const PageTitle1 = styled.div`font-weight: bold; font-size: 18px; margin-top: 40px; margin-bottom: 20px;`

const BaseListItem = styled.div`width: 100%; height: 30px; border: solid 1px gainsboro; border-radius: 10px; margin-bottom: 5px; padding-top: 5px; cursor: pointer; text-align: left; padding-left: 15px;
	&:hover { background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%); color: #ffffff; }
	@media (max-width: 1016px) {
		height: 45px;
	}
`

const BaseDiv = styled.div`border: initial; border-radius: 4px; background: white; padding-top: 5px; padding-left: 10px; padding-bottom: 3px;
	margin-top: 10px; margin-left: 5px; margin-right: 5px; text-align: left;
	float: ${(props) => props.float ? props.float : 'left'};
	border: ${(props) => props.border ? props.border : 'solid 1px #ffffff;'};

	@media (max-width: 1016px) {
		margin-left: 1px !important;
	}
	@media (min-width: 1017px) {
		width: ${(props) => props.width ? props.width : '98%'};
	}
`
const CardDiv = styled(BaseDiv)` -webkit-box-shadow: 2px 2px 5px 0px #cccccc; -moz-box-shadow: 2px 2px 5px 0px #cccccc; box-shadow: 2px 2px 5px 0px #cccccc;
	background: ${(props) => props.background ? props.background : 'transparent'};
	@media (max-width: 1016px) {
		padding-right: 3px; padding-left: 3px; padding-bottom: 10px;
	}
	@media (min-width: 1017px) {
		padding-right: 10px;
		padding-left: 10px;
		padding-bottom: 10px;
	}
`
const CardGroup = styled(BaseDiv)`padding-bottom: 10px; 
	text-align: ${(props) => props.contentAlign ? props.contentAlign : ''};
`
const CenteredContent = styled.div`margin: 0 auto; display: inline-block; text-align: left;`

const CardTitle = styled.div`font-size:16px; font-weigt: bold; text-align: right; font-weight: bold; border-radius: 4px 4px 0px 0px;
	margin-top: -5px; margin-left: -10px; 
	background: ${(props) => props.background ? props.background : 'transparent'};	
	padding-right: ${(props) => props.background ? '15px' : '0px'};	
	width: ${(props) => props.background ? "102%" : '100%'};
	color: ${(props) => props.color ? props.color : 'black'};
`

const DisablingDiv = styled.div`display: none;  z-index:1001;
    position: absolute; top: 0%; left: 0%; width: 100%;  height: 3000px;  
    background-color: red; opacity:.05;  filter: alpha(opacity=05); `

// Form Elements
const FormLine = styled.div` margin-top: 25px;
	@media (max-width: 1016px) {
		width: 100%;
	}
	@media (min-width: 1017px) {
		margin-left: 10px; margin-right: 10px;
		width: calc(100% - 20px);
	}
`
const FormElementLabel = styled.div`font-size: 16px; font-weight: bold; margin-left: 10px; text-align: left; margin-bottom: 5px;
	color: ${(props) => props.color ? props.color : 'navy'};
`
const FormElementDesc = styled.div`font-size: 12px; color: #444444;
	margin-top: ${(props) => props.marginTop ? props.marginTop : '5px'};
`
const Required = styled.span`color: red;`
const BaseTextArea = styled.textarea`border: solid 1px gainsboro; border-radius: 6px; height: 80px; font-size: 18px; padding-left: 10px; color:black; -webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25); width: 100%; font-family: Nunito;`
const BaseButton = styled.input`border-radius: 6px; font-weight: bold; background-color: white; margin-left: 5px; font-family: Nunito; text-align: center; cursor: pointer;
	font-size: ${(props) => props.fontSize ? props.fontSize : '16px' };
	height: ${(props) => props.height ? props.height : '36px' };
	color : ${(props) => props.color ? props.color : 'navy' };
	-webkit-box-shadow: inset 0 0 18px  ${(props) => props.shadowColor ? props.shadowColor : 'blue'}; 
	width: ${(props) => props.width ? props.width : '100%' };
	margin-top: ${(props) => props.marginTop ? props.marginTop : '10px' };
	&:hover { background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%); color: #ffffff; }
`
const BaseLink = styled(Link)`cursor: pointer;`
const BaseButtonLink = styled(Link)`width: 100%; max-width: 320px; border: solid 1px #188fff; border-radius: 6px; height: 40px; font-size: 18px; font-weight: bold;  background: #188fff; color: #ffffff; font-family: Nunito; text-decoration: none;
	padding-right: 15px; padding-left: 15px; padding-top: 8px; padding-bottom: 8px; `
const FormFieldError = styled.span`color: red;`

const BaseDatePickerField = styled(DatePicker)`font-size: 20px; border: solid 1px gainsboro; border-radius: 6px; font-size: 15px; font-weight: bold; height: 38px; text-align: center; cursor: pointer; z-index: 9999;`

const DrawerContentArea = styled.div`padding: 5px; margin: 5px; height: calc(95vh - 45px); max-width: 80vw; overflow: auto;
	@media (max-width: 767px) {
		max-width: 90vw;
	}
`
const DrawerBottomLine = styled.div`padding: 10px; text-align: center; border-radius: 10px 10px 0 0; min-width: 40vw;
	background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%); color: #ffffff;
	position: absolute; bottom: 0px; height: 45px; max-width: calc(100% - 20px);
`
const CloseIcon = styled.div`font-size: 18px; float: left; border: solid 2px white; color: white;  border-radius: 24px; width: 28px; text-align: center; cursor: pointer; font-weight: bold; margin-left: 5px;
	:hover {background-color: red; color: white; border: solid 2px red;}
`

export {
	MainAppView,
	Clearfix, EmptySpace, Divider, BlockDivider1, Centered, PageContent, Block,
	H1, H2, LogoImg, PageTitle1, BaseListItem,
	BaseDiv, CardDiv, CardGroup, CenteredContent, CardTitle,
	DisablingDiv,
	FormLine, FormElementLabel, FormElementDesc, Required, BaseTextArea, BaseButton, BaseLink, BaseButtonLink, FormFieldError, BaseDatePickerField,
	DrawerContentArea, DrawerBottomLine, CloseIcon
}