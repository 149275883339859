const filterStudentCoursesFree = async (studentCourses) => {
	let tempStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.listingPrice && _studentCourse.listingPrice.basePrice === 0 && _studentCourse.status === 'paid' )
	tempStudentCourses = tempStudentCourses.sort((a,b) => a.additionDate < b.additionDate ? 1 : -1)
	return tempStudentCourses
}

const filterStudentCoursesPaid = async (studentCourses) => {
	let tempStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.listingPrice && _studentCourse.listingPrice.basePrice && _studentCourse.listingPrice.basePrice > 0 && _studentCourse.status === 'paid' )
	tempStudentCourses = tempStudentCourses.sort((a,b) => a.additionDate < b.additionDate ? 1 : -1)
	return tempStudentCourses
}

const filterStudentCoursesShoppingCart = async (studentCourses) => {
	let tempStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.listingPrice && _studentCourse.listingPrice.basePrice && _studentCourse.listingPrice.basePrice > 0 && _studentCourse.status === 'shopping-cart' )
	tempStudentCourses = tempStudentCourses.sort((a,b) => a.additionDate < b.additionDate ? 1 : -1)
	return tempStudentCourses
}

const filterStudentCoursesFreeRegistered = async (studentCourses) => {
	let tempStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.listingPrice && _studentCourse.listingPrice.basePrice && _studentCourse.listingPrice.basePrice > 0 && _studentCourse.status === 'free' )
	tempStudentCourses = tempStudentCourses.sort((a,b) => a.additionDate < b.additionDate ? 1 : -1)
	return tempStudentCourses
}

const filterStudentCoursesPaidByCreditCard = async (studentCourses) => {
	let tempStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.listingPrice && _studentCourse.listingPrice.basePrice && _studentCourse.listingPrice.basePrice > 0 && _studentCourse.status === 'paid' && _studentCourse.paymentMethod === 'credit-card' )
	tempStudentCourses = tempStudentCourses.sort((a,b) => a.additionDate < b.additionDate ? 1 : -1)
	return tempStudentCourses
}

const filterStudentCoursesPaidByPaypal = async (studentCourses) => {
	let tempStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.listingPrice && _studentCourse.listingPrice.basePrice && _studentCourse.listingPrice.basePrice > 0 && _studentCourse.status === 'paid-paypal' )
	tempStudentCourses = tempStudentCourses.sort((a,b) => a.additionDate < b.additionDate ? 1 : -1)
	return tempStudentCourses
}

const filterStudentCoursesPaidByCash = async (studentCourses) => {
	let tempStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.listingPrice && _studentCourse.listingPrice.basePrice && _studentCourse.listingPrice.basePrice > 0 && _studentCourse.status === 'paid-cash' )
	tempStudentCourses = tempStudentCourses.sort((a,b) => a.additionDate < b.additionDate ? 1 : -1)
	return tempStudentCourses
}

const filterStudentCoursesWaitingPayment = async (studentCourses) => {
	let tempStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.listingPrice && _studentCourse.listingPrice.basePrice && _studentCourse.listingPrice.basePrice > 0 && (_studentCourse.status === 'will-be-paid-cash' || _studentCourse.status === 'will-be-paid-paypal') )
	tempStudentCourses = tempStudentCourses.sort((a,b) => a.additionDate < b.additionDate ? 1 : -1)
	return tempStudentCourses
}

const getStudentEmailsFromStudentCourseList = async (studentCourses) => {
	let tmpStudentEmails = []
	for (let i = 0; i < studentCourses.length; i++) {
		if (tmpStudentEmails.indexOf(studentCourses[i].email) === -1) {
			tmpStudentEmails.push(studentCourses[i].email)
		}
	}
	return tmpStudentEmails
}

const injectStudentDataIntoStudentCourses = async (studentCourses, students) => {
	let tmpStudentCourses = [...studentCourses]
	for (let i = 0; i < tmpStudentCourses.length; i++) {
		let studentData = students.filter(students => students.email === tmpStudentCourses[i].email)
		if (studentData && studentData.length > 0) {
			tmpStudentCourses[i].studentData = studentData[0]
		}
	}

	return tmpStudentCourses
}


export { filterStudentCoursesFree, filterStudentCoursesPaid, filterStudentCoursesShoppingCart,
	filterStudentCoursesFreeRegistered, filterStudentCoursesPaidByCreditCard, filterStudentCoursesPaidByPaypal, filterStudentCoursesPaidByCash,
	filterStudentCoursesWaitingPayment,

	getStudentEmailsFromStudentCourseList, injectStudentDataIntoStudentCourses,
}