import React, { useEffect } from 'react'
import Chart from 'react-apexcharts'
import ApexCharts from 'apexcharts'

function AnswersBarChart(props) {
	const defaultOptions = {
		chart: {
			id: 'answerChart',
			type: 'bar',
			stacked: true,
			background: '#fff',
			fontFamily: 'Nunito',
			foreColor: '#000',
			
			toolbar: {
				show: true,
			},
			dropShadow: {
				enabled: true,
				top: 0,
				left: 0,
				blur: 1,
				opacity: 0.7
			},
		},
		colors: ['#4CAF50', '#EC7063', '#808B96'],
		plotOptions: {
			bar: {
				horizontal: true,
				columnWidth: '35px',
				endingShape: 'rounded',
			},
		},
		grid: {
			xaxis: {
				lines: {
					show: true
				}
			},
			yaxis: {
				lines: {
					show: true
				}
			},
		},
		dataLabels: {
			enabled: true,
			style: {
				colors: ['#fff'],
				fontSize: '14px',
			},				
			offsetX: 0,
			dropShadow: {
				enabled: true
			}
		},
		xaxis: {
			categories: props.studentNames,
			labels: {
				style: {
					fontSize: '13px',
					fontWeight: 'bold',
					textAlign: 'left',
				},
			}
		},
		yaxis: {
			labels: {
				style: {
					fontSize: '13px',
					fontWeight: 'bold',
				}
			},
			max: props.maxPoint ? props.maxPoint : '',
		},
		tooltip: {
			y: {
			}
		},
		fill: {
			opacity: 1
		},
		legend: {
			position: 'bottom',
			horizontalAlign: 'right',
			offsetX: 10
		}
	}

	const tempSeries = [
		{
			name: 'Total Score - Correct',
			data: props.points
		}, {
			name: 'Missed Points - Wrong, Empty or Time Missed',
			data: props.missedPoints
		}, {
			name: 'Not Seen Yet',
			data: props.nonSeenPoints
		},
	]

	useEffect(() => {
		ApexCharts.exec('answerChart', 'hideSeries', 'Missed Points - Wrong, Empty or Time Missed')
		ApexCharts.exec('answerChart', 'hideSeries', 'Not Seen Yet')
	}, [])

	return (
		<Chart options={defaultOptions} series={tempSeries} type="bar" />
	)
}

export default AnswersBarChart
