import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import QuestionListItem from './QuestionListItem'
import QuestionContent from './QuestionContent'
import AnswerOptionList from './AnswerOptionList'

const QuestionCard = styled.div`margin-bottom: 30px; margin-left: 2px; width: calc(100% - 4px);`
const EmptyItem = styled.div`height: 40px; font-size: 18px; text-align: center; border: solid 1px gainsboro; width: 93%; border-radius: 6px; margin-top: 3px; line-height: 40px;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);
`

const ContentArea = styled(Grid)`height: calc(100vh - 60px); text-align: center; vertical-align: middle; display: table-cell; width: calc(100% - 10px); position: relative; overflow: auto; margin: 5px; border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);	
	@media (max-width: 1016px) {
		height: calc(100vh - 60px);
	}
`

function PrevQuestionList(props) {
	const [activeQuestion, setActiveQuestion] = useState()
	useEffect(() => {
		if (props.onActiveQuestionChange) {
			props.onActiveQuestionChange(activeQuestion)
		}
	}, [activeQuestion])

	useEffect(() => {
		if (props.mode !== 'active' && props.content === 'question-list' && props.gameInstance.uiState.drawerQuestionList) {
			setActiveQuestion(props.gameInstance.quiz.questions[props.gameInstance.uiState.drawerActiveQuestionId-1]) 
		}
	}, [props.gameInstance.uiState && (props.gameInstance.uiState.drawerActiveQuestionId)])


	return(
		<Grid container>
			<Grid item xs={6} sm={3} style={{ overflow: 'auto', height: 'calc(100vh - 60px)'}} >
				{
					props.gameInstance.quiz.questions.map((question, index) => question &&
						<QuestionCard key={question.id}>
							{
								props.gameInstance.quiz.currentQuestion >= index ?
									<QuestionListItem question={question} transitionDelay={question.id} activeQuestion={activeQuestion}
										selectQuestion={(q) => { if (props.mode === 'active') {setActiveQuestion(q)} } }
										showAnswers={true}
										showMeta={false}
										showDelete={false}
									/>
								:
									<EmptyItem>{index + 1}</EmptyItem>
							}
						</QuestionCard>
					)
				}
			</Grid>
			<ContentArea item xs={6} sm={9} style={{border: 'solid 1px gray'}}>
				{
					activeQuestion && (
						<div>
							<QuestionContent question={activeQuestion} mode={props.mode} videoVolume={1} videoControlType={props.videoControlType} />
							{
								(activeQuestion.cType === 'quiz' || activeQuestion.cType === 'poll' || activeQuestion.cType ==='form') &&
									<AnswerOptionList question={activeQuestion} />
							}
						</div>
					)
				}
			</ContentArea>						
		</Grid>
	)
}

export default PrevQuestionList