import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { AuthContext } from '../data/context/AuthProvider'
import { StaffDetails } from '../PageBlocks/staff/StaffDetails'
import { Layout2 } from '../components'

function TeacherAccountDashboard(props) {
	const [authData] = useContext(AuthContext)
	
	return (
		<Layout2 withAuth={true}>
			{
				authData && authData.email ?
					<Grid container style={{marginTop: '10px'}}>						
						<Grid item xs={12}>
							<StaffDetails email={authData.email} />
						</Grid>
					</Grid>
				:
					<div></div>
			}
		</Layout2>
	)
}

export default TeacherAccountDashboard
