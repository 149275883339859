// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const getCommentsForUser = async (studentEmail, userEmail) => {
	let firebaseObj = await getFirebaseObj('getCommentsForUser')
	let firestore = firebaseObj.firestore

	let tempCommmentsForStudent = []
	if (userEmail && userEmail.length > 0 && studentEmail && studentEmail.length > 0 ) {
		let sCommentsForStudent
		if (userEmail === 'yesilokulcom@gmail.com') {
			sCommentsForStudent = await firestore.collection('commentsForUsers').where('commentee.email', '==', studentEmail).get()
		} else {
			sCommentsForStudent = await firestore.collection('commentsForUsers').where('commentee.email', '==', studentEmail).where('comment.level', '==', 'teacher').get()
		}
		
		sCommentsForStudent.docs.map(doc => {
			tempCommmentsForStudent.push(doc.data())
			return null
		})
	}
	return tempCommmentsForStudent
}


const addNewCommentForUser = async (commentee, commenter, comment) => {
	let firebaseObj = await getFirebaseObj('addNewCommentForUser')
	let firestore = firebaseObj.firestore

	let newDocRef = await firestore.collection('commentsForUsers').doc()
	const newDocId = newDocRef.id
	
	let newCommentForUser = {
		id: newDocId,
		commenter: {
			email: commenter.email,
			name: commenter.name,
			roles: commenter.roles
		},
		commentee: {
			email: commentee.email,
			name: commentee.name,
			roles: commentee.roles,
		},
		comment: {
			content: comment.content,
			category: comment.category,
			level: comment.level
		},
		additionDate: new Date(),
	}	
	newDocRef.set(newCommentForUser)
	return {
		result: 'success',
		docId: newDocId,
		newCommentForUser: newCommentForUser
	}
}

const deleteCommentForUser = async (commentDocId, email) => {
	let firebaseObj = await getFirebaseObj('deleteCommentForUser')
	let firestore = firebaseObj.firestore

	let deleteResponse
	if (email === 'yesilokulcom@gmail.com') {
		deleteResponse = await firestore.collection('commentsForUsers').doc(commentDocId).delete()
	}
	return deleteResponse
}

export { getCommentsForUser, addNewCommentForUser, deleteCommentForUser }
