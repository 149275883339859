import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { isMobile } from 'react-device-detect'
import Drawer from '@material-ui/core/Drawer'
import EditIcon from '@material-ui/icons/Edit'
import CourseLessonSettings from '../Plan/CourseLessonSettings'

const DrawerContentArea = styled(Grid)`padding: 10px; height: 100%; width: 250px;`
const DrawerBottomLine = styled(Grid)` padding-left: 10px; background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%); color: #ffffff; text-align: center; height: 60px; padding-top: 10px; border-radius: 10px 10px 0 0;`

const CloseIcon = styled.div`font-size: 18px; float: left; border: solid 2px white; color: white;  border-radius: 24px; width: 28px; text-align: center; cursor: pointer; font-weight: bold; margin-left: 5px;
	:hover {background-color: red; color: white; border: solid 2px red;}
`

function CourseMainDrawer(props) {
	const [drawerState, setDrawerState] = useState('closed')

	useEffect(() => {
		if (props.onStateChange) {
			props.onStateChange(drawerState)
		}
	}, [drawerState])

	return (
		<div>
			<div style={{float: props.float}}>
				{props.content === 'course-lesson-settings' && props.showIcon && <EditIcon onClick={() => setDrawerState('open')}  style={{fontSize: props.iconSize ? props.iconSize : (isMobile ?  '28px' : '40px'), cursor: 'pointer' }} stylecolor={props.iconColor} />}
			</div>

			<Drawer anchor="right" open={drawerState === 'open' ? true : false} onClose={() => { if (props.mode === 'active') { setDrawerState('closed') } } } >
				<Grid container>
					<DrawerContentArea item xs={12}>
						{props.content === 'course-lesson-settings' && <CourseLessonSettings lesson={props.lesson} onUpdate={(newData) => props.onUpdate(newData) } />}					
					</DrawerContentArea>
					<DrawerBottomLine item xs={12}>
						{props.content === 'course-lesson-settings' && <span>DERS AYARLARI</span>}
						
						{props.mode === 'active' && <CloseIcon onClick={() => setDrawerState('closed') }>X</CloseIcon>}		
					</DrawerBottomLine>
				</Grid>
		</Drawer>			
		</div>
	)
}

export { CourseMainDrawer }
