import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { BaseTextArea, FormElementLabel, H1 } from '../../../components'

let changeFromInside = false
function Messages(props) {

	const [welcome, setWelcome] = useState(props.messagesData.welcome ? props.messagesData.welcome : '')
	const [congrats, setCongrats] = useState(props.messagesData.congrats ? props.messagesData.congrats : '')

	useEffect(() => {
		if (changeFromInside) {
			updateMessageData()
		}
	}, [welcome, congrats])

	const updateMessageData = () => {
		props.onUpdate({
			welcome: welcome,
			congrats: congrats,
		})
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<H1>Mesajlar</H1>
			</Grid>
			<Grid item xs={12} style={{textAlign: 'left', marginBottom: '30px'}}>
				Kurs içeriği ile etkileşime geçmeleri için teşvik etmek üzere öğrenciler kursunuza katıldığında veya kursunuzu tamamladığında otomatik olarak gönderilecek mesajlar (isteğe bağlı) yazın. Bir karşılama veya tebrik mesajı göndermek istemiyorsanız metin kutusunu boş bırakın.
			</Grid>
			<Grid item xs={12}>
				<FormElementLabel>Karşılama Mesajı</FormElementLabel>
				{
					props.editable ?
						<BaseTextArea value={welcome} onChange={(e) => {changeFromInside = false; setWelcome(e.target.value)}} onBlur={(e) => { changeFromInside = true; updateMessageData() }} />
					:
						<div>{welcome}</div>
				}
				
			</Grid>
			<Grid item xs={12}>
				<FormElementLabel>Tebrik Mesajı</FormElementLabel>
				{
					props.editable ?
						<BaseTextArea value={congrats} onChange={(e) => {changeFromInside = false; setCongrats(e.target.value)}} onBlur={(e) => { changeFromInside = true; updateMessageData() }} />
					:
						<div>{congrats}</div>
				}
			</Grid>
		</Grid>
	)
}

export default Messages
