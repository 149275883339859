import React, { useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

const BlockTitle = styled.div`font-size: 16px; font-weight: bold; padding-left: 10px; margin-top: 10px; border-bottom: solid 1px black;`

function StaffStats(props) {
	useEffect(() => {
		console.log('props.staffStats: ', props.staffStats)
	}, [])
	
	return (
		<Grid container style={{textAlign: 'left'}}>
			{
				props.staffStats && props.staffStats.content && props.staffStats.content.activeStats &&
				<>
					<Grid item xs={12}>
						<BlockTitle>
							İÇERİK İSTATİSTİKLERİ - AKTİF
						</BlockTitle>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Grid container style={{borderRight: 'solid 1px gainsboro', overflow: 'auto'}}>
							<Grid item xs={12}>
								Tüm Kurslar: {props.staffStats.content.activeStats.courses.all}
							</Grid>
							<Grid item xs={12}>
								<BlockTitle>
									KURSLAR - YAYIN DURUMUNA GORE
								</BlockTitle>
							</Grid>							
							<Grid item xs={12}>
								Taslak Kurslar: {props.staffStats.content.activeStats.courses.draft}
							</Grid>
							<Grid item xs={12}>
								Yayınlanmış Kurslar: {props.staffStats.content.activeStats.courses.published}
							</Grid>
							<Grid item xs={12}>
								Liste Dışı Yayınlanmış: {props.staffStats.content.activeStats.courses.publishedUnlisted}
							</Grid>
							<Grid item xs={12}>
								Yayınlanmış - Özel: {props.staffStats.content.activeStats.courses.publishedPrivate}
							</Grid>
							<Grid item xs={12}>
								<BlockTitle>
									KURSLAR - ÖĞRENCİ KAYIT DURUMUNA GÖRE
								</BlockTitle>
							</Grid>							
							<Grid item xs={12}>
								Kayıtlı Öğrencisi Olanlar: {props.staffStats.content.activeStats.courses.haveStudentsRegistered}
							</Grid>
							<Grid item xs={12}>
								Sepette Öğrencisi Olanlar: {props.staffStats.content.activeStats.courses.haveStudentsInCart}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Grid container style={{borderRight: 'solid 1px gainsboro', overflow: 'auto'}}>
							<Grid item xs={12}>
								<BlockTitle>
									MÜFREDATLAR
								</BlockTitle>
							</Grid>
							<Grid item xs={12}>
								Tüm Müfredatlar: {props.staffStats.content.activeStats.curriculums.all}
							</Grid>
							<Grid item xs={12}>
								Kursta Kullanılan Müfredat: {props.staffStats.content.activeStats.curriculums.usedForCourse}
							</Grid>
							<Grid item xs={12}>
								Kursta Kullanılmamış Müfredat: {props.staffStats.content.activeStats.curriculums.nonUsedForCourse}
							</Grid>

							<Grid item xs={12}>
								<BlockTitle>
									DERSLER
								</BlockTitle>
							</Grid>							
							<Grid item xs={12}>
								Toplam Ders Sayısı: {props.staffStats.content.activeStats.curriculums.allLessonsCount}
							</Grid>
							<Grid item xs={12}>
								Sunumu Olan Ders Sayısı: {props.staffStats.content.activeStats.curriculums.lessonsOnlyContainLessonTemplateCount}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Grid container style={{borderRight: 'solid 1px gainsboro', overflow: 'auto'}}>
							<Grid item xs={12}>
								<BlockTitle>
									DERS SUNUMLARI
								</BlockTitle>
							</Grid>
							<Grid item xs={12}>
								Tüm Ders Sunumları: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.allLessonTemplatesCount : ''}
							</Grid>
							<Grid item xs={12}>
								Toplam Slayt Sayısı: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.allSlidesCount : ''}
							</Grid>
							
							<Grid item xs={12}>
								<BlockTitle>
									İÇERİK TÜRÜNE GÖRE
								</BlockTitle>
							</Grid>							
							<Grid item xs={12}>
								İçerik: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.cType.content : ''}
							</Grid>
							<Grid item xs={12}>
								Soru: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.cType.quiz : ''}
							</Grid>
							<Grid item xs={12}>
								Anket: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.cType.poll : ''}
							</Grid>
							<Grid item xs={12}>
								Form: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.cType.form : ''}
							</Grid>
							<Grid item xs={12}>
								<BlockTitle>
									CEVAP TÜRÜNE GÖRE SORULAR
								</BlockTitle>
							</Grid>							
							<Grid item xs={12}>
								Çoktan Seçmeli: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.aType.multiple : ''}
							</Grid>
							<Grid item xs={12}>
								Doğru-Yanlış: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.aType.boolean : ''}
							</Grid>
							<Grid item xs={12}>
								Açık Uçlu: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.aType.freeText : ''}
							</Grid>
							<Grid item xs={12}>
								Resim Yükleme: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.aType.imageUpload : ''}
							</Grid>
							<Grid item xs={12}>
								<BlockTitle>
									SLAYT İÇERİĞİNE GÖRE
								</BlockTitle>
							</Grid>							
							<Grid item xs={12}>
								Metin: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.qType.text : ''}
							</Grid>
							<Grid item xs={12}>
								Resim + Metin: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.qType.image : ''}
							</Grid>
							<Grid item xs={12}>
								Video + Metin: {props.staffStats.content.activeStats.lessonTemplates ? props.staffStats.content.activeStats.lessonTemplates.qType.video : ''}
							</Grid>
						</Grid>
					</Grid>
				</>
			}
		</Grid>
	)
}

export { StaffStats }
