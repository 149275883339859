import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import CurriculumListItem from '../../PageBlocks/curriculum/CurriculumListItem'
import { getCurriculumsOfStaff, CreateNewCurriculum } from '../../data/firebase/curriculumFirebase'
import { Layout2, FormLine, BaseButton, H1 } from '../../components'
import { AuthContext } from '../../data/context/AuthProvider'

const CreatePage = styled.div`margin: 10px; width: 100%; margin-left: auto; margin-right: auto; padding: 5px;`
const NewButton = styled(BaseButton)`float: right; width: 200px;`

function CurriculumList(props) {
	let history = useHistory()
	const [authData] = useContext(AuthContext)
	
	const [curriculums, setCurriculums] = useState([])

	useEffect(async () => {
		if (authData && authData.email) {
			let tempCurriculums = await getCurriculumsOfStaff(authData.email)
			tempCurriculums.sort(function(a,b) {
				if (a.meta.dates.curriculumCreated < b.meta.dates.curriculumCreated) return 1
				if (a.meta.dates.curriculumCreated > b.meta.dates.curriculumCreated) return -1
				return 0
			})
			setCurriculums(tempCurriculums)
		}
	}, [authData.email])

	const createNew = async () => {
		let docId = await CreateNewCurriculum(authData.email)
		history.push(`curriculum-designer/${docId}`)
	}

	return (
		<Layout2 withAuth={true}>
			<H1>MÜFREDATLARIM</H1>
			<CreatePage>
				{
					authData.roles && authData.roles.some(role => ['owner', 'admin', 'teacher'].indexOf(role) >=0 ) && (
						<FormLine>
							<NewButton type="button" onClick={() => createNew()} value="YENİ MÜFREDAT" />
						</FormLine>
					)
				}
				<Grid container>
				{
					curriculums && curriculums.length > 0 &&
						curriculums.map((curriculum) => curriculum &&
							<Grid item xs={12} sm={4} md={3} key={curriculum.id}>
								<CurriculumListItem curriculum={curriculum} />
							</Grid>
						)
				}
				</Grid>
			</CreatePage>
		</Layout2>
	)
}

export default CurriculumList
