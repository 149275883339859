import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { AuthContext } from '../../../../data/context/AuthProvider'

const LessonComp = styled.div`text-align: left; cursor: pointer; margin-bottom: 10px; border-radius: 6px; margin: 2px;
	border: ${(props) => props.selected ? 'solid 2px navy' : 'solid 1px gainsboro'}; 
	// -webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);
`
const Content = styled.div`height: 60px; overflow: hidden; padding: 5px;`

function LessonInstanceListItem(props) {
	const [authData] = useContext(AuthContext)
	const [lessonTitle, setLessonTitle] = useState(props.gameInstance.title.length > 20 ? `${props.gameInstance.title.substring(0, 20)}...` : props.gameInstance.title)

	useEffect(() => {
		if (props.gameInstance) {
			setLessonTitle(props.gameInstance.title.length > 20 ? `${props.gameInstance.title.substring(0, 20)}...` : props.gameInstance.title)
		}
	}, [props.gameInstance])

    return (
		<LessonComp selected={(props.selectedInstance && props.selectedInstance.key === props.gameInstance.key) ? true : false }>
			<Content onClick={() => { props.selectInstance(props.gameInstance) }}>
				<Grid container key={props.gameInstance.key}>
					<Grid item xs={2}>
						{
							props.gameInstance.status === 'CREATED' ?									
								<span><PlayCircleFilledIcon /></span>
							:
								props.gameInstance.status === 'IN_PROGRESS' ?
									<span><PlayCircleFilledIcon /></span>
									:        
										<span><AssessmentIcon /></span>
						}
					</Grid>
					<Grid item xs={8}>
						<div style={{textAlign: 'left', fontSize: '12px', marginTop: '3px'}}>
							<Tooltip title={props.gameInstance.title === lessonTitle ? '' : props.gameInstance.title }>
								{
									props.gameInstance.status === 'CREATED' ?									
										<span>{lessonTitle}</span>
									:
										props.gameInstance.status === 'IN_PROGRESS' ?
											<span>{lessonTitle}</span>
											:        
												<span>{lessonTitle}</span>
								}
							</Tooltip>
						</div>
					</Grid>
					<Grid item xs={2}>
						{
							props.gameInstance.players &&
								<b style={{fontSize: '11px'}}>({props.gameInstance.players ?  Object.keys(props.gameInstance.players).length : 0})</b>
						}
					</Grid>

					<Grid item xs={10}></Grid>
					<Grid item xs={2}>
						{
							(!props.gameInstance.players || Object.keys(props.gameInstance.players).length < 3) &&
								authData.roles &&
								(
									!props.gameInstance.players ||
									(authData.roles.some(role => ['admin', 'teacher'].indexOf(role) >= 0 ) && Object.keys(props.gameInstance.players).length < 3 ) ||
									authData.roles.some(role => ['owner'].indexOf(role) >= 0 )
								) &&
								<div>
									<DeleteForeverIcon onClick={() => props.deleteInstance(props.gameInstance.key)} color="action" fontSize="small" style={{cursor: 'pointer'}} />
								</div>
						}
					</Grid>
				</Grid>
			</Content>
		</LessonComp>
    )
}

export default LessonInstanceListItem
