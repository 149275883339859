import React, { useState } from 'react'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'


const NextButtonArea = styled.div`margin-right: 10px; margin-bottom: 25px; position: absolute; bottom: 5px; height: 60px; float: right; right: 5px; z-index: 10;`

function NextStepButtons(props) {
	const [openDialog, setOpenDialog] = useState(false)
	
	return (
		<div>
			<NextButtonArea>
				<Fab color="secondary" aria-label="Finalize" size="small" onClick={() => setOpenDialog(true)}><HighlightOffIcon /></Fab> &nbsp;
				{!props.isLastQuestion && <Fab color="primary" aria-label="Next" onClick={() => props.onNextQuestion()}><NavigateNextIcon /></Fab>}
			</NextButtonArea>

			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle id="alert-dialog-title">{"Dersi bitirmek istediğinizden emin misiniz?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Onaylarsanız ders sonlandırılıp sonuca geçilecektir. Dersi bu aşamada sonlandırmak istediğinize emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Iptal
                    </Button>
                    <Button onClick={() => { props.onFinalizeLesson(); setOpenDialog(false)}} color="primary" autoFocus>
                        Sonlandır
                    </Button>
                </DialogActions>
            </Dialog>
			
		</div>
	)
}

export default NextStepButtons
