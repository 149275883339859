import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import linkifyHtml from 'linkifyjs/html'
import 'linkify-plugin-hashtag'
import 'linkify-plugin-mention'
import Grid from '@material-ui/core/Grid'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ShareIcon from '@material-ui/icons/Share'
import DeleteIcon from '@mui/icons-material/Delete'
import styled from 'styled-components'
import { AuthContext } from '../../data/context/AuthProvider'
import { getDateFromUnixSeconds, getBrandData } from '../../utils'
import { deletePost } from '../../data/firebase/postFirebase'
import { UrlPreview } from './UrlPreview'
import { ImageListPreview } from './ImageListPreview'
// import { storage } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const ProfileImg = styled.img`height: 40px; border-radius: 50%; background-color: #f0f0f0; padding: 4px; cursor: pointer;`
const ActionLink = styled.div`cursor: pointer; padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px; border-radius: 4px; font-size: 12px;
	&:hover {
		background-color: rgba(29, 155, 240, 0.2); color: rgba(29, 155, 240, 1); font-weight: bold;
	} 
`
const PageSlug = styled.span` font-size: 12px;`
const PostDate = styled.span` font-size: 12px;
	&:hover {
		text-decoration: underline; cursor: pointer;
	} 
`

const linkifyOptions = {
	target: {
		url: '_blank',
		email: '_blank',
		hashtag: '_blank',
		mention: '_blank'
	},
	formatHref: { 
		hashtag: (href) => `${getBrandData().publicWebsite}/hashtag/${href.substr(1)}`,
		mention: (href) => `${getBrandData().publicWebsite}/pprofile${href}/index`
	},
	format: (value, type) => {
		if (type === 'url' && value.length > 50) {
			value = `<br />${value.slice(0, 50)}…<br />`
		}
		return value
	}
}

function PostListItem(props) {
	const [authData] = useContext(AuthContext)

	const [post, setPost] = useState()
	const [postDate, setPostDate] = useState()

	const [liked, setLiked] = useState(false)
	const [likes, setLikes] = useState([])

	useEffect(() => {
		if (props.post && authData && authData.email) {
			setPost(props.post)

			let tempPostDate = getDateFromUnixSeconds(props.post.createDate.seconds)
			
			let minutes = Math.round(moment.duration(moment().diff(tempPostDate)).asMinutes())
			let hours = Math.round(moment.duration(moment().diff(tempPostDate)).asHours())
			
			if (hours > 24) {
				tempPostDate = tempPostDate.format('DD MMMM')
			} else if (minutes > 59) {
				tempPostDate = `${hours}h`
			} else {
				tempPostDate = `${minutes}m`
			}
			setPostDate(tempPostDate)
		}
	}, [props.post, authData.email])

	useEffect(() => {
		if (post) {
			let allLikes = post.interactions ? post.interactions.filter(_interaction => _interaction.type === 'like') : []
			setLikes((allLikes && allLikes.length > 0) ? allLikes : [])
		
			if (post.interactions && post.interactions.length > 0) {
				let myLikes = post.interactions.filter(_interaction => _interaction.email === authData.email && _interaction.type === 'like')
				if (myLikes && myLikes.length > 0) {
					setLiked(true)
				} else {
					setLiked(false)
				}
			} else {
				setLiked(false)
			}
		}
	}, [post])


	const deleteThisPost = async () => {
		if (post.images && post.images.length > 0) {
			deleteFilesOfPost([...post.images])
		}
		
		await deletePost(post.id)
		if (props.onDeletePost) {
			props.onDeletePost(post.id)
		}
	}

	const deleteFilesOfPost = async (images) => {
		let firebaseObj = await getFirebaseObj('getCommentsForUser fbo_PageBlocks')
		let storage = firebaseObj.storage

		images.map((_image) => {
			const fileRef = storage.ref(`${_image.folder}/${_image.fileName}`)
			fileRef.delete().then(() => { }).catch((error) => { console.log('File could not be deleted.') })

			const fileRefThumb = storage.ref(`${_image.folder}/${_image.thumb_fileName}`)
			fileRefThumb.delete().then(() => { }).catch((error) => { console.log('File could not be deleted.') })

			return null
		})
	}
	
	return (
		<div>
		{
			post &&
				<Grid container style={{border: 'solid 1px gainsboro', borderRadius: '6px', marginTop: '10px', backgroundColor: '#ffffff'}}>
					<Grid item xs={2} sm={1} style={{padding: '10px'}}>
						<a href={`/pprofile/${props.pageData.slug}/index`} target="_blank" rel="noopener noreferrer">
							<ProfileImg src={(props.pageData.introDetails && props.pageData.introDetails.images && props.pageData.introDetails.images.profile && props.pageData.introDetails.images.profile.url) ?  props.pageData.introDetails.images.profile.url : 'https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3'} />
						</a>
					</Grid>
					<Grid item xs={10} sm={11} style={{paddingLeft: '15px'}}>
						<Grid container style={{padding: '10px'}}>
							<Grid item xs={12}>
								<b>{props.pageData.title}</b> <PageSlug>@{props.pageData.slug}</PageSlug> · <a href={`/post/${post.id}`} target="_blank" rel="noopener noreferrer"><PostDate>{postDate}</PostDate></a>
								{/* {props.post.creeateDate.format('MMM dd YYYY')} */}
							</Grid>

							<Grid item xs={12}>
								<div dangerouslySetInnerHTML={{ __html: linkifyHtml(post.text, linkifyOptions) }} style={{fontSize: '14px', marginTop: '10px', marginBottom: '10px'}}></div>
								
								{
									post.gif &&
										<div>
											<img src={post.gif} alt="post" />
										</div>
								}
								{
									post.linkMeta && post.linkMeta.url &&
										<div>
											<UrlPreview urlMetaData={post.linkMeta} />
										</div>
								}
								{
									post.images && post.images.length > 0 &&
										<div>
											<ImageListPreview images={post.images} />
										</div>
								}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} style={{borderTop: ' solid 1px #e0e0e0', padding: '5px'}}>
						<Grid container>
							<Grid item xs={3} style={{paddingLeft: '15px'}}>
								<ThumbUpIcon style={{fontSize: 'inherit', marginRight: '5px', color: (liked ? 'blue' : 'gray') }} />
								{
									likes && likes.length > 0 ?
										<span>{likes.length} Likes</span>
									:
										<span>0 Likes</span>
								}
							</Grid>
							<Grid item xs={3}>
								<ActionLink style={{width: '100px'}} >
									<ShareIcon style={{height: '12px', marginRight: '5px'}} />
									Share
								</ActionLink>
							</Grid>
							<Grid item xs={3}>
								<ActionLink style={{width: '100px'}}>
									<ChatBubbleOutlineIcon style={{fontSize: 'inherit', marginRight: '5px'}} />
									Comment
								</ActionLink>
							</Grid>
							<Grid item xs={3}>
								<ActionLink style={{width: '100px'}} onClick={() => deleteThisPost()}>
									<DeleteIcon style={{fontSize: 'inherit', marginRight: '5px'}} />
									Delete
								</ActionLink>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
		}

		{
			!props.post &&
				<div>Post Data Not Found</div>
		}
		</div>
	)
}

export { PostListItem }
