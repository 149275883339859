import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const SelectComp = styled(Select)`
		height: ${(props) => props.height ?  props.height : '40px'};
		font-size: ${(props) => props.fontSize ? props.fontSize : '18px'};
		padding-left: 2px;
		text-align: ${(props) => props.textAlign ? props.textAlign : 'left'};
		float: ${(props) => props.float ? props.float : ''};
		width: ${(props) => props.width ? props.width : '100%'};
		zIndex: 9999;
	`

function BaseSelect(props) {
	const csStyles = {
		dropdownIndicator: () => ({ 
			color: props.dropdownIndicatorColor ? props.dropdownIndicatorColor : '',
		}), 
		singleValue: (provided) => ({ ...provided, 
			color: props.singleValueColor ? props.singleValueColor : '',
			fontWeight: 'bold',
		}),
		placeholder: (provided) => ({ ...provided,
			color: props.placeHolderColor ? props.placeHolderColor : '',
			width: (props.width ? props.width : '100%'),
		}),
		menu: (provided) => ({ ...provided,
			border: props.menuBorder ? props.menuBorder : '',
			width: (props.width ? props.width : '100%'),
		}),
		option: (provided) => ({ ...provided,
			backgroundColor: 'white',
			color: 'black',
			':active': {
				...provided[':active'],
				backgroundColor: 'blue',
				color: 'white',
			},			
		}),
		menuPortal: (provided) => ({ ...provided,
			zIndex: 9999
		}),
		control: (provided) => ({ ...provided,
			border: props.controlBorder ? props.controlBorder : (  (props.showAsRequired && props.value.length === 0) ? 'solid 2px red' : ''),
			WebkitBoxShadow: 'inset 0 0 6px rgba(30,30,30,0.25)',
			width: (props.width ? props.width : '100%'),
			':hover': {
				border: props.controlHoverBorder ? props.controlHoverBorder : ''
			},
		}),
	}

	return (
		<SelectComp 
			onChange={(newValue) => { props.onChange(newValue)} }
			options={props.options}
			value={props.value ? props.value : (props.isMulti ? [] : '')}
			styles={csStyles}
			isMulti={props.isMulti ? props.isMulti : false}
			instanceId={props.instanceId ? props.instanceId : 'rs'}
			height={props.height}
			fontSize={props.fontSize}
			textAlign={props.textAlign}
			width={props.width}
			menuPosition={props.fixed ? 'fixed' : 'absolute'}
			menuPlacement={props.portalPlacement ?  props.portalPlacement : 'bottom' }
			menuPortalTarget={document.body}
		/>
	)
}

export { BaseSelect }

// import React from 'react'
// import Select from 'react-select'
// import styled from 'styled-components'

// const SelectComp = styled(Select)`width: 100%; height: 32px; font-size: 18px; padding-left: 2px;`

// function BaseSelect(props) {

// 	const csStyles = {
// 		dropdownIndicator: () => ({ color: props.dropdownIndicatorColor ? props.dropdownIndicatorColor : '' }), 
// 		singleValue: (provided) => ({ ...provided, color: props.singleValueColor ? props.singleValueColor : '' }),
// 		placeholder: (provided) => ({ ...provided, color: props.placeHolderColor ? props.placeHolderColor : '' }),
// 		menu: (provided) => ({ ...provided, border: props.menuBorder ? props.menuBorder : '' }),
// 		control: (provided) => ({ ...provided, border: props.controlBorder ? props.controlBorder : (  (props.showAsRequired && props.value.length === 0) ? 'solid 2px red' : ''),
// 											'-webkit-box-shadow': 'inset 0 0 6px rgba(30,30,30,0.25)',
// 										':hover': {border: props.controlHoverBorder ? props.controlHoverBorder : ''} }),
// 	}

// 	return (
// 		<SelectComp 
// 			onChange={(newValue) => { props.onChange(newValue)} }
// 			options={props.options}
// 			value={props.value}
			
// 			styles={csStyles}
// 		/>
// 	)
// }

// export { BaseSelect }