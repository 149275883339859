import React, { useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthContext } from '../../data/context/AuthProvider'
import { getUserInfoByEmail, updateUserInfo } from '../../data/firebase/userFirebase'
import { LogoImg, BaseInput, BaseButton, BaseLink, EmptySpace, FormElementLabel } from '../../components'
import { getBrandData, getGeoInfo } from '../../utils'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const PageTitle= styled.div`font-weight: bold; font-size: 18px; margin-top: 20px; margin-bottom: 10px;`
const Block = styled.div` border-radius: 6px; margin-top: 10px; padding: 10px;
	-webkit-box-shadow:inset 0 0 6px rgba(30, 30, 30, 0.25);
`

let loginInProgress = false
function LoginComponent(props) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [submitData, setSubmitData] = useState()

	const [, setAuthContextData] = useContext(AuthContext)

	const loginApp = async () => {
		if (loginInProgress === false) {
			loginInProgress = true

			if (email && password) {
				setSubmitData({email: email, password: password})
				try {
					let firebaseObj = await getFirebaseObj('loginApp fbo_PageBlocks')
					console.log('firebaseObj: ', firebaseObj)
					let firebaseAuth = firebaseObj.firebaseAuth

					let response = await firebaseAuth.signInWithEmailAndPassword(email, password)
					localStorage.setItem('email', response.user.email)
					localStorage.setItem('email2', btoa(password))

					let userData = await getUserInfoByEmail(email)
					userData.emailVerified = response.user.emailVerified
					userData.uid = response.user.uid

					if (userData) {
						if (!userData.location || !userData.location.city) {
							let _location = await getGeoInfo()
							userData.location = _location
							userData.lastLogin = new Date()
							await updateUserInfo(email, userData)
						} else {
							userData.lastLogin = new Date()
							await updateUserInfo(email, userData)
						}

						setAuthContextData({
							user: response.user.email,
							email: response.user.email,
							name: userData.name ? userData.name : response.user.email,
							roles: userData.roles ? userData.roles : [],
							lessons: userData.lessons ? userData.lessons : [],
							status: userData.status ? userData.status : 'disabled',
						})
					} else {
						setAuthContextData({
							user: response.user.email,
							email: response.user.email,
							name: '',
							roles: [],
							lessons: [],
							status: 'disabled',
						})
					}
					props.onLogin(userData)
					loginInProgress = false

				}
				catch(err) {
					let errMessage = err.code + ': ' + err.message
					if (err.code === 'auth/wrong-password') {
						errMessage = 'Şifreniz hatalı, lütfen kontrol ederek tekrar deneyin. Eğer şifrenizi unuttuysanız "Şifremi Unuttum" linkini tıklayın.'
					} else if (err.code === 'auth/user-not-found') {
						errMessage = 'Bu email adresi kayıtlı değil. Eğer email adresiniz doğru ise "Yeni Kullanıcı Kaydı" bölümünden kaydolabilirsiniz.'
					} else if (err.code === 'auth/invalid-email') {
						errMessage = 'Lütfen geçerli bir email adresi giriniz.'
					}
					toast.error(errMessage, {
						position: toast.POSITION.TOP_CENTER
					})
					
					loginInProgress = false
				}
			}
		}
	}	
	return (
		<>
			<Grid container alignItems="center">
				<Grid item xs={false} sm={2} md={3}></Grid>
				<Grid item xs={12} sm={8} md={6} style={{textAlign: 'center'}}>
					<Block container alignItems="center">
						<Grid item xs={12} style={{display: (props.showLogoPart ? 'block' : 'none')}}>
							<EmptySpace marginTop='30px' />
							<BaseLink to="/">
								<LogoImg src={`/images/${getBrandData().logoFolder}/logo.png`} float="none" />
							</BaseLink>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'center'}}>
							<PageTitle>KULLANICI GİRİŞİ</PageTitle>
							<div>Lütfen kullanıcı bilgilerinizi girerek oturum açınız.</div>
						</Grid>
						<Grid item xs={12}>
							<EmptySpace marginTop='20px' />	
							<FormElementLabel>E-Posta Adresi</FormElementLabel>
							<BaseInput value={email} onChange={(newValue) => {setEmail(newValue.trim().toLowerCase())} } showAsRequired={submitData && !email} maxLength="80" width="calc(100% - 30px)" />
						</Grid>
						<Grid item xs={12}>
							<EmptySpace marginTop='20px' />							
							<FormElementLabel>Password</FormElementLabel>						
							<BaseInput type="password" value={password} onChange={(newValue) => {setPassword(newValue)} } showAsRequired={submitData && !password} width="calc(100% - 30px)" />
						</Grid>
						<Grid item xs={12}>
							<EmptySpace marginTop='20px' />
							<BaseButton type="button" value="Login" onClick={() => loginApp()} style={{width: 'calc(100% - 30px)'}} />
							<EmptySpace marginTop='20px' />
						</Grid>
						{/* <Grid item xs={12}>
							<BaseLink to="/user/forgot-password">Şifremi Unuttum</BaseLink>
						</Grid> */}
					</Block>
				</Grid>
				<Grid item xs={false} sm={2} md={3}></Grid>
				<ToastContainer />
			</Grid>
		</>
	)
}

export { LoginComponent }
