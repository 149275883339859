import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { H1 } from '../../../components'
import { removeNonEnglishChars } from '../../../utils'

const StatsArea = styled.div`padding: 10px; border-radius: 6px; width: 100%;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);
`
const StatsLine = styled(Grid)`margin-bottom: 5px;  text-align: left; height: 70px; `
const AnswerImage = styled.img`height: 60px; max-width: 90%; border-radius: 6px;`
const AnswerText = styled.div`font-size: 16px; font-weight: bold; overflow: hidden; border: solid 1px gainsboro; border-radius: 6px; width: 90%; height: 100%; padding-top: 15px; padding-left: 5px; padding-right: 5px;`
const ChartLine = styled.div`color: white; text-align: center; font-weigt: bold; border-radius: 6px; height: 30px; font-size: 18px;`

const answerStyles = [
	{color: '#d89e00'},
	{color: '#1368ce'},
	{color: '#26890c'},
	{color: '#e21b3c'},
	{color: '#cc00cc'},
	{color: '#3366FF'},
	{color: '#001f3f'},
	{color: '#9966FF'},
	{color: '#08AEEA'},
	{color: '#FF4136'},
	{color: '#FF851B'},
	{color: '#85144b'},
	{color: '#3D9970'},
	{color: '#A01A7D'},
	{color: '#E09F7D'},
	{color: '#EC4067'},
	{color: '#07020D'},
	{color: '#716A5C'},
	{color: '#26547C'},
	{color: '#EF475F'},
]

function AnswerStats(props) {
	const [stats, setStats] = useState()
	const [activeQuestion, setActiveQuestion] = useState()

	useEffect(() => {
		createStats()
	}, [])

	const createStats = () => {
		const answerData = {
			data: [],
			values: [],
			topPlayer: {
				score: 0,
				playerKey: '',
			},
			correctAnswers: [],
			maxAnswerForOneOption: 0,
		}

		const playerAnswers = []
		const playerKeys = props.game.players ? Object.keys(props.game.players) : []
		const currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
		setActiveQuestion(currentQuestion)
		
		for (let i = 0; i < playerKeys.length; i++) {
			const player = props.game.players[playerKeys[i]]
			if (!player.answers) {
				continue
			}
			const answer = player.answers[currentQuestion.id]
			if (answer) {
				playerAnswers.push(answer.answer)
			}
		}

		for (let j = 0; j < currentQuestion.answerOptions.length; j++) {
			if ((currentQuestion.answerOptions[j].optionText && currentQuestion.answerOptions[j].optionText.length > 0 ) || (currentQuestion.answerOptions[j].optionImage && currentQuestion.answerOptions[j].optionImage.url && currentQuestion.answerOptions[j].optionImage.url.length > 0 ) ) {
				let nrOfAnswers = 0			
				for (let i = 0; i < playerAnswers.length; i++) {
					if (currentQuestion.cType === 'quiz' && currentQuestion.aType === 'free-text') {
						if (removeNonEnglishChars(currentQuestion.answerOptions[j].optionText).toLowerCase().trim() === removeNonEnglishChars(playerAnswers[i].freeText).toLowerCase().trim()) {
							nrOfAnswers++
						}
					}
					else if (JSON.stringify(currentQuestion.answerOptions[j]) === JSON.stringify(playerAnswers[i])) { // Tum object yerine A,B,C,D,E gibi şık sistemine geçilmeli. Soruların hazırlandığı anda şık da girilmeli ve tüm karşılaştırma ve veriler sadeleşmeli
						nrOfAnswers++
					}
				}
				if (nrOfAnswers > answerData.maxAnswerForOneOption) {
					answerData.maxAnswerForOneOption = nrOfAnswers
				}
				answerData.data.push(nrOfAnswers)

				let answerType = (currentQuestion.answerOptions[j].optionImage && currentQuestion.answerOptions[j].optionImage.url) ? 'image' : 'text'
				let answerText = (answerType === 'image') ? currentQuestion.answerOptions[j].optionImage.url : currentQuestion.answerOptions[j].optionText
				
				if (currentQuestion.cType === 'quiz' && currentQuestion.aType === 'free-text') {
					answerData.values.push({answerText: answerText, isCorrect: true, answerType: answerType})
					answerData.correctAnswers.push(j)
				} else {
					if (currentQuestion.answerOptions[j].isCorrect) {
						answerData.values.push({answerText: answerText, isCorrect: true, answerType: answerType})
						answerData.correctAnswers.push(j)
					} else {
						answerData.values.push({answerText: answerText, isCorrect: false, answerType: answerType})
					}
				}
			}
		}
		setStats(answerData)
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<H1>CEVAP DAĞILIMI</H1>
			</Grid>
			<Grid item xs={12}>
				{
					stats && stats.data && stats.data.length > 0 &&
						<StatsArea>
							{
								stats.data.map((data, index) => (
									<StatsLine container key={index}>
										<Grid item xs={2} sm={1}>
											{
												activeQuestion.cType === 'quiz' &&
													<div style={{ marginTop: '15px'}}>
														{
															stats.values[index].isCorrect ?
																	<CheckCircleOutlineIcon style={{color: 'green', fontSize: '32px'}} />
																:
																	<HighlightOffIcon style={{color: 'red', fontSize: '16px'}} />
														}
													</div>
											}
										</Grid>
										<Grid item xs={4} sm={3}>
											{
												stats.values[index].answerType === 'text' &&
													<AnswerText>{stats.values[index].answerText}</AnswerText>
											}
											{
												stats.values[index].answerType === 'image' &&
													<AnswerImage src={stats.values[index].answerText} alt="option" />
											}
										</Grid>
										<Grid item xs={6} sm={8}>
											<ChartLine style={{width: `${(parseFloat(stats.data[index]) * 100.0) / parseFloat(stats.maxAnswerForOneOption)}%`, backgroundColor: answerStyles[index].color, marginTop: '15px' }}>
												{stats.data[index]}
											</ChartLine>									
										</Grid>
									</StatsLine>
								))
							}
						</StatsArea>
				}
			</Grid>
		</Grid>
	)
}

export default AnswerStats