import Resizer from 'react-image-file-resizer'
// import { storage } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const resizeFile = (file) => new Promise(resolve => {
	Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0, uri => { resolve(uri) }, 'base64' 	)
})


const uploadTaskPromise = async (fileBlob, folder, fileName, uploadType) => {
	let firebaseObj = await getFirebaseObj('uploadTaskPromise')
	let storage = firebaseObj.storage

	return new Promise(function(resolve, reject) {
		let uploadTask
		if (uploadType === 'put_string') {
			uploadTask = storage.ref(`${folder}/${fileName}`).putString(fileBlob, 'data_url')
		} else {
			uploadTask = storage.ref(`${folder}/${fileName}`).put(fileBlob)
		}

		uploadTask.on('state_changed',
			function(snapshot) {
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
				console.log('Upload is ' + progress + '% done')
			},
			function error(err) {
				console.log('error', err)
				reject()
			},
			function complete() {
				uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
					resolve(downloadURL)
				})
			}
		)
	})
}

const uploadAndSaveImage = async (fileBlob, type, fileType, folder) => {
	let timestamp = Date.now()
	let blobFileName = fileBlob.name ? fileBlob.name.split(' ').join('-') : ''
	let fileName =`${type}-${timestamp}-${blobFileName}`	
	let url_uploaded = await uploadTaskPromise(fileBlob, folder, fileName, 'put')
	if (fileType === 'image') {
		let thumbFileName =`${type}-${timestamp}-thumb-${blobFileName}`
		let thumbnailImage = await resizeFile(fileBlob)
		let url_uploaded_thumb = await uploadTaskPromise(thumbnailImage, folder, thumbFileName, 'put_string')
		return {
			url: url_uploaded,
			fileName: fileName,
			thumb_url: url_uploaded_thumb,
			thumb_fileName: thumbFileName,
			fileType: fileType,
			folder: folder,
		}
	} else {
		return({
			url: url_uploaded,
			fileName: fileName,
			fileType: fileType,
			folder: folder,
		})
	}
}

export { uploadAndSaveImage }
