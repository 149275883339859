import React, { useState, useEffect } from 'react'
import { useForm } from 'usetheform'
import { PreviewURL } from './components/PreviewURL'
import { PreviewMedia } from './components/PreviewMedia'
import { PeviewGif } from './components/PeviewGif'
import './Styles.css'

const Preview = (props) => {
	const { state } = useForm()
	const [plainText, setPlainText] = useState('')

	const hidePreviewURL = (state && state.media) || (state && state.gif)
	useEffect(() => {
		setPlainText((props && props.editorData) ? props.editorData.plainText : '')
	}, [props])
	
	return (
		<div className="Preview__Wrapper">
			{!hidePreviewURL && <PreviewURL plainText={plainText} onURLFound={(urlData) => props.onURLFound(urlData) } />}
			<PreviewMedia />
			<PeviewGif />
		</div>
  )
}

export { Preview }
