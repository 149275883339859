import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Form } from 'usetheform'
import { WhatsHappeningBar } from './components/WhatsHappeningBar/WhatsHappeningBar'
import { Preview } from './components/Preview/Preview'
import { UploadMediaBar } from './components/UploadMediaBar/UploadMediaBar'
import { PrivacyPicker } from './components/PrivacyPicker/PrivacyPicker'
import { Submit } from './components/Submit/Submit'
import { CharacterCounter } from './components/CharacterCounter/CharacterCounter'
import './Styles.css'
import { addPost } from '../../../data/firebase/postFirebase'
import { AuthContext } from '../../../data/context/AuthProvider'
import { uploadAndSaveImage } from '../../../data/firebase/fileUploadFirebase1'

const MAX_CHARS_ALLOWED = 400

const NewPostComp = styled.div`text-align: center; margin: auto; border-radius: 6px; background-color: white;`

function NewPost(props) {
	const [authData] = useContext(AuthContext)
	const [editorData, setEditorData] = useState()
	const [urlData, setUrlData] = useState()
	const [privacyData, setPrivacyData] = useState()
	const [mediaData, setMediaData] = useState()
	const [gifData, setGifData] = useState()
	
	const manageFormData = (_formStateData) => {
		setMediaData(_formStateData.media)
		setGifData(_formStateData.gif)
	}

	const savePost = async () => {
		let newPostData = {
			pageId: props.pageId,
			owner: authData.email,
			text: (editorData) ? editorData.plainText.replace('\n', '<br/>') : '',
			linkMeta: (urlData && urlData.metaData) ? {
				title: urlData.metaData['title'],
				image: urlData.metaData['og:image'],
				imageWidth: urlData.metaData['og:image:width'],
				imageHeight: urlData.metaData['og:image:height'],
				description: urlData.metaData['description'],
				url: urlData.metaData['og:url'],
				type: urlData.metaData['og:type'],
				siteName: urlData.metaData['og:site_name'],
			} : {},
			gif: (gifData && gifData.images) ? gifData.images.original.url : '',
			images: [],
			privacy: privacyData,
			createDate: new Date()
		}

		if (mediaData && mediaData.length > 0) {
			for (let i = 0; i < mediaData.length; i++ ) {
				let folder = `PostShareImages/${moment().format('YYYY')}/${moment().format('MM')}/${moment().format('DD')}/${moment().format('HH')}/${moment().format('mm')}`
				let uploadedFileData = await uploadAndSaveImage(mediaData[i], 'postImage', 'image', folder)
				newPostData.images.push(uploadedFileData)
			}
		}
		await addPost(newPostData)
		if (props.onNewPost) {
			newPostData.createDate = {seconds: (new Date().getTime() / 1000), nanoseconds: 0}
			props.onNewPost(newPostData)
		}

		setEditorData(null)
		setUrlData(null)
	}
	return (
		<NewPostComp>
			<Form onChange={(formState) => { manageFormData(formState) } }>
				<WhatsHappeningBar maxChars={MAX_CHARS_ALLOWED} onChange={(_editorData) => setEditorData(_editorData)} />
				<Preview editorData={editorData} onURLFound={(urlData) => setUrlData(urlData) } />
				<div className="ActionBar">
					<PrivacyPicker onChange={(_privacyData) => setPrivacyData(_privacyData)} />
					<UploadMediaBar />
					<div className="ActionBar__Submit">
						<CharacterCounter maxChars={MAX_CHARS_ALLOWED} />
						<span className="ThematicBreakVertical" />
						<Submit editorData={editorData} onClick={async () => { await savePost(); } } maxChars={MAX_CHARS_ALLOWED} />
					</div>
				</div>
			</Form>
		</NewPostComp>
	)
}

export { NewPost }
