import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { PostListItem } from './PostListItem'
import { NewPost } from './NewPost/index'
import { getPostsForPage } from '../../data/firebase/postFirebase'
import { H1 } from '../../components'

function PostList(props) {
	const [posts, setPosts] = useState([])

	useEffect(async () => {
		if (props.page) {
			let tempPosts = await getPostsForPage(props.page.id)
			setPosts(tempPosts)
		}
	}, [props.page])

	const addPostToState = (newPostData) => {
		let tmpPosts = [newPostData, ...posts]
		setPosts(tmpPosts)
	}

	const removePostFromState = async (postId) => {
		let tmpPosts = posts.filter(_post => _post.id !== postId)
		setPosts(tmpPosts)
	}

	return (
		<Grid item xs={12} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
			<Grid container>
				<Grid item xs={11}>												
					<H1 style={{marginBottom: '5px'}}>
						POSTS ({(posts && posts.length > 0 ) ? <span>{posts.length}</span> : <span>0</span>})
					</H1>
				</Grid>
				<Grid item xs={false} sm={1} md={2}></Grid>
				<Grid item xs={12} sm={10} md={8}>
					<NewPost onNewPost={(newPostData) => { addPostToState(newPostData) } } pageId={props.page.id} />
					<div style={{maxHeight: 'calc(100vh - 400px)', minHeight: '200px', overflow: 'auto', marginTop: '20px'}}>													
						{
							posts && posts.length > 0 ?
								posts.map((_post, index) =>
									<PostListItem key={index} post={_post} pageData={props.page} onDeletePost={(postId) => removePostFromState(postId)}/>
								)
							:
								<div>Post yok.</div>
						}
					</div>
				</Grid>
				<Grid item xs={false} sm={1} md={2}></Grid>
			</Grid>
		</Grid>	
	)
}

export { PostList }
