import React, { useState, useEffect, useContext } from 'react'
import LessonTemplateDesigner from '../../PageBlocks/quiz/create/LessonTemplateDesigner'
import { getLessonTemplateData, getGameTemplateRef } from '../../data/firebase/live/firebaseGameCreationUtils'
import { Layout2 } from '../../components'
import { AuthContext } from '../../data/context/AuthProvider'

function Create(props) {
	const [authData] = useContext(AuthContext)

	const [lessonTemplateKey] = useState((props && props.match && props.match.params && props.match.params.pincode) ? props.match.params.pincode :  null)
	const [lessonTemplate, setLessonTemplate] = useState({})

	useEffect(() => {
		if (lessonTemplateKey && authData.email) {
			joinGame(lessonTemplateKey)
		}
	}, [lessonTemplateKey, authData.email])

	const joinGame = (_lessonTemplateKey) => {		
		getLessonTemplateData(_lessonTemplateKey, (gameData) => {		
			if (gameData) {
				if ((authData.roles && authData.roles.some(role => ['teacher'].indexOf(role) >= 0) && gameData.owner === authData.email) || // system teacher and game owner can edit
					(authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0) ) || // system owner can edit
					(authData.roles && authData.roles.some(role => ['editor'].indexOf(role) >= 0) && gameData.editors && gameData.editors.indexOf(authData.email) >= 0 ) // system editor and game editor can edit
				) {
					initGameListener(gameData)
				} else {
					const snack = { variant: 'error', message: 'Could not find matching game' }
					props.showSnackbar(snack)
				}
			} else {
				const snack = { variant: 'info', message: 'No game found' }
				props.showSnackbar(snack)
			}
		})
	}
	
	
	
	const initGameListener = async (_lessonTemplate) => {
		let lessonTemplateRef = await getGameTemplateRef(_lessonTemplate.key)
		
		lessonTemplateRef.on('value', (snapshot) => {
			const ltSnapshot = snapshot.val()
			if (!ltSnapshot.phase) { ltSnapshot.phase = 'setup' }
			
			if (ltSnapshot) { setLessonTemplate(ltSnapshot) }
			else { setLessonTemplate('') }
		})
	}

	return (
		<Layout2 withAuth={true}>
			{
				lessonTemplate && lessonTemplate.key && 
					<div>
						<LessonTemplateDesigner showSnackbar={props.showSnackbar} game={lessonTemplate} />
					</div>
			}
		</Layout2>
	)
}

export default Create
