import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import QuestionContent from '../shared/QuestionContent'

const QuestionSection = styled.div`height: calc(100vh - 50px); text-align: center; overflow: auto; vertical-align: middle; display: table-cell; width: 100vw;
	@media (max-width: 1016px) {
		height: calc(100vh - 70px);
	}
`
const NextButtonArea = styled.div`position: absolute; bottom: 10px; height: 60px; float: right; right: 10px; z-index: 10;`

function PhaseShowQuestion(props) {
	const [question, setQuestion] = useState('')

	useEffect(() => {
		setQuestion(props.game.quiz.questions[props.game.quiz.currentQuestion])
	}, [props.game.quiz.currentQuestion])

	const nextPhase = () => {
		props.gameFunc.update({ phase: 'answer' }) // NEXT PHASE ON FB
	}

	// const shuffle = (array) => { // SIRALAMA TEST SORULARI ICIN KULLANILACAK SONRA
	// 	let counter = array.length
		
	// 	while (counter > 0) {
	// 		const index = Math.floor(Math.random() * counter)
	// 		counter--
	// 		const temp = array[counter]
	// 		array[counter] = array[index]
	// 		array[index] = temp
	// 	}
	// 	return array
	// }

	return (
		<div>
			{ question &&
				<QuestionSection>
					<QuestionContent question={question} 
						imageBrushColor="red"
						videoPlaying={false} videoControlType="host" videoUseMaxScreen={true} videoVolume={1}
						onVideoEnd={null}
						gameFunc={props.gameFunc}
						gameKey={props.game.key}
						gamePhase={props.game.phase}
					/>
				</QuestionSection>
			}
			
			<NextButtonArea>
				<Fab color="primary" aria-label="add" onClick={() => nextPhase()}>
					<NavigateNextIcon />
				</Fab>
			</NextButtonArea>
		</div>
	)
}

export default PhaseShowQuestion
