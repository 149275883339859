// import { firestore } from '../../utils/firebaseSettings'
// import { getAllStudentCourses } from '../firebase/studentCoursesFirebase'
// import { getCoursesOfStaff } from '../firebase/courseFirebase'

const filterRegisteredAtLeastOneCourseStudents = async (students, studentCourses) => {
	let studentEmails = await getUniqueStudentEmailsFromStudentCourseList(studentCourses)
	let tempStudents = students.filter(_student => studentEmails.indexOf(_student.email) >= 0 )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const filterPaidAtLeastOneCourseStudents = async (students, studentCourses) => {
	let filteredStudentCourses = studentCourses.filter(_studentCourse => (
		(_studentCourse.status === 'paid' || _studentCourse.status === 'paid-cash' || _studentCourse.status === 'paid-paypal') 
		&& _studentCourse.listingPrice.basePrice > 0)
	)
	let studentEmails = await getUniqueStudentEmailsFromStudentCourseList(filteredStudentCourses)
	let tempStudents = students.filter(_student => studentEmails.indexOf(_student.email) >= 0 )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const filterWillBePaidAtLeastOneCourseStudents = async (students, studentCourses) => {
	let filteredStudentCourses = studentCourses.filter(_studentCourse => 
		_studentCourse.status === 'will-be-paid-cash' || _studentCourse.status === 'will-be-paid-paypal'
	)
	let studentEmails = await getUniqueStudentEmailsFromStudentCourseList(filteredStudentCourses)
	let tempStudents = students.filter(_student => studentEmails.indexOf(_student.email) >= 0 )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const filterRegisteredAtLeastOneFullDiscountedCourseStudents = async (students, studentCourses) => {
	let filteredStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.status === 'free')
	let studentEmails = await getUniqueStudentEmailsFromStudentCourseList(filteredStudentCourses)
	let tempStudents = students.filter(_student => studentEmails.indexOf(_student.email) >= 0 )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const filterRegisteredAtLeastOneFreeCourseStudents = async (students, studentCourses) => {
	let filteredStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.status === 'paid' && _studentCourse.listingPrice.basePrice === 0)
	let studentEmails = await getUniqueStudentEmailsFromStudentCourseList(filteredStudentCourses)
	let tempStudents = students.filter(_student => studentEmails.indexOf(_student.email) >= 0 )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const filterRegisteredFreeCourseButNoPaidStudents = async (students, studentCourses) => {
	let atLeastOneCourseStudents = await filterRegisteredAtLeastOneFreeCourseStudents(students, studentCourses)
	let filteredStudentCourses = atLeastOneCourseStudents.filter(_studentCourse => !(
		(_studentCourse.status === 'paid' || _studentCourse.status === 'paid-cash' || _studentCourse.status === 'paid-paypal') 
		&& _studentCourse.listingPrice.basePrice > 0)
	)
	let studentEmails = await getUniqueStudentEmailsFromStudentCourseList(filteredStudentCourses)
	let tempStudents = students.filter(_student => studentEmails.indexOf(_student.email) >= 0 )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const filterNonCourseRegisteredStudents = async (students, studentCourses) => {
	let studentEmails = await getUniqueStudentEmailsFromStudentCourseList(studentCourses)
	let tempStudents = students.filter(_student => studentEmails.indexOf(_student.email) === -1 )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const filterStudentsWithMentor = async (students) => {
	let tempStudents = students.filter(_student => _student.mentorship && _student.mentorship.mentor )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const filterStudentsWithoutMentor = async (students) => {
	let tempStudents = students.filter(_student => !(_student.mentorship && _student.mentorship.mentor) )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

// const filterCourseRegisteredStudentsWithoutMentor = async (students, studentCourses) => {
// 	let tempStudents = await filterRegisteredAtLeastOneCourseStudents(students, studentCourses)
// 	tempStudents = tempStudents.filter(_student => !(_student.mentorship && _student.mentorship.mentor) )
// 	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
// 	return tempStudents
// }

// const filterNonCourseRegisteredStudentsWithMentor = async (students, studentCourses) => {
// 	let studentEmails = await getUniqueStudentEmailsFromStudentCourseList(studentCourses)
// 	let tempStudents = students.filter(_student => studentEmails.indexOf(_student.email) === -1 )
// 	tempStudents = tempStudents.filter(_student => _student.mentorship && _student.mentorship.mentor )
// 	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
// 	return tempStudents
// }

const filterByMentor = async (students, mentorEmail) => {
	let tempStudents = students.filter(_student => _student.mentorship && _student.mentorship.mentor === mentorEmail )
	tempStudents = tempStudents.sort((a,b) => a.created < b.created ? 1 : -1)
	return tempStudents
}

const getUniqueStudentEmailsFromStudentCourseList = async (studentCourses) => {
	let studentEmails = []
	for (let i = 0; i < studentCourses.length; i++) {
		if (studentEmails.filter(_studentEmail => _studentEmail === studentCourses[i].email).length === 0) {
			studentEmails.push(studentCourses[i].email)
		}
	}
	return studentEmails
}

const getUniqueStudentEmailsFromStudentList = async (students) => {
	let studentEmails = []
	for (let i = 0; i < students.length; i++) {
		if (studentEmails.filter(_student => _student === students[i].email).length === 0) {
			studentEmails.push(students[i].email)
		}
	}
	return studentEmails
}

const addStudentCoursesToStudents = async (students, studentCourses) => {
	let tempStudents = [...students]
	for (let i = 0; i < tempStudents.length; i++) {
		let thisStudentCourses = studentCourses.filter(_studentCourse => _studentCourse.email === tempStudents[i].email)
		tempStudents[i].studentCourses = thisStudentCourses
		
		tempStudents[i].coursePaymentStatusStats = {
			paid: thisStudentCourses.filter(_studentCourse => _studentCourse.status === 'paid').length, // by credit card
			inShoppingCart: thisStudentCourses.filter(_studentCourse => _studentCourse.status === 'shopping-cart').length,
			paidCash: thisStudentCourses.filter(_studentCourse => _studentCourse.status === 'paid-cash').length,
			willBePaidByCash: thisStudentCourses.filter(_studentCourse => _studentCourse.status === 'will-be-paid-cash').length,
			paidPaypal: thisStudentCourses.filter(_studentCourse => _studentCourse.status === 'paid-paypal').length,
			willBePaidByPaypal: thisStudentCourses.filter(_studentCourse => _studentCourse.status === 'will-be-paid-paypal').length,
			freeForPaidCourse: thisStudentCourses.filter(_studentCourse => _studentCourse.status === 'free').length,
		}

		let totalCourseAmount = 0
		let totalCourseAmountPaid = 0
		let totalCourseAmountWillBePaid = 0
		let totalCourseAmountInCart = 0
		let totalCourseAmountWithFullDiscount = 0
		let totalDiscountAmount = 0

		let paidCourseCount = 0
		let willBePaidCourseCount = 0
		let fullDiscountCourseCount = 0		
		for (let j = 0; j < thisStudentCourses.length; j++) {
			totalCourseAmount += thisStudentCourses[j].listingPrice.totalPrice
			let _studentCourseInLoop = thisStudentCourses[j]
			if ((_studentCourseInLoop.status === 'paid' || _studentCourseInLoop.status === 'paid-cash' || _studentCourseInLoop.status === 'paid-paypal') && _studentCourseInLoop.listingPrice.basePrice > 0) {
				totalCourseAmountPaid += _studentCourseInLoop.listingPrice.totalPrice
				paidCourseCount++
			} else if (_studentCourseInLoop.status === 'will-be-paid-cash' || _studentCourseInLoop.status === 'will-be-paid-paypal') {
				totalCourseAmountWillBePaid += _studentCourseInLoop.listingPrice.totalPrice
				willBePaidCourseCount++
			} else if (_studentCourseInLoop.status === 'free') {
				totalCourseAmountWithFullDiscount += _studentCourseInLoop.listingPrice.totalPrice
				totalDiscountAmount += _studentCourseInLoop.listingPrice.totalPrice
				fullDiscountCourseCount++
			} else if (_studentCourseInLoop.status === 'shopping-cart') {
				totalCourseAmountInCart += _studentCourseInLoop.listingPrice.totalPrice
			}
		}

		tempStudents[i].courseCountStats = {
			paidCourseCount: paidCourseCount,
			willBePaidCourseCount: willBePaidCourseCount,
			fullDiscountCourseCount: fullDiscountCourseCount,

			nonFreeCourseCount: thisStudentCourses.filter(_studentCourse => _studentCourse.listingPrice.basePrice > 0).length,
			freeCourseCount: thisStudentCourses.filter(_studentCourse => _studentCourse.listingPrice.basePrice === 0).length,			
		}

		tempStudents[i].amountStats = {
			totalCourseAmount: totalCourseAmount,
			totalCourseAmountPaid: totalCourseAmountPaid,
			totalCourseAmountWillBePaid: totalCourseAmountWillBePaid,
			totalCourseAmountInCart: totalCourseAmountInCart,
			totalCourseAmountWithFullDiscount: totalCourseAmountWithFullDiscount,
			totalDiscountAmount: totalDiscountAmount,
			// averageDiscount: 0
		}
	}
	return tempStudents
}

// const updateAllStudentCourseRecords = async () => {
// 	let allStudentCourses = await getAllStudentCourses('yesilokulcom@gmail.com')
// 	let allCourses = await getCoursesOfStaff('yesilokulcom@gmail.com')
	
// 	for (let i = 0; i < allStudentCourses.length; i++) {
// 		let studentCourse = allStudentCourses[i]
// 		let tempCourses = allCourses.filter(_course => _course.id === studentCourse.courseId)
// 		if (tempCourses && tempCourses.length === 1) {
// 			let course = tempCourses[0]
// 			studentCourse.curriculum = {
// 				curriculumId: course.meta.curriculum.curriculumId
// 			}
// 			studentCourse.listingPrice = {
// 				basePrice: course.meta.pricing.basePrice,
// 				currency: course.meta.pricing.currency,				
// 				totalPrice: (course.meta.pricing.basePrice * course.meta.stats.lessonCount)
// 			}

// 			// if (studentCourse.id === '04AhbeY7KTocPdbE04rS') {
// 				console.log('studentCourse after additions: ', studentCourse)
// 				let studentCourseDocRef = await firestore.collection('studentCourses').doc(studentCourse.id)
// 				await studentCourseDocRef.set(studentCourse)
// 			// }			
// 		}
// 	}
// }


export {
	filterRegisteredAtLeastOneCourseStudents, filterPaidAtLeastOneCourseStudents, filterWillBePaidAtLeastOneCourseStudents, // Course Based Filters
	filterRegisteredAtLeastOneFullDiscountedCourseStudents,
	filterRegisteredAtLeastOneFreeCourseStudents, filterRegisteredFreeCourseButNoPaidStudents, // Course Based Filters
	filterNonCourseRegisteredStudents,	filterStudentsWithMentor, filterStudentsWithoutMentor, // filterCourseRegisteredStudentsWithoutMentor, filterNonCourseRegisteredStudentsWithMentor,
	filterByMentor,
	getUniqueStudentEmailsFromStudentCourseList, getUniqueStudentEmailsFromStudentList, addStudentCoursesToStudents,
	// updateAllStudentCourseRecords // SPECIAL OR TEMP
}
