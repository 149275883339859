import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { AuthContext } from '../../../../../data/context/AuthProvider'

const ListItem = styled.div`font-size: 14px; margin-top: 10px;`
const AnswerCircle = styled.div`float: left; width: 20px; height: 20px; border-radius: 40px; color: white; text-align: center; font-size: 12px; margin: 2px; line-height: 20px; cursor: pointer;`
const CorrectAnswer = styled(AnswerCircle)`background-color: green;`
const WrongAnswer = styled(AnswerCircle)`background-color: red;`
const NoAnswer = styled(AnswerCircle)`background-color:  gray;`
const NotAsked = styled(AnswerCircle)`background-color: #f2f2f2;`
const NoNeedToEvaluate = styled(AnswerCircle)`background-color: black;`

function StudentAnswerListItem(props) {
	const [authData] = useContext(AuthContext)
	const getAnswerText = (answer, index, q) => {
		return answer.optionText ? answer.optionText : ( answer.optionText ?  answer.optionText : '' )
	}

    return (
		<ListItem>
			<Grid container>
				<Grid item xs={3}>
					<div>
						<Tooltip title={props.student.email && props.student.email.length > 0 ? 'Verified User' : 'Guest User'}>
							{
								props.student.email && props.student.email.length > 0 ?
									<VerifiedUserIcon style={{color: '#3dc706', fontSize: '12px' }} />
								:
									<RemoveCircleIcon style={{color: 'red', fontSize: '12px' }} />
							}
						</Tooltip>
						{` `}
						{
							(authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 )) ?
								<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2FlessonResults~2F${props.student.id}`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'black'}}>
									{props.studentIndex + 1}
								</a>
								:
								<span>{props.studentIndex + 1}</span>
						}
						{` - `}
						{
							(authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 ) && props.student.email) ?
								<a href={`/student/${props.student.email}`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'black'}}>
									{props.student.name}
								</a>
							:
								<span>props.student.email</span>
						}
						{
							props.student.stats && props.student.stats.totalScorePercent && props.student.stats.totalScore ?
								<div style={{marginTop: '5px', fontWeight: 'bold', color: 'maroon', fontSize: '10px', paddingLeft: '15px'}}>
									{props.student.stats.correctPercent}% Doğru - {props.student.stats.totalScore} Puan
								</div>
							:
								<div></div>
						}
					</div>
				</Grid>
				<Grid item xs={9}>
					{
						props.lessonInstance.quiz.questions.map((q, index) =>
							<span key={index}>
								{
									index >= props.currentQuestionIndex && props.currentQuestionIndex.length !== props.currentQuestionIndex ? // Not Last question and index is smaller than Current Index
										<Tooltip title={`Not reached yet`}>
											<NotAsked>{index + 1}</NotAsked>
										</Tooltip>
									:
										q.cType === 'content' || q.cType === 'poll' || q.cType === 'form' ?
											<Tooltip title={`Sunum içeriği`}>
												<NoNeedToEvaluate>{index + 1}</NoNeedToEvaluate>
											</Tooltip>
										:
											props.student.answers &&  props.student.answers[index + props.answerIndexOffset] && props.student.answers[index + props.answerIndexOffset].score > 0 ?
												props.student.answers[index + props.answerIndexOffset].isCorrect ?
														<Tooltip title={`${props.student.answers[index + props.answerIndexOffset].score} Puan - Verilen Cevap: ${getAnswerText(props.student.answers[index + props.answerIndexOffset].answer)}`}>
															<CorrectAnswer>{index + 1}</CorrectAnswer>
														</Tooltip>
													:
														<Tooltip title={`0 Puan - Yanlış Cevap - Verilen Cevap: ${getAnswerText(props.student.answers[index + props.answerIndexOffset].answer, index, q)}`}>
															<WrongAnswer>{index + 1}</WrongAnswer>
														</Tooltip>
											:												
												<Tooltip title={`0 Puan - Cevap Yok`}>
													<NoAnswer>{index + 1}</NoAnswer>
												</Tooltip>
								}
							</span>
						)
					}
				</Grid>
			</Grid>
		</ListItem>
    )
}

export { StudentAnswerListItem }
