import React from 'react'
import styled from 'styled-components'

import StarIcon from '@material-ui/icons/Star'
import TripOriginIcon from '@material-ui/icons/TripOrigin'
import FilterVintageIcon from '@material-ui/icons/FilterVintage'
import CropSquareIcon from '@material-ui/icons/CropSquare'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

const AnswerItem = styled.div`margin-left: 2px; margin-right: 2px;`
const AnswerClickArea = styled.div`height: 17vh; width: 100%; border-radius: 6px; border: solid 3px white;
	&:hover { border: solid 3px black; }
	@media (max-width: 767px) { height: 13vh;}
`
const AnswerNonClickArea = styled.div`height: 17vh; width: 100%; border-radius: 6px; border: solid 3px white;
	@media (max-width: 767px) { height: 13vh;}
`
const AnswerImage = styled.img`max-height: 16vh; max-width: 80%; margin: 2px; line-height: 14vh; border-radius: 6px;
	@media (max-width: 767px) {
		max-height: calc(13vh - 10px);
		max-width: 70%;
		overflow: hidden;
	}
`

const answerStyles = [	
	{
		icon: <CropSquareIcon style={{fontSize: 'inherit'}} />,
		color: '#d89e00',
	}, {
		icon: <StarIcon style={{fontSize: 'inherit'}} />,
		color: '#1368ce',
	}, {
		icon: <TripOriginIcon style={{fontSize: 'inherit'}} />,
		color: '#26890c',
	}, {
		icon: <FilterVintageIcon style={{fontSize: 'inherit'}} />,
		color: '#e21b3c',
	}, {
		icon: <AccessTimeIcon style={{fontSize: 'inherit'}} />,
		color: '#cc00cc',
	}, {
		icon: <CropSquareIcon style={{fontSize: 'inherit'}} />,
		color: '#3366FF',
	}, {
		icon: <StarIcon style={{fontSize: 'inherit'}} />,
		color: '#001f3f',
	}, {
		icon: <TripOriginIcon style={{fontSize: 'inherit'}} />,
		color: '#9966FF',
	}, {
		icon: <FilterVintageIcon style={{fontSize: 'inherit'}} />,
		color: '#08AEEA',
	}, {
		icon: <AccessTimeIcon style={{fontSize: 'inherit'}} />,
		color: '#FF4136',
	}
]

const AnswerIconArea = styled.div`float: left; color: white; margin-left: 5px; pointer-events: none;
	margin: 0 auto; height: 100%; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center; 
	font-size: ${(props) => props.fontSize ? props.fontSize : '64px' };
	@media (max-width: 1067px) {
		font-size: ${(props) => props.fontSize ? props.fontSize : '28px' };
		max-width: 30%;
	}
	@media (max-width: 767px) {
		font-size: ${(props) => props.fontSize ? props.fontSize : '20px' };
		max-width: 30%;
	}
`
const AnswerText = styled.div`font-weight: bold; color: white; margin-left: 15px; overflow: hidden; pointer-events: none; 
	margin: 0 auto; height: 100%; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center; 
	font-size: ${(props) => props.fontSize ? props.fontSize : '28px' };
	@media (max-width: 767px) {
		font-size: ${(props) => props.fontSize ? props.fontSize : '20px' };
	}
`

function AnswerOption(props) {	
	const design = answerStyles[props.index]
	let answerType = (props.answer.optionImage && props.answer.optionImage.url) ? 'image' : 'text'
	let answerText = (answerType === 'image') ? props.answer.optionImage.url : props.answer.optionText
	
	return (
		<AnswerItem>
			{
				(props.answerQuestion && answerText.length > 0) && ( // CLICKABLE
					<AnswerClickArea onClick={() => { props.answerQuestion(props.answer); }} style={{ backgroundColor: design.color, cursor: 'pointer' }}>
						{
							props.answerCount < 5 && // PRO does not need icons
								<AnswerIconArea style={{cursor: 'pointer'}} fontSize={props.fontSize}>{design.icon}</AnswerIconArea>
						}
						{
							(answerType==='image') ?
								answerText.length > 0 ?
									<AnswerImage src={answerText} alt="answer" />
								:
									<span></span>
							:
								<AnswerText style={{cursor: 'pointer'}} fontSize={props.fontSize} >{answerText}</AnswerText>
						}
					</AnswerClickArea>
				)
			}
			{
				(!props.answerQuestion && answerText && answerText.length > 0) && ( // NO CLICK
					<AnswerNonClickArea style={{ backgroundColor: design.color }}>
						{
							props.answerCount < 5 && // PRO does not need icons
								<AnswerIconArea>{design.icon}</AnswerIconArea>
						}
						{
							(answerType === 'image') ?
								answerText.length > 0 ?
									<AnswerImage src={answerText} alt="answer" />
								:
									<span></span>
							:
								<AnswerText style={{cursor: 'pointer'}} fontSize={props.fontSize}>{answerText}</AnswerText>
						}
					</AnswerNonClickArea>
				)
			}
		</AnswerItem>
	)
}

export default AnswerOption
