import moment from 'moment'
import { getCourseStudentsByCourseIdList } from '../firebase/studentCoursesFirebase'

const getCourseListStats = async (courses) => {
	let draftCourses = courses.filter(_course => _course.meta.settings.status === 'draft')
	let publishedPublicCourses = courses.filter(_course => _course.meta.settings.status === 'published')
	let publishedUnlistedCourses = courses.filter(_course => _course.meta.settings.status === 'published-unlisted')
	let publishedPrivateCourses = courses.filter(_course => _course.meta.settings.status === 'published-private')
	let coursesContainsStudentsInShoppingCart = courses.filter(_course => _course.students && _course.students.inShoppingCart && _course.students.inShoppingCart.length > 0
		&& (_course.meta.settings.status === 'published' || _course.meta.settings.status === 'published-unlisted' || _course.meta.settings.status === 'published-private') // Only published may have students
	)
	let coursesContainsStudentsRegistered = courses.filter(_course => _course.students && _course.students.registered && _course.students.registered.length > 0
		&& (_course.meta.settings.status === 'published' || _course.meta.settings.status === 'published-unlisted' || _course.meta.settings.status === 'published-private')  // Only published may have students
	)

	let lastCourseCreateDate = moment('2020-01-01', 'YYYY-MM-dd')
	let lastCourseUpdateDate = moment('2020-01-01', 'YYYY-MM-dd')
	for (let i = 0; i < courses.length; i++) {
		if (lastCourseCreateDate < moment(courses[i].meta.dates.courseCreated)) {
			lastCourseCreateDate = moment(courses[i].meta.dates.courseCreated)
		}
		if (lastCourseUpdateDate < moment(courses[i].meta.dates.courseLastUpdated)) {
			lastCourseUpdateDate = moment(courses[i].meta.dates.courseLastUpdated)
		}
	}

	
	let courseListStats = {
		all: courses.length,
		draft: draftCourses.length,
		published: publishedPublicCourses.length,
		publishedUnlisted: publishedUnlistedCourses.length,
		publishedPrivate: publishedPrivateCourses.length,
		haveStudentsInCart: coursesContainsStudentsInShoppingCart.length,
		haveStudentsRegistered: coursesContainsStudentsRegistered.length,
		lastCreateDate: lastCourseCreateDate.toDate(),
		lastUpdateDate: lastCourseUpdateDate.toDate(),
	}

	return courseListStats
}

const getRegisteredStudentsForCourseList = async (courses) => {
	let courseIds = []
	let coursesStudents = []
	for (let i = 0; i < courses.length; i++) {
		let _course =  courses[i]
		courseIds.push(_course.id)
		if (courseIds.length === 10 || i === courses.length - 1 ) {
			if (courseIds.length <= 10) {
				let tempCoursesStudents = await getCourseStudentsByCourseIdList(courseIds)
				coursesStudents = coursesStudents.concat(tempCoursesStudents)
			}
			courseIds = []
		}
	}

	return coursesStudents
}

const addRegisteredStudentsIntoCourses = async (courses, coursesStudents) => {
	let tempCourses =[...courses]

	coursesStudents.sort((a, b) => b.additionDate - a.additionDate)

	for (let i = 0; i < tempCourses.length; i++) {
		let tempStudentsOfCourse = coursesStudents.filter(_studentCourse => _studentCourse.courseId === tempCourses[i].id)
		let tempRegisteredStudents = tempStudentsOfCourse.filter(_studentCourse => 
			_studentCourse.status === 'paid' ||
			_studentCourse.status === 'paid-cash' ||
			_studentCourse.status === 'will-be-paid-cash' ||
			_studentCourse.status === 'paid-paypal' ||
			_studentCourse.status === 'will-be-paid-paypal' ||
			_studentCourse.status === 'free'
		)
		let tempStudentsInCart = tempStudentsOfCourse.filter(_student => _student.status === 'shopping-cart')
		tempCourses[i].students = {
			registered: tempRegisteredStudents,
			inShoppingCart: tempStudentsInCart
		}
	}
	return (tempCourses)
}

export { getCourseListStats, getRegisteredStudentsForCourseList, addRegisteredStudentsIntoCourses }
