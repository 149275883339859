import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'

const TimerArea = styled.div`width: 100%; clear: both; height: 52px;
	@media (max-width: 767px) {
		height: 52px;
	}
`
const SecondsText = styled.div`font-size: 32px; height: 42px; text-align: right; font-weight: bold; margin-right: 80px; color: red;`

function Timer(props) {	
	const [text, setText] = useState('')
	const [completed, setCompleted] = useState(0)
	const [startTime, setStartTime] = useState(new Date().getTime())

	useEffect(() => {
		let interval
		if (props.timeLimit > 0) {
			let tempCounter = props.timeLimit
			let totalTime = props.timeLimit * 1000
			setStartTime(new Date().getTime())
			interval = setInterval(() => {
				var now = new Date().getTime()
				var elapsed = now - startTime
				tempCounter = (totalTime - elapsed)
				if (tempCounter < 0) {
					tempCounter = 0
				}
				
				let currentCount = tempCounter === null ? props.timeLimit : tempCounter	
				if (currentCount < 0) {
					currentCount = 0
				}
				setCompleted(currentCount / totalTime)
				setText(Math.ceil(tempCounter / 1000).toString())
				
				if (tempCounter <= 0) {
					if (props.onTimerCompleted) {
						props.onTimerCompleted()
					}
					clearInterval(interval)
				}
			}, 900)
		}

		return () => clearInterval(interval)
	}, [])

	return (
		<TimerArea>
		{ completed && completed !== 0 &&
			<div>
				<SecondsText style={{color: (completed * 100) > 50 ? 'green' : ( (completed * 100) > 33 ? 'orange' : 'red') }}>
					{text}
				</SecondsText>
				<LinearProgress 
					variant="determinate"
					value={completed * 100}
					style={{
						width: '100%',
						bottom: '0px',
						backgroundColor: (completed * 100) > 50 ? 'green' : ( (completed * 100) > 33 ? 'orange' : 'red'),
					}}
				/>
			</div>
		}
		</TimerArea>
	)
}

export { Timer }
