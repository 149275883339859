import React, { useState, useEffect } from 'react'
import styled  from 'styled-components'
import Grid from '@material-ui/core/Grid'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

const PlayersList = styled.div`border: solid 1px gainsboro; border-radius: 8px; width: calc(100% - 20px); margin-left: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(30, 30, 30, 0.25);
	@media (max-width: 767px) {
		width: calc(100% - 4px); margin-left: 2px;
	}
`
const ListHeader = styled(Grid)`width: 98%; border-bottom: solid 1px gainsboro;`
const ListTitle = styled.div`float: left; font-weight: bold; font-size: 30px; margin-left: 10px; text-align: left; line-height: 60px;
	@media (max-width: 767px) {
		font-size: 24px; line-height: 40px;
	}
`
const ListCount = styled.div`float: left; font-weight: bold; font-size: 60px; color: #1a53ff; margin-right: 10px; text-align: left;
	@media (max-width: 767px) {
		font-size: 40px;
	}
`
const PlayerListContent = styled.div`height: calc(100vh - 150px); overflow: auto; width: 100%;
	@media (max-width: 767px) {
		height: calc(100vh - 150px);
	}
`
const BackgroundDiv = styled.div`z-index: -1; min-height: 100%;
	background-image: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.3)), url('/images/backgrounds/back-1.png'); 
	background-repeat: no-repeat; background-attachment: fixed;
`
const PlayerListArea = styled.div`width: 100%; height: calc(100vh - 150px); overflow: auto; 
	-webkit-box-shadow: inset 0 0 6px rgba(30, 30, 30, 0.25);
`
const AnswerArea = styled.div`width: 100%; height: calc(100vh - 150px); overflow: auto; font-size: 24px; padding: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(30, 30, 30, 0.25);
`

const PlayerAnswerItem = styled.div`width: 95%; height: 80px; text-align: center; overflow: hidden; position: relative; margin-left: 3px; margin-top: 3px; margin-bottom: 3px; border-radius: 6px;
	-webkit-box-shadow: inset 0 0 6px ${(props) => props.selected ? 'green' : 'rgba(30, 30, 30, 0.25)'};
`
const PlayerNameText = styled.div`font-weight: bold; font-size: 14px; width: 100%; color: white; height: 24px; overflow: hidden;
	background-color: ${(props) => props.answered ? 'green' : '#666666'};
`
const PlayerAnswer = styled.span`cursor: pointer; color: black;  height: 55px;`

function QuestionPlayerAnswers(props) {
	const [players, setPlayers] = useState([])
	const [playersUI, setPlayersUI] = useState([])
	const [answerCount, setAnswerCount] = useState(0)
	const [selectedAnswer, setSelectedAnswer] = useState('')

	useEffect(() => {
		props.onAnswerSelect(selectedAnswer.playerKey)
	}, [selectedAnswer])

	useEffect(() => {
		if (props.mode !== 'active' && props.gameInstance.uiState.drawerSelectedPlayerAnswerKey) {
			let tempSelectedAnswer = (props.gameInstance.players[props.gameInstance.uiState.drawerSelectedPlayerAnswerKey].answers[props.question.id])
			let answerContent = getAnswerContent(tempSelectedAnswer, props.gameInstance.players[props.gameInstance.uiState.drawerSelectedPlayerAnswerKey])
			setSelectedAnswer({...answerContent})
		}
	}, [props.gameInstance.uiState && (props.gameInstance.uiState.drawerSelectedPlayerAnswerKey)])

	useEffect(() => {
		let tempPlayers = props.gameInstance.players
		if (!tempPlayers) {
			tempPlayers = [];
		} else {
			tempPlayers = Object.values(tempPlayers);
		}
		setPlayers(tempPlayers)
	}, [props.gameInstance])

	useEffect(() => {
		let tempList = []
		let answersCollected = 0
		players.map((player, index) => {
			let answerContent = {content: ''}
			if (player.answers && player.answers[props.question.id]) {
				answersCollected++
				let answerObj = player.answers[props.question.id]
				answerContent = getAnswerContent(answerObj, player)
			}

			tempList.push(
				<Grid item xs={12} key={index}>
					<PlayerAnswerItem selected={(selectedAnswer && (selectedAnswer.playerKey === answerContent.playerKey && selectedAnswer.playerEmail === answerContent.playerEmail )) ? true : false}>
						<Grid container>
							<Grid item xs={12}>
								<PlayerNameText answered={(answerContent.content && answerContent.content !== '') ? true : false }>
									{player.email && player.email.length > 0 ? <VerifiedUserIcon style={{fontSize: '12px', color: '#00fc04'}} title={player.email} /> : ''} {player.name} 
									<span style={{fontSize: '9px'}}>{player.email ? '(' + player.email + ')' : ''}</span>								
								</PlayerNameText>
							</Grid>
							<Grid item xs={2}>
								{
									(props.question.cType === 'quiz' && answerContent.content !== '') ?
										answerContent.score > 0 ?
											<CheckCircleIcon style={{color: 'green'}} />
										:
											<CancelIcon style={{color: 'red'}} />
									: null
								}
							</Grid>
							<Grid item xs={10}>
								<PlayerAnswer onClick={() => setSelectedAnswer({...answerContent})}>
									{answerContent.content}
								</PlayerAnswer>
							</Grid>
						</Grid>
					</PlayerAnswerItem>
				</Grid>
			)
			return null
		})
		setPlayersUI(tempList)		
        setAnswerCount(answersCollected)
	}, [players, selectedAnswer])

	const getAnswerContent = (answerFullResult, player) => {
		let answerContent = {content: ''}
		const answerObj = answerFullResult.answer
		if (answerObj) {
			if (props.question.aType === 'multiple' || props.question.aType === 'boolean') {
				if (answerObj.optionImage && answerObj.optionImage.url && answerObj.optionImage.url.length > 0) {
					answerContent = { content: <img src={answerObj.optionImage.url} style={{maxWidth: '100%', maxHeight: '50px', borderRadius: '6px', marginTop: '2px'}} alt="answer option" />, 
						score: answerFullResult.score, answerTime: answerFullResult.answerTime, 
						answerObj, type: 'image', subType: 'optionImage', imgUrl: answerObj.optionImage.url, thumbImgUrl: answerObj.optionImage.thumb_url, playerName: player.name, playerEmail: player.email, playerKey: player.key,
					}
				} else {
					answerContent = { content :answerObj.optionText, answerObj, type: 'text', subType: 'optionText', score: answerFullResult.score, answerTime: answerFullResult.answerTime, playerName: player.name, playerEmail: player.email, playerKey: player.key, }
				}
			}
			else if (props.question.aType === 'free-text') {
				answerContent = { content : answerObj.freeText, answerObj, type: 'text', subType: 'freeText', score: answerFullResult.score, answerTime: answerFullResult.answerTime, playerName: player.name, playerEmail: player.email, playerKey: player.key, }
			}
			else if (props.question.aType === 'image-upload') {
				answerContent = { content :<img src={ answerObj.uploadedImage.thumb_url ? answerObj.uploadedImage.thumb_url :  answerObj.uploadedImage.url} style={{maxWidth: '100%', maxHeight: '50px', borderRadius: '6px', marginTop: '2px'}} alt="uploaded answer" />, 
					answerObj, type: 'image', subType: 'uploadedImage',
					imgUrl: answerObj.uploadedImage.url, thumbImgUrl: answerObj.uploadedImage.thumb_url, score: answerFullResult.score, answerTime: answerFullResult.answerTime, playerName: player.name, playerEmail: player.email, playerKey: player.key,
				}
			}
		}

		return answerContent
	}

	return (
		<PlayersList>
			<ListHeader container>
				<Grid item xs={9} sm={6}>
					<ListTitle>Cevaplar</ListTitle>
				</Grid>
				<Grid item xs={3} sm={6}>
					<ListCount>{answerCount}/{players.length}</ListCount>
				</Grid>
			</ListHeader>	
				
			<PlayerListContent>
				<BackgroundDiv>
					<Grid container>
						<Grid item xs={6} sm={3}>
							<PlayerListArea>
								<Grid container style={{overflow: 'hidden'}}>
									{playersUI}
								</Grid>
							</PlayerListArea>
						</Grid>
						<Grid item xs={6} sm={9}>
							<AnswerArea>
								{
									selectedAnswer &&
										<PlayerAnswerItem style={{height: '95%'}}>
											<Grid container>
												<Grid item xs={12}>
													<PlayerNameText answered={(selectedAnswer.content && selectedAnswer.content !== '') ? true : false }>{selectedAnswer.playerName} {selectedAnswer.playerEmail ? '(' + selectedAnswer.playerEmail + ')' : ''}</PlayerNameText>
												</Grid>
												<Grid item xs={12} style={{marginTop: '20px'}}>
													<div>
														{
															(props.question.cType === 'quiz' && selectedAnswer.content !== '') ?
																selectedAnswer.score > 0 ?
																	<CheckCircleIcon style={{color: 'green'}} />
																:
																	<CancelIcon style={{color: 'red'}} />
															: null
														}
													</div>
													{
														selectedAnswer && selectedAnswer.type === 'image' && selectedAnswer.imgUrl && (
															<img src={selectedAnswer.imgUrl} style={{maxWidth: 'calc(100% - 10px)', margin: '5px', borderRadius: '6px'}} alt="Student answer" />
														)
													}
													{
														selectedAnswer && selectedAnswer.type === 'text' && (
															<span>{selectedAnswer.content}</span>
														)
													}													
												</Grid>
											</Grid>
										</PlayerAnswerItem>
								}
							</AnswerArea>
						</Grid>
					</Grid>
				</BackgroundDiv>
			</PlayerListContent>
		</PlayersList>
	)
}

export default QuestionPlayerAnswers