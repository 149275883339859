// import { fire } from '../../../utils/firebaseSettings'
import { getFirebaseObj } from '../../../utils/firebaseSettings'
import { generateGameId } from '../../../utils/appUtil'

const fetchGameTemplate = async (gameTemplateKey, _callback) => {
	let firebaseObj = await getFirebaseObj('fetchGameTemplate')
	let fire = firebaseObj.fire

	await fire.database().ref('lesson_templates').orderByChild('templateKey').equalTo(gameTemplateKey)
		.once('value', _callback)
}

function getLessonTemplateData(gameTemplateKey, _callback) {
	fetchGameTemplate(gameTemplateKey, (snapshot) => {
		let gameTemplate
		if (snapshot.val()) {			
			snapshot.forEach((child) => {
				gameTemplate = child.val()
			})
		}
		return _callback(gameTemplate)
	})
}

const getGameTemplateRef = async (gameKey) => {
	let firebaseObj = await getFirebaseObj('getGameTemplateRef')
	let fire = firebaseObj.fire

	let gameRef = fire.database().ref(`lesson_templates/${gameKey}`)
	return gameRef
}

const addGameTemplateAndReturnRef = async () => {
	let firebaseObj = await getFirebaseObj('addGameTemplateAndReturnRef')
	let fire = firebaseObj.fire

	let gameRef = fire.database().ref(`/lesson_templates`).push()
	return gameRef
}

const updateGameTemplateData = async (lessonTemplateKey, gameUpdatedData, _callback) => {
	let firebaseObj = await getFirebaseObj('updateGameTemplateData')
	let fire = firebaseObj.fire

	fire.database().ref(`lesson_templates/${lessonTemplateKey}`).update(gameUpdatedData, (error) => {
		_callback(error)
	})
}

const updateQuestionDataInTemplate = async (lessonTemplateKey, questionIndex, questionUpdatedData, _callback) => {
	let firebaseObj = await getFirebaseObj('updateQuestionDataInTemplate')
	let fire = firebaseObj.fire

	fire.database().ref(`lesson_templates/${lessonTemplateKey}/quiz/questions/${questionIndex}`).update(questionUpdatedData, (error) => {
		_callback(error)
	})
}

const updateQuestionListInQuizInTemplate = async (lessonTemplateKey, newSlideList) => {
	let firebaseObj = await getFirebaseObj('updateQuestionListInQuizInTemplate')
	let fire = firebaseObj.fire

	await fire.database().ref(`lesson_templates/${lessonTemplateKey}/quiz/questions`).set(newSlideList, (error) => {
		return error
	})
}








const addGameInstanceAndReturnRef = async () => { // Create instance from a template
	let firebaseObj = await getFirebaseObj('addGameInstanceAndReturnRef')
	let fire = firebaseObj.fire

	let gameRef = await fire.database().ref(`/lesson_instances`).push()
	return gameRef
}
const updateGameInstanceData = async (gameKey, gameUpdatedData, _callback) => { // Update instance data for first creation
	let firebaseObj = await getFirebaseObj('updateGameInstanceData')
	let fire = firebaseObj.fire

	if (gameKey) {
		fire.database().ref(`lesson_instances/${gameKey}`).update(gameUpdatedData, (error) => {
			_callback(error)
		})
	} else {
		console.log('no game key: ', gameKey, gameUpdatedData)
	}
}

const createNewGameInstance = (gameTemplateKey, newInstanceName, _callback) => {
	getLessonTemplateData(gameTemplateKey, async (gameTemplate) => {
		let gameRef = await addGameInstanceAndReturnRef()		
		let tempGame = { ...gameTemplate }
		tempGame.players = []
		tempGame.key = gameRef.key
		tempGame.title = newInstanceName
		tempGame.templateKey = gameTemplate.templateKey
		tempGame.gameId = generateGameId()
	
		updateGameInstanceData(tempGame.key, tempGame, (error) => {
			_callback(error, tempGame.gameId, tempGame.key)
		})
	})
}

const deleteGameInstance = async (gameInstanceKey, _callback) => {
	let firebaseObj = await getFirebaseObj('deleteGameInstance')
	let fire = firebaseObj.fire

	let gameInstanceRef = fire.database().ref(`/lesson_instances/${gameInstanceKey}`)
	gameInstanceRef.remove()
		.then(function() {
			_callback(null)
		})
		.catch(function(error) {
			_callback(error)
		})
}

const fetchGameInstancesForTemplate = async (gameTemplateKey, _callback) => {
	let firebaseObj = await getFirebaseObj('fetchGameInstancesForTemplate')
	let fire = firebaseObj.fire

	fire.database().ref('lesson_instances').orderByChild('templateKey').equalTo(gameTemplateKey)
		.once('value', _callback)
}

const getGameInstancesForTemplate = (gameTemplateKey, _callback) => {
	fetchGameInstancesForTemplate(gameTemplateKey, (snapshot) => {
		let gameInstanceList = []
		if (snapshot.val()) {			
			snapshot.forEach((child) => {
				gameInstanceList.push(child.val())
			})
		}
		return _callback(gameInstanceList)
	})
}

export { getLessonTemplateData, getGameInstancesForTemplate, getGameTemplateRef, addGameTemplateAndReturnRef, updateGameTemplateData, 
	updateQuestionDataInTemplate, updateQuestionListInQuizInTemplate,
	createNewGameInstance, deleteGameInstance
}
