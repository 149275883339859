import React from 'react'
import styled from 'styled-components'
import QuestionContent from '../shared/QuestionContent'
import { H1 } from '../../../components'

const QuestionSection = styled.div`text-align: center; overflow: auto; width: calc(100% - 20px); height: calc(100vh - 75px); margin-left: 10px; margin-right: 10px; margin-top: 20px; border-radius: 6px; padding-left: 5px; padding-right: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);
`

function PhaseShowContent(props) {
	let currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]

	if (props.game.quiz.remoteMode) {
		return (
			<div>
				{ currentQuestion &&
					<QuestionSection>
						<QuestionContent question={currentQuestion} videoPlaying={false} onVideoEnd={null} videoUseMaxScreen={true} gamePhase={props.game.phase} />
					</QuestionSection>
				}
			</div>
		)
	} else {
		return (
			<H1>Sunum ekranı için ana ekrana bakın!</H1>
		)
	}
}

export default PhaseShowContent
