import React, { useState } from 'react'
import styled  from 'styled-components'
import Switch from '@material-ui/core/Switch'
import { H1, FormLine, FormElementLabel, BaseButton } from '../../../components'

const HostPage = styled.div`margin: 10px; max-width: 400px; width: 90%; margin-left: auto; margin-right: auto;`

function PhaseSetup(props) {
    const [remoteMode, setRemoteMode] = useState(true)
	
    const startGame = () => {
        let game = {}
        let quiz = props.game.quiz
        quiz.remoteMode = remoteMode
        quiz.currentQuestion = 0

        game.quiz = quiz
        game.phase = "connection"
        game.status = "IN_PROGRESS"
        props.gameFunc.update(game)
    }

    
    return (
        <HostPage>
            <H1>AYARLAR</H1>
            
            <FormLine>
                <BaseButton type="button" onClick={() => startGame()} value="KLASİK MOD" height="48px" fontSize="24px" />
            </FormLine>

            <FormLine style={{display: 'none'}}>
                <FormElementLabel>Remote playmode</FormElementLabel>                    
                <Switch checked={remoteMode} onChange={(e) => setRemoteMode(e.target.checked)} value="remoteMode" />
            </FormLine>
        </HostPage>
    )
}

export default PhaseSetup
