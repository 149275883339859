import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import CourseLesson from './CourseLesson'

const Section = styled.div`width: calc(100% - 10px); margin: 5px; border-radius: 6px; text-align: left; padding: 10px; margin-bottom: 20px;
	-webkit-box-shadow: inset 0 0 6px rgba(60, 180, 60, 0.65);
`
const SectionNumber = styled.span`border-radius: 100px; padding: 3px; width: 40px; margin-right: 5px; background-color: #a8150a; color: white; font-size: 16px;`
const SectionTitle = styled.span`font-size: 18px; font-weight: bold;`

function CourseSection(props) {
	const [title] = useState(props.section.title)
	const [lessons, setLessons] = useState([])

	useEffect(() => {
		if (props.section.lessons) {
			setLessons(props.section.lessons)
		} else {
			setLessons([])
		}
	}, [props.section])

	// const updateSectionData = () => {
	// 	if (props.onUpdateSection && props.editable) {
	// 		let tempSection = {...props.section}
	// 		if (title) { tempSection.title = title } else { tempSection.title = '' }
	// 		props.onUpdateSection({
	// 			...props.section,
	// 			title: title
	// 		})
	// 	}
	// }

	return (
		<Section>
			<Grid container>
				<Grid item xs={12} sm={9}>
					<SectionTitle>
						{
							props.index < 9 ?
								<SectionNumber>&nbsp;{props.index + 1}&nbsp;</SectionNumber> 
							:
								<SectionNumber>{props.index + 1}</SectionNumber> 
						}
						<span>{title}</span>
					</SectionTitle>
				</Grid>
				<Grid item xs={12} sm={3} style={{textAlign: 'center', fontWeight: 'bold'}}>
					Tarih ve Saat
				</Grid>
				<Grid item xs={12}>
					{
						(lessons && lessons.length > 0) &&							
							<div>
								{
									lessons.map((lesson, index) => (
										<CourseLesson lesson={lesson} key={lesson.id} index={index}
											editable={props.editable}
											onUpdate={(lesson) => { if (props.onUpdateLesson) { props.onUpdateLesson(props.section, lesson) } } }
										/>
									))
								}
							</div>
					}
				</Grid>
			</Grid>
		</Section>
	)
}

export default CourseSection
