// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'
import { createSlug } from '../../utils/utilityFunctions'

const getPlaylists = async (userEmail) => {
	let firebaseObj = await getFirebaseObj('getPlaylists')
	let firestore = firebaseObj.firestore

	let tempPlaylists = []
	if (userEmail === 'yesilokulcom@gmail.com') {
		let sPlaylists = await firestore.collection('playlists').get()
		sPlaylists.docs.map(doc => {
			tempPlaylists.push(doc.data())
			return null
		})
	} else {
		let sPlaylists = await firestore.collection('playlists').where('owner', '==', userEmail).get()
		sPlaylists.docs.map(doc => {
			tempPlaylists.push(doc.data())
			return null
		})

		sPlaylists = await firestore.collection('playlists').where('editors', 'array-contains-any', [userEmail]).get()
		sPlaylists.docs.map(doc => {
			tempPlaylists.push(doc.data())
			return null
		})
	}

	tempPlaylists.sort(function(a,b) {
		if (a.name < b.name) return 1
		if (a.name > b.name) return -1
		return 0
	})

	return (tempPlaylists)
}

const getPlaylistBySlug = async (slug) => {
	let firebaseObj = await getFirebaseObj('getPlaylistBySlug')
	let firestore = firebaseObj.firestore

	let tempPlaylists = []
	let sPlaylists = await firestore.collection('playlists').where('slug', '==', slug).get()
	sPlaylists.docs.map(doc => {
		tempPlaylists.push(doc.data())
		return null
	})

	let tmpPlaylist
	if (tempPlaylists.length > 0) {
		tmpPlaylist = tempPlaylists[0]
	}

	return tmpPlaylist
}

const addPlaylist = async (playlistName, ownerUser) => {
	let firebaseObj = await getFirebaseObj('addPlaylist')
	let firestore = firebaseObj.firestore

	let newDocRef = await firestore.collection('playlists').doc()
	const newDocId = newDocRef.id

	let newPlaylist = {
		id: newDocId,
		owner: ownerUser,
		title: playlistName,
		slug: createSlug(playlistName),
		videos: [{
			id: 0,
			name: '',
			url: ''
		}]
	}
	newDocRef.set(newPlaylist)

	return newPlaylist
}

const updatePlaylist = async (playlistData) => {
	let firebaseObj = await getFirebaseObj('updatePlaylist')
	let firestore = firebaseObj.firestore

	let docRef = await firestore.collection('playlists').doc(playlistData.id)
	await docRef.set(playlistData)
}

export { getPlaylists, getPlaylistBySlug, addPlaylist, updatePlaylist}
