import React from 'react'
import styled from 'styled-components'
import { useForm } from 'usetheform'

const SubmitButton = styled.button`padding: 0px 24px; outline: none; border-radius: 50px; cursor: pointer; border: 0; background: none; display: flex; align-items: center; font-weight: 800; color: white; background-color: rgb(26, 145, 218); flex-shrink: 0;
	&:disabled { cursor: initial; background-color: rgb(142, 208, 249);	}
	&:enabled:hover { background-color: rgb(19, 122, 185); }
`

export const Submit = (props) => {
	const { reset } = useForm()

	const isEmpty = !(props && props.editorData && props.editorData.plainText)
	
	return (
		<SubmitButton disabled={isEmpty || props.editorData.plainText.length > props.maxChars} type="button" onClick={async () => { await props.onClick(); reset() } }>
			<span>GÖNDER</span>
		</SubmitButton>
	)
}
