import React, { useState, useEffect } from 'react'
// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'
import { getUserInfoByEmail } from '../../data/firebase/userFirebase'

const AuthContext = React.createContext([{}, () => {}])

const AuthProvider = (props) => {
	const [state, setState] = useState({})

	useEffect(async () => {
		if (!state.email) {
			if (localStorage.getItem('email') && localStorage.getItem('email2')) {
				let email = localStorage.getItem('email')
				let password = atob(localStorage.getItem('email2'))
				if (email && password) {
					let firebaseObj = await getFirebaseObj('authProvider', props.hostName)
					let firebaseAuth = firebaseObj.firebaseAuth

					firebaseAuth.signInWithEmailAndPassword(email, password)
					let response = await firebaseAuth.signInWithEmailAndPassword(email, password)
					let userStateData = {}
						
					let userData = await getUserInfoByEmail(email)
					
					if (userData) {
						userData.emailVerified = response.user.emailVerified
						userData.uid = response.user.uid
						userStateData = {
							email: email,
							name: userData.name ? userData.name : '',
							roles: userData.roles ? userData.roles : [],
							lessons: userData.lessons ? userData.lessons : [],
							status: userData.status ? userData.status : 'disabled',
						}
						setState(userStateData)
					} else {
						userStateData = {
							email: email,
							name: '',
							roles: [],
							lessons: [],
							status: 'disabled',
						}
						setState(userStateData)
					}
				}
			}
		}
	}, [])

	return (
		<AuthContext.Provider value={[state, setState]}>
			{props.children}
		</AuthContext.Provider>
	)
}

export { AuthContext, AuthProvider }
