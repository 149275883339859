import React, { Fragment, useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { getGameInstancesForTemplate } from '../../../../data/firebase/live/firebaseGameCreationUtils'
import { AuthContext } from '../../../../data/context/AuthProvider'
import { CardDiv, Toggle } from '../../../../components'

const GameCard = styled(CardDiv)`float: left; border: solid 1px gainsboro; border-radius: 6px; width: 95%; margin: 10px; text-align: center; `
const GameCardImageArea = styled.div`height: 100px; max-width: 100%; padding-top: 5px;`
const GameCardImage = styled.img`height: 90px; max-width: 100%; border-radius: 6px 6px 6px 6px; border: solid 1px gainsboro;`
const GameIDLink = styled(Link)`height: 90px; font-size: 14px; font-weight: bold; overflow: hidden;`
const SlideCount = styled.div`align: left; font-size: 18px; background-color: navy; color: white; height: 26px; width: 30px; font-weight: bold; border-radius: 30px;`
const CreateDate = styled.div`float: right; font-size: 12px;`
const OwnerEmail = styled.div`float: right; font-size: 9px; font-weight: bold;`

const LessonsList = styled.div`height: 150px; overflow: auto; border: solid 1px gainsboro; border-radius: 6px;`

const tabs = [
	{ id: 'design', label: 'Tasarım' },
	{ id: 'lessons', label: 'Dersler' },
]

function LessonTemplateListItem(props) {
    const [authData] = useContext(AuthContext)

    const [gameInstances, setGameInstances] = useState([])
    const [tab, setTab] = useState('design')
    const [coverImage, setCoverImage] = useState('https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3')

    useEffect(() => {
        if (tab === 'lessons' && (!gameInstances || gameInstances.length === 0 )) {
            getGameInstances()
        }
    }, [tab])

    useEffect(() => {
        if (props.gameTemplate) {
            if (props.gameTemplate.metaData && props.gameTemplate.metaData.coverImage) {
                setCoverImage(props.gameTemplate.metaData.coverImage.url)
            } else if (props.gameTemplate.quiz && props.gameTemplate.quiz.questions && props.gameTemplate.quiz.questions.length > 0 && props.gameTemplate.quiz.questions[0].qType === 'image_text' && props.gameTemplate.quiz.questions[0].questionContent && props.gameTemplate.quiz.questions[0].questionContent.images && props.gameTemplate.quiz.questions[0].questionContent.images.length > 0) {
                setCoverImage(props.gameTemplate.quiz.questions[0].questionContent.images[0].url)
            } else {
                setCoverImage('https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3')
            }
        }
    }, [props.gameTemplate])

    const getGameInstances = () => {
        getGameInstancesForTemplate(props.gameTemplate.templateKey, (returnedGameInstanceList) => {
            returnedGameInstanceList.sort(function(a,b) {
                if (a.created < b.created) return 1
                if (a.created > b.created) return -1
                return 0
            })
            setGameInstances(returnedGameInstanceList)
        })
    }

    return (
        <GameCard key={props.gameTemplate.key}>
            <Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />

            {
                tab === 'design' &&
                    <Grid container>
                        <Grid item xs={4}>
                            <GameCardImageArea>
                                <GameCardImage src={coverImage}  />
                            </GameCardImageArea>
                        </Grid>
                        
                        <Grid item xs={8} style={{padding: '10px'}}>
                            <GameIDLink to={`/lesson-designer/${props.gameTemplate.key}`}>
                                {
                                    props.gameTemplate.title && props.gameTemplate.title.length > 0 ?
                                        <div style={{width: '100%', overflow: 'hidden'}}>
                                            <Tooltip title={props.gameTemplate.title}>
                                                {
                                                    props.gameTemplate.title.length > 50 ?                                                    
                                                        <span>{`${props.gameTemplate.title.substring(0, 50)}...`}</span>
                                                    :
                                                        <span>{props.gameTemplate.title}</span>
                                                }
                                            </Tooltip>
                                        </div>
                                    :
                                        <>No Name</>
                                }
                            </GameIDLink>
                        </Grid>
                        
                        <Grid item xs={6}>
                            <SlideCount>{props.gameTemplate.quiz && props.gameTemplate.quiz.questions ? props.gameTemplate.quiz.questions.length : 0}</SlideCount>
                        </Grid>
                        <Grid item xs={6}>
                            <CreateDate>{moment(props.gameTemplate.created).format('DD MMMM YYYY hh:mm')}</CreateDate>
                        </Grid>
                        
                        <Grid item xs={12}>
                            {
                                (authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 )) && 
                                    <OwnerEmail>
                                        {props.gameTemplate.owner}&nbsp;
                                        <a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/database/yesil-okul-web-app-default-rtdb/data/~2Flesson_templates~2F${props.gameTemplate.key}`} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a>
                                    </OwnerEmail>
                            }
                        </Grid>
                        
                        {/* <div onClick={() => copyGame()}>COPY</div> */}
                    </Grid>
            }
            {
                tab === 'lessons' &&
                    <div>
                        <LessonsList>
                            <Grid container>
                                {
                                    gameInstances && gameInstances.length > 0 &&
                                        gameInstances.map((gameInstance) => gameInstance &&
                                            <Fragment key={gameInstance.key}>
                                                <Grid item xs={8}>
                                                    <div style={{textAlign: 'left', fontSize: '12px'}}>
                                                        {
                                                            gameInstance.status === 'CREATED' ?
                                                                <a href={`/host-game/${gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
                                                                    <PlayCircleFilledIcon style={{fontSize: '12px'}} /> {gameInstance.title}
                                                                </a>
                                                            :
                                                                gameInstance.status === 'IN_PROGRESS' ?
                                                                    <a href={`/host-game/${gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
                                                                        <PlayCircleFilledIcon /> {gameInstance.title}
                                                                    </a>
                                                                    :        
                                                                    <a href={`/host-game/${gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
                                                                        <AssessmentIcon /> {gameInstance.title}
                                                                    </a>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <b style={{fontSize: '10px'}}>{gameInstance.gameId} ({gameInstance.players ?  Object.keys(gameInstance.players).length : 0})</b>
                                                </Grid>
                                                <Grid item xs={1}>
                                                </Grid>
                                            </Fragment>
                                        )
                                }
                            </Grid>
                        </LessonsList>
                    </div>
            }
        </GameCard>
	)
}

export default LessonTemplateListItem
