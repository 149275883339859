import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { StudentDetails } from '../../student/StudentDetails'
import { AuthContext } from '../../../data/context/AuthProvider'
import { getCourseStudentsByCourseIdList } from '../../../data/firebase/studentCoursesFirebase'

const AdaptiveScreenHeight = styled.div`height: 'calc(100vh - 160px)', overflow: 'auto'`

const BlockTitle = styled.div`font-size: 16px; font-weight: bold; padding-left: 5px; margin-top: 10px; border-bottom: solid 1px black;`
const StudentListItem = styled(Grid)`padding-left: 5px; margin-top: 5px; cursor: pointer; border-bottom: solid 1px gainsboro; clear: both; min-height: 24px;
	:hover {
		background-color: gainsboro; color: navy;
	}
`
const ListNumber = styled(Grid)`border-right: solid 2px gray; text-align: left; font-size: 12px; overflow: hidden;`
const ListTextEmail = styled(Grid)`font-size: 14px; overflow: hidden; padding-left: 6px;`
const ListTextRegisterDate = styled(Grid)`font-size: 10px; line-height: 20px; margin-left: 5px; padding-left: 5px; overflow: hidden; text-align: right; padding-right: 8px;`

function CourseStudents(props) {
	const [authData] = useContext(AuthContext)
	const [studentsRegistered, setStudentsRegistered] = useState([])
	const [studentsInShoppingCart, setStudentsInShoppingCart] = useState([])
	const [selectedStudentCourse, setSelectedStudentCourse] = useState()
	
	useEffect(async() => {
		// if (props.course && props.course.students && (props.course.owner === authData.email || (authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 )))) {
		// 	setStudentsRegistered(props.course.students.registered)
		// 	setStudentsInShoppingCart(props.course.students.inShoppingCart)
		// }

		if (props.course && props.course.id && (props.course.owner === authData.email || (authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 )))) {
			let tempCourseStudents = await getCourseStudentsByCourseIdList([props.course.id])
			console.log('tempCourseStudents: ', tempCourseStudents)
			let registeredStudents = tempCourseStudents.filter(_courseStudent => _courseStudent.status === 'paid' || _courseStudent.status === 'free' || _courseStudent.status === 'paid-paypal'  || _courseStudent.status === 'paid-cash')
			console.log('registeredStudents: ', registeredStudents)
			let inShoppingCartStudents = tempCourseStudents.filter(_courseStudent => _courseStudent.status === 'shopping-cart')
			setStudentsRegistered(registeredStudents)
			setStudentsInShoppingCart(inShoppingCartStudents)
		}
	}, [props.course])
	
	if (props.course && (props.course.owner === authData.email || (authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 )))) {
		return (
			<Grid container style={{textAlign: 'left'}}>
				<Grid item xs={6} sm={4} md={3} style={{ borderRight: 'solid 1px gainsboro'}}>
					<AdaptiveScreenHeight>
						<BlockTitle>
							KAYITLI OGRENCILER ({(studentsRegistered && studentsRegistered.length > 0 ) ? <span>{studentsRegistered.length}</span> : <span>0</span>})
						</BlockTitle>
						{
							studentsRegistered && studentsRegistered.length > 0 && studentsRegistered.map((_studentCourse, index) => 
								<Tooltip title={_studentCourse.email} key={index}>
									<StudentListItem container onClick={() => setSelectedStudentCourse(_studentCourse) } 
										style={{
											backgroundColor: (selectedStudentCourse === _studentCourse ? 'navy' : ''),
											color: (selectedStudentCourse === _studentCourse ? 'white' : '#000000')
										}}
									>
										<ListNumber item xs={1}>{studentsRegistered.length - index} </ListNumber>
										<ListTextEmail item xs={11} md={7}>{_studentCourse.email} </ListTextEmail>
										<ListTextRegisterDate item xs={12} md={4}>({moment(_studentCourse.additionDate).format('YYYY-MM-DD HH:mm')})</ListTextRegisterDate>
									</StudentListItem>
								</Tooltip>
							)
						}
						<BlockTitle style={{marginTop: '25px'}}>
							SEPETE EKLEYENLER ({(studentsInShoppingCart && studentsInShoppingCart.length > 0) ? <span>{studentsInShoppingCart.length}</span> : <span>0</span> })
						</BlockTitle>
						
						{
							studentsInShoppingCart && studentsInShoppingCart.length > 0 && studentsInShoppingCart.map((_studentCourse, index) => 
								<Tooltip title={_studentCourse.email}>
									<StudentListItem container xs={12} key={index} onClick={() => setSelectedStudentCourse(_studentCourse) }
										style={{
											backgroundColor: (selectedStudentCourse === _studentCourse ? 'navy' : ''),
											color: (selectedStudentCourse === _studentCourse ? '#ffffff' : '#000000')
										}}
									>
										<ListNumber item xs={1}>{studentsInShoppingCart.length - index} </ListNumber>
										<ListTextEmail item xs={11} md={7}>{_studentCourse.email} </ListTextEmail>
										<ListTextRegisterDate item xs={12} md={4}>({moment(_studentCourse.additionDate).format('YYYY-MM-DD HH:mm')})</ListTextRegisterDate>
									</StudentListItem>
								</Tooltip>
							)
						}
					</AdaptiveScreenHeight>
				</Grid>
				<Grid item xs={6} sm={8} md={9} style={{padding: '10px'}}>
					<AdaptiveScreenHeight>
						{
							selectedStudentCourse &&
								<Grid container>
									<Grid item xs={12}>
										<StudentDetails email={selectedStudentCourse.email} />
									</Grid>
								</Grid>
						}
					</AdaptiveScreenHeight>
				</Grid>
			</Grid>
		)
	} else {
		return (
			<span></span>
		)
	}
	
}

export default CourseStudents
