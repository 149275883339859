import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { getBrandData } from '../../utils/appUtil'

function Copyright(props) {
	return (
		<div style={{marginTop: (props.marginTop ? props.marginTop : '20px') }} >
			<Typography variant="body2" color="textSecondary" align="center">
				{'Copyright © '}
				<Link to="/">
					{getBrandData().logoFolder}
				</Link>{' '}
					{new Date().getFullYear()}
				{'.'}
			</Typography>
		</div>
	)
}

export { Copyright }