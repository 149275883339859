import { getCurriculumsOfStaff } from '../firebase/curriculumFirebase'
import { getCoursesOfStaff } from '../firebase/courseFirebase'
import { getLessonTemplatesOfStaff } from '../../data/firebase/lessonTemplateFirebase'
import { getCourseListStats } from './courseUtils'
import { getCurriculumListStats } from './curriculumUtils'
import { getLessonTemplateListStats } from './lessonTemplateUtils'

const filterStaffHaveCourses = async (staffList) => {
	let tempStaffList = staffList.filter(_staff => (_staff.staffStats && _staff.staffStats.content && _staff.staffStats.content.activeStats && _staff.staffStats.content.activeStats.courses && _staff.staffStats.content.activeStats.courses.all > 0) )
	return tempStaffList
}

const filterStaffHaveNoCourses = async (staffList) => {
	let tempStaffList = staffList.filter(_staff => (!_staff.staffStats) || (_staff.staffStats && _staff.staffStats.content && _staff.staffStats.content.activeStats && _staff.staffStats.content.activeStats.courses && _staff.staffStats.content.activeStats.courses.all === 0) )
	return tempStaffList
}

const filterStaffHaveMentorRole = async (staffList) => {
	let tempStaffList = staffList.filter(_staff => (_staff.roles && _staff.roles.length > 0 && _staff.roles.some(role => ['mentor'].indexOf(role) >= 0)) )
	return tempStaffList
}

const filterStaffNoMentorRole = async (staffList) => {
	let tempStaffList = staffList.filter(_staff => (!_staff.roles) || (!_staff.roles.length === 0) || (_staff.roles && _staff.roles.length > 0 && _staff.roles.some(role => ['mentor'].indexOf(role) === -1)) )
	return tempStaffList
}

const getOneStaffStats = async (staffEmail) => {
	let lessonTemplatesOfStaff = await getLessonTemplatesOfStaff(staffEmail)
	let curriculumsOfStaff = await getCurriculumsOfStaff(staffEmail)
	let coursesOfStaff = await getCoursesOfStaff(staffEmail) // Courses includes students

	let contentStats = await getContentStatsForStaff(curriculumsOfStaff, coursesOfStaff, lessonTemplatesOfStaff)
	let mentorshipStats = await createMentorshipStatsForStaff(curriculumsOfStaff, coursesOfStaff)
	let teachingStats = await createTeachingStatsForStaff(curriculumsOfStaff, coursesOfStaff)
	let oneStaffStats = {
		content: contentStats,
		mentorship: mentorshipStats,
		teaching: teachingStats,
		lastUpdateDate: new Date(),
	}

	return oneStaffStats
}

const getContentStatsForStaff = async (curriculumsOfStaff, coursesOfStaff, lessonTemplatesOfStaff) => {
	let tempContentStats = {}
	tempContentStats.activeStats = {}
	tempContentStats.activeStats.courses = await getCourseListStats(coursesOfStaff)
	tempContentStats.activeStats.curriculums = await getCurriculumListStats(curriculumsOfStaff, coursesOfStaff)
	tempContentStats.activeStats.lessonTemplates = await getLessonTemplateListStats(lessonTemplatesOfStaff)
	return tempContentStats
}

const addStaffStatsToStaff = async (staffList, staffStats) => {
	let tempStaffList = [...staffList]
	for (let i = 0; i < tempStaffList.length; i++) {
		let thisStaffStats = staffStats.filter(_staffStat => _staffStat.email === tempStaffList[i].email)
		if (thisStaffStats && thisStaffStats.length > 0) {
			tempStaffList[i].staffStats = thisStaffStats[0]
		}
	}
	return tempStaffList
}

const createMentorshipStatsForStaff = async () => {
	let tempMentorshipStats = {}

	return tempMentorshipStats
}

const createTeachingStatsForStaff = async () => {
	let tempTeachingStats = {}

	return tempTeachingStats
}



export { filterStaffHaveCourses, filterStaffHaveNoCourses, filterStaffHaveMentorRole, filterStaffNoMentorRole,
	getOneStaffStats, addStaffStatsToStaff
}
