import React, { useState, useEffect } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import { H1 } from '../StyledComponents'


function LoadingEffect(props) {
	const [loading, setLoading] = useState(props.loading)

	useEffect(() => {
		setLoading(props.loading)
	}, [props.loading])

	return (
		<>
			<Grid item xs={12} style={{top: '0px', left: '0px', height: (loading ? ( props.height ? props.height : '200vh') : '0px'), width: (loading ? '100%' : '0px'), opacity: '0.8', position: 'absolute', zIndex: '10', display: 'flex', backgroundColor: '#ffffff' }}>
				<div style={{ opacity : '1', width: '100%', paddingTop: (props.paddingTop ? props.paddingTop : '200px'), display: (loading ? 'block' : 'none')}}>
					<H1>{props.loadingText}</H1>
					<br />
					<LinearProgress />
					<br />
					<LinearProgress color="secondary" />
				</div>
			</Grid>
		</>
	)
}

export { LoadingEffect }
