import React, { useState, useContext } from 'react'
import { Layout2, } from '../components'
import { AuthContext } from '../data/context/AuthProvider'
import { StudentDetails } from '../PageBlocks/student/StudentDetails'

function Student(props) {
	const [authData] = useContext(AuthContext)
	const [studentEmail] = useState((props && props.match && props.match.params && props.match.params.studentemail) ? props.match.params.studentemail :  null)
	
	return (
		<Layout2 withAuth={true}>
			<div style={{marginTop: '20px'}}>
				{
					studentEmail && studentEmail.length > 0 && authData && authData.email && authData.roles && authData.roles.length > 0 && (authData.roles.some(role => ['mentor'].indexOf(role) >= 0) || authData.roles.some(role => ['owner'].indexOf(role) >= 0)) &&
						<StudentDetails email={studentEmail} />
				}
			</div>
		</Layout2>
	)
}

export default Student
