import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import AnswerOptionList from '../shared/AnswerOptionList'
import AnswerCounter from '../shared/AnswerCounter'
import QuestionContent from '../shared/QuestionContent'
import { Timer } from '../../../components'

const QuestionSection = styled.div`height: 54vh; text-align: center; overflow: auto; width: 100%;`
const CounterSection = styled.div`text-align: center; overflow: auto; position: absolute; top: 0px; right: 0px; width: 100%;`
const AnswersSection = styled.div`height: 40vh; margin-top: 10px; position: absolute; bottom: 0; left: 0; width: 100%;
	@media (max-width: 767px) {
		height: 37vh;
	}
`
const AnswerCounterSection = styled.div`text-align: center; overflow: auto; width: 100%;`
const NextButtonArea = styled.div`position: absolute; bottom: 10px; height: 60px; float: right; right: 10px; z-index: 10;`


function PhaseAnswer(props) {
	let currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]

	const nextPhase = () => {
		props.gameFunc.update({ phase: 'result_question' })
	}

	return (
		<div>
			{ currentQuestion &&
				<div>
					<QuestionSection>
						<QuestionContent question={currentQuestion} 
							imageBrushColor="red"
							videoPlaying={false} onVideoEnd={null} videoControlType="host"
							gameFunc={props.gameFunc} gameKey={props.game.key} gamePhase={props.game.phase}
						/>
					</QuestionSection>
					
					<CounterSection>
						{(currentQuestion.qOptions.timeLimit !== 0) && <Timer timeLimit={currentQuestion.qOptions.timeLimit} onTimerCompleted={() => nextPhase()} />}
					</CounterSection>						

					<AnswersSection>
						<Grid container>
							<Grid item xs={12}>
								<AnswerCounterSection>
									<AnswerCounter game={props.game} onEveryoneAnswer={() => { if (currentQuestion.qOptions.timeLimit > 0) { nextPhase() } } } mode="host" />
								</AnswerCounterSection>
							</Grid>
							<Grid item xs={12}>
								<AnswerOptionList question={currentQuestion} />
							</Grid>
						</Grid>
					</AnswersSection>
					<NextButtonArea>
						<Fab color="primary" aria-label="add" onClick={() => nextPhase()}>
							<NavigateNextIcon />
						</Fab>
					</NextButtonArea>
				</div>
			}
		</div>
	)
}

export default PhaseAnswer
