// import firebase from 'firebase/app'
// import 'firebase/auth'
// import 'firebase/database'
// import 'firebase/storage'
// import 'firebase/firestore'

// // const firebaseConfig = { // YESIL OKUL
// // 	apiKey: "AIzaSyC2wTzGGl2KicRVD8D-9t32ByJPZOPIGqQ",
// //     authDomain: "yesil-okul-web-app.firebaseapp.com",
// //     databaseURL: "https://yesil-okul-web-app-default-rtdb.firebaseio.com",
// //     projectId: "yesil-okul-web-app",
// //     storageBucket: "yesil-okul-web-app.appspot.com",
// //     messagingSenderId: "1095413858826",
// //     appId: "1:1095413858826:web:29d13d43c50ffd116b15f3",
// //     measurementId: "G-L8HGYT4ZG6"
// // }


// console.log('window.location.href', window.location.href)

// const firebaseConfig = { // SOCHOOL
//     apiKey: "AIzaSyDV1mpidQ5htVh3bI90UiGzrfP4X3z4cuY",
//     authDomain: "sochool.firebaseapp.com",
//     databaseURL: "https://sochool-default-rtdb.firebaseio.com",
//     projectId: "sochool",
//     storageBucket: "sochool.appspot.com",
//     messagingSenderId: "889773227539",
//     appId: "1:889773227539:web:a67e69d15bc3651b5f89e6",
//     measurementId: "G-112DKSYWWM"
// }


// let fire
// if (!firebase.apps.length) {
// 	fire = firebase.initializeApp(firebaseConfig)
// } else {
	
// }

// const firebaseAuth = firebase.auth()
// const firestore = firebase.firestore()
// const storage = firebase.storage()

// export {
//     fire,
//     firebaseAuth,
//     firestore,
//     storage
// }


import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'


var pacificaRelayFirebaseConfig = {
	apiKey: "AIzaSyCpoC2xb_UrQp0tjwyLXuXp92h10Xm8ORA",
	authDomain: "pacifica-relay.firebaseapp.com",
	projectId: "pacifica-relay",
	storageBucket: "pacifica-relay.appspot.com",
	messagingSenderId: "195416223081",
	appId: "1:195416223081:web:18d00d0b0f16253809e1f8",
	measurementId: "G-1B5C3VK43R"
}

let pacificaRelayDefaultFirebaseApp
if (!firebase.apps.length) { // IF NO APP YET, create pacificaRelayOnServer instance
	pacificaRelayDefaultFirebaseApp = firebase.initializeApp(pacificaRelayFirebaseConfig, '[DEFAULT]')
} else {
    let tempAppsForDefault = firebase.apps.filter(_app => _app.name_ === '[DEFAULT]') // Is Pacifica relay already Initialized
    if (tempAppsForDefault && tempAppsForDefault.length === 0) { // Initialized otherwise
        pacificaRelayDefaultFirebaseApp = firebase.initializeApp(pacificaRelayFirebaseConfig, '[DEFAULT]')
    } else { // or use the already initialized one
		pacificaRelayDefaultFirebaseApp = tempAppsForDefault[0]
	}
}


const getFirebaseObj = async (p, hostName) => {
    console.log('window.location.hostname', window.location.hostname)
    if (!hostName) {
        hostName = window.location.hostname
    }
    if (hostName === 'localhost') {
        hostName = 'localhost:3000'
    }
    console.log('called from: ', p, hostName)
    let hostNameFirebaseApp

     // Is default app already Initialized
    let tempAppsForHostName = (firebase && firebase.apps && firebase.apps.length > 0) ? firebase.apps.filter(_app => (hostName && _app.name_ === hostName.replace(':', '')) ) : []
    if (!tempAppsForHostName || (tempAppsForHostName && tempAppsForHostName.length === 0)) { // Initialized otherwise        
       
        let tempApplications = []
        const firestoreRelay = pacificaRelayDefaultFirebaseApp.firestore()
        let sApplications = await firestoreRelay.collection('applications').where('hostNames', 'array-contains', hostName).get()
        sApplications.docs.map(doc => {
            tempApplications.push(doc.data())
            return null
        })
        let application = tempApplications && tempApplications.length > 0 ? tempApplications[0] : null
        let fbSettingsBase64 = ((application && application.fbSettings) ? application.fbSettings.base64 : '')

        if (fbSettingsBase64 && fbSettingsBase64.length > 0 ) {
            let fbSettings = JSON.parse(Buffer.from(fbSettingsBase64, 'base64').toString())
            try {
                tempAppsForHostName = (firebase && firebase.apps && firebase.apps.length > 0) ? firebase.apps.filter(_app => (hostName && _app.name_ === hostName.replace(':', '')) ) : []
                if (!tempAppsForHostName || (tempAppsForHostName && tempAppsForHostName.length === 0)) {
                    hostNameFirebaseApp = firebase.initializeApp(fbSettings, hostName.replace(':', ''))
                } else {
                    hostNameFirebaseApp = tempAppsForHostName[0]
                }
            } catch(error) {
                console.log('error: ', error)
                tempAppsForHostName = (firebase && firebase.apps && firebase.apps.length > 0) ? firebase.apps.filter(_app => (hostName && _app.name_ === hostName.replace(':', '')) ) : []
                if (tempAppsForHostName && tempAppsForHostName.length > 0) {
                    hostNameFirebaseApp = tempAppsForHostName[0] 
                } else {
                    console.log('9999 ???  AN UNEXPECTED STATUS REACHED  ???')
                }
            }
        }
    } else { // or use the already initialized one
        hostNameFirebaseApp = tempAppsForHostName[0]
    }

    if (hostNameFirebaseApp) {
        return {
            fire: hostNameFirebaseApp,
            firebaseDB: hostNameFirebaseApp.database(),
            firebaseAuth: hostNameFirebaseApp.auth(),
            firestore: hostNameFirebaseApp.firestore(),
            storage: hostNameFirebaseApp.storage()
        }
    } else {
        console.log('hostNameFirebaseApp not found', p, hostName, firebase.apps.length)
        return {}
    }
}

export {
    getFirebaseObj
}
