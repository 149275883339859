import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import CourseListItem from '../../PageBlocks/course/CourseListItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { getCurriculumsOfStaff } from '../../data/firebase/curriculumFirebase'
import { getCoursesOfStaff, createNewCourse } from '../../data/firebase/courseFirebase'
import { Layout2, FormLine, BaseButton, BaseSelect, H1 } from '../../components'
import { AuthContext } from '../../data/context/AuthProvider'

const CreatePage = styled.div`margin: 10px; width: 100%; margin-left: auto; margin-right: auto; padding: 5px;`
const NewButton = styled(BaseButton)`float: right; width: 200px;`

function CourseList(props) {
	let history = useHistory()
	const [authData] = useContext(AuthContext)
	
	const [openNewDialog, setOpenNewDialog] = useState(false)
	const [selectedCurriculum, setSelectedCurriculum] = useState({})
	const [curriculumOptions, setCurriculumOptions] = useState([])

	const [courses, setCourses] = useState([])
	
	useEffect(async () => {
		if (authData && authData.email) {
			let tempCourses = await getCoursesOfStaff(authData.email)
			setCourses(tempCourses)

			let curriculums = await getCurriculumsOfStaff(authData.email)
			curriculums.sort(function(a,b) {
				if (a.meta.dates.curriculumCreated < b.meta.dates.curriculumCreated) return 1
				if (a.meta.dates.curriculumCreated > b.meta.dates.curriculumCreated) return -1
				return 0
			})

			let tempCurriculumOptions = []
			curriculums.map((curriculum, index) => {
				let tempCurriculumOption = { ...curriculum}
				tempCurriculumOption.label = curriculum.title

				tempCurriculumOptions.push(tempCurriculumOption)
				return null
			})
			setCurriculumOptions(tempCurriculumOptions)
		}
	}, [authData.email])

	const createNew = async () => {
		let newCourseResult = await createNewCourse(authData.email, selectedCurriculum)

		if (newCourseResult.result === 'success') {
			history.push(`/courses/${newCourseResult.docId}`)
		} else {
			alert(newCourseResult.errorMessage)
		}		
	}

	return (
		<Layout2 withAuth={true}>
			<H1>KURSLARIM</H1>
			<CreatePage>
				{
					authData.roles && authData.roles.some(role => ['owner', 'admin', 'teacher'].indexOf(role) >=0 ) && (
						<FormLine>
							<NewButton type="button" onClick={() => setOpenNewDialog(true)} value="YENİ KURS" />
						</FormLine>
					)
				}
				<Grid container>
				{
					courses && courses.length > 0 &&
						courses.map((course, index) => course &&
							<Grid item xs={12} sm={4} key={index} style={{padding: '5px'}}>
								<CourseListItem course={course} />
							</Grid>
						)
				}
				</Grid>
			</CreatePage>

			<Dialog open={openNewDialog} onClose={() => setOpenNewDialog(false)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Yeni Kurs Ekle</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Kursu müfredatını seçiniz.
					</DialogContentText>
					<BaseSelect options={curriculumOptions} onChange={(newValue) => setSelectedCurriculum(newValue)} value={selectedCurriculum} width="320px" />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenNewDialog(false)} color="primary">
						IPTAL
					</Button>
					<Button onClick={() => { createNew(); setOpenNewDialog(false) }} color="primary">
						EKLE
					</Button>
				</DialogActions>
			</Dialog>
		</Layout2>
	)
}

export default CourseList
