import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { PagesList } from '../PageBlocks/page/PagesList'
import { getPages } from '../data/firebase/pageFirebase.js'
import { AuthContext } from '../data/context/AuthProvider'
import { Layout2 } from '../components'

function Pages(props) {

	const [authData] = useContext(AuthContext)
	const [allPages, setAllPages] = useState()
	const [filteredPages, setFilteredPages] = useState()
	
	useEffect(async () => {
		if (authData && authData.email && authData.roles && authData.roles.length > 0 && (authData.roles.some(role => ['teacher'].indexOf(role) >= 0) || authData.roles.some(role => ['owner'].indexOf(role) >= 0)) ) {
			if (authData.roles.some(role => ['teacher'].indexOf(role) >= 0) && authData.roles.some(role => ['owner'].indexOf(role) === -1)) { // Only teacher not owner
				await updatePagesFromDB()
			}
			if (authData.roles.some(role => ['owner'].indexOf(role) >= 0)) { // Only owner
				await updatePagesFromDB()				
			}
		}
	}, [authData])

	const updatePagesFromDB = async () => {
		let tmpPages = await getPages(authData.email)
		setAllPages(tmpPages)
	}

	const addPageToAll = (newPage) => {
		let tempPages = [...allPages]
		tempPages.push(newPage)
		tempPages.sort(function(a,b) {
			if (a.title < b.title) return 1
			if (a.title > b.title) return -1
			return 0
		})
		setAllPages(tempPages)
	}

	const updatePageInAll = (updatedPage, index) => {
		console.log('updatedPage: ', updatedPage)
		console.log('index: ', index)
		let tempPages = [...allPages]
		tempPages[index] = updatedPage
		tempPages.sort(function(a,b) {
			if (a.title < b.title) return 1
			if (a.title > b.title) return -1
			return 0
		})
		setAllPages(tempPages)
	}

	useEffect(() => {
		setFilteredPages(allPages)
	}, [allPages])

	return (
		<Layout2 withAuth={true}>
			<Grid container style={{marginTop: '10px'}}>
				<Grid item xs={12}>
					<PagesList pages={filteredPages} onNewPage={(newPage) => addPageToAll(newPage)} onUpdatePage={(updatedPage, index) => { updatePageInAll(updatedPage, index) } } />
				</Grid>
			</Grid>
		</Layout2>
	)
}

export default Pages
