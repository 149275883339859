import React, { useState, useEffect } from 'react'
import {unstable_batchedUpdates} from 'react-dom'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { updateQuestionDataInTemplate } from '../../../../../data/firebase/live/firebaseGameCreationUtils'
import { EmptySpace, SimpleTextListManager, H1 } from '../../../../../components'
import AnswerOptionEntryList from './AnswerOptionEntryList'
import QuestionEntry from './QuestionEntry'
import QuestionOptions from './QuestionOptions'
 
const QuestionFormArea = styled.div`margin-right: 5px; margin-bottom: 50px; border: solid 1px gainsboro; border-radius: 6px; padding: 10px; background-color: #f9f9f9;
	-webkit-box-shadow: 2px 2px 5px 0px #cccccc; -moz-box-shadow: 2px 2px 5px 0px #cccccc; box-shadow: 2px 2px 5px 0px #cccccc;
`
let changeFromInside = false
const emptyAnswerOption = { isCorrect: false }

function MainQuestionForm(props) {	
	const [id, setId] = useState(props.activeQuestion.id)
	const [questionContent, setQuestionContent] = useState({})
	const [answerOptions, setAnswerOptions] = useState([emptyAnswerOption, emptyAnswerOption, emptyAnswerOption, emptyAnswerOption])
	const [qOptions, setQOptions] = useState(props.activeQuestion.qOptions ? props.activeQuestion.qOptions : {timeLimit: 10, point: 1000})	
	const [cType, setCType] = useState('quiz')
	const [qType, setQType] = useState('image_text')
	const [aType, setAType] = useState('multiple')
	const [slideNotes, setSlideNotes] = useState(props.activeQuestion.slideNotes ? props.activeQuestion.slideNotes : [])
	
	const [firstLoadDone, setFirstLoadDone] = useState(false)
	
	useEffect(() => {
		changeFromInside = false
		if (props.activeQuestion) {
			unstable_batchedUpdates(() => {
				setId(props.activeQuestion.id)
				setQuestionContent(props.activeQuestion.questionContent ? props.activeQuestion.questionContent : {})				
				setAnswerOptions((props.activeQuestion.answerOptions && props.activeQuestion.answerOptions.length > 0) ? props.activeQuestion.answerOptions :  [emptyAnswerOption, emptyAnswerOption, emptyAnswerOption, emptyAnswerOption])
				setQOptions(props.activeQuestion.qOptions ? props.activeQuestion.qOptions : {timeLimit: 10, point: 1000})
				setCType(props.activeQuestion.cType ? props.activeQuestion.cType : 'quiz')
				setQType(props.activeQuestion.qType ? props.activeQuestion.qType : 'image_text')
				setAType(props.activeQuestion.aType ? props.activeQuestion.aType : 'multiple')
				setSlideNotes(props.activeQuestion.slideNotes ? props.activeQuestion.slideNotes : [])
			})
			setFirstLoadDone(true)
		}		
	}, [props.activeQuestion])

	useEffect(() => {
		if (firstLoadDone && changeFromInside) {
			let q = {
				id,
				questionContent: questionContent ? questionContent : {},
				answerOptions,
				qOptions,
				cType,
				qType,
				aType,
				slideNotes,
			}

			let questionID = q.id === 0 ? 0 : q.id-1
			if (q !== props.questions[questionID] && q.title !== '') {
				updateQuestionDataInTemplate(props.gameKey, questionID, q, (error) => {
					if (error) {
						console.log('question update error: ', error)
					}
				})
			}
		}
	}, [questionContent, answerOptions, qOptions, cType, qType, aType, slideNotes])

	const updateOption = (newOptionData, optionIndex) => {
		let tempAnswerOptions = [...answerOptions]
		tempAnswerOptions[optionIndex] = newOptionData
		setAnswerOptions(tempAnswerOptions)
	}

	const addAnswerOption = () => {
		if (answerOptions.length < 10) {
			let tempAnswerOptions = [...answerOptions]
			tempAnswerOptions.push({isCorrect: false})
			setAnswerOptions(tempAnswerOptions)
		}		
	}

	const deleteAnswerOption = (index) => {
		let tempAnswerOptions = [...answerOptions]
		tempAnswerOptions.splice(index, 1)
		setAnswerOptions(tempAnswerOptions)
	}

	return (
		<QuestionFormArea>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<FormControl style={{width : '100%' }}>
						<InputLabel htmlFor="ctype">Slayt Türü</InputLabel>
						<Select value={cType} onChange={(e) =>  { changeFromInside = true; setCType(e.target.value)} } name="qType" inputProps={{ id: 'ctype', }}>
							<MenuItem value="content">Sunum İçeriği</MenuItem>
							<MenuItem value="quiz">Test Sorusu</MenuItem>
							<MenuItem value="poll">Anket Sorusu</MenuItem>
							<MenuItem value="form">Form Verisi</MenuItem>						
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
				<FormControl style={{width : '100%' }}>
					<InputLabel htmlFor="qtype">İçerik Türü</InputLabel>
					<Select value={qType} onChange={(e) =>  { changeFromInside = true; setQType(e.target.value)} } name="qType" inputProps={{ id: 'qtype', }}>
						<MenuItem value="text">Metin</MenuItem>
						<MenuItem value="image_text">Resim + Metin</MenuItem>
						<MenuItem value="video_text">Video + Metin</MenuItem>
						<MenuItem value="embed_url">Web Sayfası</MenuItem>
					</Select>
				</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
				{
					(cType === 'quiz' || cType === 'poll' || cType === 'form') &&
						<FormControl style={{width : '100%' }}>
							<InputLabel htmlFor="aType">Cevap Türü</InputLabel>
							<Select value={aType}  onChange={(e) => { changeFromInside = true; setAType(e.target.value) } } inputProps={{ id: 'aType', }} >
								<MenuItem value="multiple">Seçenekli</MenuItem>
								<MenuItem value="boolean">Doğru-Yanlış</MenuItem>
								<MenuItem value="free-text">Açık uçlu metin</MenuItem>
								{/* { (cType === 'quiz' || cType === 'poll') && ( <MenuItem value="order-puzzle">Sıralama</MenuItem> ) } */}
								{ (cType === 'form') && ( <MenuItem value="image-upload">Resim Yükleme</MenuItem> ) }
							</Select>
						</FormControl>
				}
				</Grid>
			</Grid>

			<QuestionEntry qType={qType} onUpdate={(questionContentData) => { changeFromInside = true; setQuestionContent(questionContentData)} } value={questionContent} gameTemplateKey={props.gameKey} activeQuestion={props.activeQuestion} />

			<EmptySpace>&nbsp;</EmptySpace>
			{
				(cType === 'quiz' || cType === 'poll' || cType === 'form') && (aType === 'multiple' || aType === 'boolean' || aType === 'free-text' || aType ==='order-puzzle') && ( // Question Types - Show answer options
					<AnswerOptionEntryList answerOptions={answerOptions} gameTemplateKey={props.gameKey} aType={aType} cType={cType}
						addAnswerOption={() => {changeFromInside = true; addAnswerOption()} }
						onUpdate={(newOptionData, optionIndex) => {changeFromInside = true; updateOption(newOptionData, optionIndex);}}
						onDelete={(index) => {changeFromInside = true; deleteAnswerOption(index) }}
					/>
				)
			}

			{
				(cType === 'quiz' || cType === 'poll' || cType === 'form') && ( // Question Types - Show question options
					<QuestionOptions value={qOptions} cType={cType}
						onUpdate={(options) => { changeFromInside = true; setQOptions(options)} } 
					/>
				)
			}

			<EmptySpace>&nbsp;</EmptySpace>
			<H1>Notlar</H1>
			<SimpleTextListManager itemList={slideNotes} editable={true} onePerLine={true} showNumbers={true}
				onChange={(_notes) => { changeFromInside = true; setSlideNotes(_notes) } }
			/>
			
			
		</QuestionFormArea>
	)
}

export default MainQuestionForm
