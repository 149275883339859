import moment from 'moment'
import { getAllStaffStats, updateStaffStats } from '../firebase/statsFirebase'
import { getOneStaffStats } from './staffUtils'

const createStaffListStats = async (email, staffList) => {
	if (email && email.length > 0 && email === 'yesilokulcom@gmail.com') {
		// let staffList = await getAllStaff(email)
		let staffStatsList = await getAllStaffStats(email)

		for (let i = 0; i < staffList.length; i++) {
			let staff = staffList[i]
			let staffStats = staffStatsList.filter(_staffStats => _staffStats.email === staff.email)
			if ( !staffStats || 
				(staffStats && staffStats.length === 0) || 
				(staffStats && staffStats.length > 0 && !staffStats[0].lastUpdateDate) || 
				(staffStats && staffStats.length > 0 && staffStats[0].lastUpdateDate && moment(staffStats[0].lastUpdateDate.toDate()) < moment().add(-1, 'days') )
			) {
				let oneStaffStats = await getOneStaffStats(staff.email)
				let staffStatsObj =	{
					object: 'staff',
					email: staff.email,
					name: staff.name,					
					lastUpdateDate: new Date(),
					content: oneStaffStats.content,
					mentorship: oneStaffStats.mentorship,
					teaching: oneStaffStats.teaching,
				}
				
				if (staffStatsObj.content.activeStats.courses.all > 0) {
					if (staffStatsObj.email !== 'yesilokulcom@gmail.com') {
						await updateStaffStats(staffStatsObj)
					}
				}
			}
		}
	}
}

export { createStaffListStats }
