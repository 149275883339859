import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import firebase from 'firebase'
import { BaseDatePickerField, BaseInput, FormElementLabel } from '../../../components'

const Lesson = styled.div`width: calc(100% - 10px); margin: 5px; margin-left: 15px; border-radius: 6px; text-align: left; padding-left: 10px; padding-top: 10px; min-height: 60px;`
const LessonTitle = styled.span`font-size: 24px; font-weight: bold; `
const LessonTemplateData = styled.div`margin-left: 10px; margin-top: 20px; cursor: pointer; display: flex; align-items: center;`
const LessonTemplateCoverSmall = styled.img`border-radius: 6px; width: 60px; max-height: 60px;`


let changeFromInside = false

function CourseLessonSettings(props) {
	const [title, setTitle] = useState(props.lesson.title)
	const [lessonDateTime, setLessonDateTime] = useState()
	const [zoomSettings, setZoomSettings] = useState({zoomLink: '', zoomMeetingId: '', zoomPasscode: ''})
	const [lessonInstanceSettings, setLessonInstanceSettings] = useState({lessonInstanceKey: '', lessonInstanceLink: ''})

	useEffect(() => {
		changeFromInside = false
		if (props.lesson) {
			setTitle(props.lesson.title)
			if (props.lesson && props.lesson.courseSpecificData) {
			}
			if (props.lesson.courseSpecificData && props.lesson.courseSpecificData.lessonDateTime) {
				setLessonDateTime(props.lesson.courseSpecificData.lessonDateTime.toDate())
				setZoomSettings((props.lesson && props.lesson.courseSpecificData && props.lesson.courseSpecificData.zoomSettings) ? props.lesson.courseSpecificData.zoomSettings : {zoomLink: '', zoomMeetingId: '', zoomPasscode: ''})
				setLessonInstanceSettings((props.lesson && props.lesson.courseSpecificData && props.lesson.courseSpecificData.lessonInstanceSettings) ? props.lesson.courseSpecificData.lessonInstanceSettings : {lessonInstanceKey: '', lessonInstanceLink: ''})
			}
		}
	}, [props])

	useEffect(() => {
		if (changeFromInside) {
			updateLessonData()
		}
	}, [lessonDateTime])
	
	const updateLessonData = () => {
		if (props.onUpdate) { 			
			let tempCourseLessonSettings = {
				lessonDateTime: firebase.firestore.Timestamp.fromDate(lessonDateTime),
				zoomSettings: zoomSettings,
				lessonInstanceSettings: lessonInstanceSettings
			}
			props.onUpdate(tempCourseLessonSettings)
		}
	}

	return (
		<Lesson>
			<Grid container>
				<Grid item xs={12}>
					<LessonTitle>
						<span>{title}</span>
					</LessonTitle>
				</Grid>
				<Grid item xs={12}>
					{
						props.lesson.templateTitle ?
							<LessonTemplateData>
								{
									props.lesson.templateMetaData && props.lesson.templateMetaData.coverImage && props.lesson.templateMetaData.coverImage.url &&
										<LessonTemplateCoverSmall src={props.lesson.templateMetaData.coverImage.url} />
								}
								&nbsp;
								<div>
									<div>
										<a href={`/lesson-designer/${props.lesson.lessonTemplateKey}`} target="_blank" rel="noopener noreferrer">
											{props.lesson.templateTitle}&nbsp;
										</a>
									</div>
									<div style={{color: (!props.lesson.templateStats || !props.lesson.templateStats.allSlidesCount || props.lesson.templateStats.allSlidesCount < 10 ? 'red' : ''), fontSize: '11px'}}>
										{
											(!props.lesson.templateStats || !props.lesson.templateStats.allSlidesCount || props.lesson.templateStats.allSlidesCount < 10) && 
												<Tooltip title="Yetersiz içerik">
													<ErrorOutlineIcon style={{color: 'orange'}} />
												</Tooltip>
										}
										{ props.lesson.templateStats && props.lesson.templateStats.allSlidesCount} Slide => 
										{ props.lesson.templateStats && props.lesson.templateStats.qType.imageCount} resim, 
										{ props.lesson.templateStats && props.lesson.templateStats.qType.textCount} text, 
										{ props.lesson.templateStats && props.lesson.templateStats.qType.videoCount} video
									</div>
									<div style={{fontSize: '11px'}}>
										{ props.lesson.templateStats && props.lesson.templateStats.allSlidesCount} Slide => 
										{ props.lesson.templateStats && props.lesson.templateStats.cType.contentCount} içerik, 
										{ props.lesson.templateStats && props.lesson.templateStats.cType.quizCount} quiz, 
										{ props.lesson.templateStats && props.lesson.templateStats.cType.pollCount} anket, 
										{ props.lesson.templateStats && props.lesson.templateStats.cType.formCount} form
									</div>
								</div>
							</LessonTemplateData>
						:
							<div>
								<Tooltip title="Ders tanımı yapılmamış">
									<ErrorOutlineIcon style={{color: 'red'}} />
								</Tooltip>								
								<span style={{fontSize: '12px', color: 'red'}}>&nbsp;Müfredatta bu ders için hangi sunumun kullanılacağı belirlenmemiş.</span>
							</div>
					}
				</Grid>
				<Grid item xs={12} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '5px'}}>
					<FormElementLabel>
						Ders Tarih-Saati
					</FormElementLabel>
					<BaseDatePickerField selected={lessonDateTime}
						showTimeSelect timeIntervals={15} timeCaption="Time" dateFormat="yyyy - MMMM dd - HH:mm"
						style={{border: (props.lesson && props.lesson.courseSpecificData && props.lesson.courseSpecificData.dateOrderProblem && props.lesson.courseSpecificData.dateOrderProblem === true ? 'solid 1px red' : '') }}
						onChange={date => {changeFromInside = true; setLessonDateTime(date)} }
					/>
				</Grid>

				<Grid item xs={12} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '5px'}}>
					<div style={{borderBottom: 'solid 1px gainsboro', fontWeight: 'bold'}}>ZOOM AYARLARI</div>
					<FormElementLabel style={{marginTop: '10px'}}>Zoom Link</FormElementLabel>
					<div><BaseInput value={zoomSettings.zoomLink} onChange={(newValue) => setZoomSettings({...zoomSettings, zoomLink: newValue }) } onBlur={() => {changeFromInside=true; updateLessonData() } }  /></div>
					<FormElementLabel>Meeting ID</FormElementLabel>
					<div><BaseInput value={zoomSettings.zoomMeetingId} onChange={(newValue) => setZoomSettings({...zoomSettings, zoomMeetingId: newValue }) }  onBlur={() => {changeFromInside=true; updateLessonData() } } /></div>
					<FormElementLabel>Passcode</FormElementLabel>
					<div><BaseInput value={zoomSettings.zoomPasscode} onChange={(newValue) => setZoomSettings({...zoomSettings, zoomPasscode: newValue }) }  onBlur={() => {changeFromInside=true; updateLessonData() } } /></div>
					<div></div>
				</Grid>

				<Grid item xs={12} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '5px'}}>
					<div style={{borderBottom: 'solid 1px gainsboro', fontWeight: 'bold'}}>INTERAKTIF DERS SUNUM AYARLARI</div>
					<FormElementLabel style={{marginTop: '10px'}}>Interaktif Ders ID</FormElementLabel>
					<div><BaseInput value={lessonInstanceSettings.lessonInstanceKey} onChange={(newValue) => setLessonInstanceSettings({...lessonInstanceSettings, lessonInstanceKey: newValue }) } onBlur={() => {changeFromInside=true; updateLessonData() } }  /></div>
					<FormElementLabel style={{marginTop: '10px'}}>Interaktif Ders Link</FormElementLabel>
					<div><BaseInput value={lessonInstanceSettings.lessonInstanceLink} onChange={(newValue) => setLessonInstanceSettings({...lessonInstanceSettings, lessonInstanceLink: newValue }) } onBlur={() => {changeFromInside=true; updateLessonData() } }  /></div>
				</Grid>
			</Grid>
		</Lesson>
	)
}

export default CourseLessonSettings
