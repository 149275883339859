import React from 'react'
import PhaseSetup from './PhaseSetup'
import PhaseConnection from './PhaseConnection'
import PhaseStarting from './PhaseStarting'
import PhaseAwaitingQuestion from './PhaseAwaitingQuestion'
import PhaseShowQuestion from './PhaseShowQuestion'
import PhaseShowContent from './PhaseShowContent'
import PhaseAnswer from './PhaseAnswer'
import PhaseFinalResult from './PhaseFinalResult'
import PhaseResultQuestion from './PhaseResultQuestion'
import PhaseEnd from './PhaseEnd'

function Quiz(props) {
	return (
		<div>
			{props.game.phase === 'setup' && <PhaseSetup game={props.game} gameFunc={props.gameFunc} />}
			{props.game.phase === 'connection' && <PhaseConnection game={props.game} gameFunc={props.gameFunc} />}
			{props.game.phase === 'starting' && <PhaseStarting game={props.game} gameFunc={props.gameFunc} />}
			{props.game.phase === 'awaiting_question' && <PhaseAwaitingQuestion game={props.game} gameFunc={props.gameFunc} />}

			{props.game.phase === 'show_content' && <PhaseShowContent game={props.game} gameFunc={props.gameFunc} />}

			{props.game.phase === 'show_question' && <PhaseShowQuestion game={props.game} gameFunc={props.gameFunc} />}
			{props.game.phase === 'answer' && <PhaseAnswer game={props.game} gameFunc={props.gameFunc} />}
			{props.game.phase === 'result_question' && <PhaseResultQuestion game={props.game} gameFunc={props.gameFunc} />}
			{props.game.phase === 'final_result' && <PhaseFinalResult game={props.game} gameFunc={props.gameFunc} />}
			{props.game.phase === 'end' && <PhaseEnd game={props.game} gameFunc={props.gameFunc} />}		
		</div>
	)
}

export default Quiz
