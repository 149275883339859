import React, { useState, useEffect } from 'react'

import Input from '@material-ui/core/Input'
import DeleteIcon from '@mui/icons-material/Delete'

function ExcludedPartItem(props) {
	const [excludedPart, setExcludedPart] = useState(props.excludedPart)

	useEffect(async () => {
		if (props.excludedPart) {
			setExcludedPart(props.excludedPart)
		}
	}, [props.excludedPart])

	const onUpdateData = (newValue, field) => {
		if (newValue !== excludedPart[field]) {
			let tmpData = {...excludedPart}
			tmpData[field] = newValue
			setExcludedPart(tmpData)
		}
	}

	const addToExcludedParts = () => {
		if (excludedPart  && excludedPart.end && props.onUpdate) {
			props.onUpdate(excludedPart)	
		}
	}

	return (
		<div>
			{
				props.firstLine &&
					<div style={{borderBottom: 'solid 2px black'}}>
						<div style={{float: 'left', width: '28px', fontSize: '12px', fontWeight: 'bold', paddingTop: '10px' }}>
							
						</div>
						<div style={{float: 'left', width: '100px', fontWeight: 'bold' }}>
							Start
						</div>
						<div style={{float: 'left', width: '100px', marginLeft: '4px', fontWeight: 'bold'}}>
							End
						</div>
						<div style={{float: 'right', width: '16px', marginLeft: '4px', paddingTop: '12px'}}>
							
						</div>
						<div style={{clear: 'both'}}></div>
					</div>
			}
			{
				props.newAddition &&
					<div>
						<div style={{float: 'left', width: '28px', fontSize: '12px', fontWeight: 'bold', paddingTop: '10px' }}></div>
						<div style={{float: 'left', width: '100px' }}>
							<Input type="number" onChange={(e) => onUpdateData(e.target.value, 'start')} value={excludedPart.start} onBlur={() => { addToExcludedParts() } }
								style={{width: '100%', fontFamily: 'Varela Round' }} 
							/>
						</div>
						<div style={{float: 'left', width: '100px', marginLeft: '4px'}}>
							<Input type="number" onChange={(e) => onUpdateData(e.target.value, 'end')} value={excludedPart.end} onBlur={() => { addToExcludedParts() } }
								style={{width: '100%', fontFamily: 'Varela Round' }} 
							/>
						</div>
						<div style={{clear: 'both'}}></div>
					</div>
			}
			{
				excludedPart && !props.newAddition &&
					<div>
						<div style={{float: 'left', width: '28px', fontSize: '12px', fontWeight: 'bold', paddingTop: '10px' }}>
							
							{/* {excludedPart.id + 1}- &nbsp; */}
						</div>
						<div style={{float: 'left', width: '100px' }}>
							<Input type="number" onChange={(e) => onUpdateData(e.target.value, 'start')} value={excludedPart.start} onBlur={() => { props.onUpdate && props.onUpdate(excludedPart) } }
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
						</div>
						<div style={{float: 'left', width: '100px', marginLeft: '4px'}}>
							<Input type="number" onChange={(e) => onUpdateData(e.target.value, 'end')} value={excludedPart.end} onBlur={() => { props.onUpdate && props.onUpdate(excludedPart) } }
								style={{width: '100%', fontFamily: 'Varela Round' }}
							/>
						</div>
						<div style={{float: 'right', width: '24px', marginLeft: '4px', marginRight: '4px', paddingTop: '12px', textAlign: 'center'}}>
							<DeleteIcon onClick={() => { props.onDelete && props.onDelete(excludedPart)} } style={{fontSize: '14px', color: 'red', cursor: 'pointer'}} />
						</div>
						<div style={{clear: 'both'}}></div>
					</div>
			}
		</div>
	)
}

export { ExcludedPartItem }
