// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const setLessonInstanceBaseData = async (lessonInstanceLastData) => {
	let firebaseObj = await getFirebaseObj('setLessonInstanceBaseData')
	let firestore = firebaseObj.firestore

	const mainData = {
		created: lessonInstanceLastData.created,
		gameId: lessonInstanceLastData.gameId,
		key: lessonInstanceLastData.key,
		owner: lessonInstanceLastData.owner,
		phase: lessonInstanceLastData.phase,
		status: lessonInstanceLastData.status,
		templateKey: lessonInstanceLastData.templateKey,
		title: lessonInstanceLastData.title,
	}
	await firestore.collection('lessonInstances').doc(lessonInstanceLastData.key).set(mainData, {merge: true}) // returns result if necessary
}

const analyzeLesson = async (lessonInstanceLastData, second) => {
	let firebaseObj = await getFirebaseObj('analyzeLesson')
	let firestore = firebaseObj.firestore

	if (lessonInstanceLastData && lessonInstanceLastData.quiz.questions && lessonInstanceLastData.quiz.questions.length > 0 && lessonInstanceLastData.players &&  Object.keys(lessonInstanceLastData.players).length > 0) {
		await setLessonInstanceBaseData(lessonInstanceLastData)
			
		const batch = firestore.batch()
		lessonInstanceLastData.quiz.questions.map((question, index) => {
			batch.set(firestore.collection(`lessonInstances/${lessonInstanceLastData.key}/questions`).doc(`q-${index}`), question)
			return null
		})
		
		var tempPlayers = []
		Object.keys(lessonInstanceLastData.players).map((key) => tempPlayers.push(lessonInstanceLastData.players[key]))
		tempPlayers.map((player, index) => {
			let tempPlayer = {
				email: player.email,
				key: player.key,
				name: player.name,
				score: player.score,
				answers: []
			}
			for (let i = 0; i < lessonInstanceLastData.quiz.questions.length; i++) {
				if (player.answers && player.answers[i]) {
					tempPlayer.answers.push(player.answers[i])
				} else {
					tempPlayer.answers.push(null)
				}
			}

			batch.set(firestore.collection(`lessonInstances/${lessonInstanceLastData.key}/players`).doc(`p-${index}`), tempPlayer)
			return null
		})
		batch.commit()
	}
}

export { setLessonInstanceBaseData, analyzeLesson }