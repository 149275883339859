// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const getCurriculumById = async (staffEmail, id) => {
	let firebaseObj = await getFirebaseObj('getCurriculumById')
	let firestore = firebaseObj.firestore

	let tempCurriculums = []
	if (staffEmail === 'yesilokulcom@gmail.com') {
		let sCurriculums = await firestore.collection('curriculums').where('id', '==', id).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
	} else {
		let sCurriculums = await firestore.collection('curriculums').where('owner', '==', staffEmail).where('id', '==', id).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})

		sCurriculums = await firestore.collection('curriculums').where('meta.users.admins', 'array-contains-any', [staffEmail]).where('id', '==', id).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
		sCurriculums = await firestore.collection('curriculums').where('meta.users.editors', 'array-contains-any', [staffEmail]).where('id', '==', id).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
		sCurriculums = await firestore.collection('curriculums').where('meta.users.viewers', 'array-contains-any', [staffEmail]).where('id', '==', id).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
	}
	let curriculum = tempCurriculums && tempCurriculums.length > 0 ? tempCurriculums[0] : null
	return curriculum
}

const getCurriculumsOfStaff = async (staffEmail) => {
	let firebaseObj = await getFirebaseObj('getCurriculumsOfStaff')
	let firestore = firebaseObj.firestore

	let tempCurriculums = []
	if (staffEmail === 'yesilokulcom@gmail.com') {
		let sCurriculums = await firestore.collection('curriculums').get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
	} else {
		let sCurriculums = await firestore.collection('curriculums').where('owner', '==', staffEmail).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
		sCurriculums = await firestore.collection('curriculums').where('meta.users.admins', 'array-contains-any', [staffEmail]).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
		sCurriculums = await firestore.collection('curriculums').where('meta.users.editors', 'array-contains-any', [staffEmail]).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
		sCurriculums = await firestore.collection('curriculums').where('meta.users.viewers', 'array-contains-any', [staffEmail]).get()
		sCurriculums.docs.map(doc => {
			tempCurriculums.push(doc.data())
			return null
		})
	}
	return tempCurriculums
}

const CreateNewCurriculum = async (staffEmail) => {
	let firebaseObj = await getFirebaseObj('CreateNewCurriculum')
	let firestore = firebaseObj.firestore

	let newDocRef = firestore.collection('curriculums').doc()
	const newDocId = newDocRef.id
	newDocRef.set({title: 'New Curriculum', id: newDocId, meta: { dates: { curriculumCreated: Date.now() } }, owner: staffEmail })
	return newDocId
}

const UpdateCurriculumBaseData = async (curriculumId, data, _callback) => {
	let firebaseObj = await getFirebaseObj('UpdateCurriculumBaseData')
	let firestore = firebaseObj.firestore

	await firestore.collection('curriculums').doc(curriculumId).set(data, {merge: true})
}

export { getCurriculumById, getCurriculumsOfStaff, CreateNewCurriculum, UpdateCurriculumBaseData }
