import React, { useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { AuthContext } from '../../data/context/AuthProvider'
import { LogoImg, BaseInput, BaseButton, BaseLink, Centered, EmptySpace, FormElementLabel } from '../../components'
import { getBrandData } from '../../utils'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const PageTitle= styled.div`font-weight: bold; font-size: 18px; margin-top: 20px; margin-bottom: 10px;`
const FormArea = styled(Grid)`border: solid 1px gainsboro; border-radius: 8px; padding: 30px;
	-webkit-box-shadow: inset 0 0 6px rgba(30, 30, 30, 0.25);
	@media (max-width: 767px) {
		padding: 15px;
	}
`
function RegisterComponent(props) {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [submitData, setSubmitData] = useState()

	const [, setAuthContextData] = useContext(AuthContext)

	const registerUser = async () => {
		if (email && password) {			
			setSubmitData({email: email, password: password, name: name})
			let firebaseObj = await getFirebaseObj('registerUser  fbo_PageBlocks')
			let firebaseAuth = firebaseObj.firebaseAuth
			let firestore = firebaseObj.firestore

			firebaseAuth.createUserWithEmailAndPassword(email, password)
				.then((response) => {
					localStorage.setItem('email', response.user.email)
					let userData = {
						email: email,
						name: name,
						roles: ['teacher'],
						status: 'enabled',
					}
					firestore.collection('users').doc(response.user.email).set(userData)
					setAuthContextData({
						user: userData.email,
						email: userData.email,
						name: userData.name,
						roles: userData.roles,
						lessons: [],
						status: userData.status
					})
					props.onRegister(userData)
				})
				.catch((error) => {
					console.log({result: 'failed', error: error})
				})
		}
	}	
	return (
		<div style={{width: '95%', margin: 'auto'}}>
			<EmptySpace marginTop={props.marginTop ? props.marginTop : '10px'} />	
			<Centered>
				<div style={{display: (props.showLogoPart ? 'block' : 'none')}}>
					<EmptySpace marginTop='30px' />
					<BaseLink to="/">
						<LogoImg src={`/images/${getBrandData().logoFolder}/logo.png`} float="none" />
					</BaseLink>
				</div>
				
				<PageTitle>YENİ KULLANICI KAYDI</PageTitle>
			</Centered>
			<Grid container justify="space-evenly" alignItems="center">
				<FormArea item xs={12} sm={8} md={6}>

					<EmptySpace marginTop="30px" />	
					<FormElementLabel>Ad Soyad</FormElementLabel>
					<BaseInput value={name} onChange={(newValue) => {setName(newValue)} } showAsRequired={submitData && !name} maxLength="80" />
					
					<EmptySpace marginTop="30px" />	
					<FormElementLabel>E-Posta Adresi</FormElementLabel>
					<BaseInput value={email} onChange={(newValue) => {setEmail(newValue)} } showAsRequired={submitData && !email} maxLength="80" />
					
					<EmptySpace marginTop='30px' />

					<FormElementLabel>Şifre</FormElementLabel>						
					<BaseInput type="password" value={password} onChange={(newValue) => {setPassword(newValue)} } showAsRequired={submitData && !password} />
					<EmptySpace marginTop='30px' />
					<BaseButton type="button" value="KAYDOL" onClick={() => registerUser()} style={{float: 'right', width: '200px'}} />
					<EmptySpace marginTop='30px' />
				</FormArea>
			</Grid>
		</div>
	)
}

export { RegisterComponent }
