import React from 'react'
import styled  from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { getPlayerConnectionURL } from '../../../utils/appUtil'
import { H1 } from '../../../components'
import { ConnectedUsers } from '../shared/ConnectedUsers'

const GameTitle = styled.div`font-size:36px; font-weight: bold; color: navy; float: left; margin-left: 10px; margin-top: 10px;
	@media (max-width: 767px) {
		font-size: 24px;
	}
`
const CopyLink = styled.b`font-size: 12px; cursor: pointer; color: blue; text-decoration: underline;`
const PlayURL = styled(H1)`float: right; margin-right: 10px; margin-top: 10px;
	@media (max-width: 767px) {
		margin-top: 0px; margin-right: 5px; font-size: 18px;
	}
`
const NextButtonArea = styled.div`position: absolute; bottom: 10px; height: 60px; float: right; right: 10px; z-index: 10;`

function PhaseConnection(props) {
	const nextPhase = () => {
		props.gameFunc.update({ phase: 'starting' })
	}	
	let playURL = getPlayerConnectionURL(props.game.gameId)

	return (
		<Grid container>
			<Grid item xs={12} sm={6}>
				<GameTitle>{props.game.title}</GameTitle>
			</Grid>
			<Grid item xs={12} sm={6}>
				<div style={{float: 'right', marginRight: '10px', marginTop: '10px'}}>
					<PlayURL>
						<a href={playURL} target="_blank" rel="noopener noreferrer">{playURL}</a>
					</PlayURL>
					<CopyLink onClick={() => navigator.clipboard.writeText(playURL) } style={{fontSize: '12px'}}>(Copy)</CopyLink>
				</div>
			</Grid>

			<Grid item xs={12}>
				<ConnectedUsers game={props.game} animate={true} height="calc(100vh - 210px)" />
			</Grid>

			{/* <Grid item xs={12}>
				<StartButton type="button" onClick={() => nextPhase()} value="DERSİ BAŞLAT" shadowColor="green" />
			</Grid> */}

			<NextButtonArea>
				<Fab color="primary" aria-label="add" onClick={() => nextPhase()}>
					<NavigateNextIcon />
				</Fab>
			</NextButtonArea>
		</Grid>
	)
}

export default PhaseConnection
