import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import firebase from 'firebase'
import { CourseMainDrawer } from '../shared/CourseMainDrawer'
import { BaseDatePickerField } from '../../../components'

const Lesson = styled.div`width: calc(100% - 10px); margin: 5px; margin-left: 15px; border-radius: 6px; text-align: left; padding-left: 10px; padding-top: 10px; min-height: 60px;`
const InSectionNumber = styled.span`border-radius: 100px; padding: 3px; width: 40px; margin-right: 5px; background-color: gray; color: white; font-size: 14px;`
const LessonTitle = styled.span`font-size: 18px; font-weight: bold;`
const LessonTemplateData = styled.div`margin-left: 10px; margin-top: 3px; cursor: pointer; display: flex; align-items: center;`
const LessonTemplateCoverSmall = styled.img`border-radius: 6px; width: 60px; max-height: 60px;`


let changeFromInside = false

function CourseLesson(props) {
	const [title, setTitle] = useState(props.lesson.title)
	const [lessonDateTime, setLessonDateTime] = useState()

	useEffect(() => {
		changeFromInside = false
		if (props.lesson) {
			setTitle(props.lesson.title)
			if (props.lesson && props.lesson.courseSpecificData) {
			}
			if (props.lesson.courseSpecificData && props.lesson.courseSpecificData.lessonDateTime) {
				setLessonDateTime(props.lesson.courseSpecificData.lessonDateTime.toDate())
			}
		}
	}, [props])

	useEffect(() => {
		if (changeFromInside) {
			updateLessonData()
		}
	}, [lessonDateTime])
	
	const updateLessonData = () => {
		if (props.onUpdate && props.editable) { 
			let tempLesson = {...props.lesson}
			tempLesson.courseSpecificData = {
				lessonDateTime : firebase.firestore.Timestamp.fromDate(lessonDateTime)
			}
			props.onUpdate(tempLesson)
		}
	}

	const updateLessonSettings = (lessonSettings) => {
		if (props.onUpdate && props.editable) { 
			let tempLesson = {...props.lesson}
			tempLesson.courseSpecificData = lessonSettings
			props.onUpdate(tempLesson)
		}
	}

	return (
		<Lesson>
			<Grid container>
				<Grid item xs={12} sm={5}>
					<LessonTitle>
						{
							props.index < 9 ?
								<InSectionNumber>&nbsp;{props.index + 1}&nbsp;</InSectionNumber>
							:
								<InSectionNumber>{props.index + 1}</InSectionNumber>
						}
						<span>{title}</span>
					</LessonTitle>
				</Grid>
				<Grid item xs={12} sm={3}>
					{
						props.lesson.templateTitle ?
							<LessonTemplateData>
								{
									props.lesson.templateMetaData && props.lesson.templateMetaData.coverImage && props.lesson.templateMetaData.coverImage.url &&
										<LessonTemplateCoverSmall src={props.lesson.templateMetaData.coverImage.url} />
								}
								&nbsp;
								<div>
									<div>
										<a href={`/lesson-designer/${props.lesson.lessonTemplateKey}`} target="_blank" rel="noopener noreferrer">
											{props.lesson.templateTitle}&nbsp;
										</a>
									</div>
									<div style={{color: (!props.lesson.templateStats || !props.lesson.templateStats.allSlidesCount || props.lesson.templateStats.allSlidesCount < 10 ? 'red' : ''), fontSize: '11px'}}>
										{
											(!props.lesson.templateStats || !props.lesson.templateStats.allSlidesCount || props.lesson.templateStats.allSlidesCount < 10) && 
												<Tooltip title="Yetersiz içerik">
													<ErrorOutlineIcon style={{color: 'orange'}} />
												</Tooltip>
										}
										{ props.lesson.templateStats && props.lesson.templateStats.allSlidesCount} Slide => 
										{ props.lesson.templateStats && props.lesson.templateStats.qType.imageCount} resim, 
										{ props.lesson.templateStats && props.lesson.templateStats.qType.textCount} text, 
										{ props.lesson.templateStats && props.lesson.templateStats.qType.videoCount} video
									</div>
									<div style={{fontSize: '11px'}}>
										{ props.lesson.templateStats && props.lesson.templateStats.allSlidesCount} Slide => 
										{ props.lesson.templateStats && props.lesson.templateStats.cType.contentCount} içerik, 
										{ props.lesson.templateStats && props.lesson.templateStats.cType.quizCount} quiz, 
										{ props.lesson.templateStats && props.lesson.templateStats.cType.pollCount} anket, 
										{ props.lesson.templateStats && props.lesson.templateStats.cType.formCount} form
									</div>
								</div>
							</LessonTemplateData>
						:
							<div>
								<Tooltip title="Ders tanımı yapılmamış">
									<ErrorOutlineIcon style={{color: 'red'}} />
								</Tooltip>
							</div>
					}
				</Grid>
				<Grid item xs={10} sm={3} style={{textAlign: 'right', padding: '5px'}}>
					{
						props.editable ?
							<BaseDatePickerField selected={lessonDateTime}
								showTimeSelect timeIntervals={15} timeCaption="Time" dateFormat="yyyy - MMMM dd - HH:mm"
								style={{border: (props.lesson && props.lesson.courseSpecificData && props.lesson.courseSpecificData.dateOrderProblem && props.lesson.courseSpecificData.dateOrderProblem === true ? 'solid 1px red' : '') }}
								onChange={date => {changeFromInside = true; setLessonDateTime(date)} }
							/>
						:
							<span>{moment(lessonDateTime).format('YYYY-MM-DD hh:mm')}</span>
					}
					
				</Grid>
				<Grid item xs={2} sm={1}>
					{
						props.editable ?
							<CourseMainDrawer content='course-lesson-settings' float="left" iconColor="green" showIcon={true} mode="active" iconSize="22px" lesson={props.lesson} onUpdate={(newLessonSettings) => { changeFromInside=true; updateLessonSettings(newLessonSettings) } } />
						:
							<span></span>
					}
				</Grid>
			</Grid>
			
			
		</Lesson>
	)
}

export default CourseLesson
