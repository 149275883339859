import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import { getBrandData } from '../../../utils/appUtil'
import { H1, BaseInput } from '../../../components'
import { searchCourses } from '../../../data/firebase/courseFirebase'

const CourseCoverImg = styled.img`border-radius: 6px; max-width: 50px; max-height: 40px;`
const SearchResultsArea = styled.div`border-radius: 6px; padding: 10px; position: absolute; background-color: white;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);`
const SearchResultItem = styled.div`cursor: pointer; padding: 4px; border: solid 1px gainsboro; min-height: 40px; border-radius: 6px;`

let changeFromInside = false
function CoursePricing(props) {
	const [basePrice, setBasePrice] = useState(props.pricingData.basePrice ? props.pricingData.basePrice : 0)
	const [currency, setCurrency] = useState(props.pricingData.currency ? props.pricingData.currency : '')
	const [packetDiscountRate, setPacketDiscountRate] = useState(props.pricingData.packetDiscountRate ? props.pricingData.packetDiscountRate : 0)
	const [packetMemberCourses, setPacketMemberCourses] = useState(props.pricingData.packetMemberCourses ? props.pricingData.packetMemberCourses : [])

	const [searchText, setSearchText] = useState('')
	const [searchResults, setSearchResults] = useState([])
	// const [selectedNewMemberCourse, setSelectedNewMemberCourse] = useState()

	useEffect(() => {
		changeFromInside = false
		setBasePrice(props.pricingData.basePrice ? props.pricingData.basePrice : 0)
		setCurrency(props.pricingData.currency ? props.pricingData.currency : '')
		setPacketDiscountRate(props.pricingData.packetDiscountRate ? props.pricingData.packetDiscountRate : 0)
		setPacketMemberCourses(props.pricingData.packetMemberCourses ? props.pricingData.packetMemberCourses : [])
	}, [props.pricingData])

	useEffect(() => {
		// if (basePrice > 1000) {setBasePrice(1000)}
		if (basePrice < 0) {setBasePrice(0)}
		if (packetDiscountRate > 70) { setPacketDiscountRate(70)}

		if (changeFromInside) {
			updatePricingData()
		}
	}, [basePrice, currency, packetDiscountRate, packetMemberCourses])

	const updatePricingData = () => {
		props.onUpdatePricing({
			basePrice: parseFloat(basePrice),
			currency: currency,
			packetDiscountRate: parseInt(packetDiscountRate),
			packetMemberCourses: packetMemberCourses
		})
	}

	useEffect(() => {
		if (searchText && searchText.length > 6) {
			searchCourses(searchText, (resultCourses) => {
				setSearchResults(resultCourses)
			})
		} else {
			setSearchResults([])
		}
	}, [searchText])

	const removeMemberCourse = (index) => {
		let tempList = [...packetMemberCourses]
		tempList.splice(index, 1)

		setPacketMemberCourses(tempList)
	}

	const addMemberCourse = (selectedNewMemberCourse) => {
		if (selectedNewMemberCourse) {
			let foundInAlreadyAdded = packetMemberCourses.filter(member => member.id === selectedNewMemberCourse.id)
			if (foundInAlreadyAdded && foundInAlreadyAdded.length > 0) {
				alert('Bu kursa zaten pakete eklenmiş')
				setSearchText('')
				return
			}

			if (selectedNewMemberCourse.id === props.courseId) {
				alert('Bu kurs zaten aktif olarak üzerinde çalıştığınız esas kurs olduğu için listeye eklemeniz gerekmemektedir.')
				setSearchText('')
				return
			}

			let tempList = [...packetMemberCourses]
			tempList.push({
				id: selectedNewMemberCourse.id,
				courseName: selectedNewMemberCourse.courseName,
				meta: {
					intro: {
						title: selectedNewMemberCourse.meta.intro.title,
						subTitle: selectedNewMemberCourse.meta.intro.subTitle,
						coverImageUrl: selectedNewMemberCourse.meta.intro.coverImageUrl,
						coverImageThumb300Url: selectedNewMemberCourse.meta.intro.coverImageThumb300Url,
						coverImageThumb600Url: selectedNewMemberCourse.meta.intro.coverImageThumb600Url,
						language: selectedNewMemberCourse.meta.intro.language,
					},
					pricing: {
						basePrice: selectedNewMemberCourse.meta.pricing.basePrice,
						currency: selectedNewMemberCourse.meta.pricing.currency,
						packetDiscountRate: selectedNewMemberCourse.meta.pricing.packetDiscountRate,
					},
					settings: selectedNewMemberCourse.meta.settings,
					stats: selectedNewMemberCourse.meta.stats,
				}
			})
			setPacketMemberCourses(tempList)
			setSearchText('')
		}
	}


	return (
		<Grid container>
			<Grid item xs={12}>
				<H1>Fiyatlandırma</H1>
			</Grid>
			<Grid item xs={12} style={{textAlign: 'left', marginBottom: '30px'}}>
				Kursun satışına dair kurallar ve fiyatlandırma
			</Grid>

			{/* <Grid item xs={12} style={{textAlign: 'left', marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '5px'}}>
				<div>SATIŞ KURALI</div>
				<div style={{color: 'navy'}}>Bu kurs müstakil olarak satın alınabilir veya başka kurslar ile birlikte bir eğitim paketin halinde alınabilir. Satışının ne şekilde yapılmasını istiyorsunuz?</div>
				<div style={{color: 'red'}}>Kurs yayınlandıktan sonra bu ayar bir daha değiştirilemez.</div>
			</Grid> */}

			<Grid item xs={12} style={{textAlign: 'left', marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '15px'}}>
				<H1>TEMEL FİYAT - DERS ÜCRETİ</H1>
				<div style={{color: 'navy', fontSize: '14px'}}>
					Bu kursun bir öğrenci için bir ders ücretini giriniz. Bu kurs entegre diğer kurslar ile birlikte alındığında indirim uygulanabilir. Ayrıca sistem geneli genel kampanya zamanlarında indirimli fiyatlar uygulayabilir.
				</div>

				<div style={{color: 'red', fontSize: '14px'}}>
					Kurs yayınlandıktan sonra fiyatlandırma değiştirilemez.
				</div>

				{
					props.editable ?
						<div style={{marginTop: '20px'}}>					
							<BaseInput type="number" value={basePrice} onChange={(newValue) => {changeFromInside = false; setBasePrice(newValue)}} onBlur={() => { changeFromInside = true; updatePricingData()}} width="150px" /> &nbsp;&nbsp;
							<Select value={currency} onChange={(e) => { changeFromInside = true; setCurrency(e.target.value); } } style={{width: '100px'}}>
								<MenuItem value="USD">USD</MenuItem>
								<MenuItem value="EUR">EUR</MenuItem>
								<MenuItem value="GBP">GBP</MenuItem>
								<MenuItem value="CAD">CAD</MenuItem>
								<MenuItem value="AUD">AUD</MenuItem>
								<MenuItem value="TL">TL</MenuItem>
							</Select>
						</div>
					:
						<div style={{marginTop: '20px', fontSize: '18px', fontWeight: 'bold'}}>
							{basePrice} {currency}
						</div>
				}

				
				<Grid container style={{marginTop: '20px'}}>
					{
						props.lessonCount &&
							<>
								<Grid item xs={9} sm={6}>
									Ders Sayısı
								</Grid>
								<Grid item xs={3} sm={6}>
									{props.lessonCount}
								</Grid>
							</>
					}
					{
						basePrice !== undefined && currency &&
							<>
								<Grid item xs={9} sm={6}>
									Bir Ders Ücreti
								</Grid>
								<Grid item xs={3} sm={6}>
									{
										basePrice > 0 ?
											<div>{basePrice} {currency}</div>
										:
											<div>ÜCRETSİZ</div>
									}
								</Grid>
							</>
					}
					{
						props.lessonCount && basePrice !== undefined && currency &&
							<>
								<Grid item xs={9} sm={6} style={{borderTop: 'solid 1px black', fontSize: '20px', fontWeight: 'bold', marginTop: '5px', paddingTop: '5px'}}>
									KURS TOPLAM ÜCRETİ
								</Grid>
								<Grid item xs={3} sm={6} style={{color: 'red', fontSize: '20px', fontWeight: 'bold', borderTop: 'solid 1px black', marginTop: '5px', paddingTop: '5px'}}>
									{
										basePrice > 0 ?
											<div>{props.lessonCount * basePrice} {currency}</div>
										:
											<div>ÜCRETSİZ</div>
									}
								</Grid>
							</>
					}
				</Grid>
			</Grid>

			<Grid item xs={12} style={{textAlign: 'left', marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '15px'}}>
				<H1>
					ENTEGRE KURSLAR İLE PAKET
				</H1>
				<div style={{fontSize: '14px'}}>
					Birlikte satılmasını istediğiniz, zamanları birbirlerine göre planlanmış olan kurs paketi içindeki kursları buraya ekleyiniz. Bu kursların birlikte gösterilebilmesi için eklediğiniz kurs ile karşılıklı olarak eklenmesi gerekmektedir.
					Diğer kurs bu kursu eklemeden, kursunuzun tanıtım sayfasında sizin ekledikleriniz de görünmeyecektir.
				</div>
				<Grid container>
					<Grid item xs={12} style={{marginTop: '10px'}}>
						<b>İndirim Oranı:</b> Bu kursların hepsini alanlara yapılacak indirim miktarı
					</Grid>
					<Grid item xs={12}>
						{
							props.editable ?
								<BaseInput type="number" value={packetDiscountRate} onChange={(newValue) => {changeFromInside = false; setPacketDiscountRate(newValue)}} onBlur={(e) => { changeFromInside = true; updatePricingData()}} width="150px" />
							:
								<span>
									{packetDiscountRate}
								</span>
						}
						<span>&nbsp;%</span>
					</Grid>

					<Grid container>
						<Grid item xs={12}>
							<H1>Paketteki Kurslar</H1>
						</Grid>
						<Grid item xs={12}>
							{
								packetMemberCourses && packetMemberCourses.length > 0 && packetMemberCourses.map((_memberCourse, index) => (
									<Grid container style={{textAlign: 'left', marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '15px'}}>
										<Grid item xs={12} sm={4}>
											<a href={`${getBrandData().publicWebsite}/${_memberCourse.meta.intro.language}/course/${_memberCourse.meta.settings.slug}`} target="_blank" rel="noopener noreferrer">
												{_memberCourse.id}
											</a>
										</Grid>
										<Grid item xs={12} sm={6}>
											{_memberCourse.courseName}
										</Grid>
										<Grid item xs={12} sm={2}>
											{
												props.editable &&
													<ClearIcon onClick={() => { changeFromInside = true; removeMemberCourse(index); } } style={{fontSize: '14px', color: 'red', cursor: 'pointer'}} />
											}
										</Grid>
									</Grid>
								))									
							}
						</Grid>
						{
							props.editable &&
							<Grid item xs={12}>
								<div style={{marginTop: '10px', marginBottom: '5px', fontWeight: 'bold'}}>
									İlgili kursu bulmak ve listenize eklemek için Kurs ID si ile aşağıdaki kutuda arama yapın.
								</div>
									<BaseInput value={searchText} onChange={(newValue) => { setSearchText(newValue)}} 
										WebkitBoxShadow=" " border="0px" marginLeft="10px" borderBottom="solid 1px rgba(180, 60, 60, 0.65)" fontSize="16px" borderLeft="solid 1px rgba(180, 60, 60, 0.65)"
									/>							
								{
									searchResults && searchResults.length > 0 && (
										<SearchResultsArea>
											{
												searchResults.map((_course, index) => (
													<SearchResultItem key={index} onClick={() => { changeFromInside = true; addMemberCourse(_course)} }>
														{
															_course.meta && _course.meta.intro && _course.meta.intro.coverImageThumb300Url &&
																<CourseCoverImg src={_course.meta.intro.coverImageThumb300Url} />
														}
														&nbsp;
														{_course.courseName}
													</SearchResultItem>
												))
											}
										</SearchResultsArea>
									)
								}
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	)
}

export default CoursePricing
