import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { getCoursesOfStudent } from '../../data/firebase/studentCoursesFirebase'
import { getUserInfoByEmail } from '../../data/firebase/userFirebase'
import { getCoursesByCourseIdList } from '../../data/firebase/courseFirebase'
import { getCommentsForUser } from '../../data/firebase/commentsForUsersFirebase'
import { AuthContext } from '../../data/context/AuthProvider'
import { H1, Toggle } from '../../components'
import { LessonListTimeTable } from '../timetable/LessonListTimeTable'
import { StudentCourseListItem } from '../student/StudentCourseListItem'
import { CommentsAboutStudent } from './CommentsAboutStudent'
import { StudentInfo } from './StudentInfo'

const tabsStudentDataOptions = [
	{ id: 'registered-courses', label: 'KAYITLAR' },
	{ id: 'lesson-time-table', label: 'DERS TAKVİMİ' },
	{ id: 'comments-about-student', label: 'YORUMLAR' }
]

function StudentDetails(props) {
	const [authData] = useContext(AuthContext)

	const [userData, setUserData] = useState()
	const [coursesInShoppingCart, setCoursesInShoppingCart] = useState([])
	const [coursesRegistered, setCoursesRegistered] = useState([])
	const [commentsForStudent, setCommentsForStudent] = useState([])
	const [tabStudentData, setTabStudentData] = useState('registered-courses')

	useEffect(async () => {
		if (props.email && props.email.length > 0) {
			let studentUserData = await getUserInfoByEmail(props.email)
			setUserData(studentUserData)

			let studentRelatedComments = await getCommentsForUser(props.email, authData.email)
			setCommentsForStudent(studentRelatedComments)

			let tempStudentCourses = await getCoursesOfStudent(props.email)
			let tmpRegisteredStudentCourses = tempStudentCourses.filter(_studentCourse =>
				_studentCourse.status === 'paid' || // paid means paid by credit card
				_studentCourse.status === 'paid-cash' ||
				_studentCourse.status === 'will-be-paid-cash' ||
				_studentCourse.status === 'paid-paypal' ||
				_studentCourse.status === 'will-be-paid-paypal' ||
				_studentCourse.status === 'free'
			)
			let tmpShoppingCartStudentCourses = tempStudentCourses.filter(_studentCourse => _studentCourse.status === 'shopping-cart')

			let tmpRegisteredCourseIds = []
			for (let i = 0; i < tmpRegisteredStudentCourses.length; i++) {
				tmpRegisteredCourseIds.push(tmpRegisteredStudentCourses[i].courseId)
			}			

			let tmpShoppingCartCourseIds = []
			for (let i = 0; i < tmpShoppingCartStudentCourses.length; i++) {
				tmpShoppingCartCourseIds.push(tmpShoppingCartStudentCourses[i].courseId)
			}
			
			let tmpRegisteredCourses = await getCoursesByCourseIdList(tmpRegisteredCourseIds)
			let tmpShoppingCartCourses = await getCoursesByCourseIdList(tmpShoppingCartCourseIds)

			tmpRegisteredCourses.map(_course => {
				let tmpStudentCourse = tmpRegisteredStudentCourses.filter(_studentCourse => _studentCourse.courseId === _course.id)
				if (tmpStudentCourse && tmpStudentCourse.length > 0) {
					_course.courseStudentData = tmpStudentCourse[0]
				} else {
					console.log('ERROR: Course Student not found')
				}
				return null
			})

			tmpShoppingCartCourses.map(_course => {
				let tmpStudentCourse = tmpShoppingCartStudentCourses.filter(_studentCourse => _studentCourse.courseId === _course.id)
				if (tmpStudentCourse && tmpStudentCourse.length > 0) {
					_course.courseStudentData = tmpStudentCourse[0]
				} else {
					console.log('ERROR: Course Student not found')
				}
				return null
			})

			setCoursesRegistered(tmpRegisteredCourses)
			setCoursesInShoppingCart(tmpShoppingCartCourses)
		}
	}, [props.email])

	return (
		<div>
			{
				userData &&
					<StudentInfo userData={userData} />
			}
			{
				userData &&
					<Grid container>
						<Grid item xs={12} style={{margin: '10px'}}>
							<Toggle options={tabsStudentDataOptions} onChange={(newValue) => setTabStudentData(newValue) } value={tabStudentData} />
						</Grid>

						{
							tabStudentData === 'registered-courses' &&
								<>
									<Grid item xs={12} sm={7} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
										<Grid container>
											<Grid item xs={12}>
												<H1 style={{marginBottom: '5px'}}>
													Kayıtlı Oldugu Kurslar ({(coursesRegistered && coursesRegistered.length > 0 ) ? <span>{coursesRegistered.length}</span> : <span>0</span>})
												</H1>
											</Grid>
											{
												coursesRegistered && coursesRegistered.length > 0 ?
													coursesRegistered.map((_course, index) => (
															<StudentCourseListItem course={_course} key={index} />
														)
													)
												:
													<div>Yaklaşan satın alınmış bir kurs yok.</div>
											}
										</Grid>
									</Grid>
									<Grid item xs={12} sm={5} style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#ffeddb' }}>
										<Grid container>
											<Grid item xs={12}>
												<H1 style={{marginBottom: '5px'}}>
													Sepetteki Kurslar ({(coursesInShoppingCart && coursesInShoppingCart.length > 0) ? <span>{coursesInShoppingCart.length}</span> : <span>0</span> })
												</H1>
											</Grid>
											{
												coursesInShoppingCart && coursesInShoppingCart.length > 0 ?
													coursesInShoppingCart.map((_course, index) =>
														<StudentCourseListItem course={_course} key={index} />
													)
												:
													<div>Sepette eklenmiş bekleyen kurs yok.</div>
											}
										</Grid>
									</Grid>
								</>
						}

						{
							coursesRegistered && coursesRegistered.length > 0 && tabStudentData === 'lesson-time-table' &&
								<Grid container style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
									<Grid item xs={12}>
										<H1 style={{marginTop: '25px'}}>
											DERS TAKVIMI
										</H1>
										<LessonListTimeTable courses={coursesRegistered} />
									</Grid>
								</Grid>
						}
						{
							tabStudentData === 'comments-about-student' &&
								<Grid container style={{marginTop: '20px', border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px', backgroundColor: '#f4f4f4' }}>
									<Grid item xs={12}>
										<H1 style={{marginTop: '25px'}}>
											ÖĞRENCİ HAKKINDA NOTLAR - YORUMLAR
										</H1>
										<CommentsAboutStudent itemList={commentsForStudent} studentUserData={userData} editable={true} onePerLine={true} />
									</Grid>
								</Grid>
						}
					</Grid>
			}
		</div>
	)
}

export { StudentDetails }