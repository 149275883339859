import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import QuestionContent from '../shared/QuestionContent'
import NextStepButtons from '../shared/NextStepButtons'

const QuestionSection = styled.div`text-align: center; overflow: auto; width: calc(100% - 20px); height: calc(100vh - 75px); margin-left: 10px; margin-right: 10px; margin-top: 20px; border-radius: 6px; padding-left: 5px; padding-right: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);
`

function PhaseShowContent(props) {
	const [question, setQuestion] = useState('')
	
	useEffect(() => {
		const _question = props.game.quiz.questions[props.game.quiz.currentQuestion]
		if (_question) {
			setQuestion(_question)
		}
	}, [])


	const goNextQuestion = () => {
		const game = {}
		game.quiz = props.game.quiz
		game.quiz.currentQuestion = game.quiz.currentQuestion + 1
		game.phase = 'awaiting_question'
		props.gameFunc.update(game)
	}

	const finalizeLesson = () => {
		props.gameFunc.end()
	}
	
	const nextQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion + 1]
	const isLastQuestion = typeof nextQuestion === 'undefined'

	return (
		<div>
			{
				question &&
					<QuestionSection>
						<QuestionContent question={question}
							imageBrushColor="red"
							videoPlaying={false} onVideoEnd={null} videoControlType="host" videoUseMaxScreen={true} videoVolume={1} mode="active"
							gameFunc={props.gameFunc} gameKey={props.game.key} gamePhase={props.game.phase}
							controlType="host"
						/>
					</QuestionSection>
			}
			<NextStepButtons onFinalizeLesson={() => finalizeLesson()} onNextQuestion={() => goNextQuestion()} isLastQuestion={isLastQuestion} />
		</div>
	)
}

export default PhaseShowContent
