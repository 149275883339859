import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import firebase from 'firebase'
import Grid from '@material-ui/core/Grid'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import LanguageIcon from '@material-ui/icons/Language'
import Tooltip from '@material-ui/core/Tooltip'
import { AuthContext } from '../../data/context/AuthProvider'
import { FormElementLabel, Toggle } from '../../components'
import CourseSection from './Plan/CourseSection'
import CourseMetaData from './MetaData/CourseMetaData'
import CourseStudents from './students/CourseStudents'
import { updateCourseData } from '../../data/firebase/courseFirebase'
import { getBrandData } from '../../utils'

const tabs = [
	// { id: 'planning', label: 'Planlama' },
	{ id: 'design', label: 'İçerik' },
	{ id: 'meta', label: 'Bilgi' },
	{ id: 'students', label: 'Öğrenciler' },
]

let changeFromInside = false
function CurriculumDesigner(props) {
	let history = useHistory()

	const [authData] = useContext(AuthContext)
	
	const [tab, setTab] = useState((props.urlParams && props.urlParams.param2) ? props.urlParams.param2 : 'design')
	const [course, setCourse] = useState(props.curriculum ? props.curriculum : {})
	const [title, setTitle] = useState('')
	const [sections, setSections] = useState([])
	const [isEditable, setIsEditable] = useState(true)

	useEffect(() => {
		if (!props.urlParams || !props.urlParams.param2 || props.urlParams.param2 !== tab) {
			if (course && course.id) {
				history.push(`/courses/${course.id}/${tab}`)
			}
		}
	}, [tab])

	useEffect(() => {
		if (props.course) {
			changeFromInside = false
			setCourse(props.course)
			if (props.course.sections) {
				setSections(props.course.sections)
			}
			let tempCourseName = props.course.meta.settings.courseSpecificName ? props.course.meta.settings.courseSpecificName : (props.course.meta.intro.title ? props.course.meta.intro.title : props.course.title)
			setTitle(tempCourseName)

			if (props.course.meta.stats.lastLessonDate && props.course.meta.settings.status && (
					props.course.meta.settings.status === 'published' ||
					props.course.meta.settings.status === 'published-unlisted' ||
					props.course.meta.settings.status === 'published-private'
				)
			) {
				if (props.course.meta.settings.classCategory === 'privateLesson') {
					setIsEditable(true)
				} else if (moment() > moment(props.course.meta.stats.lastLessonDate.toDate()) ) {				
					setIsEditable(false)
				} else if (moment() < moment(props.course.meta.stats.firstLessonDate.toDate()) ) {				
					setIsEditable(true)
				} else {
					setIsEditable(true)
				}
			} else {
				setIsEditable(true)
			}
			
		}
	}, [props.course])

	const updateCourseMeta = (meta) => {
		setCourse( { ...course, meta: meta })
	}

	useEffect(() => {
		if (changeFromInside) {
			updateCourseData(course.id, course)
		}		
	}, [course])

	const updateLesson = (section, lesson) => {
		if (section && lesson) {
			let tempSection = {...section}
			let tempLessons = [...section.lessons]
			var foundIndex = tempLessons.findIndex(x => x.id === lesson.id)
			if (foundIndex >= 0) {
				tempLessons[foundIndex] = lesson
				tempSection.lessons = tempLessons
				updateSection(tempSection)
			} else {
				console.log('Lesson could not be found: ', foundIndex)
			}
		}
	}

	const updateSection = (section) => {
		let tempSections = sections ? [...sections] : []
		if (tempSections.length > 0) {
			var foundIndex = tempSections.findIndex(x => x.id === section.id)
			tempSections[foundIndex] = section
			
			let tempCourse = {...course}

			let previousLessonDate
			let lessonDateOrderProblem = false

			tempCourse.meta.stats.lastLessonDate = section.lessons && section.lessons.length > 0 && section.lessons[0].lessonDateTime ? section.lessons[0].lessonDateTime : firebase.firestore.Timestamp.fromDate(new Date(1970, 1, 1))
			tempSections.map((_section, sectionIndex) => {
				if (_section.lessons.length > 0) {
					_section.lessons.map((_lesson, lessonIndex) => {						
						if (_lesson.courseSpecificData && _lesson.courseSpecificData.lessonDateTime) {
							if (tempCourse.meta && tempCourse.meta.stats && tempCourse.meta.stats.lastLessonDate) {
								if (tempCourse.meta.stats.lastLessonDate.toDate() < _lesson.courseSpecificData.lessonDateTime.toDate()) {
									tempCourse.meta.stats.lastLessonDate = _lesson.courseSpecificData.lessonDateTime
								}
							} else {
								tempCourse.meta.stats.lastLessonDate = _lesson.courseSpecificData.lessonDateTime
							}

							if (lessonIndex === 0 && sectionIndex === 0) {
								previousLessonDate = _lesson.courseSpecificData.lessonDateTime	
								tempCourse.meta.stats.firstLessonDate = _lesson.courseSpecificData.lessonDateTime	
							} else {
								if (previousLessonDate && previousLessonDate.toDate() >= _lesson.courseSpecificData.lessonDateTime.toDate()) {
									lessonDateOrderProblem = true
									tempSections[sectionIndex].lessons[lessonIndex].courseSpecificData.dateOrderProblem = true
								} else {
									tempSections[sectionIndex].lessons[lessonIndex].courseSpecificData.dateOrderProblem = false
								}
								if (_lesson.courseSpecificData.lessonDateTime) {
									previousLessonDate = _lesson.courseSpecificData.lessonDateTime
								}


								if (tempCourse.meta && tempCourse.meta.stats && tempCourse.meta.stats.firstLessonDate) {
									if (tempCourse.meta.stats.firstLessonDate.toDate() > _lesson.courseSpecificData.lessonDateTime.toDate()) {
										tempCourse.meta.stats.firstLessonDate = _lesson.courseSpecificData.lessonDateTime
									}
								} else {
									tempCourse.meta.stats.firstLessonDate = _lesson.courseSpecificData.lessonDateTime
								}
							}
						}
						return null
					})
				}
				return null
			})

			if (lessonDateOrderProblem) {
				alert('Derslerin tarihleri sıralı olmalıdır. Kurs bu tarih bilgileri ile yayınlamaz. Lütfen tarihleri sıralı hale getiriniz.')
			}

			tempCourse.sections = tempSections
			setCourse(tempCourse)
			setSections(tempSections)
		}
	}
	
	return (
		<div>
			<Grid container>
				<Grid item xs={12} style={{borderBottom: 'solid 1px gainsboro', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginTop: '20px', marginBottom: '5px'}}>
					<Grid container>
						<Grid item xs={12} sm={1}>
							<FormElementLabel style={{lineHeight: '34px', marginLeft: '5px'}}>Kurs:</FormElementLabel>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={{fontSize: '20px', fontWeight: 'bold', textAlign: 'left'}}>{title}</div>
						</Grid>
						<Grid item xs={12} sm={1}>							
							{
								authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) &&
									<Tooltip title="Goto course data on firestore">
										<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fcourses~2F${props.course.id}`} target="_blank" rel="noopener noreferrer"><WhatshotIcon /></a>
									</Tooltip>
							}
							{
								authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) &&
									<Tooltip title="Goto curriculum data on firestore">
										<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fcurriculums~2F${props.course.meta.curriculum.curriculumId}`} target="_blank" rel="noopener noreferrer"><WhatshotIcon /></a>
									</Tooltip>
							}
							<Tooltip title="Bu kursun müfredatına git">
								<a href={`/curriculum-designer/${props.course.meta.curriculum.curriculumId}/design`} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a>
							</Tooltip>
							<Tooltip title="Bu kursun web sayfasına git">
								<a href={`${getBrandData().publicWebsite}/${props.course.meta.intro.language}/course/${props.course.meta.settings.slug}`} target="_blank" rel="noopener noreferrer"><LanguageIcon /></a>
							</Tooltip>
							
						</Grid>
						<Grid item xs={12} md={4}>
							<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
						</Grid>
					</Grid>
				</Grid>
				{
					tab === 'design' &&
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12}>
									{
										sections &&
											<div>												
												{
													sections.map((section, index) => section &&
														<CourseSection section={section} key={section.id} index={index} editable={isEditable}
															onUpdateLesson={(section, lesson) => { changeFromInside = true; updateLesson(section, lesson) }}
														/>
													)
												}
											</div>
									}
								</Grid>
							</Grid>
						</Grid>
				}
				{
					tab === 'meta' &&
						<Grid item xs={12}>
							<CourseMetaData course={course} urlParams={props.urlParams} onUpdateMeta={(meta) => {changeFromInside = true; updateCourseMeta(meta)}} editable={isEditable} />
						</Grid>
				}
				{
					tab === 'students' &&
						<Grid item xs={12}>
							<CourseStudents course={course} />
						</Grid>
				}
			</Grid>
		</div>
	)
}

export default CurriculumDesigner
