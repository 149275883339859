import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import HelpIcon from '@material-ui/icons/Help'
import { getAllStudentCourses, getStudentCoursesOfStudentEmailList } from '../data/firebase/studentCoursesFirebase'
import { getStudentsForEmailList, getAllMentors, getMyMenteeStudentList } from '../data/firebase/userFirebase'
import { getStudentEmailsFromStudentCourseList, injectStudentDataIntoStudentCourses } from '../data/utils/studentCourseUtils'
import { getUniqueStudentEmailsFromStudentList } from '../data/utils/studentUtils'
import { AuthContext } from '../data/context/AuthProvider'
import { Layout2, BaseInput } from '../components'
import { filterStudentCoursesFree, filterStudentCoursesPaid, filterStudentCoursesShoppingCart,
		filterStudentCoursesFreeRegistered, filterStudentCoursesPaidByCreditCard, filterStudentCoursesPaidByPaypal, filterStudentCoursesPaidByCash,
		filterStudentCoursesWaitingPayment } from '../data/utils/studentCourseUtils'

function StudentCourses(props) {
	const [authData] = useContext(AuthContext)
	const [allStudentCourses, setAllStudentCourses] = useState()
	const [filteredStudentCourses, setFilteredStudentCourses] = useState()
	const [filterByStatus, setFilterByStatus] = useState('all')
	// const [showMentorList, setShowMentorList] = useState(true)
	const [selectedMentor, setSelectedMentor] = useState()
	const [allMentorList, setAllMentorList] = useState([])
	const [searchText, setSearchText] = useState('')
	const [daysFromAddition, setDaysFromAddition] = useState(1)

	// const [userMode, setUserMode] = useState('mentor')

	useEffect(async () => {
		if (authData && authData.email && authData.roles && authData.roles.length > 0 && (authData.roles.some(role => ['mentor'].indexOf(role) >= 0) || authData.roles.some(role => ['owner'].indexOf(role) >= 0)) ) {
			if (authData.roles.some(role => ['mentor'].indexOf(role) >= 0) && authData.roles.some(role => ['owner'].indexOf(role) === -1)) { // Only mentor not owner
				setSelectedMentor(authData.email)

				let tmpMyMenteeStudents = await getMyMenteeStudentList(authData.email)
				let menteeStudentsEmailList = await getUniqueStudentEmailsFromStudentList(tmpMyMenteeStudents)
				let tmpMenteeStudentCourses = await getStudentCoursesOfStudentEmailList(menteeStudentsEmailList)
				setAllStudentCourses(tmpMenteeStudentCourses)
				// setUserMode('mentor')
			} else if (authData.roles.some(role => ['owner'].indexOf(role) >= 0)) { // Only owner
				// let tmpStudents = await getAllStudents(authData.email)

				let tmpAllStudentCourses = await getAllStudentCourses(authData.email, -1 * daysFromAddition) // Last 7 days
				let tmpStudentEmails = await getStudentEmailsFromStudentCourseList(tmpAllStudentCourses)
				let tmpStudents  = await getStudentsForEmailList(tmpStudentEmails, authData.email)
				tmpAllStudentCourses = await injectStudentDataIntoStudentCourses(tmpAllStudentCourses, tmpStudents)

				setAllStudentCourses(tmpAllStudentCourses)
				let tmpMentors = await getAllMentors()
				setAllMentorList(tmpMentors)
				// setUserMode('owner')
			}
		}
	}, [authData, daysFromAddition])

	useEffect(async () => {
		if (allStudentCourses && allStudentCourses.length > 0) {
			let tmpStudentCourses = await applyStatusFilter(allStudentCourses)
			// if (showMentorList && selectedMentor) {
			// 	tmpStudentCourses = await applyMentorFilter(tmpStudentCourses)
			// }
			if (searchText && searchText.length > 0) {
				tmpStudentCourses = await applySearchTextFilter(tmpStudentCourses)
			}
			setFilteredStudentCourses(tmpStudentCourses)
		}
	}, [allStudentCourses, filterByStatus, selectedMentor, allMentorList, searchText])

	const applyStatusFilter = async (studentCoursesToFilter) => {
		let tempFilteredList = []
		if (filterByStatus === 'all') {
			tempFilteredList = studentCoursesToFilter
		} else if (filterByStatus === 'free-event') {
			tempFilteredList = await filterStudentCoursesFree(studentCoursesToFilter)
		} else if (filterByStatus === 'paid') {
			tempFilteredList = await filterStudentCoursesPaid(studentCoursesToFilter)
		}  else if (filterByStatus === 'shopping-cart') {
			tempFilteredList = await filterStudentCoursesShoppingCart(studentCoursesToFilter)
		}  else if (filterByStatus === 'free-registered') {
			tempFilteredList = await filterStudentCoursesFreeRegistered(studentCoursesToFilter)
		}  else if (filterByStatus === 'paid-by-credit-card') {
			tempFilteredList = await filterStudentCoursesPaidByCreditCard(studentCoursesToFilter)
		}  else if (filterByStatus === 'paid-by-paypal') {
			tempFilteredList = await filterStudentCoursesPaidByPaypal(studentCoursesToFilter)
		}  else if (filterByStatus === 'paid-by-cash') {
			tempFilteredList = await filterStudentCoursesPaidByCash(studentCoursesToFilter)
		}  else if (filterByStatus === 'waiting-payment') {
			tempFilteredList = await filterStudentCoursesWaitingPayment(studentCoursesToFilter)
		}
		return tempFilteredList
	}

	const applySearchTextFilter = async (pStudentCourses) => {
		let tempFilteredStudentCourses = [...pStudentCourses]
		if (searchText && searchText.length > 0) {
			tempFilteredStudentCourses = tempFilteredStudentCourses.filter(_studentCourse => 
				(_studentCourse.email && _studentCourse.email.toLowerCase().indexOf(searchText) >= 0)
				|| (_studentCourse.courseId && _studentCourse.courseId.toLowerCase().indexOf(searchText) >= 0)
				|| ( _studentCourse.courseName && _studentCourse.courseName.toLowerCase().indexOf(searchText) >= 0)
				|| ( _studentCourse.status && _studentCourse.status.toLowerCase().indexOf(searchText) >= 0)
			)
		}
		return tempFilteredStudentCourses
	}

	return (
		<Layout2 withAuth={true}>
		{/* <div style={{cursor: 'pointer'}} onClick={() => { alert('updateAllStudentCourseRecords starting'); updateAllStudentCourseRecords()} } >KURSLARI GUNCELLE</div> */}
			{
				allStudentCourses ?
					<Grid container style={{marginTop: '10px'}}>
						<Grid item xs={12} sm={5}>
							<FormControl style={{width: '50%'}}>
								<InputLabel htmlFor="mentor">Filter By Status</InputLabel>
								<Select value={filterByStatus} onChange={(e) =>  { setFilterByStatus(e.target.value)} }>
									<MenuItem value="all">All Activity</MenuItem>
									<MenuItem value="free-event">Free Event Registrations</MenuItem>
									<MenuItem value="paid">All Paid Registrations</MenuItem>
									<MenuItem value="shopping-cart">In Shopping Cart</MenuItem>
									<MenuItem value="free-registered">Free Registered to Paid Course</MenuItem>
									<MenuItem value="paid-by-credit-card">Paid By Credit Card Only</MenuItem>
									<MenuItem value="paid-by-paypal">Paid By Paypal Only</MenuItem>
									<MenuItem value="paid-by-cash">Paid By Cash Only</MenuItem>
									<MenuItem value="waiting-payment">Waiting Payment</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={2}>
							<FormControl style={{width: '100%'}}>
								<InputLabel htmlFor="mentor">Kayıt Tarihinden Geçen Gün</InputLabel>
								<Select value={daysFromAddition} onChange={(e) =>  { setDaysFromAddition(e.target.value)} }>
									<MenuItem value="1">1 Gun</MenuItem>
									<MenuItem value="3">3 Gün</MenuItem>
									<MenuItem value="7">7 Gün</MenuItem>
									<MenuItem value="15">15 Gün</MenuItem>
									<MenuItem value="30">30 Gün</MenuItem>
									<MenuItem value="60">60 Gün</MenuItem>
									<MenuItem value="90">90 Gün</MenuItem>
									<MenuItem value="365">365 Gün</MenuItem>
									<MenuItem value="1000">Tüm Kayıtlar</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={false} sm={1}></Grid>
						<Grid item xs={12} sm={4}>
							<BaseInput value={searchText} onChange={(newValue) => setSearchText(newValue.toLowerCase())} width="calc(100% - 80px)" /> <Tooltip title="Search courses by; ..."><HelpIcon /></Tooltip>
						</Grid>
						<Grid item xs={12}>
							<div style={{marginTop: '20px'}}>								
								{
									filteredStudentCourses && filteredStudentCourses.map((_studentCourse, index) => (										
										<div style={{margin: '10px', border: 'solid 1px gainsboro', textAlign: 'left', padding: '5px', borderRadius: '4px'}} key={index}>
											<Grid container>
												<Grid item xs={12}>
													<div style={{fontWeight: 'bold', float: 'left'}}>
														<span style={{color: 'red'}}>
															<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2FstudentCourses~2F${_studentCourse.id}`} target="_blank" rel="noopener noreferrer" style={{}}>
																{moment(_studentCourse.additionDate).format('YYYY-MM-DD HH:mm')}
															</a>
														</span>
														<span style={{marginLeft: '20px'}}>
															<a href={`/courses/${_studentCourse.courseId}/meta/intro`} target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>
																{_studentCourse.courseName}
															</a>
															&nbsp;&nbsp;
															<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fcourses~2F${_studentCourse.courseId}`} target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>
																Fire
															</a>
														</span>
													</div>
													<div style={{fontWeight: 'bold', float: 'right'}}>
														<a href={`/student/${_studentCourse.email}`} target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>
															{_studentCourse.studentData.name}
														</a>
													</div>
													<div style={{clear: 'both'}}></div>
													<div style={{float: 'left'}}>
														<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fusers~2F${_studentCourse.email}`}  target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>
															{_studentCourse.email}
														</a>
													</div>
													<div style={{float: 'right'}}>
														{_studentCourse.status} - <b style={{color: 'red'}}>{_studentCourse.listingPrice.totalPrice} {_studentCourse.listingPrice.currency}</b>
													</div>
												</Grid>
											</Grid>
										</div>
									))
								}
							</div>
						</Grid>
					</Grid>
				:
					<div></div>
			}
		</Layout2>
	)
}

export default StudentCourses
