import React from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import InsertLinkIcon from '@mui/icons-material/InsertLink'

const PreviewURLObj = styled.div`display: flex; font-size: 15px; position: relative; border: 1px solid rgb(196, 207, 214); width: 100%; max-width: 500px; border-radius: 10px;  overflow: hidden; outline-style: none;  
	animation: fadeIn 0.35s ease-in forwards; margin: 0 0 10px 0;`
const ImgArea = styled.div`width: 25%; padding-top: 25%; position: relative; overflow: hidden; min-width: 84px;`
const PreviewImg = styled.img`transform: translate(-50%, -50%); left: 50%; top: 50%; height: 100%; display: block; position: absolute;`
const ContentArea = styled.div`padding: 10px; flex: 1; display: flex; flex-direction: column; text-align: left; justify-content: center;`

const Title = styled.div`font-weight: bold; font-size: 16px;`
const Description = styled.div`font-size: 14px; margin-top: 5px;`
const Footer = styled(Grid)`font-size: 12px; color: #3b6af7; margin-top: 5px;`


function UrlPreview(props) {
	return (
		<a href={props.urlMetaData.url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'black', marginTop: '10px'}}>
			<PreviewURLObj>
				<ImgArea>
					<PreviewImg alt={props.urlMetaData.title} src={props.urlMetaData.image} />
				</ImgArea>
				<ContentArea>
					<Title>{props.urlMetaData.title}</Title>
					<Description>{props.urlMetaData.description}</Description>
					<Footer container>
						<Grid item xs={1}>
							<InsertLinkIcon />
						</Grid>
						<Grid item xs={11}>
							{props.urlMetaData.url}
						</Grid>
					</Footer>
					<div style={{clear: 'both'}}></div>
				</ContentArea>
			</PreviewURLObj>
		</a>
	)
}

export { UrlPreview }
