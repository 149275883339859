// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const getPostsForPage = async (pageId) => {
	let firebaseObj = await getFirebaseObj('getPostsForPage')
	let firestore = firebaseObj.firestore

	let tempPosts = []
	let sPosts = await firestore.collection('posts').where('pageId', '==', pageId).get()
	sPosts.docs.map(doc => {
		tempPosts.push(doc.data())
		return null
	})

	tempPosts.sort((a, b) => (a.createDate.seconds > b.createDate.seconds) ? -1 : (a.createDate.seconds < b.createDate.seconds) ? 1 : 0 )
	return (tempPosts)
}

const addPost = async (newPostData) => {
	let firebaseObj = await getFirebaseObj('addPost')
	let firestore = firebaseObj.firestore

	let newDocRef = await firestore.collection('posts').doc()
	const newDocId = newDocRef.id

	const newPost = {...newPostData}
	newPost.id = newDocId

	newDocRef.set(newPost)

	return newPost
}

const deletePost = async (postId) => {
	let firebaseObj = await getFirebaseObj('deletePost')
	let firestore = firebaseObj.firestore

	let deleteResponse = await firestore.collection('posts').doc(postId).delete()
	return deleteResponse
}

export { getPostsForPage, addPost, deletePost }
