import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { StaffDetails } from './StaffDetails'
import { AuthContext } from '../../data/context/AuthProvider'

const BlockTitle = styled.div`font-size: 16px; font-weight: bold; padding-left: 10px; margin-top: 10px; border-bottom: solid 1px black;`
const StaffListItem = styled(Grid)`padding-left: 15px; margin-top: 5px; cursor: pointer; border-bottom: solid 1px gainsboro;
	:hover {
		background-color: gainsboro;
	}
`

function StaffList(props) {
	const [authData] = useContext(AuthContext)
	const [selectedStaff ,setSelectedStaff] = useState()

	useEffect(() => {
		if (props.staffList && props.staffList.length > 0) {
			setSelectedStaff(props.staffList[0])
		} else {
			setSelectedStaff(null)
		}		
	}, [props.staffList])
	
	if (props.staffList && props.staffList.length > 0 && authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 )) {
		return (
			<Grid container style={{textAlign: 'left'}}>
				<Grid item xs={6} sm={3}>
					<Grid container>
						<Grid item xs={12}>
							<BlockTitle>
								OGRETMENLER ({(props.staffList && props.staffList.length > 0 ) ? <span>{props.staffList.length}</span> : <span>0</span>})
							</BlockTitle>
						</Grid>
						<Grid item xs={12}>
							<Grid container style={{height: 'calc(100vh - 160px)', borderRight: 'solid 1px gainsboro', overflow: 'auto'}}>
								{
									props.staffList.map((staff, index) => 
										<StaffListItem item xs={12} key={index} onClick={() => setSelectedStaff(staff) } 
											style={{
												backgroundColor: (selectedStaff === staff ? 'navy' : ''),
												color: (selectedStaff === staff ? '#ffffff' : '#000000')
											}}
										>
											{staff.name ? <span>{staff.name} <span style={{fontSize: '10px'}}>({staff.email})</span></span> : staff.email }
											<span> ({ (staff.staffStats && staff.staffStats.content && staff.staffStats.content.activeStats && staff.staffStats.content.activeStats.courses) ? staff.staffStats.content.activeStats.courses.all : '0'  } Courses)</span>
										</StaffListItem>
									)
								}
							</Grid>
						</Grid>
					</Grid>

				</Grid>
				<Grid item xs={6} sm={9} style={{padding: '10px'}}>
					{
						selectedStaff &&
							<Grid container>
								<Grid item xs={12}>
									<StaffDetails email={selectedStaff.email} />
								</Grid>
							</Grid>
					}
				</Grid>
			</Grid>
		)
	} else {
		return (
			<span>Listede hiç öğretmen yok</span>
		)
	}
}

export default StaffList
