import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const ToggleMain = styled.div`padding: 2px; border-radius: 4px; margin-left: 2px; margin-right: 2px;
	width: ${(props) => props.width ? props.width : 'calc(100% - 4px)'};
	height: ${(props) => props.height ? props.height + 'px' : '30px'};
	float: ${(props) => props.float ? props.float : ''};
	// -webkit-box-shadow: inset 0 0 3px rgba(30,30,30,0.25);
	border: solid 1px gainsboro;
	@media (max-width: 767px) { 
		padding: 2px;
		width: ${(props) => props.mWidth ? props.mWidth : ''};
	}
`
const EmptyOption = styled.div`color: #eeeeee; text-align: center; float: left; cursor: pointer; color: black; border-radius: 4px; margin-right: 2px; margin-left: 2px;
	height: ${(props) => props.height ? parseInt(props.height)-6 + 'px' : '24px'};
	padding: ${(props) => props.padding ? props.padding : '0px'};
	:hover {
		background-color: #888888;
		color: white;
	}
`
const SelectedOption = styled(EmptyOption)`border-radius: 4px; color: #001a4d; font-weight: bold; margin-right: 2px; margin-left: 2px;
	background-color: #99bbff;
	height: ${(props) => props.height ? parseInt(props.height)-6 + 'px' : '24px'};
	padding: ${(props) => props.padding ? props.padding : '0px'};
	:hover {
		background-color: #0542B9;
	}
`
const OptionText = styled.div`
	margin-top: 3px;
	font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};
	@media (max-width: 767px) { 
		font-size: ${(props) => props.mFontSize ? props.mFontSize : '12px'};
	}
`

function Toggle(props) {
	const [toggleItems, setToogleItems] = useState([])
	const options = props.options
	let itemWidthPercent = `calc(${Math.round(100/options.length)}% - 10px)`

	useEffect(() => {
		let selectedOption = props.value ? props.value : options[0].id
		let tempToggleItems = []
		options.map(option => {
			option.id === selectedOption ?
			tempToggleItems.push(
						<SelectedOption style={{width: itemWidthPercent }} id={option.id} key={option.id} status="selected" padding={props.padding} height={props.height}>
							<OptionText fontSize={props.fontSize ? props.fontSize : "14px"}>{option.label}</OptionText>
						</SelectedOption>
					)
				:
				tempToggleItems.push(
						<EmptyOption style={{width: itemWidthPercent }} id={option.id} key={option.id} status="notSelected" onClick={() => { if(props.onChange) { props.onChange(option.id) } } } padding={props.padding} height={props.height}>
							<OptionText fontSize={props.fontSize ? props.fontSize : "12px"}>{option.label}</OptionText>
						</EmptyOption>
					)

			return null
		})
		setToogleItems(tempToggleItems)
	}, [props.value, props.options])

	return (
		<ToggleMain width={props.width} mWidth={props.mWidth} height={props.height} float={props.float}>
			{toggleItems}
		</ToggleMain>
	)
}

export { Toggle }
