import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import Intro from './Intro'
import MetaList from './MetaList'
import Messages from './Messages'
import Users from './Users'


const LeftMenu = styled.div`margin-top: 20px; `
const MenuItem = styled.div`font-size: 16px; text-align: left; min-height: 28px;  margin: 10px; padding: 10px; border-radius: 6px; cursor: pointer;
	:hover { background-color: gainsboro;  }
`

let changeFromInside = false
function MetaDataManager(props) {
	let history = useHistory()

	const [menu, setMenu] = useState((props.urlParams && props.urlParams.param3) ? props.urlParams.param3 : 'intro')
	
	useEffect(() => {
		if (props.urlParams && props.urlParams.param3) {
			setMenu(props.urlParams.param3)
		} else {
			history.push(`/curriculum-designer/${props.curriculum.id}/meta/intro`)
			setMenu('intro')
		}
	}, [])

	useEffect(() => {
		if (!props.urlParams.param3) {
			history.push(`/curriculum-designer/${props.curriculum.id}/meta/intro`)
			setMenu('intro')
		}
	}, [props.urlParams])

	useEffect(() => {
		if (changeFromInside) {
			history.push(`/curriculum-designer/${props.curriculum.id}/meta/${menu}`)
		}
	}, [menu])

	return (
		<Grid container>
			<Grid item xs={12} md={2} style={{ height: 'calc(100vh - 150px)', overflow: 'auto', borderRight: 'solid 1px gainsboro'}}>
				<LeftMenu>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('intro')}} style={{ 'backgroundColor': ( menu === 'intro' ? 'gainsboro' : 'white') }}>Tanıtım Sayfası Bilgileri</MenuItem>

					<MenuItem onClick={() => { changeFromInside = true; setMenu('goals')}} style={{ 'backgroundColor': ( menu === 'goals' ? 'gainsboro' : 'white') }}>Neler Öğrenilecek?</MenuItem>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('requirements')}} style={{ 'backgroundColor': ( menu === 'requirements' ? 'gainsboro' : 'white') }}>Gereksinimler</MenuItem>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('studentGroup')}} style={{ 'backgroundColor': ( menu === 'studentGroup' ? 'gainsboro' : 'white') }}>Hedef Öğrenciler</MenuItem>
					
					<MenuItem onClick={() => { changeFromInside = true; setMenu('messages')}} style={{ 'backgroundColor': ( menu === 'messages' ? 'gainsboro' : 'white') }}>Karşılama ve Tebrik Mesajları</MenuItem>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('users')}} style={{ 'backgroundColor': ( menu === 'users' ? 'gainsboro' : 'white') }}>Kullanıcılar</MenuItem>
					{/* <MenuItem onClick={() => { changeFromInside = true; setMenu('pricing')}} style={{ 'backgroundColor': ( menu === 'pricing' ? 'gainsboro' : 'white') }}>Fiyatlandırma</MenuItem> */}
				</LeftMenu>
			</Grid>
			<Grid item xs={12} md={1}></Grid>
			<Grid item xs={12} md={8}>
				{ menu && menu === 'intro' && 
					<Intro introData={props.curriculum.meta && props.curriculum.meta.intro ? props.curriculum.meta.intro : {}} curriculumId={props.curriculum.id} editable={props.editable}
						onUpdate={(introData) => props.onUpdateMeta({...props.curriculum.meta, intro: introData})}
					/> 
				}
				{ menu && menu === 'goals' && 
					<MetaList itemType="goals" editable={props.editable}
						items={(props.curriculum.meta && props.curriculum.meta.goals) ? props.curriculum.meta.goals : ['']}
						onUpdateList={(listType, listItems) => {props.onUpdateMeta({...props.curriculum.meta, [listType]: listItems})}} 
						text1="Öğrenciler, kursunuzda ne öğrenecek?"
					/> 
				}
				{ menu && menu === 'requirements' &&
					<MetaList itemType="requirements" editable={props.editable}
						items={(props.curriculum.meta && props.curriculum.meta.requirements) ? props.curriculum.meta.requirements : ['']}
						onUpdateList={(listType, listItems) => {props.onUpdateMeta({...props.curriculum.meta, [listType]: listItems})}} 
						text1="Kurs gereksinimleri veya ön koşulları var mı?"
					/> 
				}
				{ menu && menu === 'studentGroup' &&
					<MetaList itemType="studentGroup" editable={props.editable}
						items={(props.curriculum.meta && props.curriculum.meta.studentGroup) ? props.curriculum.meta.studentGroup : ['']}
						onUpdateList={(listType, listItems) => {props.onUpdateMeta({...props.curriculum.meta, [listType]: listItems})}} 
						text1="Hedef öğrencileriniz kimlerdir?"
					/> 
				}
				{ menu && menu === 'messages' && 
					<Messages editable={props.editable}
						messagesData={props.curriculum.meta && props.curriculum.meta.messages ? props.curriculum.meta.messages : {}}
						onUpdate={(messagesData) => props.onUpdateMeta({...props.curriculum.meta, messages: messagesData})}
					/>
				}
				{ menu && menu === 'users' && 
					<Users
						owner={props.curriculum.owner}
						usersData={props.curriculum.meta && props.curriculum.meta.users ? props.curriculum.meta.users : {}}
						onUpdate={(usersData) => props.onUpdateMeta({...props.curriculum.meta, users: usersData})}
					/>
				}
				{/* { menu && menu === 'pricing' &&
					<Pricing
						pricingData={props.curriculum.meta && props.curriculum.meta.pricing ?  props.curriculum.meta.pricing : {}}
						onUpdate={(pricingData) => props.onUpdateMeta({...props.curriculum.meta, pricing: pricingData})}
					/> 
				} */}
			</Grid>
			<Grid item xs={12} md={1}></Grid>
		</Grid>
	)
}

export default MetaDataManager
