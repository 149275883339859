import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
// import ReactPlayer from 'react-player/youtube'
import { getGeoInfo } from '../../../utils'
import { ConnectedUsers } from '../shared/ConnectedUsers'
import { H1, EmptySpace } from '../../../components'
import { LoginComponent } from '../../../PageBlocks/user/LoginComponent'
import { GuestUserName } from '../../user/GuestUserName'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
// import VolumeUpIcon from '@material-ui/icons/VolumeUp'
// import VolumeOffIcon from '@material-ui/icons/VolumeOff'

const PlayPage = styled.div`margin: 10px; width: 98%; margin-left: auto; margin-right: auto; text-align: center;`
const UserWelcomeMessage = styled.div`font-size: 28px; font-weight: bold; margin: 10px;`
const PlayerName = styled.span`color: red; font-size: 48px; line-height: 48px;`

const UserInfo = styled.div`text-align: center; height: 90vh; line-height: 40vh;`
const WaitingMessage = styled.div`width: 100%; text-align: center; font-size: 18px;`

// const backgroundMusics = [
//     'https://www.youtube.com/watch?v=Q4Jq_ELdniI',
//     'https://www.youtube.com/watch?v=xy_NKN75Jhw',
//     'https://www.youtube.com/watch?v=x2NzoLMWAwQ',
//     'https://www.youtube.com/watch?v=zyPuad8VplU',
//     'https://www.youtube.com/watch?v=B4EGnHJFawY',
//     'https://www.youtube.com/watch?v=a4w3yP7qhOc',
//     'https://www.youtube.com/watch?v=bfHH6YImnZs',
//     'https://www.youtube.com/watch?v=nLv74c-0mFI&t=30',
//     'https://www.youtube.com/watch?v=zLDOQdehzv8&t=60',
//     'https://www.youtube.com/watch?v=x_ai5jsDMTM&t=60',
//     'https://www.youtube.com/watch?v=a4cD0Klp5t0&t=60',
//     'https://www.youtube.com/watch?v=84UkG809LUU&t=30',
//     'https://www.youtube.com/watch?v=fZmhUZK1_b0',
// ]

// const musicIndex = Math.floor(Math.random() * backgroundMusics.length)

function PhaseConnection(props) {
    const [name, setName] = useState('')
    // const [muted, setMuted] = useState(false)
    // const [playing, setPlaying] = useState(true)
    // const [volume, setVolume] = useState(0.2)
    const [playerName, setPlayerName] = useState()
    // const [backgroundMusic, setBackgroundMusic] = useState('')

    // useEffect(() => {
    //     setBackgroundMusic(backgroundMusics[musicIndex])
    // }, [])
    
    const createPlayer = async (userName, email) => {
        if (userName.length > 2) {
            let _location = await getGeoInfo()            
            let player = {
                name: userName.trim(),
                score: 0,
                email: email,
                location: _location ? _location.country_name + ' - ' + _location.region + ' - ' + _location.city : 'unknown'
            }
            props.addPlayer(player)
            
        } else {
            alert('Belirlediginiz isim en az 3 karakter olmalıdır.')
        }
    }
    
    useEffect(() => {
        let players = props.game.players
        if (players && props.playerKey && props.playerKey.length > 0 && players[props.playerKey]) {
            setPlayerName(players[props.playerKey].name)
        }
    }, [props.playerKey])

    // useEffect(() => {
    //     if (playerName && playerName.length > 0) {
    //         setPlaying(true)
    //     } else {
    //         setPlaying(false)
    //     }
    // }, [playerName])
    
    if (props.game.quiz.remoteMode) {
        return (
            <PlayPage>
                {
                    props.game && playerName &&
                        <div>
                            <UserWelcomeMessage>
                                Hoşgeldin <PlayerName>{props.player.name}{props.player.email && props.player.email.length > 0 ? <VerifiedUserIcon style={{color: '#3dc706'}} /> : ''}</PlayerName>
                            </UserWelcomeMessage>

                            <ConnectedUsers game={props.game} animate={true} height="calc(100vh - 240px)" />
                            
                            <WaitingMessage>
                                {/* <VolumeUpIcon style={{display: muted ? 'none' : 'block', cursor: 'pointer', color: 'green', float: 'left'}} onClick={() => { setMuted(!muted); setVolume(0.2) }} />
                                <VolumeOffIcon style={{display: muted ? 'block' : 'none', cursor: 'pointer', color: 'red', float: 'left'}} onClick={() => { setMuted(!muted); setVolume(0.2) }} /> */}
                                Dersin başlaması bekleniyor
                            </WaitingMessage>
                            
                            {/* <div style={{display: 'none'}} >
                                <ReactPlayer url={`${backgroundMusic}&origin=${document.location.origin}`} playing={playing} loop={true} volume={volume} muted={muted} controls={false}
                                    onError={(e) => { console.log('onError ', e) } }
                                />
                            </div> */}
                            
                        </div>
                }
                {
                    !playerName &&
                        <div>
                            <LoginComponent onLogin={(user) => { createPlayer(user.name, user.email)} } showLogoPart={false}></LoginComponent>
                            
                            <EmptySpace marginTop='20px' />
                            <div>veya</div>
                            <EmptySpace marginTop='20px' />

                            <GuestUserName createPlayer={() => createPlayer(name, '')} onUpdateName={(n) => setName(n)} name={name} />
                        </div>
                }
            </PlayPage>
        )
    } else {
        return (
            <PlayPage>
                {
                    playerName &&
                        <UserInfo>
                            <H1>Merhaba <span className="dynamic-text">{props.player.name}</span>!</H1>
                            <Typography variant="subtitle1"> Ana ekranı takip edin. Adınız ekranda görünüyor olmalı.</Typography>
                        </UserInfo>
                }
                {
                    !playerName &&
                        <GuestUserName createPlayer={(n) => createPlayer()} onUpdateName={(n) => setName(n)} name={name} />
                }
            </PlayPage>
        )
    }
}

export default PhaseConnection
