import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import ClearIcon from '@material-ui/icons/Clear'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { BaseInput } from '../../components'
import { searchLessonTemplates } from '../../data/firebase/lessonTemplateFirebase'
import { AuthContext } from '../../data/context/AuthProvider'

const Lesson = styled.div`width: calc(100% - 10px); margin: 5px; margin-left: 15px; border-radius: 6px; text-align: left; padding-left: 10px; padding-top: 10px; min-height: 60px;`
const InSectionNumber = styled.span`border-radius: 100px; padding: 3px; width: 40px; margin-right: 5px; background-color: gray; color: white; font-size: 14px;`
const LessonTitle = styled.span`font-size: 18px; font-weight: bold;`
const SearchResultsArea = styled.div`border-radius: 6px; padding: 10px; position: absolute; background-color: white;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);`
const SearchResultItem = styled.div`cursor: pointer; padding: 4px; border: solid 1px gainsboro; min-height: 40px; border-radius: 6px;`
const LessonTemplateData = styled.div`margin-left: 10px; margin-top: 3px; cursor: pointer; display: flex; align-items: center;`
const LessonTemplateCoverSmall = styled.img`border-radius: 6px; max-width: 50px; max-height: 40px;`


let changeFromInside = false

function CurriculumLesson(props) {
	const [authData] = useContext(AuthContext)

	const [title, setTitle] = useState(props.lesson.title)
	const [searchText, setSearchText] = useState('')
	const [searchResults, setSearchResults] = useState([])
	const [selectedLessonTemplate, setSelectedLessonTemplate] = useState()

	useEffect(() => {
		changeFromInside = false
		setTitle(props.lesson.title)
		if (props.usedLessonTemplates) {
			const tempLessonTemplates = props.usedLessonTemplates.filter(lessonTemplate => lessonTemplate.key === props.lesson.lessonTemplateKey)
			if (tempLessonTemplates && tempLessonTemplates.length === 1) {
				setSelectedLessonTemplate(tempLessonTemplates[0])
			}
		}
	}, [props])

	useEffect(() => {
		if (changeFromInside) {
			updateLessonData()
		}
	}, [selectedLessonTemplate])
	
	const updateLessonData = () => {
		if (props.onUpdate && props.editable) { 
			let tempLesson = {...props.lesson}
			if (title) { tempLesson.title = title } else { tempLesson.title = '' }
			if (selectedLessonTemplate) { tempLesson.lessonTemplateKey = selectedLessonTemplate.key } else { tempLesson.lessonTemplateKey = '' }
			props.onUpdate(tempLesson)
		}
	}

	useEffect(async () => {
		if (searchText && searchText.length > 2) {
			let resultLessonTemplates = await searchLessonTemplates(searchText, authData.email)
			setSearchResults(resultLessonTemplates)			
		} else {
			setSearchResults([])
		}
	}, [searchText])

	return (
		<Lesson  style={{cursor: ( props.editable ? 'move' : '')}}>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<LessonTitle>
						{
							props.index < 9 ?
								<InSectionNumber>&nbsp;{props.index + 1}&nbsp;</InSectionNumber> 
							:
								<InSectionNumber>{props.index + 1}</InSectionNumber> 
						}
						{
							props.editable ?
								<BaseInput value={title} onChange={(newValue) => setTitle(newValue) } onBlur={() => { if (props.onUpdate) { updateLessonData() }} } WebkitBoxShadow=" " border="0px" borderBottom="solid 1px gainsboro" borderLeft="solid 1px gainsboro" fontSize="16px" width="calc(100% - 35px)" />
							:
								<span>{title}</span>
						}
						
					</LessonTitle>
				</Grid>
				<Grid item xs={11} sm={5}>
					{
						props.editable ?	
							<div>
								{
									selectedLessonTemplate ?
										<LessonTemplateData>
											{
												selectedLessonTemplate.metaData && selectedLessonTemplate.metaData.coverImage && selectedLessonTemplate.metaData.coverImage.url &&
													<LessonTemplateCoverSmall src={selectedLessonTemplate.metaData.coverImage.url} />
											}
											&nbsp;
											{/* <a href={`/lesson-designer/${selectedLessonTemplate.key}`} target="_blank" rel="noopener noreferrer">{selectedLessonTemplate.title}&nbsp;</a> */}
											<div>
												<div>
													<a href={`/lesson-designer/${selectedLessonTemplate.key}`} target="_blank" rel="noopener noreferrer">
														{selectedLessonTemplate.title}&nbsp;
													</a>
												</div>
												<div style={{color: (selectedLessonTemplate.stats.allSlidesCount < 10 ? 'red' : ''), fontSize: '11px'}}>
													{
														selectedLessonTemplate.stats.allSlidesCount < 10 && 
															<Tooltip title="Yetersiz içerik">
																<ErrorOutlineIcon style={{color: 'orange'}} />
															</Tooltip>
													}
													{selectedLessonTemplate.stats.allSlidesCount} Slide => {selectedLessonTemplate.stats.qType.imageCount} resim, {selectedLessonTemplate.stats.qType.textCount} text, {selectedLessonTemplate.stats.qType.videoCount} video
												</div>
												<div style={{fontSize: '11px'}}>
													{selectedLessonTemplate.stats.allSlidesCount} Slide => {selectedLessonTemplate.stats.cType.contentCount} içerik, {selectedLessonTemplate.stats.cType.quizCount} quiz, {selectedLessonTemplate.stats.cType.pollCount} anket, {selectedLessonTemplate.stats.cType.formCount} form
												</div>
											</div>
											<ClearIcon onClick={() => {changeFromInside = true; setSelectedLessonTemplate()}} style={{fontSize: '10px', color: 'red'}} />
										</LessonTemplateData>
									:
										<div>
											<BaseInput value={searchText} onChange={(newValue) => { setSearchText(newValue)}} WebkitBoxShadow=" " border="0px" marginLeft="10px" borderBottom="solid 1px rgba(180, 60, 60, 0.65)" fontSize="16px" borderLeft="solid 1px rgba(180, 60, 60, 0.65)" />
											{
												searchResults && searchResults.length > 0 && (
													<SearchResultsArea>
														{
															searchResults.map((lessonTemplate, index) => (
																<SearchResultItem key={index} onClick={() => { changeFromInside = true; setSelectedLessonTemplate(lessonTemplate)} }>
																	{
																		lessonTemplate.metaData && lessonTemplate.metaData.coverImage && lessonTemplate.metaData.coverImage.url &&
																			<LessonTemplateCoverSmall src={lessonTemplate.metaData.coverImage.url} />
																	}
																	&nbsp;
																	{lessonTemplate.title}
																</SearchResultItem>
															))
														}
													</SearchResultsArea>
												)
											}
										</div>
								}
							</div>
						:
							<div>
								<LessonTemplateData>
									{
										props.lesson.templateMetaData && props.lesson.templateMetaData.coverImage && props.lesson.templateMetaData.coverImage.url &&
											<LessonTemplateCoverSmall src={props.lesson.templateMetaData.coverImage.url} />
									}
									&nbsp;
									<a href={`/lesson-designer/${props.lesson.lessonTemplateKey}`} target="_blank" rel="noopener noreferrer">{props.lesson.title}&nbsp;</a>
								</LessonTemplateData>
							</div>
					}
				</Grid>
				<Grid item xs={1} style={{textAlign: 'right'}}>
					{
						props.editable &&
							<ClearIcon onClick={() => {if (props.onDeleteLesson) { changeFromInside = true; props.onDeleteLesson()}} } style={{fontSize: '14px', color: 'red', cursor: 'pointer'}} />
					}
				</Grid>
			</Grid>
		</Lesson>
	)
}

export default CurriculumLesson
