import moment from 'moment'
// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'
import { getCoursesByCourseIdList } from './courseFirebase'

const getAllStudentCourses = async (email, days) => {
	let firebaseObj = await getFirebaseObj('getAllStudentCourses')
	let firestore = firebaseObj.firestore

	let allStudentCourses = []
	if (email && email.length > 0 && email === 'yesilokulcom@gmail.com') {
		let sStudentCourses
		if (days) {
			sStudentCourses =  await firestore.collection('studentCourses').where('additionDate', '>', new Date(moment().add(days, 'days').toDate()).getTime() ).orderBy('additionDate', 'desc').get()
		} else {
			sStudentCourses = await firestore.collection('studentCourses').orderBy('additionDate', 'desc').get()
		}
		sStudentCourses.docs.map(doc => {
			allStudentCourses.push(doc.data())			
			return null
		})
	}

	if (allStudentCourses.length > 0) {
		let courseIds = []
		for (let i = 0; i < allStudentCourses.length; i++) {
			if (courseIds.indexOf(allStudentCourses[i].courseId) === -1) {
				courseIds.push(allStudentCourses[i].courseId)
			}
		}

		let courses = await getCoursesByCourseIdList(courseIds)
		for (let i = 0; i < allStudentCourses.length; i++) {
			let tempCourses = courses.filter(_course => _course.id === allStudentCourses[i].courseId)
			if (tempCourses && tempCourses.length > 0) {
				let tempCourseName = tempCourses[0].meta.settings.courseSpecificName ? tempCourses[0].meta.settings.courseSpecificName : (tempCourses[0].meta.intro.title ? tempCourses[0].meta.intro.title : tempCourses[0].title)
				allStudentCourses[i].courseName = tempCourseName
			}
		}
	}

	return allStudentCourses
}

const getCoursesOfStudent = async (email) => {
	let firebaseObj = await getFirebaseObj('getCoursesOfStudent')
	let firestore = firebaseObj.firestore

	let tempCourseStudents = []
	if (email && email.length > 0) {
		let sCourseStudents = await firestore.collection('studentCourses').where('email', '==', email).get()
		sCourseStudents.docs.map(doc => {
			tempCourseStudents.push(doc.data())
			return null
		})
	}
	return tempCourseStudents
}

const getStudentCoursesOfStudentEmailList = async (studentEmails) => {
	let firebaseObj = await getFirebaseObj('getStudentCoursesOfStudentEmailList')
	let firestore = firebaseObj.firestore

	let tempStudentCourses = []
	if (studentEmails && studentEmails.length > 0) {
		let tmpStudentEmails = []
		for (let i = 0; i < studentEmails.length; i++ ) {
			tmpStudentEmails.push(studentEmails[i])
			if (tmpStudentEmails.length === 10 || i === studentEmails.length -1 ) {
				if (tmpStudentEmails.length <= 10) {
					let sStudentCourses = await firestore.collection('studentCourses').where('email', 'in', tmpStudentEmails).get()
					sStudentCourses.docs.map(doc => {
						tempStudentCourses.push(doc.data())
						return null
					})
				}
				tmpStudentEmails = []
			}
		}
	}
	return tempStudentCourses
}

const updatePaymentStatusOfMyMentee = async (status, studentCourseObj) => {
	let firebaseObj = await getFirebaseObj('updatePaymentStatusOfMyMentee')
	let firestore = firebaseObj.firestore

	let newStudentCourse = {...studentCourseObj}
	newStudentCourse.status = status
	newStudentCourse.updateDate = Date.now()
	let studentCourseDocRef = await firestore.collection('studentCourses').doc(studentCourseObj.id)
	await studentCourseDocRef.set(newStudentCourse)
}

const getCourseStudentsByCourseIdList = async (courseIds) => {
	let firebaseObj = await getFirebaseObj('getCourseStudentsByCourseIdList')
	let firestore = firebaseObj.firestore

	let tempCourseStudents = []
	if (courseIds && courseIds.length > 0) {
		let sCourseStudents = await firestore.collection('studentCourses').where('courseId', 'in', courseIds).get()
		sCourseStudents.docs.map(doc => {
			tempCourseStudents.push(doc.data())
			return null
		})
	}
	return tempCourseStudents
}

export { getAllStudentCourses, getCoursesOfStudent, getStudentCoursesOfStudentEmailList, updatePaymentStatusOfMyMentee, getCourseStudentsByCourseIdList }
