import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import DehazeIcon from '@material-ui/icons/Dehaze'
import { LogoImg, Clearfix } from '../../../components'
import { AuthContext } from '../../../data/context/AuthProvider'
import { getBrandData } from '../../../utils/appUtil'

const HeaderMain = styled.div`width: 98%; border-radius: 0 0 10px 10px; margin: auto;
	-webkit-box-shadow: 6px 6px 25px 0px rgba(194,155,219,0.75); -moz-box-shadow: 6px 6px 25px 0px rgba(194,155,219,0.75); box-shadow: 6px 6px 25px 0px rgba(194,155,219,0.75);`

const MenuLine = styled.div`width: 100%; border-radius: 0 0 10px 10px;`
const LogoBlock = styled.div`float: left; width: 30%; text-align: left; margin-left: 10px; margin-top: 8px; `
const HeaderLink = styled.span`font-size: 16px; margin-left: 5px; font-weight: bold; cursor: pointer;`
const DrawerWidthElement = styled.div` padding-left: 10px; color: #ffffff; text-align: center; height: 60px; padding-top: 10px; border-radius: 10px 10px 0 0;
	background-image: ${(props) => props.isEmpty ? '' : 'linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)'}; 	
	@media (max-width: 320px) { width: 250px; }
	@media (min-width: 321px ) and (max-width: 400px ) { width: 300px; }
	@media (min-width: 401px ){ width: 350px; }
`
const DrawerContentArea = styled.div`padding: 10px; height: 100%;`
const DrawerMenuItem = styled.div`width: 100%; height: 36px; padding-left: 5px; padding-top: 8px; cursor: pointer; border-radius: 10px; color: #000000;
	&:hover { background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%); color: #ffffff; }
`
const MenuIcon = styled(DehazeIcon)`margin-top: 2px; margin-right: 10px; border: solid 1px green; color: green; border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer;`

function Header(props) {
	const [authData] = useContext(AuthContext)

	const [drawerState, setDrawerState] = useState(false)
	return (
		<HeaderMain>
			<MenuLine style={{height: ((props.headerMode === 'player' || props.headerMode === 'host') ? '35px' : '60px') }}>
				<div style={{float: 'left'}}>
					<LogoBlock>
						<Link to="/">
							<LogoImg src={`/images/${getBrandData().logoFolder}/logo.png`} float="left"
								style={{
									cursor: 'pointer',
									marginLeft: ((props.headerMode === 'player' || props.headerMode === 'host') ? '10px' : '20px'),
									maxHeight: ((props.headerMode === 'player' || props.headerMode === 'host') ? '32px' : '48px')
								}}
							/>
						</Link>
					</LogoBlock>
				</div>
				{
					(!props.game || !props.game.phase || (props.game.phase === 'setup' || props.game.phase === 'connection'  || props.game.phase === 'final_result'  || props.game.phase === 'end')) &&
					<div style={{float: 'right'}}>
						<MenuIcon onClick={() => setDrawerState(true)}  style={{fontSize: '36px'}}/>
					</div>
				}
				
			</MenuLine>
			
			<Clearfix />

			<Drawer anchor="right" open={drawerState} onClose={() => setDrawerState(false)} >
				<DrawerContentArea>
					{
						authData.email && authData.roles && authData.roles.some(role => ['teacher', 'owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/lesson-designer">
								<DrawerMenuItem>
									<HeaderLink>Derslerim</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{
						authData.email && authData.roles && authData.roles.some(role => ['teacher', 'owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/curriculum-designer">
								<DrawerMenuItem>
									<HeaderLink>Müfredatlarım</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{
						authData.email && authData.roles && authData.roles.some(role => ['teacher', 'owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/courses">
								<DrawerMenuItem>
									<HeaderLink>Kurslarım</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{
						authData.email && authData.roles && authData.roles.some(role => ['teacher', 'owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/playlists">
								<DrawerMenuItem>
									<HeaderLink>Video Listelerim</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{
						authData.email && authData.roles && authData.roles.some(role => ['teacher', 'owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/pages">
								<DrawerMenuItem>
									<HeaderLink>Sayfalarım</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{/* {
						authData.email && authData.roles && authData.roles.some(role => ['mentor', 'owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/mentor">
								<DrawerMenuItem>
									<HeaderLink>Rehberlik</HeaderLink>
								</DrawerMenuItem>
							</Link>
					} */}
					{
						authData.email && authData.roles && authData.roles.some(role => ['mentor', 'owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/students">
								<DrawerMenuItem>
									<HeaderLink>Öğrenciler</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{
						authData.email && authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/staff">
								<DrawerMenuItem>
									<HeaderLink>Öğretmenler</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{
						authData.email && authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/student-courses">
								<DrawerMenuItem>
									<HeaderLink>Kurs Kayıt Hareketleri</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{
						authData.email && authData.roles && authData.roles.some(role => ['mentor', 'teacher'].indexOf(role) >= 0) && (!props.game) &&
							<Link to="/teacher-account-dashboard">
								<DrawerMenuItem>
									<HeaderLink>Hesabım</HeaderLink>
								</DrawerMenuItem>
							</Link>
					}
					{
						authData.email ?
							<div>
								<Link to="/logout">
									<DrawerMenuItem>
										<HeaderLink>Oturumu Kapat</HeaderLink>
									</DrawerMenuItem>
								</Link>
							</div>
						:
							<div>
								<Link to="/login">
									<DrawerMenuItem>
										<HeaderLink>Oturum Aç</HeaderLink>
									</DrawerMenuItem>
								</Link>
							</div>
					}
				</DrawerContentArea>
				<DrawerWidthElement isEmpty={authData.name ? false : true}>
					<div>{authData.name ? authData.name : ' '}</div>
					<div>{authData.email ? authData.email : ' '}</div>
				</DrawerWidthElement>
			</Drawer>

		</HeaderMain>
	)
}

export { Header }

