import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import {useDropzone} from 'react-dropzone'
import Resizer from 'react-image-file-resizer'
import ImageIcon from '@material-ui/icons/Image'
import FileCopyIcon from '@material-ui/icons/FileCopy'
// import { storage } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const ImageUploadContainer = styled.div`background: #dadada; display: flex; justify-content: center; align-items: center; text-align: center; border-radius: 8px; font-size: 12px; cursor: pointer;
	width: ${(props) => props.width ? props.width : '98%'};
	height: ${(props) => props.height ? props.height : 'calc(100vh - 600px)'};
	padding: ${(props) => props.padding ? props.padding : '50px'};
	margin: ${(props) => props.margin ? props.margin : '10px'};
`

function FileUpload(props) {
	const [pasteAreaName, setPasteAreaName] = useState('')

	const resizeFile = (file) => new Promise(resolve => {
		Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0, uri => { resolve(uri) }, 'base64' 	)
	})

	document.onpaste = function(pasteEvent) {
		if (props.fileType === 'image' && pasteAreaName === props.pasteItemId){
			let item = pasteEvent.clipboardData.items[0]	   
			if (item.type.indexOf("image") === 0) {
				var blob = item.getAsFile()
				uploadAndSaveImage(blob)
			}
		}
	}

	const onDrop = useCallback(acceptedFiles => {
		if (acceptedFiles[0] === '') {
			console.error(`not an image, the image file is a ${typeof(acceptedFiles[0])}`)
		}
		uploadAndSaveImage(acceptedFiles[0])
	}, [])

	const uploadAndSaveImage = async (fileBlob) => {
		let firebaseObj = await getFirebaseObj('uploadAndSaveImage')
		let storage = firebaseObj.storage

		let timestamp = Date.now()
		let blobFileName = fileBlob.name ? fileBlob.name.split(' ').join('-') : ''
		let fileName =`${props.type}-${timestamp}-${blobFileName}`
		if (props.fileType === 'image') {
			const uploadTask = storage.ref(`${props.folder}/${fileName}`).put(fileBlob)
			uploadTask.on("state_changed", null, console.error, () => {
				storage
					.ref(props.folder)
					.child(fileName)
					.getDownloadURL()
					.then(async (url_uploaded) => {
						let thumbFileName =`${props.type}-${timestamp}-thumb-${blobFileName}`
						let thumbnailImage = await resizeFile(fileBlob)
						const uploadTask = storage.ref(`${props.folder}/${thumbFileName}`).putString(thumbnailImage, 'data_url')
						uploadTask.on("state_changed", null, console.error, () => {
							storage
								.ref(props.folder)
								.child(thumbFileName)
								.getDownloadURL()
								.then((url_uploaded_thumb) => {
									props.onUploadComplete({
										url: url_uploaded,
										fileName: fileName,
										thumb_url: url_uploaded_thumb,
										thumb_fileName: thumbFileName,
									})
								})
							})
					})
			})
		} else {
			const uploadTask = storage.ref(`${props.folder}/${fileName}`).put(fileBlob)
			uploadTask.on("state_changed", null, console.error, () => {
				storage
					.ref(props.folder)
					.child(fileName)
					.getDownloadURL()
					.then((url_uploaded) => {
						props.onUploadComplete({
							url: url_uploaded,
							fileName: fileName
						})
					})
			})
		}
	}

	const {getRootProps, getInputProps} = useDropzone({onDrop, accept: (props.fileType === 'image' ? 'image/jpeg, image/png' : '')  })
  
	return (
		<div>
			<FileCopyIcon onClick={() => setPasteAreaName(pasteAreaName.length > 0 ? '' : props.pasteItemId)} style={{color: (pasteAreaName === props.pasteItemId ? 'red' : 'gray'), fontSize: '12px'}} title="Select to paste" />
			<ImageUploadContainer {...getRootProps({className: 'dropzone'})} height={props.height ? props.height: null} padding={props.padding ? props.padding: null} margin={props.margin ? props.margin: null} width={props.width ? props.width: null}>
				<input {...getInputProps()} />
				{
					props.containerType === 'dropzone' &&
						<p style={{fontSize: '20px'}}>Dosyayı sürükleyip bu alana bırakın veya seçmek için tıklayın</p>
						
				}
				{
					props.containerType === 'icon' &&					
						<ImageIcon />					
				}				
			</ImageUploadContainer>
		</div>
	)
}

export default FileUpload
