import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { FormElementLabel, FormElementDesc, SimpleTextListManager } from '../../../../components'

import { AuthContext } from '../../../../data/context/AuthProvider'
import { updateLessonItemPublishData } from '../../../../data/firebase/lessonItemsFirebase'

let changeFromInside = false
const ScreenBlock = styled(Grid)`text-align: left; border: solid 1px gainsboro; border-radius: 6px; padding: 5px; margin-top: 20px; padding-left: 10px;`

function LessonItemSettings(props) {
	const [authData] = useContext(AuthContext)
	const [publishStatus, setPublishStatus] = useState(props.lessonItem.publish && props.lessonItem.publish.status ? props.lessonItem.publish.status : 'draft')
	const [domainAllowance, setDomainAllowance] = useState(props.lessonItem.publish && props.lessonItem.publish.domainAllowance ? props.lessonItem.publish.domainAllowance : 'only-selected-domains')
	const [allowedDomains, setAllowedDomains] =  useState(props.lessonItem.publish && props.lessonItem.publish.allowedDomains ? props.lessonItem.publish.allowedDomains : [])

	useEffect(() => {
		if (changeFromInside === true && authData) {
			let publishData = {
				publish: {
					status: publishStatus ? publishStatus : 'draft',
					domainAllowance: domainAllowance ? domainAllowance : 'only-selected-domains',
					allowedDomains: allowedDomains ?allowedDomains : []
				}
			}
			updateLessonItemPublishData(props.lessonItem.key, props.lessonItem.owner, authData.email, publishData)
			if (props.onSettingsChange) {
				props.onSettingsChange(publishData)
			}
		}
		changeFromInside = false
	}, [publishStatus, domainAllowance, allowedDomains])

	useEffect(() => {
		console.log('props.lessonItem: ', props.lessonItem)
		changeFromInside = false
		if (props.lessonItem && props.lessonItem.publish) {
			console.log('props.lessonItem.publish: ', props.lessonItem.publish)
			setPublishStatus(props.lessonItem.publish && props.lessonItem.publish.status ? props.lessonItem.publish.status : 'draft')
			setDomainAllowance(props.lessonItem.publish && props.lessonItem.publish.domainAllowance ? props.lessonItem.publish.domainAllowance : 'only-selected-domains')
			setAllowedDomains(props.lessonItem.publish && props.lessonItem.publish.allowedDomains ? props.lessonItem.publish.allowedDomains : [])
		}
	}, [props.lessonItem])

	return (
		<Grid container>
			<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
				<FormElementLabel>Yayınlanma Durumu</FormElementLabel>
				<RadioGroup aria-label="Publish Status" name="publishStatus" value={publishStatus} onChange={(e) => { changeFromInside = true; setPublishStatus(e.target.checked ? e.target.value : ''); } }>
					<FormControlLabel value="draft" control={<Radio />} label="Draft" />
					<FormControlLabel value="public" control={<Radio />} label="Herkese Açık" />
					<FormControlLabel value="unlisted" control={<Radio />} label="Liste Dışı" />
					<FormControlLabel value="private" control={<Radio />} label="Gizli" />
				</RadioGroup>

				{ publishStatus === 'draft' && <FormElementDesc>Çalışma modunda, hiç kimseye görüntülenmiyor</FormElementDesc> }
				{ publishStatus === 'public' && <FormElementDesc>Yayınlandı ve herkese görüntüleniyor.</FormElementDesc> }
				{ publishStatus === 'unlisted' && <FormElementDesc>Yayınlandı ancak sadece bu dersin sitedeki adresini bilenler görebilir.</FormElementDesc> }
				{ publishStatus === 'private' && <FormElementDesc>Yayınlandı ancak sadece siz ve paylaştığınız diğer kullanıcılar görebilir.</FormElementDesc> }
			</ScreenBlock>
			<Grid item xs={false} sm={2}></Grid>

			<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
				<FormElementLabel>Yayına İzin Verilen Domainler</FormElementLabel>
				<FormElementDesc>Bu dersin hangi web sitelerinde yayınlanmasını istiyorsanız, domain adreslerini ekleyiniz.</FormElementDesc>
				<RadioGroup aria-label="Allowed Domains" name="domainAllowance" value={domainAllowance} onChange={(e) => { changeFromInside = true; setDomainAllowance(e.target.checked ? e.target.value : ''); } }>
					<FormControlLabel value="all-domains" control={<Radio />} label="Tüm Web Siteleri" />
					<FormControlLabel value="only-selected-domains" control={<Radio />} label="Sadece Belirli Domainler" />
				</RadioGroup>

				{
					domainAllowance === 'only-selected-domains' &&
						<SimpleTextListManager itemList={(allowedDomains) ? allowedDomains : ['']} editable={true} onePerLine={true} showNumbers={true}
							onChange={(items) => { changeFromInside = true; setAllowedDomains(items) } }
						/>
				}
			</ScreenBlock>
		</Grid>
	)
}

export { LessonItemSettings }
