import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { StudentDetails } from '../student/StudentDetails'
import { AuthContext } from '../../data/context/AuthProvider'

const BlockTitle = styled.div`font-size: 16px; font-weight: bold; padding-left: 10px; margin-top: 10px; border-bottom: solid 1px black;`
const StudentListItem = styled(Grid)`padding-left: 15px; margin-top: 5px; cursor: pointer; border-bottom: solid 1px gainsboro;
	:hover {
		background-color: gainsboro;
	}
`

function StudentList(props) {
	const [authData] = useContext(AuthContext)
	const [selectedStudent ,setSelectedStudent] = useState()

	useEffect(() => {
		if (props.students && props.students.length > 0) {
			setSelectedStudent(props.students[0])
		} else {
			setSelectedStudent(null)
		}
	}, [props.students])
	
	if (props.students && props.students.length > 0 && authData.roles && authData.roles.some(role => ['owner', 'mentor', 'teacher'].indexOf(role) >= 0 )) {
		return (
			<Grid container style={{textAlign: 'left'}}>
				<Grid item xs={6} sm={3}>
					<Grid container>
						<Grid item xs={12}>
							<BlockTitle>
								OGRENCILER ({(props.students && props.students.length > 0 ) ? <span>{props.students.length}</span> : <span>0</span>})
							</BlockTitle>
						</Grid>
						<Grid item xs={12}>
							<Grid container style={{height: 'calc(100vh - 160px)', borderRight: 'solid 1px gainsboro', overflow: 'auto'}}>
								{
									props.students && props.students.length > 0 && props.students.map((student, index) => 
										<StudentListItem item xs={12} key={index} onClick={() => setSelectedStudent(student) } 
											style={{
												backgroundColor: (selectedStudent === student ? 'navy' : ''),
												color: (selectedStudent === student ? '#ffffff' : '#000000')
											}}
										>
											<span>{index + 1}) {student.name ? student.name : student.email} </span>
											<span style={{color: (student.studentCourses.length === (student.courseCountStats.nonFreeCourseCount + student.courseCountStats.freeCourseCount) ) ? '' : 'red' }}>
												({student.studentCourses ? student.studentCourses.length : '0'} - {student.courseCountStats.paidCourseCount} paid, {student.courseCountStats.willBePaidCourseCount} will be paid, {student.courseCountStats.freeCourseCount} free) 
											</span>
											<span style={{fontSize: '10px'}}>({student.email}) {student.location ? '(' + student.location.country_name + ')' : ''} ({moment(student.created).format('YYYY-MM-DD hh:mm a')})</span>
										</StudentListItem>
									)
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6} sm={9} style={{padding: '10px'}}>
					{
						selectedStudent &&
							<Grid container>
								<Grid item xs={12}>
									<StudentDetails email={selectedStudent.email} />
								</Grid>
							</Grid>
					}
				</Grid>
			</Grid>
		)
	} else {
		return (
			<span>Listede hiç öğrenci yok</span>
		)
	}
}

export { StudentList }
