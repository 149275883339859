import React, { useState, useEffect } from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import Input from '@material-ui/core/Input'
import { ReactSortable } from 'react-sortablejs'
import styled from 'styled-components'

const ListItem = styled.div`float: left; margin: 2px; border: solid 1px gray; border-radius: 4px; min-height: 30px; cursor: pointer;`
const ListItemNumber = styled.b`border-right: solid 1px gray; font-weight: bold; padding-right: 6px;
	-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; 
	cursor: move;
`
const ListItemText = styled.span`padding-left: 6px;
	-webkit-user-select: text; -moz-user-select: text; -ms-user-select: text; user-select: text; 
`
const RemoveListItemArea = styled.div`background-color: #c27272; width: 20px; float: right; border: solid 1px #c27272; border-radius: 4px; height: 100%; margin-left: 10px; min-height: 30px; padding-top: 5px; cursor: pointer;
	&:hover { background-color: #d10404; }
`
const NewListItemInput = styled(Input)`font-family: Nunito; margin: 2px; padding: 4px; font-size: 12px; float: left; width: 150px; heigth: 24px; border-radius: 4px; max-width: 95%; overflow: auto; text-overflow: ellipsis; border: solid 1px #ad0085;`

let changeFromInside = false
function SimpleTextListManager(props) {
	const [listItem, setListItem] = useState('')
	const [listItems, setListItems] = useState([])
	// const [textCase, setTextCase] = useState(props.textCase ? props.textCase : 'lower')

	// useEffect(() => {
	// 	setTextCase
	// }, [])

	useEffect(() => {
		if (props.itemList !== listItems) {
			changeFromInside = false
			setListItems(props.itemList)
		}
	}, [props.itemList])

	useEffect(() => {
		if (changeFromInside) {
			props.onChange(listItems)
		}		
	}, [listItems])

	const onNewListKeyPress = (event) => {
		if (event.charCode  === 13  && listItem && listItem.length > 0 && listItems.indexOf(listItem) === -1) {
			changeFromInside = true
			if (props.textCase) {
				if (props.textCase === 'upper') {
					setListItems([...listItems, listItem.toUpperCase()])
				} else if (props.textCase === 'lower') {
					setListItems([...listItems, listItem.toLowerCase()])
				} else {
					setListItems([...listItems, listItem])
				}
			} else {
				setListItems([...listItems, listItem.toLowerCase()])
			}
			setListItem('')
		}
	}

	const onNewListItemChange = (e) => {		
		setListItem(e.target.value)
	}

	const removeListItem = (i) => {
		changeFromInside = true
		setListItems(listItems.filter((_listItem, index) => index !== i))
	}

	const reOrderItems = (newOrderedList) => {
		let tempList = []
		if (listItems && listItems.length > 0 && newOrderedList && newOrderedList.length > 0) {
			for (let i = 0; i < newOrderedList.length; i++) {
				let itemText = ''
				Object.entries(newOrderedList[i]).map(item => {
					if (item && item.length === 2) {
						if (typeof(item[0]) != "boolean" && !isNaN(item[0])) {							
							itemText += item[1]
						}
					}
					return null
				})
				tempList.push(itemText)
			}

			let changed = false
			for (let i = 0; i < tempList.length; i++) {
				if (tempList[i] !== listItems[i]) {
					changed = true
				}
			}
			if (changed) {
				changeFromInside = true
				setListItems(tempList)
			}
		}
	}

	return(
		<div style={{width: '100%'}}>
		{
			(listItems && listItems.length > 0) &&
				<ReactSortable list={listItems} setList={(e) => {reOrderItems(e)}} delayOnTouchStart={true} delay={10} filter={"span"} preventOnFilter={false}>
					{
						listItems.map((_listItem, index) => 
							<ListItem key={index} style={{width: (props.onePerLine ? '100%' : '')}} >
								<div style={{float: 'left', paddingLeft: '5px', marginTop: '5px'}}>
									{
										props.showNumbers ?
											<ListItemNumber>{index + 1}</ListItemNumber>
											:
											<ListItemNumber> </ListItemNumber>
									}
									<ListItemText>{_listItem}</ListItemText>
								</div>
								{
									props.editable &&
										<RemoveListItemArea>
											<ClearIcon onClick={() => {removeListItem(index)}} style={{fontSize: '18px', color: 'white'}} />
										</RemoveListItemArea>
								}
							</ListItem>
						)
					}
				</ReactSortable>
		}
			
			{
				props.editable &&
					<NewListItemInput type="text" onKeyPress={(e) => onNewListKeyPress(e)} onChange={(e) => onNewListItemChange(e)} value={listItem} maxLength="50"
						style={{width: (props.onePerLine ? '100%' : '')}} 
					/>
			}
			<div style={{clear: 'both'}}></div>
		</div>
	)
}

export { SimpleTextListManager }
