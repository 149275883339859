import React, { useState, useEffect } from 'react'
import {unstable_batchedUpdates} from 'react-dom'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import ClearIcon from '@material-ui/icons/Clear'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import FileUpload from '../../../../data/firebase/fileUploadFirebase'
// import { storage } from '../../../../utils/firebaseSettings'
import { getFirebaseObj } from '../../../../utils/firebaseSettings'
import { SimpleTextListManager, H1, H2, HelpDesc, FormElementLabel } from '../../../../components'
import { updateLessonTemplateCoverImage, getTemplateMetaData, updateLessonTemplateTags, updateLessonTemplateSettings, updateLessonTemplateDescription,
	updateFullLessonTemplateByImport
} from '../../../../data/firebase/lessonTemplateFirebase'
import { updateGameTemplateData } from '../../../../data/firebase/live/firebaseGameCreationUtils'

let changeFromInside = false

function MetaDataForm(props) {
	const [coverImageUrl, setCoverImageUrl] = useState('')
	const [coverImageFileName, setCoverImageFileName] = useState('')
	// const [thumbCoverImageUrl, setThumbCoverImageUrl] = useState('')
	const [thumbCoverImageFileName, setThumbCoverImageFileName] = useState('')

	const [tags, setTags] = useState([])
	const [settings, setSettings] = useState({
		showResultsOnStudentScreen: 'enabled',
		showResultsOnTeacherScreen: 'enabled'
	})

	const [description, setDescription] = useState('')

	useEffect(async () => {
		let metaData = await getTemplateMetaData(props.lessonTemplate.key)
		
		changeFromInside = false
		unstable_batchedUpdates(() => {
			if (metaData && metaData.coverImage) {
				setCoverImageUrl((metaData.coverImage) ? metaData.coverImage.url : '')
				setCoverImageFileName((metaData.coverImage) ? metaData.coverImage.fileName : '')
				// setThumbCoverImageUrl((metaData.coverImage) ? metaData.coverImage.thumb_url : '')
				setThumbCoverImageFileName((metaData.coverImage) ? metaData.coverImage.thumb_fileName : '')
				setDescription(metaData.description ? metaData.description : '')
			}
		})
		if (metaData && metaData.tags) {
			setTags(metaData.tags)
		}
		
		setSettings((metaData && metaData.settings) ? metaData.settings : {
			showResultsOnStudentScreen: 'enabled',
			showResultsOnTeacherScreen: 'enabled'
		})
	}, [props.lessonTemplate])

	const deleteFile = async () => {
		let firebaseObj = await getFirebaseObj('MetaDataForm fbo_PageBlocks')
		let storage = firebaseObj.storage
	
		const fileRef = storage.ref(`/MICRO-LESSON-IMAGES/${props.lessonTemplate.key}/${coverImageFileName}`)
		const fileThumbRef = storage.ref(`/MICRO-LESSON-IMAGES/${props.lessonTemplate.key}/${thumbCoverImageFileName}`)
		fileRef.delete().then(() => {
			fileThumbRef.delete()
			updateLessonTemplateCoverImage(props.lessonTemplate.key, null)
			setCoverImageLocalState(null)
		}).catch((error) => {
			console.log('File could not be deleted. error:', error)
		})
	}

	const setCoverImageLocalState = (coverImage) => {
		setCoverImageUrl((coverImage) ? coverImage.url : '')
		setCoverImageFileName((coverImage) ? coverImage.fileName : '')
		// setThumbCoverImageUrl((coverImage) ? coverImage.thumb_url : '')
		setThumbCoverImageFileName((coverImage) ? coverImage.thumb_fileName : '')
	}

	const updateTagList = (tagList) => {
		if (changeFromInside) {
			setTags(tagList)
			updateLessonTemplateTags(props.lessonTemplate.key, tagList)
		}
	}

	const updateDescription = () => {
		if (changeFromInside) {
			updateLessonTemplateDescription(props.lessonTemplate.key, description)
		}
	}

	const exportLessonDesign = async () => {
		const fileName = props.lessonTemplate.title + '-' + moment().format('YYYY-MM-DD-hh-mm')  + '-' + props.lessonTemplate.key;
		let metaData = await getTemplateMetaData(props.lessonTemplate.key)
		const exportObject = {
			phase: props.lessonTemplate.phase,
			quiz: props.lessonTemplate.quiz,
			status: props.lessonTemplate.status,
			title: props.lessonTemplate.title + ' - ' + Date.now(),
			metaData: metaData
		}
		const json = JSON.stringify(exportObject);
		const blob = new Blob([json],{type:'application/json'});
		const href = await URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = href;
		link.download = fileName + ".json";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const importLessonDesign = async (e) => {
		const fileReader = new FileReader();
		fileReader.readAsText(e.target.files[0], "UTF-8");
		fileReader.onload = e => {
			console.log("e.target.result", JSON.parse(e.target.result))
			// setFiles(e.target.result);
			let fullLessonTemplate = JSON.parse(e.target.result)
			let lessomTemplateForLiveDB = {
				phase: fullLessonTemplate.phase,
				quiz: fullLessonTemplate.quiz,
				status: fullLessonTemplate.status,
				title: fullLessonTemplate.title
			}
			updateFullLessonTemplateByImport(props.lessonTemplate.key, fullLessonTemplate)
			updateGameTemplateData(props.lessonTemplate.key, lessomTemplateForLiveDB, () => {})
		}
	}

	useEffect(() => {
		if (changeFromInside) {
			updateLessonTemplateSettings(props.lessonTemplate.key, settings)
		}
	}, [settings])

	return (
		<Grid container style={{textAlign: 'left'}}>
			<Grid item xs={12} style={{marginBottom: '10px', borderBottom: 'solid 1px gainsboro'}}>
				<H1>BİLGİ VE AYARLAR</H1>
			</Grid>

			<Grid item xs={12} sm={6} style={{border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px'}}>
				{
					(coverImageUrl.length > 0) ?								
						<Grid container>
							<Grid item xs={12}>
								<H2>
									<span style={{marginRight: '10px'}}>
										KAPAK RESMİ
									</span>
									<HelpDesc helpText="Bu dersin tanıtımının yapıldığı ekranlarda (öğrencilerin ulaştığı web sitesi dahil) dersi tanıtmak için gösterilecek kapak resmi" />
									{/* <Tooltip title="Bu dersin tanıtımının yapıldığı ekranlarda (öğrencilerin ulaştığı web sitesi dahil) dersi tanıtmak için gösterilecek kapak resmi">
										<HelpIcon style={{color: 'gray', fontSize: '18px'}} />
									</Tooltip> */}
								</H2>
							</Grid>
							<Grid item xs={1}></Grid>
							<Grid item xs={10}>
								<img src={coverImageUrl} style={{maxHeight: '400px', maxWidth: '100%', marginBottom: '10px', borderRadius: '8px'}} alt="Cover Preview" />
							</Grid>
							<Grid item xs={1}>
								<ClearIcon color="action" onClick={() => { changeFromInside = true; deleteFile();  }} title="Kaldır" style={{float: 'right', cursor: 'pointer'}} />
							</Grid>
						</Grid>
					:
						<Grid container>
							<Grid item xs={12}>
								<H2>
									<span style={{marginRight: '10px'}}>
										KAPAK RESMİ
									</span>
									<HelpDesc helpText="Bu dersin tanıtımının yapıldığı ekranlarda (öğrencilerin ulaştığı web sitesi dahil) dersi tanıtmak için gösterilecek kapak resmi" />
								</H2>
							</Grid>
							<Grid item xs={12}>
								<FileUpload folder={`/MICRO-LESSON-IMAGES/${props.lessonTemplate.key}`} fileType="image" containerType="dropzone" gameTemplateKey={props.lessonTemplate.key} type="lesson-template-cover-image"
									height="400px" margin="auto" 
									onUploadComplete={(e) => { changeFromInside = true; setCoverImageLocalState(e); updateLessonTemplateCoverImage(props.lessonTemplate.key, { url: e.url, fileName: e.fileName, thumb_url: e.thumb_url, thumb_fileName: e.thumb_fileName} ) } }
								/>
							</Grid>
						</Grid>
				}
			</Grid>
			<Grid item xs={12} sm={6} style={{border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px'}}>
				<FormElementLabel>Ders Açıklaması</FormElementLabel>
				<ReactQuill value={description}
					onChange={(newValue) => setDescription(newValue)}
					onBlur={() => {changeFromInside = true; updateDescription(); } }
					style={{height: '180px'}}
				/>
			</Grid>

			<Grid item xs={12} style={{ height: '10px'}}></Grid>
			
			<Grid item xs={12} sm={6} style={{border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px'}}>
				<Grid container>
					<Grid item xs={12}>
						<H2>
							<span style={{marginRight: '10px'}}>
								ETİKETLER
							</span>
							<HelpDesc helpText="Bu dersi tanımlayan anahtar kelimeler. Bu etiketler aramalarda ve listelemelerde kullanılmaktadır. " />
						</H2>
					</Grid>
					<Grid item xs={12}>
						<SimpleTextListManager itemList={tags} onChange={(tagList) => {changeFromInside = true; updateTagList(tagList)} } editable={true} />
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} sm={6} style={{border: 'solid 1px gainsboro', borderRadius: '6px', padding: '10px'}}>
				<Grid container style={{textAlign: 'left'}}>
					<Grid item xs={12}>
						<H2>
							<span style={{marginRight: '10px'}}>
								AYARLAR
							</span>
							<HelpDesc helpText="Bu sunumun derste kullanımına dair genel ayarlar. Bu ayarlar tüm slaytlar için geçerlidir." />
						</H2>
					</Grid>
					<Grid item xs={12}>
						<Switch color="primary"
							checked={settings && settings.showResultsOnStudentScreen === 'disabled' ? false : true}
							style={{color: settings && settings.showResultsOnStudentScreen === 'disabled' ? 'red' : 'green'}}
							onChange={(e) => { changeFromInside = true; setSettings({...settings, showResultsOnStudentScreen: (e.target.checked ? 'enabled' : 'disabled') }) } }
						/>
						{
							settings && settings.showResultsOnStudentScreen === 'disabled' ?
								<span style={{color: 'red'}}>Sonuçları Öğrenciye Gösterme </span>
							:								
								<span style={{color: 'green'}}>Sonuçları Öğrenciye Göster </span>
						}						
						<HelpDesc helpText="Ders sırasında sorulan soruların sonuçlarını öğrenci uygulamasının ekranında göster-gizle" />
					</Grid>

					<Grid item xs={12}>
						<Switch color="primary"
							checked={settings && settings.showStudentListResultsOnStudentScreen === 'disabled' ? false : true}
							style={{color: settings && settings.showStudentListResultsOnStudentScreen === 'disabled' ? 'red' : 'green'}}
							onChange={(e) => { changeFromInside = true; setSettings({...settings, showStudentListResultsOnStudentScreen: (e.target.checked ? 'enabled' : 'disabled') }) } }
						/>
						{
							settings && settings.showStudentListResultsOnStudentScreen === 'disabled' ?
								<span style={{color: 'red'}}>Diğer Öğrenci Sonuçlarını Öğrenciye Gösterme </span>
							:								
								<span style={{color: 'green'}}>Diğer Öğrenci Sonuçlarını Öğrenciye Göster </span>
						}						
						<HelpDesc helpText="Diğer öğrencilerin sorulara verdikleri cevaplara dair soru ve genel sonuçları göster veya gizle" />
					</Grid>

					<Grid item xs={12}>
						<Switch color="primary"
							checked={settings && settings.showResultsOnTeacherScreen === 'disabled' ? false : true}
							style={{color: settings && settings.showResultsOnTeacherScreen === 'disabled' ? 'red' : 'green'}}
							onChange={(e) => { changeFromInside = true; setSettings({...settings, showResultsOnTeacherScreen: (e.target.checked ? 'enabled' : 'disabled') }) } }
						/>
						{
							settings && settings.showResultsOnTeacherScreen === 'disabled' ?
								<span style={{color: 'red'}}>Sonuçları Öğretmene Gösterme </span>
							:
								<span style={{color: 'green'}}>Sonuçları Öğretmene Göster </span>
						}
						<HelpDesc helpText="Ders sırasında sorulan soruların sonuçlarını öğretmen uygulamasının ekranında göster-gizle" />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={6} style={{padding: '20px'}}>				
				<Button variant="contained" onClick={() => exportLessonDesign()} color="primary">EXPORT</Button>
				<Button variant="contained" component="label" style={{marginLeft: '20px' }}>
					IMPORT
					<input type="file" hidden onChange={importLessonDesign} />
				</Button>
			</Grid>
		</Grid>
	)
}

export default MetaDataForm
