import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Clearfix } from '../../../components'
import ClearIcon from '@material-ui/icons/Clear'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { Stage, Layer, Image as KonvaImage, Line, 
//	Text 
} from 'react-konva'
import { updateDrawingData, clearDrawingData } from '../../../data/firebase/live/firebaseGameHostAndPlayUtils'

const QuestionImageArea = styled.div`text-align: center; width: 100%;`
const Main = styled.div`width: 100%; margin: 0 auto; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center;`
const FullScreenImage = styled.img`width: 98vw; max-height: (100vh - 30px); z-index: 10; top: 30px; left: 0px; position: absolute; align: center; margin-left: 1vw; border-radius: 6px; border: solid 2px gray;`

const ButtonsArea = styled.div`cursor: pointer; z-index: 100; float: right; width: 60px; position: absolute; left: 40vw; top: 5px;`
const ClearButton = styled(ClearIcon)` float: right;
	@media (max-width: 767px) {
		display: none;
	}
`

function QuestionImage(props) {
	const [question, setQuestion] = useState(props.question)
	const [showFullScreenImage, setShowFullScreenImage] = useState(false)

	const [img, setImg] = useState()
	const [brushColor, setBrushColor] = useState()
	const [brushRadius, setBrushRadius] = useState()

	const [stageWidth, setStageWidth] = useState()
	const [stageHeight, setStageHeight] = useState()

	const [tool] = useState('pen')
	const [lines, setLines] = useState([])
	const isDrawing = useRef(false)

	let image

	useEffect(() => {
		setQuestion(props.question)
		image = new window.Image()
		image.src = props.question.questionContent.images[0].url
		image.addEventListener('load', handleImageLoad)

		return function cleanup () {
			image.removeEventListener('load', handleImageLoad)
		}
	}, [props.question.id])

	useEffect(() => { 
		setBrushColor(props.brushColor ? props.brushColor : 'red')
	}, [props.brushColor])

	useEffect(() => {
		setBrushRadius(props.brushRadius ? props.brushRadius : 3)
	}, [props.brushRadius])

	useEffect(() => {
		updateDrawingsWithScaling()
	}, [props.question.drawing, img, stageWidth, stageHeight])

	const updateDrawingsWithScaling = () => {
		if(img && stageWidth && stageHeight) {
			if (props.question.drawing && props.question.drawing.length > 0 && props.question.drawingAreaSize) {
				let hostDrawingHeight = props.question.drawingAreaSize.height
				let hostDrawingWidth = props.question.drawingAreaSize.width
	
				let xScaleRatioFromHost = stageWidth / hostDrawingWidth
				let yScaleRatioFromHost = stageHeight / hostDrawingHeight
				let tempLines = [...props.question.drawing]
				
				for (let i = 0; i < tempLines.length; i++) {
					let tempLine = tempLines[i]
					for (let j = 0; j < tempLine.points.length; j++) {
						let point = tempLine.points[j]
						if (j % 2 === 0) {						
							point = point * xScaleRatioFromHost
						} else {
							point = point * yScaleRatioFromHost
						}
						tempLine.points[j] = point
					}
					tempLines[i] = tempLine
				}
				setLines(tempLines)
			} else if (props.question.drawing !== lines) {
				setLines([])
			}
		}
	}
	const handleImageLoad = () => {
		setImg(image)

		const originalWidth = image.width
		const originalHeight = image.height
		
		let avaiableWidth = window.innerWidth > 1000 ? (window.innerWidth - 40) :  (window.innerWidth - 5)
		let avaiableHeight = window.innerHeight * ( (props.question.qType === 'image_text' || props.gamePhase === 'show_question') ? 0.8 : 0.4)
		if (props.gamePhase === 'answer') { // Answer screen has additional answer area
			avaiableHeight = avaiableHeight * 0.6
		}

		const xScaleRatio = avaiableWidth / originalWidth
		const yScaleRatio = avaiableHeight / originalHeight
		
		const scaleRatio = Math.min(xScaleRatio, yScaleRatio)

		let imgHeight = originalHeight * scaleRatio
		let imgWidth = originalWidth * scaleRatio
		setStageHeight(imgHeight)
		setStageWidth(imgWidth)

		updateDrawingsWithScaling()
	}
	const handleMouseDown = (e) => {
		isDrawing.current = true
		const pos = e.target.getStage().getPointerPosition()
		setLines([...lines, { tool, points: [pos.x, pos.y] }])
	}
	
	const handleMouseMove = (e) => {
		if (!isDrawing.current) { // no drawing - skipping
			return
		}
		const stage = e.target.getStage()
		const point = stage.getPointerPosition()
		
		let lastLine = lines[lines.length - 1]		
		lastLine.points = lastLine.points.concat([point.x, point.y]) // add point
	
		lines.splice(lines.length - 1, 1, lastLine) // replace last
		setLines(lines.concat())
	}
	
	const handleMouseUp = () => {
		isDrawing.current = false
		updateDrawing()
	}

	const updateDrawing = () => {
		if (props.gameFunc) { // Play cannot update game
			const drawingData = { ...props.question, drawingAreaSize: {width: stageWidth, height: stageHeight}, drawing: lines }
			updateDrawingData(drawingData, props.gameKey, props.question.id)
		}
	}

	const clearDrawing = () => {
		if (props.gameFunc) { // Play cannot update game
			const drawingData = { ...props.question, drawingAreaSize: {width: stageWidth, height: stageHeight}, drawing: [] }
			clearDrawingData(drawingData, props.gameKey, props.question.id)
		}
	}
	
    return (
        <QuestionImageArea>
			{
				question && img && brushColor && brushRadius && stageWidth && stageHeight &&
					<Main>
						{
							question && showFullScreenImage ?
								<FullScreenImage src={props.question.questionContent.images[0].url} />
							:
								<Stage width={stageWidth} height={stageHeight} style={{margin: 'auto'}}
									onMouseDown={(e) => handleMouseDown(e)}
									onMousemove={(e) => handleMouseMove(e)}
									onMouseup={() => handleMouseUp()}
								>
									<Layer style={{margin: 'auto', width: stageWidth, height: stageHeight }}>
										<KonvaImage image={img} width={stageWidth} height={stageHeight}  />
									</Layer>
									<Layer>
										{
											lines && lines.length > 0 && lines.map((line, i) => (
												<Line key={i}
													points={line.points}
													stroke={brushColor ? brushColor : 'red'}
													strokeWidth={brushRadius ? brushRadius : 3 }
													tension={0.5}
													lineCap="round"
													globalCompositeOperation={
														line.tool === 'eraser' ? 'destination-out' : 'source-over'
													}
												/>
											))
										}
									</Layer>
								</Stage>
						}
						<ButtonsArea>
							<ClearButton color="action" onClick={() => { setLines([]); clearDrawing()  }} title="Temizle" />
							<FullscreenIcon onClick={() => setShowFullScreenImage(!showFullScreenImage)} title="Full screen open/close" />
						</ButtonsArea>
						<Clearfix />
					</Main>
			}
		</QuestionImageArea>
    )
}

export default QuestionImage
