import React, { useState, useEffect, useContext } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { AuthContext } from '../../../../data/context/AuthProvider'
import { getGameInstancesForTemplate, createNewGameInstance, deleteGameInstance } from '../../../../data/firebase/live/firebaseGameCreationUtils'
import { setLessonInstanceBaseData } from '../../../../data/firebase/lessonInstanceFirebase'
import { createNewSelfPacedLesson, getLessonItems, deleteLessonItem } from '../../../../data/firebase/lessonItemsFirebase'
import { DrawerContentArea, BaseInput, Toggle } from '../../../../components'
import LessonInstanceListItem from './LessonInstanceListItem'

const ButtonArea = styled.div`position: -webkit-sticky; position: sticky; top: 0px; zIndex: 10; background-color: white; text-align: center; height: 60px;`

const usageAreas = [
	{ id: 'LiveLesson', label: 'Canlı Ders' },
	{ id: 'SelfPaced', label: 'Öğrenci Görevi' },
]

function LessonInstanceList(props) {
	const [authData] = useContext(AuthContext)
	const [openNewDialog, setOpenNewDialog] = useState(false)
	const [gameInstances, setGameInstances] = useState([])
	const [newInstanceName, setNewInstanceName] = useState('')
	const [usageArea, setUsageArea] = useState({ id: 'LiveLesson', label: 'Canlı Ders' })
	const [lessonItemToDelete, setLessonItemToDelete] = useState()
	const [showDeleteLessonItemDialog, setShowDeleteLessonItemDialog] = useState(false)

	useEffect(() => {
		getGameInstances()
	}, [])

	useEffect(() => {
		if (props.selectedInstance) {
			let tmpGameInstances = [...gameInstances]
			var foundIndex = tmpGameInstances.findIndex(x => x.key === props.selectedInstance.key)
			if (foundIndex >= 0) {
				tmpGameInstances[foundIndex] = props.selectedInstance
				setGameInstances(tmpGameInstances)
			} else {
				console.log('Selected Lesson Instance could not be found at the index of all Instances : ', foundIndex)
			}
		}
	},[props.selectedInstance])

	useEffect(() => {
		console.log('gameInstances: ', gameInstances)
	}, [gameInstances])

	const getGameInstances = async () => {
        getGameInstancesForTemplate(props.game.templateKey, async (returnedGameInstanceList) => {
			let lessonItems = await getLessonItems(props.game.templateKey)
			lessonItems = lessonItems.concat(returnedGameInstanceList)
			lessonItems.sort(function(a,b) {
                if (a.created < b.created) return 1
                if (a.created > b.created) return -1
                return 0
			})
            setGameInstances(lessonItems)
        })
    }
	
	const deleteInstance = (gameInstanceKey, _lessonItem) => {
		if (_lessonItem.userMode === 'SelfPaced') {
			if (_lessonItem.userStats && _lessonItem.userStats.startedStudentCount) { // There are students already started. This will be implemented later
				
			} else {
				setLessonItemToDelete(_lessonItem)
				setShowDeleteLessonItemDialog(true)
			}
		} else {
			deleteGameInstance(gameInstanceKey, (error) => {
				if (error) {
					console.log('Lesson Instance Delete Error: ', error)
				} else {
					// getGameInstances()
					let tmpGameInstances = [...gameInstances]
					var foundIndex = tmpGameInstances.findIndex(x => x.key === gameInstanceKey)
					if (foundIndex >= 0) {
						tmpGameInstances.splice(foundIndex, 1);
						setGameInstances(tmpGameInstances)
					} else {
						console.log('Selected Lesson Instance could not be found at the index of all Instances : ', foundIndex)
					}
				}
			})
		}
    }

	const createGameInstance = async (_gameTemplate) => {
		if (usageArea === 'SelfPaced') {
			let newLessonItem = await createNewSelfPacedLesson(_gameTemplate, newInstanceName) // returns lesson item if necessary
			let tmpInstances = [...gameInstances]
			tmpInstances.push(newLessonItem)
			setGameInstances(tmpInstances)

			setNewInstanceName('')
			setOpenNewDialog(false)
		} else if (usageArea === 'LiveLesson') {
			createNewGameInstance(_gameTemplate.templateKey, newInstanceName, async (error, gameId, instanceKey) => {
				if (error) {
					const snack = { variant: 'error', message: 'Unexpected internal error' }
					props.showSnackbar(snack)
				} else {
					_gameTemplate.gameId = gameId
					_gameTemplate.key = instanceKey
					_gameTemplate.title = newInstanceName
					await setLessonInstanceBaseData(_gameTemplate)
					
					setNewInstanceName('')					
					setOpenNewDialog(false)

					let tmpInstances = [...gameInstances]
					tmpInstances.push(_gameTemplate)
					setGameInstances(tmpInstances)
				}
			})
		}
		setUsageArea({ id: 'LiveLesson', label: 'Canlı Ders' })
    }

    return (
		<div>
			<DrawerContentArea style={{height: isMobile ? 'calc(100vh - 70px)': 'calc(100vh - 160px)', padding: '0px', margin: '0px'}} >
				<ButtonArea>
					<Fab color="primary" aria-label="add" size="small" onClick={() => setOpenNewDialog(true)}>
						<AddIcon />
					</Fab>
				</ButtonArea>
					{
						gameInstances && gameInstances.length > 0 &&
							gameInstances.map((gameInstance, index) => gameInstance &&
								<LessonInstanceListItem gameInstance={gameInstance} showDelete={false} key={index}
									selectedInstance={props.selectedInstance}
									deleteInstance={(i) => deleteInstance(i, gameInstance)} 
									selectInstance={(i) => props.setActiveLesson(i)}
								/>
							)
					}
			</DrawerContentArea>

			{
				showDeleteLessonItemDialog &&
					<Dialog open={showDeleteLessonItemDialog} onClose={() => setShowDeleteLessonItemDialog(false)}>
						<DialogTitle id="alert-dialog-title">{`Bu dersi silmek istediğizden emin misiniz?`}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Devam ederseniz, bu ders ve derse dair öğrenci sonuçları silinecek. Silmek istediğinizden eminseniz lütfen onaylayın.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setShowDeleteLessonItemDialog(false)} color="primary">
								İPTAL
							</Button>
							<Button onClick={async () => { await deleteLessonItem(lessonItemToDelete.key, lessonItemToDelete.owner, authData.email); getGameInstances(); setShowDeleteLessonItemDialog(false); }} color="primary" autoFocus>
								SİL
							</Button>
						</DialogActions>
					</Dialog>
			}

			<Dialog open={openNewDialog} onClose={() => setOpenNewDialog(false)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Yeni Ders Ekle</DialogTitle>
				<DialogContent>
					<div style={{width: '400px'}}>
						<DialogContentText>Derse bir isim belirleyin.</DialogContentText>
						<BaseInput autoFocus id="name" value={newInstanceName} onChange={(newValue) => setNewInstanceName(newValue)} />
					</div>
					<div style={{marginTop: '30px', width: '400px'}}>
						<DialogContentText>Kullanım yeri</DialogContentText>
						<Toggle options={usageAreas} onChange={(newValue) => setUsageArea(newValue) } value={usageArea} />
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenNewDialog(false)} color="primary">
						IPTAL
					</Button>
					<Button onClick={() => createGameInstance(props.game)} color="primary">
						EKLE
					</Button>
				</DialogActions>
			</Dialog>
		</div>
    )
}

export default LessonInstanceList
