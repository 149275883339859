import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { Header } from './Header'
import { Copyright } from '../../simple/Copyright'
// import { initGA, logPageView } from '../../../utils'
import { AuthContext } from '../../../data/context/AuthProvider'
import { LoginComponent } from '../../../PageBlocks/user/LoginComponent'

function Layout2(props) {
	const [user, setUser] = useState()
	const [authData] = useContext(AuthContext)
	useEffect(() => {
		// if (!window.GA_INITIALIZED) {
		// 	initGA()
		// 	window.GA_INITIALIZED = true
		// }
		// logPageView()
	}, [props.param])

	return (
		<div>
			{
				<Header headerMode={props.headerMode} game={props.game} user={user} />
			}
			{
				props.withAuth && !authData.email ?
					<Grid container>
						<Grid item xs={12}>
							<LoginComponent onLogin={(user) => setUser(user) }></LoginComponent>
						</Grid>
					</Grid>
				:
					<Grid container>
						<Grid item xs={12}>
							{props.children}
							{
								props.containCopyright ?
									<Copyright />
								:
									null
							}
						</Grid>
					</Grid>
			}
		</div>
	)
}

export { Layout2 }
