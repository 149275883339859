import React, { useState, useEffect } from 'react'
import { ReactSortable } from 'react-sortablejs'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ClearIcon from '@material-ui/icons/Clear'
import CurriculumLesson from './CurriculumLesson'
import { BaseInput, BaseButton } from '../../components'

const Section = styled.div`width: calc(100% - 10px); margin: 5px; border-radius: 6px; text-align: left; padding: 10px; margin-bottom: 20px;
	-webkit-box-shadow: inset 0 0 6px rgba(60, 180, 60, 0.65);
`
const SectionNumber = styled.span`border-radius: 100px; padding: 3px; width: 40px; margin-right: 5px; background-color: #a8150a; color: white; font-size: 16px;`
const SectionTitle = styled.span`font-size: 18px; font-weight: bold;`
const NewButton = styled(BaseButton)`width: 100px; height: 20px; font-size: 12px; color: white;`

function CurriculumSection(props) {
	const [title, setTitle] = useState(props.section.title)
	const [lessons, setLessons] = useState([])
	const [openDialog, setOpenDialog] = useState(false)

	useEffect(() => {
		if (props.section.lessons) {
			setLessons(props.section.lessons)
		} else {
			setLessons([])
		}
	}, [props.section])

	const reOrderLessons = (newOrderedList) => {
		if (props.onUpdateSection && props.editable) {
			let changed = false
			for (let i = 0; i < newOrderedList.length; i++) {
				if (newOrderedList[i].id !== lessons[i].id) {
					changed = true
				}
			}
			if (changed) {
				let tempItems = []
				let newItemIndex = 0
				for (let i = 0; i < newOrderedList.length; i++ ) {
					let tempItem = newOrderedList[i]
					if (tempItem) {					
						tempItem.order = newItemIndex
						tempItems.push(tempItem)
						newItemIndex++
					}
				}
				setLessons(tempItems)
				props.onUpdateSection({
					...props.section,
					lessons: tempItems
				})
			}
		}
	}

	const updateSectionData = () => {
		if (props.onUpdateSection && props.editable) { 
			let tempSection = {...props.section}
			if (title) { tempSection.title = title } else { tempSection.title = '' }
			props.onUpdateSection({
				...props.section,
				title: title
			})
		}
	}

	const deleteLesson = async (lesson) => {
		if (props.onDeleteLesson && props.editable) {
			await props.onDeleteLesson(props.section, lesson)
			reOrderLessons(props.section.lessons)
		}
	}

	return (
		<Section style={{cursor: ( props.editable ? 'move' : '')}}>
			<Grid container>
				<Grid item xs={12} sm={9}>
					<SectionTitle>
						{
							props.index < 9 ?
								<SectionNumber>&nbsp;{props.index + 1}&nbsp;</SectionNumber> 
							:
								<SectionNumber>{props.index + 1}</SectionNumber> 
						}
						{
							props.editable ?
								<BaseInput value={title} onChange={(newValue) => setTitle(newValue.toUpperCase()) } onBlur={() => { if (props.onUpdateSection) { updateSectionData()} } } WebkitBoxShadow=" " border="0px" 
									borderBottom="solid 1px rgba(60, 180, 60, 0.65)" borderLeft="solid 1px rgba(60, 180, 60, 0.65)" fontSize="24px" 
									width="calc(100% - 40px)" 
								/>
							:
								<span>{title}</span>
						}
						
					</SectionTitle>
				</Grid>
				<Grid item xs={12} sm={3} style={{textAlign: 'right'}}>
					{
						props.editable &&
							<div>
								<NewButton type="button" onClick={() => props.onCreateNewLesson(props.section)} value="DERS EKLE" />&nbsp;&nbsp;
								<ClearIcon onClick={() => {setOpenDialog(true)}} style={{fontSize: '18px', color: 'red', cursor: 'pointer'}} />
							</div>
					}
				</Grid>
				<Grid item xs={12}>
					{
						(lessons && lessons.length > 0) &&
							<div>
								{
									props.editable ?
										<ReactSortable list={lessons} setList={(e) => {reOrderLessons(e)}} delayOnTouchStart={true} delay={10} filter={"input"} preventOnFilter={false}>
											{
												lessons.map((lesson, index) => (
													<CurriculumLesson lesson={lesson} key={lesson.id} editable={true}
														onUpdate={(lesson) => { if (props.onUpdateLesson) { props.onUpdateLesson(props.section, lesson) } } }
														usedLessonTemplates={props.usedLessonTemplates}
														onDeleteLesson={() => { if (props.onDeleteLesson) { deleteLesson(lesson)} } }
														index={index}
													/>
												))
											}
										</ReactSortable>
								:
									<div>
										{
											lessons.map((lesson, index) => (
												<CurriculumLesson lesson={lesson} key={lesson.id} index={index} editable={false} />
											))
										}
									</div>
								
								}
							</div>
					}
				</Grid>
			</Grid>
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"Silmek istediğinizden emin misiniz?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Bölümü ve altındaki tüm ders planını silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Iptal
                    </Button>
                    <Button onClick={() => { props.onDeleteSection(props.section); setOpenDialog(false)}} color="primary" autoFocus>
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
		</Section>
	)
}

export default CurriculumSection
