import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'

function HelpDesc(props) {
	return (
		<Tooltip title={props.helpText}>
			<HelpIcon style={{color: 'gray', fontSize: '18px'}} />
		</Tooltip>
	)
}

export { HelpDesc }
