import React, { useState, useEffect, useContext } from 'react'
import Button from '@mui/material/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@mui/material/TextField'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import LanguageIcon from '@material-ui/icons/Language'
import Tooltip from '@material-ui/core/Tooltip'
import { getPageBySlug, updatePage } from '../../data/firebase/pageFirebase'
import { AuthContext } from '../../data/context/AuthProvider'
import { Toggle } from '../../components'
import { PostList } from '../post/PostList'
import { getBrandData } from '../../utils'
import { PageSettings } from './PageSettings'

const tabsPageDataOptions = [
	{ id: 'posts', label: 'POSTS' },
	{ id: 'details', label: 'AYARLAR' },
]

function PageManager(props) {
	const [authData] = useContext(AuthContext)

	const [page, setPage] = useState()
	const [tabPageData, setTabPageData] = useState('posts')

	useEffect(async () => {
		if (props.page) {
			let tempPage = await getPageBySlug(props.page.slug)
			setPage(tempPage)
		}
	}, [props.page])

	const updatePageData = (fieldName, fieldValue) => {
		let tmpPage = {...page}
		tmpPage[fieldName] = fieldValue
		setPage(tmpPage)
	}
	
	const savePage = () => {
		updatePage(page)
		if (props.onUpdatePage && props.page.title !== page.title ) {
			props.onUpdatePage(page)
		}
	}

	return (
		<div>
			{
				page &&
					<Grid container>
						<>
							<Grid item xs={9} style={{paddingLeft: '10px'}}>
								<TextField type="text" onChange={(e) => updatePageData('title', e.target.value)} value={page.title} label="Title" variant="standard" color="success"
									style={{width: '100%', fontFamily: 'Varela Round !important', fontSize: '24px !important' }} 
								/>
							</Grid>
							<Grid item xs={1}>
								{
									authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) &&
										<Tooltip title="Goto page data on firestore">
											<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fpages~2F${page.id}`} target="_blank" rel="noopener noreferrer"><WhatshotIcon /></a>											
										</Tooltip>
								}
								<Tooltip title="Goto playlist on website">
									<a href={`${getBrandData().publicWebsite}/pprofile/${page.slug}/index`} target="_blank" rel="noopener noreferrer"><LanguageIcon /></a>										
								</Tooltip>
							</Grid>
							<Grid item xs={2}>
								<Button variant="outlined" onClick={() => savePage() }>Kaydet</Button>
							</Grid>
							<Grid item xs={12} style={{margin: '10px'}}>
								<Toggle options={tabsPageDataOptions} onChange={(newValue) => setTabPageData(newValue) } value={tabPageData} />
							</Grid>

							<Grid item xs={12} style={{display: (tabPageData === 'posts' ? 'block' : 'none') }}>
								<PostList page={page} onUpdatePage={(_page) => { setPage(_page); props.onUpdatePage(_page) } } />
							</Grid>
							
							<Grid item xs={12}  style={{display: (tabPageData === 'details' ? 'block' : 'none') }}>
								<PageSettings page={page} onUpdatePageData={(_page) => {setPage(_page)} } />
							</Grid>
						</>
					</Grid>
			}
		</div>
	)
}

export { PageManager }
