// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'

const getLessonResults = async (key) => {
	let firebaseObj = await getFirebaseObj('getLessonResults')
	let firestore = firebaseObj.firestore

	let tempLessonResults = []
	let sLessonResults = await firestore.collection('lessonResults').where('lesson.key', '==', key).get()
	sLessonResults.docs.map(doc => {
		tempLessonResults.push(doc.data())
		return null
	})

	tempLessonResults = tempLessonResults.sort((a,b) => a.stats.totalScore < b.stats.totalScore ? 1 : -1)	
	return tempLessonResults
}

const deleteLessonResultsForLessonItem = async (lessonItemkey, ownerEmail, userEmail) => {
	let firebaseObj = await getFirebaseObj('deleteLessonResultsForLessonItem')
	let firestore = firebaseObj.firestore

	if (userEmail === 'yesilokulcom@gmail.com' || userEmail === ownerEmail ) {
		let lessonResults = await getLessonResults(lessonItemkey)
		lessonResults.map(async (_lessonResult) => {
			await firestore.collection('lessonResults').doc(_lessonResult.id).delete()
		})
	}
}

export { getLessonResults, deleteLessonResultsForLessonItem }
