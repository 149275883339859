import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import FileUpload from '../../../data/firebase/fileUploadFirebase'
// import { storage } from '../../../utils/firebaseSettings'
import { getFirebaseObj } from '../../../utils/firebaseSettings'
import { BaseInput, FormElementLabel, SimpleTextListManager  } from '../../../components'
// const ReactQuillEditor = dynamic(() => import('react-quill'), {ssr: false})

let changeFromInside = false
function Intro(props) {
	const [title, setTitle] = useState(props.introData.title ? props.introData.title : '')
	const [subTitle, setSubTitle] = useState(props.introData.subTitle ? props.introData.subTitle : '')
	const [description, setDescription] = useState(props.introData.description ? props.introData.description : '')
	const [language, setLanguage] = useState(props.introData.language ? props.introData.language : 'en')
	const [level, setLevel] = useState(props.introData.level ? props.introData.level : '0')
	const [category, setCategory] = useState(props.introData.category ? props.introData.category : 'course')

	const [coverImageUrl, setCoverImageUrl] = useState(props.introData.coverImageUrl ? props.introData.coverImageUrl : '')
	const [coverImageFileName, setCoverImageFileName] = useState(props.introData.coverImageFileName ? props.introData.coverImageFileName : '')
	const [thumbCoverImageUrl, setThumbCoverImageUrl] = useState(props.introData.thumbCoverImageUrl ? props.introData.thumbCoverImageUrl : '')
	const [thumbCoverImageFileName, setThumbCoverImageFileName] = useState(props.introData.thumbCoverImageFileName ? props.introData.thumbCoverImageFileName : '')

	const [tags, setTags] = useState(props.introData.tags ? props.introData.tags : [])

	useEffect(() => {

	}, [props.introData])

	const deleteFile = async () => {
		let firebaseObj = await getFirebaseObj('CurriculumIntro fbo_PageBlocks')
		let storage = firebaseObj.storage
	
		const fileRef = storage.ref(`/CURRICULUM-IMAGES/${props.curriculumId}/${coverImageFileName}`)
		const fileThumbRef = storage.ref(`/CURRICULUM-IMAGES/${props.curriculumId}/${thumbCoverImageFileName}`)
		fileRef.delete().then(() => {
			fileThumbRef.delete()
			changeFromInside = true
			setCoverImageLocalState(null)			
		}).catch((error) => {
			console.log('File could not be deleted. error:', error)
		})
	}

	const setCoverImageLocalState = (coverImage) => {
		setCoverImageUrl((coverImage) ? coverImage.url : '')
		setCoverImageFileName((coverImage) ? coverImage.fileName : '')
		setThumbCoverImageUrl((coverImage) ? coverImage.thumb_url : '')
		setThumbCoverImageFileName((coverImage) ? coverImage.thumb_fileName : '')
	}

	useEffect(() => {
		if (changeFromInside) {
			updateIntroData()
		}
	}, [language, level, category, coverImageUrl, coverImageFileName, thumbCoverImageUrl, thumbCoverImageFileName, tags ])

	const updateIntroData = () => {
		props.onUpdate({
			title: title,
			subTitle: subTitle,
			description: description,
			language: language,
			level: level,
			category: category,
			coverImageUrl: coverImageUrl,
			coverImageFileName: coverImageFileName,
			thumbCoverImageUrl: thumbCoverImageUrl,
			thumbCoverImageFileName: thumbCoverImageFileName,
			tags: tags
		})
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<FormElementLabel>Kurs Başlığı</FormElementLabel>
				{
					props.editable ?
						<BaseInput value={title} maxLength="100" onChange={(newValue) => { changeFromInside = false; setTitle(newValue)}} onBlur={(e) => { changeFromInside = true; updateIntroData()}} />
					:
						<div>{title}</div>
				}
				
			</Grid>
			<Grid item xs={12}>
				<FormElementLabel>Kurs Alt Başlığı</FormElementLabel>
				{
					props.editable ?
						<BaseInput value={subTitle} maxLength="120" onChange={(newValue) => { changeFromInside = false; setSubTitle(newValue)}} onBlur={(e) => { changeFromInside = true; updateIntroData()}} />
					:
						<div>{subTitle}</div>
				}
			</Grid>
			
			<Grid item xs={12} style={{height: '30px'}}></Grid>
			<Grid item xs={12} sm={4}>
				<FormElementLabel>Dil</FormElementLabel>
				{
					props.editable ?
						<Select value={language} onChange={(e) => { changeFromInside = true; setLanguage(e.target.value); } } style={{width: '98%'}}>
							<MenuItem value="en">English</MenuItem>
							<MenuItem value="es">Español</MenuItem>
							<MenuItem value="fr">Française</MenuItem>
							<MenuItem value="de">Deutsche</MenuItem>
							<MenuItem value="tr">Türkçe</MenuItem>
						</Select>
					:
						<div>{language}</div>
				}
			</Grid>
			<Grid item xs={12} sm={4}>
				<FormElementLabel>Seviye</FormElementLabel>
				{
					props.editable ?
						<Select value={level} onChange={(e) => { changeFromInside = true; setLevel(e.target.value); } } style={{width: '98%'}}>
							<MenuItem value="0">Seviye Seçin</MenuItem>
							<MenuItem value="1">Başlangıç Seviyesi</MenuItem>
							<MenuItem value="2">Orta Seviyesi</MenuItem>
							<MenuItem value="3">İleri Seviye</MenuItem>
						</Select>
					:
						<div>{level}</div>
				}
			</Grid>
			<Grid item xs={12} sm={4}>
				<FormElementLabel>Kategori</FormElementLabel>
				{
					props.editable ?
						<Select value={category} onChange={(e) => { changeFromInside = true; setCategory(e.target.value); } } style={{width: '98%'}}>
							<MenuItem value="course">Kurs (Sınıf)</MenuItem>
							<MenuItem value="seminar">Seminer</MenuItem>
							<MenuItem value="exam">Sınav</MenuItem>
							<MenuItem value="contest">Yarışma</MenuItem>
							<MenuItem value="event">Etkinlik</MenuItem>
						</Select>
					:
						<div>{category}</div>
				}
			</Grid>
			<Grid item xs={12} style={{height: '30px'}}></Grid>

			<Grid item xs={12} sm={6} style={{height: '430px'}}>
				<FormElementLabel>Kurs Açıklaması</FormElementLabel>
				{
					props.editable ?
						<ReactQuill value={description}
							onChange={(newValue) => { setDescription(newValue); } }
							onBlur={() => {changeFromInside = true; updateIntroData(); } }
							style={{height: '350px'}}
						/>
					:
						<div>{description}</div>
			
				}
			</Grid>
			<Grid item xs={12} sm={6} style={{height: '430px'}}>
				{
					(coverImageUrl.length > 0) ?								
						<Grid container>
							<Grid item xs={12}>
								<FormElementLabel>Kapak Resmi</FormElementLabel>
							</Grid>
							<Grid item xs={1}></Grid>
							<Grid item xs={10}>
								<img src={coverImageUrl} style={{maxHeight: '400px', maxWidth: '100%', marginBottom: '10px', borderRadius: '8px'}} alt="Cover Preview" />
							</Grid>
							<Grid item xs={1}>
								{
									props.editable &&
										<ClearIcon color="action" onClick={() => { changeFromInside = true; deleteFile();  }} title="Kaldır" style={{float: 'right', cursor: 'pointer'}} />
								}
							</Grid>
						</Grid>
					:
						<div>
							{
								props.editable &&
									<FileUpload folder={`/CURRICULUM-IMAGES/${props.curriculumId}`} fileType="image" containerType="dropzone" gameTemplateKey={props.curriculumId} type="curriculum-cover-image"
										height="400px" margin="auto" 
										onUploadComplete={(e) => { changeFromInside = true; setCoverImageLocalState(e); } }
									/>
							}
						</div>
				}
				
			</Grid>
			
			<Grid item xs={12} style={{height: '30px'}}></Grid>
			<Grid item xs={12}>
				<FormElementLabel>Etiketler</FormElementLabel>
				<SimpleTextListManager itemList={tags} onChange={(tagList) => {changeFromInside = true; setTags(tagList)} } editable={props.editable} />
			</Grid>
			<div style={{height: '100px'}}>&nbsp;</div>
		</Grid>
	)
}

export default Intro
