import React from 'react'
import { Typography } from '@material-ui/core'
import Podium from '../shared/Podium'

function PhaseFinalResult(props) {
    if (props.game.quiz.remoteMode) {
        return (
            <div>
                <div>
                    <Typography variant="h5">Program sona erdi</Typography>
                </div>
                <Podium game={props.game} />
            </div>
                           
        )
    } else {
        return (
            <div className="phase-container">
                <div className="quiz-top-section"></div>
                <div className='quiz-middle-section'>
                    <Typography variant="h5">Sonuçlar için ana ekrana bakın</Typography>
                </div>
                <div className="quiz-bottom-section"></div>
            </div>
        )
    }
}

export default PhaseFinalResult
