import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@mui/material/Button'
import { PageManager } from './PageManager'
import { AuthContext } from '../../data/context/AuthProvider'
import { addPage } from '../../data/firebase/pageFirebase'
import { BaseInput } from '../../components'

const BlockTitle = styled.div`font-size: 16px; font-weight: bold; padding-left: 10px; margin-top: 10px; border-bottom: solid 1px black;`
const ListItem = styled.div`margin-left: 10px; margin-right: 5px; margin-top: 5px; cursor: pointer; border-bottom: solid 1px gainsboro; border-radius: 6px; padding: 5px;
	:hover {
		background-color: gainsboro;
	}
`

function PagesList(props) {
	const [authData] = useContext(AuthContext)
	const [selectedPage, setSelectedPage] = useState()
	const [selectedPageIndex, setSelectedPageIndex] = useState()
	const [newPageName, setNewPageName] = useState('')
	const [openNewDialog, setOpenNewDialog] = useState(false)
	const [pages, setPages] = useState([])

	useEffect(() => {
		if (!pages || (pages && props.pages && pages.length !== props.pages.length) ) {
			if (props.pages && props.pages.length > 0) {
				setPages(props.pages)
				setSelectedPage(props.pages[0])
				setSelectedPageIndex(0)
			} else {
				setPages([])
				setSelectedPage(null)
				setSelectedPageIndex(null)
			}
		}
	}, [props.pages])

	const addNew = async () => {
		if (newPageName && newPageName.length > 0 && authData && authData.email)  {
			let newPage = await addPage(newPageName, authData.email)
			if (props.onNewPage) {
				props.onNewPage(newPage)
			}
			setOpenNewDialog(false)
		}
	}
	
	return (
		<div>
			<Grid container style={{textAlign: 'left'}}>
				<Grid item xs={6} sm={3}>
					<Grid container>
						<Grid item xs={9}>
							<BlockTitle>
								PAGES ({(props.pages && props.pages.length > 0 ) ? <span>{props.pages.length}</span> : <span>0</span>})
							</BlockTitle>
						</Grid>
						<Grid item xs={3} style={{paddingLeft: '10px'}}>
							<Button variant="outlined" onClick={() => setOpenNewDialog(true) }>Yeni</Button>
						</Grid>
						{
							(props.pages && props.pages.length > 0 && authData.roles && authData.roles.some(role => ['owner', 'mentor', 'teacher'].indexOf(role) >= 0 )) ? 
								<Grid item xs={12}>
									<Grid container style={{maxHeight: 'calc(100vh - 160px)', borderRight: 'solid 1px gainsboro', overflow: 'auto'}}>
										{
											props.pages.map((_page, index) => 
												<Grid item xs={12} key={index}>
													<ListItem onClick={() => { setSelectedPage(_page); setSelectedPageIndex(index) } }
														style={{
															backgroundColor: ((selectedPage && selectedPage.id === _page.id) ? 'navy' : ''),
															color: ((selectedPage && selectedPage.id === _page.id) ? '#ffffff' : '#000000')
														}}
													>
														<div>
															<span>{index + 1} | </span>
															<span style={{fontWeight: 'bold'}}>{_page.title}</span>
														</div>
														<div style={{fontSize: '11px', width: '100%', textAlign: 'right'}}>{_page.owner}</div>
													</ListItem>
												</Grid>
											)
										}
									</Grid>
								</Grid>
							:
								<span>No playlist defined yet.</span>
						}
					</Grid>
				</Grid>
				<Grid item xs={6} sm={9} style={{padding: '10px'}}>
					{
						selectedPage &&
							<Grid container>
								<Grid item xs={12}>
									<PageManager page={selectedPage} onUpdatePage={(updatedPage) => {if (props.onUpdatePage) {	props.onUpdatePage(updatedPage, selectedPageIndex) } } } />
								</Grid>
							</Grid>
					}
				</Grid>				
			</Grid>
			<Dialog open={openNewDialog} onClose={() => setOpenNewDialog(false)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Yeni Sayfa</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Yeni Sayfa Adı
					</DialogContentText>
					<BaseInput autoFocus id="name" value={newPageName} onChange={(newValue) => setNewPageName(newValue)} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenNewDialog(false)} color="primary">
						IPTAL
					</Button>
					<Button onClick={() => addNew()} color="primary">
						EKLE
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)	
}

export { PagesList }
