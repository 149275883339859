import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { LoginComponent } from '../PageBlocks/user/LoginComponent'
import { RegisterComponent } from '../PageBlocks/user/RegisterComponent'
import { AuthContext } from '../data/context/AuthProvider'
import { Layout2 } from '../components'

const GoTo = styled.div`cursor: pointer; margin-top: 40px; font-size: 18px; font-weight: bold;`

function LoginRegister(props) {
	let history = useHistory()
	const [authData] = useContext(AuthContext)

	const [screenType, setScreenType] = useState('login')
	const [user, setUser] = useState()
	

	useEffect(() => {
		if (authData.email && authData.roles && authData.roles.some(role => ['teacher', 'owner', 'editor', 'admin'].indexOf(role) >= 0) && (!props.game)) {
			history.push('/courses')
		}
	}, [authData])

	return (
		<Layout2 withAuth={false}>
			{
				!authData.email &&
					<div>
						{
							screenType === 'login' &&
								<Grid container>
									<Grid item xs={12}>
										<LoginComponent onLogin={(user) => setUser(user) } marginTop="60px" />
									</Grid>
									<Grid item xs={12}>
										<GoTo onClick={() => setScreenType('register') }>Kayıt Ol</GoTo>
									</Grid>
								</Grid>
						}
						{
							screenType === 'register' &&
								<Grid container>
									<Grid item xs={12}>
										<RegisterComponent onRegister={(user) => setUser(user) } marginTop="60px" />
									</Grid>
									<Grid item xs={12}>
										<GoTo onClick={() => setScreenType('login') }>Kullanıcı Girişi</GoTo>
									</Grid>
								</Grid>
						}
					</div>
			}
		</Layout2>
	)
}

export default LoginRegister
