import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../data/context/AuthProvider'
// import { firebaseAuth } from '../utils'
import { getFirebaseObj } from '../utils/firebaseSettings'

function Logout(props) {
	let history = useHistory()
	const [, setAuthContextData] = useContext(AuthContext)

	useEffect(async () => {
		let firebaseObj = await getFirebaseObj('Logout fbo_PageBlocks')
		let firebaseAuth = firebaseObj.firebaseAuth

		firebaseAuth.signOut().then(() => {
			localStorage.removeItem('email')
			localStorage.removeItem('email2')
			setAuthContextData({
				user: null,
				email: '',
				name: '',
				roles: [],
				lessons: [],
			})
			history.push(`/`)
		})
	}, [props])

	return (
		<div>LOGGING OUT</div>
	)
}

export default Logout
