import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { updatePaymentStatusOfMyMentee } from '../../data/firebase/studentCoursesFirebase'
import { AuthContext } from '../../data/context/AuthProvider'
import { getBrandData } from '../../utils'


const CourseName = styled.div`padding-left: 10px; font-weight: bold; `
const CourseListItem = styled.div`padding: 10px; margin: 5px; border: solid 1px gainsboro; border-radius: 4px; background-color: white;`
const CourseCardImage = styled.img`height: 30px; max-width: 100%; border-radius: 6px 6px 6px 6px; margin-top: 3px; margin-bottom: 3px;`

function StudentCourseListItem(props) {
	const [authData] = useContext(AuthContext)
	const [paymentStatus, setPaymentStatus] = useState(props.course.courseStudentData.payment)
	const [coverImage, setCoverImage] = useState('https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3')

	useEffect(() => {
		if (props.course) {
			setPaymentStatus(props.course.courseStudentData.status)

			let tmpCoverImage = 'https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3'

			if (props.course.meta && props.course.meta.intro) {
				tmpCoverImage = props.course.meta.intro.coverImageThumb300Url
			} else {
				tmpCoverImage = 'https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3'
			}
			setCoverImage(tmpCoverImage)
		}
	}, [props.course])

	return (
		<CourseListItem>
			<Grid container style={{fontSize: '13px'}}>
				<Grid item xs={12} sm={1}>
					<a href={`${getBrandData().publicWebsite}/${props.course.meta.intro.language}/course/${props.course.meta.settings.slug}`} target="_blank" rel="noopener noreferrer">
						<CourseCardImage src={coverImage} />
					</a>
				</Grid>
				<Grid item xs={12} sm={6}>
					<CourseName>{props.course.meta.settings.courseSpecificName ? props.course.meta.settings.courseSpecificName : (props.course.meta.intro.title ? props.course.meta.intro.title : props.course.title)}</CourseName>
				</Grid>
				<Grid item xs={12} sm={5} style={{textAlign: 'right'}}>
					<b>Kurs Baslangıcı:</b> {moment(props.course.meta.stats.firstLessonDate.toDate()).format('YYYY-MM-DD')}
					<br />
					{props.course.meta.stats.lessonCount} ders, {props.course.meta.stats.lessonCount * props.course.meta.pricing.basePrice} {props.course.meta.pricing.currency}
					<br />
					<b>Kayıt Tarihi:</b> {moment(props.course.courseStudentData.additionDate).format('YYYY-MM-DD')}
					<br />
					<b>Son Guncelleme:</b> {moment(props.course.courseStudentData.updateDate).format('YYYY-MM-DD')}
				</Grid>
				{
					authData && authData.email && (authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 )) ?
						<Grid item xs={12} sm={6} style={{marginBottom: '5px', border: 'solid 1px red', borderRadius: '4px', padding: '2px'}}>
							{
								props.course.courseStudentData.status === 'paid' ? // Paid by credit card, no need to make any changes
									<div><CheckCircleIcon style={{color: 'green'}} /> Paid By Credit Card</div>
								:
									<Grid container>
										<Grid item xs={12} sm={5}>Ödeme Durumu</Grid>
										<Grid item xs={12} sm={7}>
											<FormControl style={{width: '100%'}}>
												{
													(props.course.courseStudentData.status === 'paid-cash' || props.course.courseStudentData.status === 'paid-paypal') && 
														<CheckCircleIcon style={{color: 'green'}} />
												}
												<Select value={paymentStatus} onChange={(e) => { updatePaymentStatusOfMyMentee(e.target.value, props.course.courseStudentData)} } name="paymentStatus">
													<MenuItem value='shopping-cart'>Sepette - Kredi Kartı Ile Odenecek</MenuItem>
													<MenuItem value='paid-cash'>Nakit Ödendi</MenuItem>
													<MenuItem value='will-be-paid-cash'>Nakit Ödenecek</MenuItem>
													<MenuItem value='paid-paypal'>Paypal Ile Odendi</MenuItem>
													<MenuItem value='will-be-paid-paypal'>Paypal Ile Odenecek</MenuItem>
													<MenuItem value='free'>Ücretli Kursa - Ücretsiz Kayıt (%100 İndirim) </MenuItem>
												</Select>
											</FormControl>
										</Grid>
									</Grid>
							}
						</Grid>
					:
						<Grid item xs={12}>
							{props.course.courseStudentData.status}
						</Grid>
				}
				{
					authData && authData.email && (authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 )) &&
						<Grid item xs={12} sm={6} style={{marginBottom: '5px', textAlign: 'right', border: 'solid 1px red', borderRadius: '4px', padding: '2px'}}>
						{/* <div style={{marginTop: '5px', marginBottom: '5px', , width: '100%', border: 'solid 1px red', borderRadius: '4px', padding: '2px'}}> */}
							<b>Course Registration Id: </b>
							<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2FstudentCourses~2F${props.course.courseStudentData.id}`} target="_blank" rel="noopener noreferrer">
								{props.course.courseStudentData.id}
							</a>								
						</Grid>
				}															
			</Grid>
		</CourseListItem>
	)
}

export { StudentCourseListItem }