import React, { Component } from 'react'
import styled from 'styled-components'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { Toggle } from '../../../components'

const ComponentDiv = styled.div`max-width: 600px;`
const LeaderBoardTable = styled.div`width: 100%; overflow: auto;
	height:  ${(props) => props.tableHeight ? props.tableHeight : 'calc(100vh - 200px)'};
`
const LeaderBoardRow = styled.div`font-weight: bold; clear: both; width: 100%; border-bottom: solid 1px gainsboro; overflow: hidden; margin: auto;`
const Index = styled.div`width: 15%; float: left; text-align: left; padding-left: 3px;`
const Name = styled.div`width: 45%; float: left; text-align: left; overflow: hidden;`
const Point = styled.div`float: left; text-align: left; overflow: hidden; width: 40%;`

const colorPalette = [
	'#e21b3c',
	'#1368ce',
	'#26890c',
	'#000000',
	'#000000'
]

const tabs = [
	{ id: 'currentQuestion', label: 'BU SORU' },
	{ id: 'total', label: 'GENEL LIDERLIK' },
]

let interval
class Leaderboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			leaderboardData: this.getLeaderboardData(),
			page: 0,
			rowsPerPage: 10,
			selectedLeaderboard: 0,
		}
		this.startScoreCounter = this.startScoreCounter.bind(this)
		this.getScoreCellWidth = this.getScoreCellWidth.bind(this)
	}

	componentDidMount() {
		setTimeout(this.startScoreCounter, 2000);
	}

	startScoreCounter() {
		const that = this
		interval = setInterval(() => {
			const newLeaderBoard = that.state.leaderboardData
			let done = true

			for (let i = 0; i < newLeaderBoard.players.length; i++) {
				const player = newLeaderBoard.players[i]
				if (player.currentQuestionScore > 100) {
					done = false
					player.currentQuestionScore-= 10
					// player.activeQuestionScore++
					player.totalScore+=10
				}
				if (player.currentQuestionScore > 0) {
					done = false
					player.currentQuestionScore--
					// player.activeQuestionScore++
					player.totalScore++
				}
			}

			newLeaderBoard.players.sort((a, b) => ((b.totalScore < a.totalScore) ? -1 : (b.totalScore > a.totalScore) ? 1 : 0))
			that.setState({ leaderboardData: newLeaderBoard })
			if (done) {
				clearInterval(interval)
			}
		}, 10)
	}

	componentWillUnmount() {
		clearInterval(interval)
	}

	getLeaderboardData() {
		const leaderboard = {
			players: [],
		}
		const playerKeys = this.props.game.players ? Object.keys(this.props.game.players) : []

		const currentQuestion = this.props.game.quiz.questions[this.props.game.quiz.currentQuestion]
		const playerList = []

		for (let i = 0; i < playerKeys.length; i++) {
			const playerScoreData = {}
			const player = this.props.game.players[playerKeys[i]]
			if (player.name) {
				let score = 0
			
				if (player.answers) {
					for (let j = 0; j < this.props.game.quiz.questions.length; j++) {
						const question = this.props.game.quiz.questions[j]
						const answer = player.answers[question.id]
	
						if (answer) {
							if (answer.questionId === currentQuestion.id) {
								playerScoreData.activeQuestionScore = answer.score
								playerScoreData.currentQuestionScore = answer.score								
								playerScoreData.lastScore = answer.score
								playerScoreData.isCorrect = answer.isCorrect
							} else {
								score += answer.score
							}
						}
					}
				}
				playerScoreData.totalScore = score
				playerScoreData.name = player.name
				playerScoreData.key = player.key
				playerList.push(playerScoreData)
			}			
		}

		const sortedPLayerList = [...playerList]
		leaderboard.players = sortedPLayerList.sort((a, b) => ((b.totalScore < a.totalScore) ? -1 : (b.totalScore > a.totalScore) ? 1 : 0))
		const newCurrentQuestionPlayerList = [...playerList].filter(player => (player.isCorrect && player.isCorrect === true))
		leaderboard.playersCurrentQuestionOrdered = newCurrentQuestionPlayerList.sort((a, b) => ((b.activeQuestionScore < a.activeQuestionScore) ? -1 : (b.activeQuestionScore > a.activeQuestionScore) ? 1 : 0))		
		return leaderboard
	}

	getLeaderboardScore(player) {
		if (player.currentQuestionScore === 0) {
			return `${player.totalScore} (+${player.lastScore})`
		}
		return `${player.totalScore}           `
	}

	getScoreCellWidth() {
		let length = 0
		const leaderboardData = this.getLeaderboardData()

		for (let i = 0; i < leaderboardData.players.length; i++) {
			const player = leaderboardData.players[i]
			const scoreWidth = `${player.totalScore} (+${player.lastScore})`
			if (scoreWidth.length > length) {
				length = scoreWidth.length
			}
		}
		return (length * 6) + 65;
	}

    handleChangePage = (event, page) => {
		this.setState({ page })
    }

    handleChangeRowsPerPage = (event) => {
    	this.setState({ rowsPerPage: event.target.value })
    }

    render() {
		const { leaderboardData } = this.state
		return (
			<ComponentDiv>
				<Toggle options={tabs} onChange={(newValue) => { if(this.props.selectTab) { this.props.selectTab(newValue) } } } value={this.props.game.uiState.leaderboardTab ? this.props.game.uiState.leaderboardTab : 'currentQuestion'} />
				{ this.props.game.uiState.leaderboardTab === 'currentQuestion' &&
					<div style={{ border: 'solid 4px #e042f5', borderRadius: '8px' }}>
						<LeaderBoardTable tableHeight={this.props.tableHeight}>
							{
								leaderboardData.playersCurrentQuestionOrdered.slice(0, (leaderboardData.playersCurrentQuestionOrdered.length > 50 ? (this.props.listCount ? this.props.listCount : 50) : leaderboardData.playersCurrentQuestionOrdered.length )).map((player, index) => (
									<LeaderBoardRow key={index} style={{fontSize: `${(16 + (5-(index*2))) > 14 ? (16 + (5-(index*2))) : 14 }px`, height: `${ (30 + (5-index*2)) > 24 ? (30 + (5-index*2)) : 24 }px`, color: colorPalette[index] }}>
										<Index><CheckCircleOutlineIcon style={{color: 'green'}} /> {index + 1}</Index>
										<Name>{player.name}</Name>
										<Point>{ (player.activeQuestionScore && parseInt(player.activeQuestionScore) > 0) ? player.activeQuestionScore : ''}</Point>
									</LeaderBoardRow>
								))
							}
						</LeaderBoardTable>
					</div>
				}
				{ this.props.game.uiState.leaderboardTab === 'total' &&
					<div style={{ border: 'solid 4px #3bbdf5', borderRadius: '8px' }}>
						<LeaderBoardTable tableHeight={this.props.tableHeight}>
							{
								leaderboardData.players.slice(0, (leaderboardData.players.length > 50 ? (this.props.listCount ? this.props.listCount : 50) : leaderboardData.players.length )).map((player, index) => (
									<LeaderBoardRow key={index} style={{fontSize: `${(15 + (5-(index*2))) > 13 ? (15 + (5-(index*2))) : 13 }px`, height: `${ (30 + (5-index*2)) > 24 ? (30 + (5-index*2)) : 24 }px`, color: colorPalette[index] }}>
										<Index>{index + 1}</Index>
										<Name>{player.name}</Name>
										<Point>{this.getLeaderboardScore(player)}</Point>
									</LeaderBoardRow>
								))
							}
						</LeaderBoardTable>
					</div>
				}
			</ComponentDiv>
		)
    }
}

export default Leaderboard
