import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import AnswerOptionList from '../shared/AnswerOptionList'
// import AnswerChart from '../shared/AnswerChart'
import AnswerStats from '../shared/AnswerStats'
import MainDrawer from '../shared/MainDrawer'
// import AnswersBarChart from '../shared/AnswersBarChart'
import Leaderboard from '../shared/Leaderboard'
import QuestionContent from '../shared/QuestionContent'
import NextStepButtons from '../shared/NextStepButtons'
import { Toggle } from '../../../components'

const QuestionNumber = styled.div`font-size: 18px; margin-top: 5px; font-weight: bold;`
const AnswerChartArea = styled.div`padding-top: 10px; margin: 10px;`
const BottomButtonsArea = styled(Grid)`position: absolute; bottom: 10px; height: 54px; width: 100%;
	@media (max-width: 767px) {
		bottom: 5px;
	}
`
const LeaderBoardArea = styled.div` margin: 5px; border-radius: 8px; margin-top: 0px;`
const QuestionSection = styled.div`height: 54vh; text-align: center; overflow: auto; width: 100%;`
const AnswersSection = styled.div`height: 34vh; margin-top: 10px; position: absolute; bottom: 0; left: 0; width: 100%;
	@media (max-width: 767px) {
		height: 32vh;
	}
`

const tabs = [
	{ id: 'result', label: 'SONUÇ' },
	{ id: 'question', label: 'SORU' },
]

function PhaseResultQuestion(props) {
	const [tab, setTab] = useState('result')
	const [leaderboardTab, setLeaderboardTab] = useState('currentQuestion')
	const [drawerQuestionList, setDrawerQuestionList] = useState('closed')
	const [drawerLessonInfo, setDrawerLessonInfo] = useState('closed')
	const [drawerUserList, setDrawerUserList] = useState('closed')
	const [drawerReport, setDrawerReport] = useState('closed')
	const [drawerAnswerList, setDrawerAnswerList] = useState('closed')
	const [drawerActiveQuestionId, setDrawerActiveQuestionId] = useState(1)
	const [drawerSelectedPlayerAnswerKey, setDrawerSelectedPlayerAnswerKey] = useState('')

	const [currentQuestion, setCurrentQuestion] = useState(props.game.quiz.questions[props.game.quiz.currentQuestion])

	useEffect(() => {
		setCurrentQuestion(props.game.quiz.questions[props.game.quiz.currentQuestion])
	}, [])
	

	useEffect(() => {
		if (tab && leaderboardTab && drawerQuestionList && drawerLessonInfo && drawerUserList && drawerReport && drawerActiveQuestionId) {
			props.gameFunc.update({
				uiState: { 
					resultQuestionTab: tab,
					leaderboardTab: leaderboardTab,
					drawerQuestionList: drawerQuestionList,
					drawerLessonInfo: drawerLessonInfo,
					drawerUserList: drawerUserList,
					drawerReport: drawerReport,
					drawerActiveQuestionId: drawerActiveQuestionId,
					drawerAnswerList: drawerAnswerList,
					drawerSelectedPlayerAnswerKey: drawerSelectedPlayerAnswerKey,
				} 
			})
		}
	}, [tab, leaderboardTab, drawerQuestionList, drawerLessonInfo, drawerUserList, drawerReport, drawerActiveQuestionId, drawerAnswerList, drawerSelectedPlayerAnswerKey])
	
	const goNextQuestion = () => {
		const game = {}
		game.quiz = props.game.quiz
		game.quiz.currentQuestion = game.quiz.currentQuestion + 1
		game.phase = 'awaiting_question'
		props.gameFunc.update(game)
	}

	const finalizeLesson = () => {
		props.gameFunc.end()
	}

	const nextQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion + 1]
	const isLastQuestion = typeof nextQuestion === 'undefined'
	
	return (
		<Grid container>
			<Grid item xs={12}>		
				<Grid container>
					<Grid item xs={3} sm={4}>
						<QuestionNumber>{props.game.quiz.currentQuestion + 1} / {props.game.quiz.questions.length}</QuestionNumber>
					</Grid>
					<Grid item xs={6} sm={4} style={{marginTop: '10px'}}>
						<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
					</Grid>
					<Grid item xs={3} sm={4}>
						{ props.game.uiState &&
							<MainDrawer content='lesson-info' gameInstance={props.game} float="right" iconColor="green" showIcon={true} onStateChange={(newState) => setDrawerLessonInfo(newState)} mode="active" />
						}
						{/* <LessonInfoDrawer gameInstance={props.game} float="right" /> */}
					</Grid>
				</Grid>
			</Grid>
			
			{
				tab === 'result' &&
					<Grid item xs={12}>
						<Grid container>
							{
								(currentQuestion.cType === 'quiz' || currentQuestion.cType === 'poll') &&
								<Grid item xs={12} sm={6}>
									<AnswerChartArea>
										<AnswerStats game={props.game} />
									</AnswerChartArea>					
								</Grid>
							}
							{
								currentQuestion.cType === 'quiz' &&
									<Grid item xs={12} sm={6}>
										<LeaderBoardArea>
											{ props.game.uiState &&
												<Leaderboard game={props.game} listCount="50" usedPage="host-result-question" tableHeight="calc(100vh - 220px)" selectTab={(newValue) => {setLeaderboardTab(newValue)}} />
											}
										</LeaderBoardArea>
									</Grid>
							}
							

							<BottomButtonsArea item xs={12}>
								<Grid container>
									<Grid item xs={6}>
										{ props.game.uiState &&
											<div style={{width : '100%'}}>
												<MainDrawer content='question-list' gameInstance={props.game} float="left" showIcon={true} mode="active" videoControlType="host"
													onStateChange={(newState) => setDrawerQuestionList(newState)}
													onActiveQuestionChange={(q) => { if(q) { setDrawerActiveQuestionId(q.id)} } }
												/>
												<MainDrawer content='user-list' gameInstance={props.game} float="left" iconColor="red" showIcon={true} mode="active"
													onStateChange={(newState) => setDrawerUserList(newState)}
												/>
												<MainDrawer content='report' gameInstance={props.game} float="left" iconColor="blue" showIcon={true} mode="active"
													selectTab={(newValue) => {setLeaderboardTab(newValue)}}
													onStateChange={(newState) => setDrawerReport(newState)}
												/>
												<MainDrawer content='question-player-answers' gameInstance={props.game} question={props.game.quiz.questions[props.game.quiz.currentQuestion]} float="left" iconColor="green" showIcon={true} mode="active"
													onStateChange={(newState) => setDrawerAnswerList(newState)}
													onAnswerSelect={(answerKey) => { if(answerKey) { setDrawerSelectedPlayerAnswerKey(answerKey)} } }
												/>
											</div>
										}
									</Grid>
									<Grid item xs={6}>
										<NextStepButtons onFinalizeLesson={() => finalizeLesson()} onNextQuestion={() => goNextQuestion()} isLastQuestion={isLastQuestion} />
									</Grid>
								</Grid>
							</BottomButtonsArea>
						</Grid>
					</Grid>
			}

			{
				tab === 'question' && currentQuestion && currentQuestion.answerOptions &&
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12}>
								<QuestionSection>
									<QuestionContent question={currentQuestion} videoPlaying={false} onVideoEnd={null} videoMaxHeight="240px" mode="active" videoControlType="host" />
								</QuestionSection>

								<AnswersSection>
									<AnswerOptionList question={currentQuestion} />
								</AnswersSection>
							</Grid>
						</Grid>
					</Grid>
			}
		</Grid>
	)
}

export default PhaseResultQuestion
