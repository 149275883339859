import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import { getFirstLinkInsideText, getMetaDataOfUrl } from '../../../../../../utils'

const PreviewURLObj = styled.div`display: flex; font-size: 15px; position: relative; border: 1px solid rgb(196, 207, 214); width: 100%; max-width: 500px; border-radius: 10px;  overflow: hidden; outline-style: none;  
	animation: fadeIn 0.35s ease-in forwards; margin: 0 0 10px 0;`
const ImgArea = styled.div`width: 25%; padding-top: 25%; position: relative; overflow: hidden; min-width: 84px;`
const PreviewImg = styled.img`transform: translate(-50%, -50%); left: 50%; top: 50%; height: 100%; display: block; position: absolute;`
const ContentArea = styled.div`padding: 10px; flex: 1; display: flex; flex-direction: column; text-align: left; justify-content: center;`
const CloseButton = styled.button`cursor: pointer; border: 0; outline: none; background-color: rgba(0, 0, 0, 0.77); position: absolute; height: 28px; width: 28px; top: 4px; left: 4px; border-radius: 50%; z-index: 1;
	&:before, &:after { content: ""; position: absolute; left: 50%; top: 50%; width: 1.4px; height: 60%; background-color: white; z-index: 1; }
	&:before { transform: translate(-50%, -50%) rotate(-45deg); }
	&:after { transform: translate(-50%, -50%) rotate(45deg); }
`

const Title = styled.div`font-weight: bold; font-size: 16px;`
const Description = styled.div`font-size: 14px; margin-top: 5px;`
const Footer = styled(Grid)`font-size: 12px; color: #3b6af7; margin-top: 5px;`

export const PreviewURL = (props) => {
	const [enablePreview, setEnablePreview] = useState(true)
	const [url, setUrl] = useState('')
	const [urlMetaData, setUrlMetaData] = useState()
	useEffect(async () => {
		let tmpURL =  await getFirstLinkInsideText(props.plainText)
		
		if (tmpURL) {
			if (tmpURL !== url) {
				let tmpUrlMetaData = await getMetaDataOfUrl(tmpURL)
				setUrl(tmpURL)
				setUrlMetaData(tmpUrlMetaData)
				if (props.onURLFound) {
					let urlData = {url: tmpURL, metaData: tmpUrlMetaData }
					props.onURLFound(urlData)
				}
			}
		} else {
			setUrl(null)
			setUrlMetaData(null)
			if (props.onURLFound) {
				let urlData = {url: null, metaData: null }
				props.onURLFound(urlData)
			}
		}
		
	}, [props.plainText])

	if (!enablePreview) {
		return null
	}

	return (
		<div>
			{
				urlMetaData && enablePreview &&
					<PreviewURLObj>
						{
							urlMetaData['og:image'] && urlMetaData['og:image'].length > 0 &&
								<ImgArea>
									<CloseButton type="button" onClick={() => setEnablePreview(false)} />
									<PreviewImg alt={urlMetaData['title']} src={urlMetaData['og:image']} />
								</ImgArea>
						}
						<ContentArea>
							<Title>{urlMetaData['title']}</Title>
							<Description>{urlMetaData['description']}</Description>
							<Footer container>
								<Grid item xs={1}>
									<InsertLinkIcon />
								</Grid>
								<Grid item xs={11}>
									{urlMetaData['og:url']}
								</Grid>
							</Footer>
							<div style={{clear: 'both'}}></div>
						</ContentArea>
					</PreviewURLObj>
			}
		</div>
	)
}
