import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import AssessmentIcon from '@material-ui/icons/Assessment'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Switch from '@material-ui/core/Switch'
import { CardDiv, H1, Toggle } from '../../../../components'
import { AuthContext } from '../../../../data/context/AuthProvider'
import Podium from '../../shared/Podium'
import { OneQuestionResult } from '../report/question/OneQuestionResult'
import { StudentAnswerListItem } from '../report/question/StudentAnswerListItem'

const LessonDetailsComp = styled.div``

const tabs = [
	{ id: 'scoreboard', label: 'Puan Tablosu' },
	{ id: 'student-answer-table', label: 'Öğrenci Cevapları' },
	{ id: 'question-based-answers', label: 'Soru Bazlı Cevaplar' },
]

function LessonDetails(props) {
	const [authData] = useContext(AuthContext)
	const [playerList, setPlayerList] = useState([])
	const [tab, setTab] = useState('scoreboard')
	const [showStudentListOneQuestionResult, setShowStudentListOneQuestionResult] = useState(true)	

	useEffect(async () => {
		let tempPlayers = []
		if(props.gameInstance && props.gameInstance.players) {
			Object.keys(props.gameInstance.players).map((key) => tempPlayers.push(props.gameInstance.players[key]))
			console.log('tempPlayers from firebase: ', tempPlayers)
			setPlayerList(tempPlayers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))) // Sort by name
		}
	}, [props])

    return (
		<LessonDetailsComp>
			<CardDiv>
				<Grid container key={props.gameInstance.key}>
					<Grid item xs={9}>
						<div style={{textAlign: 'left', fontSize: '14px', fontWeight: 'bold'}}>
							{
								props.gameInstance.status === 'CREATED' ?
									<a href={`/host-game/${props.gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
										<PlayCircleFilledIcon style={{fontSize: '11px', color: 'green' }} /> {props.gameInstance.title}
									</a>
								:
									props.gameInstance.status === 'IN_PROGRESS' ?
										<a href={`/host-game/${props.gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
											<PlayCircleFilledIcon style={{fontSize: '11px', color: 'orange'}} /> {props.gameInstance.title}
										</a>
										:        
										<a href={`/host-game/${props.gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
											<AssessmentIcon style={{fontSize: '11px', color: 'black'}} /> {props.gameInstance.title}
										</a>
							}
						</div>
					</Grid>
					<Grid item xs={1}>
						{
							authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) &&
								<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/database/yesil-okul-web-app-default-rtdb/data/~2Flesson_instances~2F${props.gameInstance.key}`} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a>
						}
					</Grid>
					<Grid item xs={2}>
						<b style={{fontSize: '12px'}}>{props.gameInstance.gameId} ({props.gameInstance.players ?  Object.keys(props.gameInstance.players).length : 0})</b>
					</Grid>
				</Grid>
			</CardDiv>
			
			<Grid container>
				<Grid item xs={12} style={{marginTop: '20px'}}>
					<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
				</Grid>				
				{
					tab === 'scoreboard' &&
						<Grid item xs={12}>
							<CardDiv>
								<H1>PUAN TABLOSU</H1>
								<Podium game={props.gameInstance} />
							</CardDiv>
						</Grid>
				}
				{
					tab === 'student-answer-table' &&
						<Grid item xs={12}>
							<CardDiv>
								<H1>ÖĞRENCİ CEVAPLARI ({props.gameInstance.players ? Object.keys(props.gameInstance.players).length : 0})</H1>
								{
									props.gameInstance.players && playerList && playerList.map((student, studentIndex) =>
										<StudentAnswerListItem studentIndex={studentIndex} student={student} key={studentIndex} lessonInstance={props.gameInstance} currentQuestionIndex={props.gameInstance.quiz.currentQuestion} answerIndexOffset={1} />
									)
								}						
							</CardDiv>
						</Grid>
				}
				{
					tab === 'question-based-answers' &&
						<Grid item xs={12}>
							<CardDiv>
								<H1>SORU BAZLI SONUCLAR</H1>
								<div>
									<Switch color="primary"
										checked={showStudentListOneQuestionResult}
										style={{color: showStudentListOneQuestionResult ? 'green' : 'red'}}
										onChange={(e) => { setShowStudentListOneQuestionResult(!showStudentListOneQuestionResult) } }
									/>
									{
										showStudentListOneQuestionResult ?
											<span style={{color: 'green'}}>Öğrenciler Gösteriliyor</span>
										:
											<span style={{color: 'red'}}>Öğrenciler Gizlendi</span>
									}
								</div>
								{
									props.gameInstance.quiz && props.gameInstance.quiz.questions && props.gameInstance.quiz.questions.map((question, index) =>
										<OneQuestionResult question={question} key={index} lessonInstance={props.gameInstance} lessonResults={playerList} questionIndex={index} showStudentListDetails={showStudentListOneQuestionResult} answerIndexOffset={1} />
									)
								}								
							</CardDiv>
						</Grid>
				}
			</Grid>
			<div style={{height: '80px'}}>&nbsp;</div>
		</LessonDetailsComp>
    )
}

export default LessonDetails
