import React, { useState, useEffect } from 'react'
import { BaseInput } from './BaseInput'
import Grid from '@material-ui/core/Grid'
import ClearIcon from '@material-ui/icons/Clear'

function TextBoxListItem(props) {
	const [itemValue, setItemValue] = useState(props.itemValue)

	useEffect(() => {
		setItemValue(props.itemValue)
	}, [props.itemValue])

	return (
		<Grid container style={{padding: '10px'}}>
			<Grid item xs={10} sm={11}>
				<BaseInput value={itemValue} onChange={(newValue) => setItemValue(newValue)} onBlur={(e) => props.onUpdateItem(e.target.value, props.itemIndex)} fontSize="20px" width="95%" />&nbsp;&nbsp;
			</Grid>
			<Grid item xs={2} sm={1}>
				<ClearIcon onClick={() => {props.onDeleteItem(props.itemIndex)}} style={{fontSize: '18px', color: 'red', cursor: 'pointer'}} />
			</Grid>
		</Grid>
	)
}

export { TextBoxListItem }
