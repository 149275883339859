import React, { useState } from 'react'
import { Helmet } from "react-helmet"
import './App.scss'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Curriculum from './pages/curriculum/Curriculum'
import CurriculumList from './pages/curriculum/CurriculumList'
import Course from './pages/course/Course'
import CourseList from './pages/course/CourseList'
import LessonTemplateList from './pages/presentations/LessonTemplateList'
import Create from './pages/presentations/Create'
import Host from './pages/liveLesson/Host'
import Play from './pages/liveLesson/Play'
import Mentor from './pages/Mentor'
import Pages from './pages/Pages'
import Playlists from './pages/Playlists'
import Students from './pages/Students'
import Student from './pages/Student'
import StudentCourses from './pages/StudentCourses'
import Staff from './pages/Staff'
import TeacherAccountDashboard from './pages/TeacherAccountDashboard'
import LoginRegister from './pages/LoginRegister'
import Logout from './pages/Logout'
import { CustomizedSnackbars } from './components'
import { getBrandData } from './utils/appUtil'
import { AuthProvider } from './data/context/AuthProvider'

function App(props) {
	const [snack, setSnack] = useState('')
	
	const hideSnackbar = () => {
		const tempSnack = { ...snack}
		tempSnack.open = false
		setSnack(tempSnack)
	}

	const showSnackbar = (tempSnack) => {		
		tempSnack.open = true
		setSnack(tempSnack)
	}
	
	return (
		<AuthProvider>
			<Router>
				<div className="App">
					<Helmet>
						<link rel="shortcut icon" href={`/images/${getBrandData().logoFolder}/favicon.ico`} />
						<title>{getBrandData().appName}</title>
						<link rel="manifest" href={`/images/${getBrandData().logoFolder}/manifest.webmanifest`} />
					</Helmet>
					<div id="content" style={{clear: 'both'}}>
						
						<Route path="/play/:pincode" render={(props) => <Play showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/play" render={(props) => <Play showSnackbar={showSnackbar} {...props} />} />
						
						<Route path="/host-game/:pincode" render={(props) => <Host showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/host-game" render={(props) => <Host showSnackbar={showSnackbar} {...props} />} />
						
						<Route exact path="/lesson-designer" render={(props) => <LessonTemplateList showSnackbar={showSnackbar} {...props} />} />
						<Route path="/lesson-designer/:pincode" render={(props) => <Create showSnackbar={showSnackbar} {...props} />} />

						<Route exact path="/curriculum-designer" render={(props) => <CurriculumList showSnackbar={showSnackbar} {...props} />} />
						<Route path="/curriculum-designer/:id/:param2?/:param3?" render={(props) => <Curriculum showSnackbar={showSnackbar} {...props} />} />
						
						<Route exact path="/courses" render={(props) => <CourseList showSnackbar={showSnackbar} {...props} />} />
						<Route path="/courses/:id/:param2?/:param3?" render={(props) => <Course showSnackbar={showSnackbar} {...props} />} />						

						<Route exact path="/pages" render={(props) => <Pages showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/playlists" render={(props) => <Playlists showSnackbar={showSnackbar} {...props} />} />
						
						<Route exact path="/student/:studentemail" render={(props) => <Student showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/mentor" render={(props) => <Mentor showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/students" render={(props) => <Students showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/student-courses" render={(props) => <StudentCourses showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/staff" render={(props) => <Staff showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/teacher-account-dashboard" render={(props) => <TeacherAccountDashboard showSnackbar={showSnackbar} {...props} />} />

						<Route exact path="/login" render={(props) => <LoginRegister showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/logout" render={(props) => <Logout showSnackbar={showSnackbar} {...props} />} />

						<Route exact path="/" render={(props) => <Play showSnackbar={showSnackbar} {...props} />} />
					</div>
					{
						snack && 
							<CustomizedSnackbars snack={snack} hideSnackbar={hideSnackbar} />
					}
				</div>
			</Router>
		</AuthProvider>
	)
}

export default App
