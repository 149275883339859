import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import AnswerOptionList from '../shared/AnswerOptionList'
import AnswerCounter from '../shared/AnswerCounter'
import QuestionContent from '../shared/QuestionContent'
import ReactPlayer from 'react-player/youtube'
import { Timer, H1 } from '../../../components'
import { removeNonEnglishChars } from '../../../utils'

const AnswersSectionLocal = styled.div`height: calc(100vh - 50px); text-align: center;`

// For remote mode
const QuestionText = styled.div`width: 100%; text-align: center; margin-top: 10px; font-size: 64px; color: black;`
const CounterSection = styled.div`text-align: center; overflow: auto; position: absolute; top: 0px; right: 0px; width: 100%;`

const QuestionSection = styled.div`height: 54vh; text-align: center; overflow: auto; width: 100%;`
const AnswerCounterSection = styled.div`text-align: center; overflow: auto; width: 100%;`
const AnswersSection = styled.div`height: 40vh; margin-top: 10px; position: absolute; bottom: 0; left: 0; width: 100%;
	@media (max-width: 767px) {
		height: 37vh;
	}
`
const YourAnswerArea = styled.div`margin: auto; margin-top: 100px; font-size: 20px;`
const YourAnswer = styled.div`font-size: 36px; font-weight: bold; width: 95%; margin: auto; color: red;`

function PhaseAnswer(props) {
	const [isTimeLimited, setIsTimeLimited] = useState(true)
	const [startTime, setStartTime] = useState()
	const [question, setQuestion] = useState()
	const [hasAnswered, setHasAnswered] = useState(false)
	const [selectedAnswer, setSelectedAnswer] = useState('')

	useEffect(() => {
		let tempQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
		setQuestion(tempQuestion)

		if (props.player && props.player.answers && props.player.answers[tempQuestion.id] && props.player.answers[tempQuestion.id].answer) {
			setSelectedAnswer(props.player.answers[tempQuestion.id].answer)
			setHasAnswered(true)	
		} else {

		}
		setStartTime(Date.now())
		setIsTimeLimited(tempQuestion.qOptions.timeLimit > 0 ? true : false)
	}, [])

	useEffect(() => {
		let tempQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
		setQuestion(tempQuestion)
	}, [props.game.quiz.questions[props.game.quiz.currentQuestion]])

    const answerQuestion = (answer) => {
		setSelectedAnswer(answer)
		setHasAnswered(true)
		const answerTime = Date.now() - startTime
		let score = 0
		let isCorrect = false

		if (question.cType === 'quiz') {
			if (question.aType === 'free-text') {
				let foundAnswer = question.answerOptions.filter(answerOption => (answerOption.optionText && removeNonEnglishChars(answerOption.optionText).toLowerCase().trim() === removeNonEnglishChars(answer.freeText).toLowerCase().trim()))
				if (foundAnswer && foundAnswer.length > 0) {
					isCorrect = true
				}
			} else {
				if (answer.isCorrect) {
					isCorrect = true					
				}
			}
			if (isCorrect) {
				if (parseInt(question.qOptions.point) > 0) {
					score += question.qOptions.point

					if (question.qOptions.timeLimit) {
						const timeFactorUsed = answerTime / (question.qOptions.timeLimit * 1000)
						score = Math.floor(score - (score * Math.min(timeFactorUsed, 0.5)) ) // decrease used time from question point
					}
				}
			}			
		}

		const playerAnswer = {
			answer,
			score,
			answerTime,
			questionId: question.id,
			isCorrect: (question.cType === 'quiz' ? isCorrect : null)
		}
		props.saveAnswer(playerAnswer)
    }
	
	if (props.game.quiz.remoteMode && question) {
		if (hasAnswered) {
			let questionText1 = question.texts && question.texts.length > 0 && question.texts[0].url ? question.texts[0].url : ''
			let qfontSize =	questionText1.length < 30 ? '48px': (questionText1.length < 60 ? '36px': (questionText1.length < 90 ? '24px': (questionText1.length < 120 ? '18px': '14px' ) ) )
			return (
				<div>
					<QuestionText style={{fontSize: qfontSize}}>
						{questionText1}
					</QuestionText>                        

					<YourAnswerArea>Tebrikler, Cevabın alındı.
						<YourAnswer>
							{
								(selectedAnswer && selectedAnswer.optionImage && selectedAnswer.optionImage.url) ? 
									<img src={selectedAnswer.optionImage.url} style={{width: '250px', borderRadius: '6px'}} alt="Your answer" />
								:
									<span>{selectedAnswer.optionText}</span>
							}
						</YourAnswer>
					</YourAnswerArea>
					
					<CounterSection>
						{isTimeLimited && <Timer timeLimit={question.qOptions.timeLimit} />}						
					</CounterSection>
					
					<AnswerCounterSection>
						<H1>Sürenin bitmesi bekleniyor.</H1>							
						<AnswerCounter game={props.game}/>
					</AnswerCounterSection>
				</div>
			)
		}

		return (
			<div>
				{
					question &&
						<div>
							<QuestionSection>
								<QuestionContent question={question} videoPlaying={false} onVideoEnd={null} hideVideo={false} gamePhase={props.game.phase} />
							</QuestionSection>

							<CounterSection>
								{isTimeLimited && <Timer timeLimit={question.qOptions.timeLimit} />}
							</CounterSection>

							<AnswersSection>
								<AnswerOptionList question={question} answerQuestion={(a) => answerQuestion(a)} gameInstanceKey={props.game.key} player={props.player} />
							</AnswersSection>							
						</div>
				}
				{
					question.qOptions.timeLimit !== 0 && question.qType !== 'video_text' &&
						<div style={{display: 'none'}}>
							<ReactPlayer url={`https://www.youtube.com/watch?v=09FF5_aM9rY&origin=${document.location.origin}`} playing={true} loop={true} volume={0.2} muted={false} controls={false}
								onError={(e) => { console.log('onError ', e) } }
							/>
						</div>
				}
			</div>
		)
	} else {
		if (hasAnswered) {
			return (
				<div>
					<YourAnswerArea>Cevabınız: 
						<YourAnswer>
							{
								(selectedAnswer && selectedAnswer.optionImage && selectedAnswer.optionImage.url) ?
									<img src={selectedAnswer.optionImage.url} style={{width: '250px'}} alt="Your answer" />
								:
									<span>{selectedAnswer.optionText}</span>
							}
						</YourAnswer>
					</YourAnswerArea>
				</div>
			)
		} 
	}
	
	
	return (
		<div>
			{
				question &&
					<AnswersSectionLocal>
						<AnswerOptionList question={question} answerQuestion={(a) => answerQuestion(a)} containerClassName="full-height" gameInstanceKey={props.game.key} player={props.player} />
					</AnswersSectionLocal>
			}
		</div>
	)
}
export default PhaseAnswer
