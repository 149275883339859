import React, { useState, useEffect } from 'react'
import { ReactSortable } from 'react-sortablejs'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { calculateDefaultTimeLimit } from '../../../../utils/appUtil'
import QuestionListItem from '../../shared/QuestionListItem'
import { updateQuestionListInQuizInTemplate } from '../../../../data/firebase/live/firebaseGameCreationUtils'
import { updateQuestionListAndStatsInTemplate } from '../../../../data/firebase/lessonTemplateFirebase'

const QuestionCard = styled.div`margin-bottom: 10px; margin-left: 2px; width: calc(100% - 4px); `
const ButtonArea = styled.div`position: -webkit-sticky; position: sticky; top: 0px; zIndex: 10; background-color: white; text-align: center; height: 60px;`

function QuestionList(props) {
	const [questions, setQuestions] = useState([])

	const [gameKey, setGameKey] = useState('')

	useEffect(() => {
		if (props.game) {
			setGameKey(props.game.key)
			
			if (props.game.quiz && props.game.quiz.questions && props.game.quiz.questions.length > 0) {
				let tempQuestions = []
				props.game.quiz.questions.map(q => {
					if (q) {
						tempQuestions.push(q)
					}
					return null
				})
				setQuestions(tempQuestions)
				if (props.activeQuestion) {
					let activeQuestionID = props.activeQuestion ? (props.activeQuestion.id === 0 ? 0 : props.activeQuestion.id - 1) : 0
					props.setActive(props.game.quiz.questions[activeQuestionID])
				}
			}
		}
	}, [props.game])

	const reOrderQuestions = (newOrderedQuestions) => {
		let changed = false
		for (let i = 0; i < newOrderedQuestions.length; i++) {
			if (newOrderedQuestions[i].id !== questions[i].id) {
				changed = true
			}
		}
		if (changed) {
			let tempQuestions = []
			let newQIndex = 0
			for (let i = 0; i < newOrderedQuestions.length; i++ ) {
				let tempQuestion = newOrderedQuestions[i]
				if (tempQuestion) {
					newQIndex++
					tempQuestion.id = newQIndex
					tempQuestions.push(tempQuestion)
				}
			}
			updateQuestionListInQuizInTemplate(gameKey, tempQuestions) // In Firebase
			updateQuestionListAndStatsInTemplate(gameKey, tempQuestions) // In Firestore
		}
	}
	
	const deleteQuestion = (questionToDelete) => {
		let tempQuestions = []
		let newQIndex = 0
		for (let i = 0; i < questions.length; i++ ) {
			let tempQuestion = questions[i]
			if (tempQuestion) {
				if (questionToDelete.id !== tempQuestion.id) {
					newQIndex++
					tempQuestion.id = newQIndex
					tempQuestions.push(tempQuestion)
				}
			}
		}
		setQuestions(tempQuestions)
		updateQuestionListInQuizInTemplate(gameKey, tempQuestions) // In Firebase
		updateQuestionListAndStatsInTemplate(gameKey, tempQuestions) // In Firestore
	}

	const addNewQuestion = () => {
		const question = {
			questionContent: {},
			answerOptions: [],
			qOptions: {
				timeLimit: 10,
				point: 1000
			},
			cType: 'quiz',
			qType: 'image_text',
			aType: 'multiple',
		}
		
		if (questions.length === 0) {
			question.id = 1
		} else {
			question.id = Math.max(...questions.map(q => q && q.id)) + 1
		}

		if (!question.qOptions.timeLimit) {
			question.qOptions.timeLimit = calculateDefaultTimeLimit(question)
		}

		let tempQuestions = [] // [...questions]
		let tempQuestion
		if (!props.activeQuestion) {
			tempQuestions = [...questions]
			tempQuestions.push(question)
		} else {
			for (let i = 0; i < props.activeQuestion.id; i++) {
				tempQuestion = {...questions[i]}
				tempQuestion.id = i+1
				tempQuestions.push(tempQuestion)
			}

			question.id = (props.activeQuestion.id + 1)
			tempQuestions.push(question)

			for (let i = props.activeQuestion.id; i < questions.length; i++) {
				tempQuestion = {...questions[i]}
				tempQuestion.id = i+2
				tempQuestions.push(tempQuestion)
			}
		}
		setQuestions(tempQuestions)
		props.setActive(question)
		updateQuestionListInQuizInTemplate(gameKey, tempQuestions) // In Firebase
		updateQuestionListAndStatsInTemplate(gameKey, tempQuestions) // In Firestore
	}

	useEffect(() => {
		if (props.activeQuestion && props.activeQuestion.id === questions.length && !props.activeQuestion.questionContent) { //Only for new added question
			let element = document.getElementById(`q_${props.activeQuestion.id}`)
			element.scrollIntoView()
		}
	}, [props.activeQuestion])

    return (
		<div>
				<ButtonArea>
					<Fab color="primary" aria-label="add" size="small" onClick={() => addNewQuestion()}>
						<AddIcon />
					</Fab>
				</ButtonArea>
				<ReactSortable list={questions} setList={(e) => {reOrderQuestions(e)}} delayOnTouchStart={true} delay={10}>
					{
						questions.map((question) => question &&
							<QuestionCard key={question.id}>
								<QuestionListItem question={question} id={`q_${question.id}`} activeQuestion={props.activeQuestion}
									deleteQuestion={(q) => deleteQuestion(q)}
									selectQuestion={(q) => { props.setActive(q) }}
								/>
							</QuestionCard>
						)
					}
				</ReactSortable>
				<div style={{marginTop: '50px'}}>&nbsp;</div>
		</div>
    )
}

export default QuestionList
