import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { H1, BaseInput, BaseButton } from '../../components'


const NickNameSetArea = styled(Grid)`max-width: 600px; margin: auto; text-align: center; height: 250px;
    border: solid 1px gainsboro; border-radius: 8px; padding: 50px;
    -webkit-box-shadow: inset 0 0 6px rgba(30, 30, 30, 0.25);
    @media (max-width: 767px) {
		padding: 20px;
	}
`
const SubmitButton = styled(BaseButton)`float: right; width: 200px;`

function GuestUserName(props) {
	const addGuestButton = useRef(0)

	const addGuestAsPlayer = () => {
		addGuestButton.current.setAttribute("disabled", "disabled")
		setTimeout(() => {  
			props.createPlayer();
			if (addGuestButton && addGuestButton.current) {
				addGuestButton.current.removeAttribute("disabled") 
			}
		}, 1000);		
	}
	
	return (
		<NickNameSetArea container>
			<Grid item xs={12}>
				<H1>Misafir kullanıcı adınızı belirleyin.</H1>
			</Grid>
			<Grid item xs={12}>
				<BaseInput value={props.name} onChange={(newValue) => { props.onUpdateName(newValue.replace(/\s{2,}/g, ' ')) }} color="navy" fontSize="48px" textAlign="center" height="60px" maxWidth="600px" width="85%" maxLength="16" />
			</Grid>

			<Grid item xs={12}>
				<SubmitButton type="button" ref={addGuestButton} onClick={() => { addGuestAsPlayer() }} value="BELIRLE VE KATIL" />
			</Grid>								
		</NickNameSetArea>
	)
}

export { GuestUserName }
