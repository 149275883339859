// import { firestore } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'
import { getLessonTemplate, updateQuestionListAndStatsInTemplate } from './lessonTemplateFirebase'
import { deleteLessonResultsForLessonItem } from './lessonResultFirebase'

const createNewSelfPacedLesson = async (_lessonTemplate, newInstanceName) => {
	let firebaseObj = await getFirebaseObj('createNewSelfPacedLesson')
	let firestore = firebaseObj.firestore

	// Create stats and sync firestore lesson template questions with firebase live template // Questions are not in auto-sync when we update a question
	await updateQuestionListAndStatsInTemplate(_lessonTemplate.key, _lessonTemplate.quiz.questions)
	// After stats creations and sync, get template data from firestore to use in New Lesson Item
	let lessonTemplate = await getLessonTemplate(_lessonTemplate.key)
	console.log('lessonTemplate: ', lessonTemplate)

	let newDocRef = await firestore.collection('lessonItems').doc()
	const newDocId = newDocRef.id

	let newLessonItem = {
		created: Date.now(),
		id: newDocId,
		key: newDocId,
		owner: lessonTemplate.owner,
		phase: 'setup',
		quiz: lessonTemplate.quiz,
		stats: lessonTemplate.stats,
		metaData: lessonTemplate.metaData ?  lessonTemplate.metaData : {},
		templateKey: lessonTemplate.templateKey,		
		templateTitle: lessonTemplate.title,
		title: newInstanceName,
		status: 'CREATED',
		userMode: 'SelfPaced'
	}
	newDocRef.set(newLessonItem)

	return newLessonItem
}


const getLessonItems = async (templateKey) => {
	let firebaseObj = await getFirebaseObj('getLessonItems')
	let firestore = firebaseObj.firestore

	let tempLessonItems = []
	let sLessonItems = await firestore.collection('lessonItems').where('templateKey', '==', templateKey).get()
	sLessonItems.docs.map(doc => {
		tempLessonItems.push(doc.data())
		return null
	})
	
	return (tempLessonItems)
}

const getLessonItem = async (key) => {
	let firebaseObj = await getFirebaseObj('getLessonItem')
	let firestore = firebaseObj.firestore

	let tempLessonItems = []
	let sLessonItems = await firestore.collection('lessonItems').where('key', '==', key).get()
	sLessonItems.docs.map(doc => {
		tempLessonItems.push(doc.data())
		return null
	})

	let tempLessonItem
	if (tempLessonItems.length > 0) {
		tempLessonItem = tempLessonItems[0]
	}

	return (tempLessonItem)
}

const deleteLessonItem = async (key, ownerEmail, userEmail) => {
	let firebaseObj = await getFirebaseObj('deleteLessonItem')
	let firestore = firebaseObj.firestore

	if (userEmail === 'yesilokulcom@gmail.com' || userEmail === ownerEmail ) {
		await deleteLessonResultsForLessonItem(key, ownerEmail, userEmail)
		await firestore.collection('lessonItems').doc(key).delete()
	}
}

const updateLessonItemPublishData = async (lessonItemKey, ownerEmail, userEmail, publishData) => {
	let firebaseObj = await getFirebaseObj('updateLessonItemPublishData')
	let firestore = firebaseObj.firestore

	if (userEmail === 'yesilokulcom@gmail.com' || userEmail === ownerEmail ) {
		firestore.collection('lessonItems').doc(lessonItemKey).set({publish: publishData.publish }, {merge: true})
	}
}

export {
	createNewSelfPacedLesson, getLessonItems, getLessonItem, 
	deleteLessonItem, updateLessonItemPublishData
}
