import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import HelpIcon from '@material-ui/icons/Help'
import { getAllStudentCourses, getStudentCoursesOfStudentEmailList } from '../data/firebase/studentCoursesFirebase'
import { filterRegisteredAtLeastOneCourseStudents, filterPaidAtLeastOneCourseStudents, filterWillBePaidAtLeastOneCourseStudents, // Course Based Filters
	filterRegisteredAtLeastOneFreeCourseStudents, filterRegisteredAtLeastOneFullDiscountedCourseStudents, filterRegisteredFreeCourseButNoPaidStudents,
	filterNonCourseRegisteredStudents,	filterStudentsWithMentor, filterStudentsWithoutMentor, // filterCourseRegisteredStudentsWithoutMentor, filterNonCourseRegisteredStudentsWithMentor,
	filterByMentor,
	getUniqueStudentEmailsFromStudentList,
	addStudentCoursesToStudents } from '../data/utils/studentUtils'
import { getAllStudents, getAllMentors, getMyMenteeStudentList } from '../data/firebase/userFirebase'
import { AuthContext } from '../data/context/AuthProvider'
import { Layout2, BaseInput } from '../components'
import { StudentList } from '../PageBlocks/student/StudentList'
// import { updateAllStudentCourseRecords } from '../data/utils/studentUtils'

function Students(props) {

	const [authData] = useContext(AuthContext)
	const [allStudents, setAllStudents] = useState()
	const [filteredStudents, setFilteredStudents] = useState()
	const [allStudentCourses, setAllStudentCourses] = useState()
	const [filterByCourseRegistrationType, setFilterByCourseRegistrationType] = useState('all')
	const [filterByMentorType, setFilterByMentorType] = useState('all')
	const [showMentorList, setShowMentorList] = useState(true)
	const [selectedMentor, setSelectedMentor] = useState()
	const [allMentorList, setAllMentorList] = useState([])
	const [searchText, setSearchText] = useState('')
	const [daysFromRegister, setDaysFromRegister] = useState(1)

	const [userMode, setUserMode] = useState('mentor')

	useEffect(async () => {
		if (authData && authData.email && authData.roles && authData.roles.length > 0 && (authData.roles.some(role => ['mentor'].indexOf(role) >= 0) || authData.roles.some(role => ['owner'].indexOf(role) >= 0)) ) {
			if (authData.roles.some(role => ['mentor'].indexOf(role) >= 0) && authData.roles.some(role => ['owner'].indexOf(role) === -1)) { // Only mentor not owner
				setSelectedMentor(authData.email)

				let tmpMyMenteeStudents = await getMyMenteeStudentList(authData.email)
				let menteeStudentsEmailList = await getUniqueStudentEmailsFromStudentList(tmpMyMenteeStudents)
				let tmpMenteeStudentCourses = await getStudentCoursesOfStudentEmailList(menteeStudentsEmailList)
				setAllStudentCourses(tmpMenteeStudentCourses)
				tmpMyMenteeStudents = await addStudentCoursesToStudents(tmpMyMenteeStudents, tmpMenteeStudentCourses)
				setAllStudents(tmpMyMenteeStudents)
				setUserMode('mentor')
			}
			if (authData.roles.some(role => ['owner'].indexOf(role) >= 0)) { // Only owner
				let tmpStudents = await getAllStudents(authData.email, -1 * daysFromRegister) // Last 7 days registered

				let tmpAllStudentCourses = await getAllStudentCourses(authData.email)
				setAllStudentCourses(tmpAllStudentCourses)
					
				tmpStudents = await addStudentCoursesToStudents(tmpStudents, tmpAllStudentCourses)
				setAllStudents(tmpStudents)
				
				let tmpMentors = await getAllMentors()
				setAllMentorList(tmpMentors)
				setUserMode('owner')
			}
		}
	}, [authData, daysFromRegister])

	useEffect(async () => {
		if (allStudents && allStudents.length > 0) {
			let tmpStudents = await applyCourseRegistrationTypeFilter(allStudents)
			tmpStudents = await applyMentorTypeFilter(tmpStudents)
			if (showMentorList && selectedMentor) {
				tmpStudents = await applyMentorFilter(tmpStudents)
			}
			if (searchText && searchText.length > 0) {
				tmpStudents = await applySearchTextFilter(tmpStudents)
			}
			setFilteredStudents(tmpStudents)
		}
	}, [filterByCourseRegistrationType, filterByMentorType, selectedMentor, allStudents, allMentorList, searchText])

	const applyCourseRegistrationTypeFilter = async (studentsToFilter) => {
		let tempFilteredStudents = []
		if (filterByCourseRegistrationType === 'all') {
			tempFilteredStudents = studentsToFilter
			setShowMentorList(true)
		} else if (filterByCourseRegistrationType === 'registered-at-least-one-course') {
			let tmpStudents = await filterRegisteredAtLeastOneCourseStudents(studentsToFilter, allStudentCourses)
			tmpStudents = await addStudentCoursesToStudents(tmpStudents, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(true)
		} else if (filterByCourseRegistrationType === 'paid-at-least-one-course') {
			let tmpStudents = await filterPaidAtLeastOneCourseStudents(studentsToFilter, allStudentCourses)
			tmpStudents = await addStudentCoursesToStudents(tmpStudents, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(true)
		} else if (filterByCourseRegistrationType === 'will-be-paid-at-least-one-course') {
			let tmpStudents = await filterWillBePaidAtLeastOneCourseStudents(studentsToFilter, allStudentCourses)
			tmpStudents = await addStudentCoursesToStudents(tmpStudents, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(true)
		} else if (filterByCourseRegistrationType === 'registered-at-least-one-full-discounted-course') {
			let tmpStudents = await filterRegisteredAtLeastOneFullDiscountedCourseStudents(studentsToFilter, allStudentCourses)
			tmpStudents = await addStudentCoursesToStudents(tmpStudents, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(true)
		} else if (filterByCourseRegistrationType === 'registered-at-least-one-free-course') {
			let tmpStudents = await filterRegisteredAtLeastOneFreeCourseStudents(studentsToFilter, allStudentCourses)
			tmpStudents = await addStudentCoursesToStudents(tmpStudents, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(true)
		} else if (filterByCourseRegistrationType === 'registered-free-course-but-no-paid') {
			let tmpStudents = await filterRegisteredFreeCourseButNoPaidStudents(studentsToFilter, allStudentCourses)
			tmpStudents = await addStudentCoursesToStudents(tmpStudents, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(true)
		} else if (filterByCourseRegistrationType === 'no-course-yet') {
			let tmpStudents = await filterNonCourseRegisteredStudents(studentsToFilter, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(true)
		}
		return tempFilteredStudents
	}

	const applyMentorTypeFilter = async (studentsToFilter) => {
		let tempFilteredStudents = []
		if (filterByMentorType === 'all') {
			tempFilteredStudents = studentsToFilter
			setShowMentorList(true)
		} else if (filterByMentorType === 'have-mentor') {
			let tmpStudents = await filterStudentsWithMentor(studentsToFilter)
			tmpStudents = await addStudentCoursesToStudents(tmpStudents, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(true)
		} else if (filterByMentorType === 'does-not-have-mentor') {
			let tmpStudents = await filterStudentsWithoutMentor(studentsToFilter)
			tmpStudents = await addStudentCoursesToStudents(tmpStudents, allStudentCourses)
			tempFilteredStudents = tmpStudents
			setShowMentorList(false)
		}
		return tempFilteredStudents
	}

	const applyMentorFilter = async (pStudents) => {
		if (selectedMentor === 'all' || !selectedMentor) {
			return pStudents
		} else {
			let tmpStudents = await filterByMentor(pStudents, selectedMentor)
			return tmpStudents
		}
	}

	const applySearchTextFilter = async (pStudents) => {
		let tempFilteredStudents = [...pStudents]
		if (searchText && searchText.length > 0) {
			tempFilteredStudents = tempFilteredStudents.filter(_student => 
				(_student.name && _student.name.toLowerCase().indexOf(searchText) >= 0)
				|| (_student.email && _student.email.toLowerCase().indexOf(searchText) >= 0)
				|| ( _student.phone && _student.phone.toLowerCase().indexOf(searchText) >= 0)
				|| (_student.location && _student.location.country_name && _student.location.country_name.toLowerCase().indexOf(searchText) >= 0)
				|| (_student.location && _student.location.region && _student.location.region.toLowerCase().indexOf(searchText) >= 0)
				|| (_student.location && _student.location.city && _student.location.city.toLowerCase().indexOf(searchText) >= 0)
				|| (_student.mentorship && _student.mentorship.mentor && _student.mentorship.mentor.toLowerCase().indexOf(searchText) >= 0)
				|| ( _student.parentName && _student.parentName.toLowerCase().indexOf(searchText) >= 0)
				|| (_student.status && _student.status.toLowerCase().indexOf(searchText) >= 0)
				|| (_student.age && _student.age.label && _student.age.label.toLowerCase().indexOf(searchText) >= 0)
			)
		}
		return tempFilteredStudents
	}

	return (
		<Layout2 withAuth={true}>
		{/* <div style={{cursor: 'pointer'}} onClick={() => { alert('updateAllStudentCourseRecords starting'); updateAllStudentCourseRecords()} } >KURSLARI GUNCELLE</div> */}
			{
				allStudents ?
					<Grid container style={{marginTop: '10px'}}>
						<Grid item xs={12} sm={4}>
							<FormControl style={{width: '50%'}}>
								<InputLabel htmlFor="mentor">Filter By Course Registration</InputLabel>
								<Select value={filterByCourseRegistrationType} onChange={(e) =>  { setFilterByCourseRegistrationType(e.target.value)} }>
									<MenuItem value="all">All Students</MenuItem>
									<MenuItem value="registered-at-least-one-course">Registered At Least One Course</MenuItem>
									<MenuItem value="paid-at-least-one-course">Paid At Least One Course</MenuItem>
									<MenuItem value="will-be-paid-at-least-one-course">Will Be Paid At Least One Course</MenuItem>
									<MenuItem value="registered-at-least-one-full-discounted-course">Registered At Least One Full Discounted Course</MenuItem>
									<MenuItem value="registered-free-course-but-no-paid">Registered Free Course But No Paid</MenuItem>
									<MenuItem value="no-course-yet">No Course Yet</MenuItem>
									{/* <MenuItem value="have-mentor">Has Mentor</MenuItem>
									<MenuItem value="does-not-have-mentor">Does Not Have Mentor</MenuItem>
									<MenuItem value="does-not-have-mentor-but-have-course">Bought Course But Does Not Have Mentor</MenuItem>
									<MenuItem value="have-mentor-but-no-course">Has Mentor But No Course</MenuItem> */}
								</Select>
							</FormControl>
							{
								userMode === 'owner' &&
									<FormControl style={{width: '50%'}}>
										<InputLabel htmlFor="mentor">Filter By Mentor</InputLabel>
										<Select value={filterByMentorType} onChange={(e) =>  { setFilterByMentorType(e.target.value)} }>
											<MenuItem value="all">All Students</MenuItem>
											<MenuItem value="have-mentor">Has Mentor</MenuItem>
											<MenuItem value="does-not-have-mentor">Does Not Have Mentor</MenuItem>
										</Select>
									</FormControl>
							}
						</Grid>
						<Grid item xs={12} sm={2}>
							{
								userMode === 'owner' && showMentorList &&
									<FormControl style={{width: '100%'}}>
										<InputLabel htmlFor="mentor">Mentor</InputLabel>
										<Select value={selectedMentor} onChange={(e) =>  { setSelectedMentor(e.target.value)} }>
											<MenuItem value="all">All</MenuItem>
											{
												allMentorList && allMentorList.length > 0 && allMentorList.map((_mentor, index) => (
													<MenuItem value={_mentor.email} key={index}>{_mentor.name}</MenuItem>
												))
											}
										</Select>
									</FormControl>
							}
						</Grid>
						<Grid item xs={12} sm={2}>
							<FormControl style={{width: '100%'}}>
								<InputLabel htmlFor="mentor">Kayıt Tarihinden Geçen Gün</InputLabel>
								<Select value={daysFromRegister} onChange={(e) =>  { setDaysFromRegister(e.target.value)} }>
									<MenuItem value="1">1 Gun</MenuItem>
									<MenuItem value="3">3 Gün</MenuItem>
									<MenuItem value="7">7 Gün</MenuItem>
									<MenuItem value="15">15 Gün</MenuItem>
									<MenuItem value="30">30 Gün</MenuItem>
									<MenuItem value="1000">Tüm Kayıtlar</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={4}>
							<BaseInput value={searchText} onChange={(newValue) => setSearchText(newValue.toLowerCase())} width="calc(100% - 80px)" /> <Tooltip title="Search students by; Name, Email, Phone Number, Country, City, Mentor Name, Parent Name and Status."><HelpIcon /></Tooltip>
						</Grid>
						<Grid item xs={12}>
							{
								filteredStudents &&
									<StudentList students={filteredStudents} />
							}
						</Grid>
					</Grid>
				:
					<div></div>
			}
		</Layout2>
	)
}

export default Students
