import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import QuestionVideo from './QuestionVideo'
import QuestionImage from './QuestionImage'
import { H1 } from '../../../components'

const ComponentArea = styled.div``
const QuestionText = styled.div`width: 100%; text-align: center; margin-top: 10px; font-size: 64px; color: black;`

function QuestionContent(props) {
	const [screenWidth, setScreenWidth] = useState(1000)

	useEffect(() => {
		setScreenWidth(window.innerWidth)
	}, [])

	let qfontSize = '18px'
	if (screenWidth > 960) {
		qfontSize = props.question.questionContent.texts[0].text.length < 30 ? '48px'
			: (props.question.questionContent.texts[0].text.length < 60 ? '36px'
				: (props.question.questionContent.texts[0].text.length < 90 ? '28px'
					: (props.question.questionContent.texts[0].text.length < 120 ? '24px': '20px' 
				)
			)
		)
	} else {
		qfontSize = props.question.questionContent.texts[0].text.length < 30 ? '24px'
			: (props.question.questionContent.texts[0].text.length < 60 ? '20px'
				: (props.question.questionContent.texts[0].text.length < 90 ? '18px'
					: (props.question.questionContent.texts[0].text.length < 120 ? '16px'
						: '14px' 
					)
				)
			)
	}

    return (
        <ComponentArea>
			<QuestionText style={{fontSize: qfontSize}}>
				{props.question.questionContent.texts[0].text}
			</QuestionText>
			
			{
				(
					props.question.qType === 'image_text' 
					&& props.question.questionContent 
					&& props.question.questionContent.images 
					&& props.question.questionContent.images.length > 0
					&& props.question.questionContent.images[0].url
					&& props.question.questionContent.images[0].url.length > 0
				) &&
					<QuestionImage question={props.question} gameFunc={props.gameFunc} gameKey={props.gameKey} brushColor={props.imageBrushColor} gamePhase={props.gamePhase} />
			}

			{
				(props.question.qType === 'video_text' && props.question.questionContent && props.question.questionContent.videos && props.question.questionContent.videos.length > 0 && props.question.questionContent.videos[0].url && props.question.questionContent.videos[0].url.length > 0) &&
					<div>
						{
							props.hideVideo === true ? null :
								props.videoControlType === 'host' ?
									<QuestionVideo playing={props.videoPlaying} question={props.question} videoInfo={props.question.questionContent.videos[0]} onEnd={props.onVideoEnd} lessonInstanceKey={props.gameKey}
										useMaxScreen={props.videoUseMaxScreen} controlType={props.videoControlType} mode={props.mode} videoVolume={props.videoVolume}
									/>
								:
									<>
										{
											(props.question.questionContent.videos[0] && props.question.questionContent.videos[0].soundOption === 3) ? // 3 = ONLY SOUND  
												<H1 style={{color: 'red', marginTop: '20px'}}>Sesi birlikte dinleyelim.</H1>
											: // (1 - VIDEO+SOUND   OR   2 - ONLY VIDEO)
												<H1 style={{color: 'red', marginTop: '20px'}}>Video için öğretmen ekranına bakın!</H1>
										}
									</>
						}
					</div>
			}
			{
				(props.question.qType === 'embed_url') &&
					<div>
						{
							props.question.questionContent.embedUrl && props.question.questionContent.embedUrl.length > 0 ?
								<div>
									<iframe src={props.question.questionContent.embedUrl} style={{width: '100%', height: 'calc(100vh - 160px)'}} title="Embedded URL" frameBorder="0" border="0" cellSpacing="0" />
								</div>
							:
								<div style={{width: '100%', height: '300px'}}>No URL Found. Go to next slide.</div>
						}
					</div>
			}
		</ComponentArea>
    )
}

export default QuestionContent
