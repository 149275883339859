const getLessonTemplateListStats = async (lessonTemplates) => {
	let allSlidesCount = 0

	let booleanCount = 0
	let freeTextCount = 0
	let imageUploadCount = 0
	let multipleCount = 0

	let contentSlidesCount = 0	
	let formSlidesCount = 0
	let pollSlidesCount = 0
	let quizSlidesCount = 0
	
	let imageCount = 0
	let textCount = 0
	let videoCount = 0

	for (let i = 0; i < lessonTemplates.length; i++) {
		let activeLessonTemplate = lessonTemplates[i]
		if (activeLessonTemplate && activeLessonTemplate.stats) {
			allSlidesCount += activeLessonTemplate.stats.allSlidesCount

			booleanCount += activeLessonTemplate.stats.aType.booleanCount
			freeTextCount += activeLessonTemplate.stats.aType.freeTextCount
			imageUploadCount += activeLessonTemplate.stats.aType.imageUploadCount
			multipleCount += activeLessonTemplate.stats.aType.multipleCount
			
			contentSlidesCount += activeLessonTemplate.stats.cType.contentCount
			formSlidesCount += activeLessonTemplate.stats.cType.formCount
			pollSlidesCount += activeLessonTemplate.stats.cType.pollCount
			quizSlidesCount += activeLessonTemplate.stats.cType.quizCount
	
			imageCount += activeLessonTemplate.stats.qType.imageCount
			textCount += activeLessonTemplate.stats.qType.textCount
			videoCount += activeLessonTemplate.stats.qType.videoCount
		}		
	}

	let lessonTemplatesStats = {
		allLessonTemplatesCount: lessonTemplates.length,
		allSlidesCount: allSlidesCount,
		aType: {
			boolean: booleanCount,
			freeText: freeTextCount,
			imageUpload: imageUploadCount,
			multiple: multipleCount,
		},
		cType: {
			content: contentSlidesCount,
			form: formSlidesCount,
			poll: pollSlidesCount,
			quiz: quizSlidesCount,
		},
		qType: {
			image: imageCount,
			text: textCount,
			video: videoCount
		}					
	}

	return lessonTemplatesStats
}

export { getLessonTemplateListStats }
