import React, { useEffect }  from 'react'
import styled from 'styled-components'
import Podium from '../shared/Podium'
import { H1 } from '../../../components'
import { analyzeLesson } from '../../../data/firebase/lessonInstanceFirebase'

const HeaderText = styled(H1)`font-size: 36px; margin-top: 15px;`

function PhaseFinalResult(props) {
    useEffect(() => {
        analyzeLesson(props.game, 2)
    }, [])

    return (
        <div>                
            <HeaderText>SONUÇLAR</HeaderText>
            <Podium game={props.game} />
        </div>
    )
}

export default PhaseFinalResult
