import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { ReactSortable } from 'react-sortablejs'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { AuthContext } from '../../data/context/AuthProvider'
import { UpdateCurriculumBaseData } from '../../data/firebase/curriculumFirebase'
import { getAllLessonTemplatesOfCurriculum } from '../../data/firebase/lessonTemplateFirebase'
import { FormElementLabel, BaseInput, BaseButton, Toggle } from '../../components'
import CurriculumSection from './CurriculumSection'
import MetaDataManager from './MetaForms/MetaDataManager'

const tabs = [
	{ id: 'design', label: 'Tasarım' },
	{ id: 'meta', label: 'Bilgi' },
]
let changeFromInside = false

const NewButton = styled(BaseButton)`float: right; width: 150px; height: 24px; font-size: 14px; color: white;`

function CurriculumDesigner(props) {
	let history = useHistory()
	const [authData] = useContext(AuthContext)
	
	const [tab, setTab] = useState((props.urlParams && props.urlParams.param2) ? props.urlParams.param2 : 'design')
	const [curriculum, setCurriculum] = useState(props.curriculum ? props.curriculum : {})
	const [title, setTitle] = useState('')
	const [usedLessonTemplates, setUsedLessonTemplates] = useState([])
	const [sections, setSections] = useState([])
	const [canEditCurriculum, setCanEditCurriculum] = useState(false)

	useEffect(() => {
		if (!props.urlParams || !props.urlParams.param2 || props.urlParams.param2 !== tab) {
			history.push(`/curriculum-designer/${curriculum.id}/${tab}`)
		}
	}, [tab])

	useEffect(async () => {
		changeFromInside = false
		if (props.curriculum) {
			setCurriculum(props.curriculum)
			if (props.curriculum.sections) {
				setSections(props.curriculum.sections)
			}
			setTitle(props.curriculum.title)
			let lsTemp = await getAllLessonTemplatesOfCurriculum(props.curriculum.id)
			setUsedLessonTemplates(lsTemp)

			
			if (authData && authData.email && 
				(
					(authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 )) || // System Owner
					(props.curriculum && props.curriculum.meta && props.curriculum.meta.users && props.curriculum.meta.users.admins && props.curriculum.meta.users.admins.indexOf(authData.email) >= 0) || // Curriculum admin
					(props.curriculum && props.curriculum.meta && props.curriculum.meta.users && props.curriculum.meta.users.editors && props.curriculum.meta.users.editors.indexOf(authData.email) >= 0) || // Curriculum editor				
					(authData.email === props.curriculum.owner) // Curriculum owner
				)
			) {
				setCanEditCurriculum(true)
			}
		}
	}, [props.curriculum])

	useEffect(() => {
		if (changeFromInside) {
			UpdateCurriculumBaseData(curriculum.id, curriculum)
		}		
	}, [curriculum])
	
	const updateCurriculumBase = () => {
		changeFromInside = true
		setCurriculum( { ...curriculum, title : title })
	}

	const createNewSection = () => {
		let tempSections = sections ? [...sections] : []
		let emptySection = {
			title: 'Yeni Bölüm',
			order: tempSections.length,
			id: uuidv4(),
		}
		tempSections.push(emptySection)
		changeFromInside = true
		setCurriculum( { ...curriculum, sections : tempSections })
		setSections(tempSections)
	}

	const addNewLesson = (section) => {
		if (section) {
			let tempSection = {...section}
			let emptyLesson = {
				title: 'Yeni Ders',
				order: tempSection.lessons ? tempSection.lessons.length : 0,
				id: uuidv4(),
			}
			if (!tempSection.lessons) {
				tempSection.lessons = []
			}
			tempSection.lessons.push(emptyLesson)
			updateSection(tempSection)
		}
	}

	const updateLesson = (section, lesson) => {
		if (section && lesson) {
			let tempSection = {...section}
			let tempLessons = [...section.lessons]
			var foundIndex = tempLessons.findIndex(x => x.id === lesson.id)
			if (foundIndex >= 0) {
				tempLessons[foundIndex] = lesson
				tempSection.lessons = tempLessons
				updateSection(tempSection)
			} else {
				console.log('Lesson could not be found: ', foundIndex)
			}
		}
	}

	const deleteLesson = (section, lesson) => {
		if (section && lesson) {
			let tempSection = {...section}
			let tempLessons = section.lessons.filter(x => x.id !== lesson.id)
			if (tempLessons) {
				tempSection.lessons = tempLessons
				updateSection(tempSection)
			}
		}
	}

	const updateSection = (section) => {
		let tempSections = sections ? [...sections] : []
		if (tempSections.length > 0) {
			var foundIndex = tempSections.findIndex(x => x.id === section.id)
			tempSections[foundIndex] = section
			
			changeFromInside = true
			setCurriculum( { ...curriculum, sections : tempSections })
			setSections(tempSections)
		}
	}

	const deleteSection = (section) => {
		if (section) {
			let tempSections = sections.filter(x => x.id !== section.id)
			changeFromInside = true
			setCurriculum( { ...curriculum, sections : tempSections })
			setSections(tempSections)
		}
	}

	const reOrderSections = (newOrderedList) => {
		let changed = false
		for (let i = 0; i < newOrderedList.length; i++) {
			if (newOrderedList[i].id !== sections[i].id) {
				changed = true
			}
		}
		if (changed) {
			let tempItems = []
			let newItemIndex = 0
			for (let i = 0; i < newOrderedList.length; i++ ) {
				let tempItem = newOrderedList[i]
				if (tempItem) {					
					tempItem.order = newItemIndex
					tempItems.push(tempItem)
					newItemIndex++
				}
			}
			setCurriculum( { ...curriculum, sections : tempItems })
			setSections(tempItems)
		}
	}

	const updateCurriculumMeta = (meta) => {
		changeFromInside = true
		setCurriculum( { ...curriculum, meta: meta })
	}
	

	return (
		<div>
			<Grid container>
				<Grid item xs={12} style={{borderBottom: 'solid 1px gainsboro', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginTop: '20px', marginBottom: '5px'}}>
					<Grid container>
						<Grid item xs={12} md={1}>
							<FormElementLabel style={{lineHeight: '34px', marginLeft: '5px'}}>Müfredat Adı</FormElementLabel>
						</Grid>
						<Grid item xs={12} md={6}>
							<BaseInput value={title} maxLength="80" onChange={(newValue) => setTitle(newValue) } onBlur={() => updateCurriculumBase()} />
						</Grid>
						<Grid item xs={12} md={1}>							
							{
								authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) &&
									<a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fcurriculums~2F${props.curriculum.id}`} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a>
							}
						</Grid>
						<Grid item xs={12} md={4}>
							<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
						</Grid>
					</Grid>
				</Grid>
				{
					tab === 'design' &&
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={false} sm={2}></Grid>
								<Grid item xs={8}>
									{
										canEditCurriculum &&
											<NewButton type="button" onClick={() => createNewSection()} value="BÖLÜM EKLE" />
									}
								</Grid>
								<Grid item xs={false} sm={2}></Grid>
								<Grid item xs={false} sm={2}></Grid>
								<Grid item xs={12} sm={8}>
									{
										sections &&
											<div>
												<ReactSortable list={sections} setList={(e) => {reOrderSections(e)}} delayOnTouchStart={true} delay={10} filter={"input"} preventOnFilter={false}>
													{
														sections.map((section, index) => section &&
															<CurriculumSection section={section} key={section.id} usedLessonTemplates={usedLessonTemplates} editable={canEditCurriculum}
																onUpdateSection={(s) => updateSection(s)} 
																onDeleteSection={(s) => deleteSection(s)}
																onCreateNewLesson={(s) => addNewLesson(s)} 
																onUpdateLesson={(section, lesson) => updateLesson(section, lesson)}
																onDeleteLesson={(section, lesson) => {deleteLesson(section, lesson)}}
																index={index}
															/>
														)
													}
												</ReactSortable>
											</div>
									}
								</Grid>
								<Grid item xs={false} sm={2}></Grid>
							</Grid>
						</Grid>
				}
				{
					tab === 'meta' &&
						<Grid item xs={12}>
							<MetaDataManager curriculum={curriculum} urlParams={props.urlParams} onUpdateMeta={(meta) => {updateCurriculumMeta(meta)}} editable={canEditCurriculum} />
						</Grid>
				}
			</Grid>
		</div>
	)
}

export default CurriculumDesigner
