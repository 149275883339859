import firebase from 'firebase'
import moment from 'moment'
import { getCurriculumById } from './curriculumFirebase'
import { getAllLessonTemplatesOfCurriculum } from './lessonTemplateFirebase'
// import { firestore, storage } from '../../utils/firebaseSettings'
import { getFirebaseObj } from '../../utils/firebaseSettings'
import { createSlug, convertImgURLToDataURLviaCanvas, base64toBlob, resizeImage } from '../../utils/utilityFunctions'
import { getRegisteredStudentsForCourseList, addRegisteredStudentsIntoCourses } from '../utils/courseUtils'

const getCoursesOfStaff = async (ownerEmail) => {
	let firebaseObj = await getFirebaseObj('getCoursesOfStaff')
	let firestore = firebaseObj.firestore

	let tempCourses = []
	if (ownerEmail === 'yesilokulcom@gmail.com') {
		let sCourses = await firestore.collection('courses').get()
		sCourses.docs.map(doc => {
			tempCourses.push(doc.data())
			return null
		})
	} else {
		let sCourses = await firestore.collection('courses').where('owner', '==', ownerEmail).get()
		sCourses.docs.map(doc => {
			tempCourses.push(doc.data())
			return null
		})
	}

	tempCourses.sort(function(a,b) { // Sort by date
		if (a.meta && a.meta.dates && b.meta && b.meta.dates) {
			if (a.meta.dates.courseCreated < b.meta.dates.courseCreated) return 1
			if (a.meta.dates.courseCreated > b.meta.dates.courseCreated) return -1
			return 0
		} else {
			return 0
		}
	})

	let upComing60DaysCourses = tempCourses.filter(_course => _course.meta && _course.meta.stats && _course.meta.stats.firstLessonDate && ( (_course.meta.stats.firstLessonDate.toDate() > moment().add(-120, 'days').toDate() ) && ( _course.meta.stats.firstLessonDate.toDate() < moment().add(60, 'days').toDate() ) ) )
	
	let coursesStudents = await getRegisteredStudentsForCourseList(upComing60DaysCourses)
	tempCourses = await addRegisteredStudentsIntoCourses(tempCourses, coursesStudents)

	console.log('tempCourses:', tempCourses)

	return (tempCourses)
}


const getCourseById = async (ownerEmail, id) => {
	let firebaseObj = await getFirebaseObj('getCourseById')
	let firestore = firebaseObj.firestore

	let tempCourses = []
	if (ownerEmail === 'yesilokulcom@gmail.com') {
		let sCourses = await firestore.collection('courses').where('id', '==', id).get()
		sCourses.docs.map(doc => {
			tempCourses.push(doc.data())
			return null
		})
	} else {
		let sCourses = await firestore.collection('courses').where('owner', '==', ownerEmail).where('id', '==', id).get()
		sCourses.docs.map(doc => {
			tempCourses.push(doc.data())
			return null
		})		
	}
	let course = tempCourses && tempCourses.length > 0 ? tempCourses[0] : null
	console.log('course: ', course)
	return course
}

const getCoursesByCourseIdList = async (courseIds) => {
	let firebaseObj = await getFirebaseObj('getCoursesByCourseIdList')
	let firestore = firebaseObj.firestore

	let courses = []
	let tmpCourseIds = []
	for (let i = 0; i < courseIds.length; i++) {
		tmpCourseIds.push(courseIds[i])
		if (tmpCourseIds.length === 10 || i === courseIds.length - 1 ) {
			if (tmpCourseIds.length <= 10) {
				let sCourses = await firestore.collection('courses').where('id', 'in', tmpCourseIds).get()
				let tempCourses = []
				sCourses.docs.map(doc => {
					tempCourses.push(doc.data())
					return null
				})
				courses = courses.concat(tempCourses)
			}
			tmpCourseIds = []
		}
	}
	return courses		
}

const searchCourses = async (searchText, _callback) => {
	let firebaseObj = await getFirebaseObj('searchCourses')
	let firestore = firebaseObj.firestore

	let sCourses = await firestore.collection('courses').where('id', '==', searchText).get()
	let tempCourses = []
	if (sCourses) {
		sCourses.docs.map(doc => {
			tempCourses.push(doc.data())
			return null
		})
	}
	_callback(tempCourses)
}

const createNewCourse = async (ownerEmail, curriculum) => {
	let firebaseObj = await getFirebaseObj('createNewCourse')
	let firestore = firebaseObj.firestore

	const curriculumCheckResult = await checkCurriculumForNewCourse(curriculum)

	if (curriculumCheckResult.result === 'failed') {
		return {
			result: 'failed',
			errorMessage: curriculumCheckResult.errorMessage
		}
	}	
	
	let newDocRef = await firestore.collection('courses').doc()
	const newDocId = newDocRef.id
	
	let newCourse = {...curriculum}
	newCourse.id = newDocId
	let slugText = createSlug(curriculum.meta.intro.title ? curriculum.meta.intro.title : curriculum.title) + '-' + newDocId
	newCourse.meta.settings = { slug: slugText }

	newCourse = await updateCourseContentWithCurriculum(newCourse, curriculum)
	newCourse.meta.stats.created = firebase.firestore.Timestamp.fromDate(new Date())
	
	newDocRef.set(newCourse)

	// COPY IMAGES AND CREATE THUMBNAILS
	createCourseImages(newCourse, slugText, newDocRef)
	
	return {
		result: 'success',
		docId: newDocId
	}
}

const updateCourseData = async (courseId, data) => {
	let firebaseObj = await getFirebaseObj('updateCourseData')
	let firestore = firebaseObj.firestore

	await firestore.collection('courses').doc(courseId).set(data, {merge: true})
}

const syncCourseWithCurriculum = async (userName, courseId, curriculumId, callback) => {
	let firebaseObj = await getFirebaseObj('syncCourseWithCurriculum')
	let firestore = firebaseObj.firestore

	let oldCourse = await getCourseById(userName, courseId)	
	let courseDocRef = await firestore.collection('courses').doc(courseId)
	let curriculum = await getCurriculumById(userName, curriculumId)	
	let newCourse = {...curriculum}

	newCourse = await updateCourseContentWithCurriculum(newCourse, curriculum)

	newCourse.id = oldCourse.id
	newCourse.meta.settings = oldCourse.meta.settings ? oldCourse.meta.settings : {}
	newCourse.meta.dates.courseLastUpdated = Date.now()
	newCourse.meta.pricing = oldCourse.meta.pricing ? oldCourse.meta.pricing : {}
	
	newCourse.meta.stats.created = oldCourse.meta.stats.created ? oldCourse.meta.stats.created : ''
	newCourse.meta.stats.firstLessonDate = oldCourse.meta.stats.firstLessonDate ? oldCourse.meta.stats.firstLessonDate : ''
	newCourse.meta.stats.lastLessonDate = oldCourse.meta.stats.firstLessonDate ? oldCourse.meta.stats.lastLessonDate : ''
	newCourse.meta.stats.updated = firebase.firestore.Timestamp.fromDate(new Date())

	for (let i = 0; i < newCourse.sections.length; i++) {
		let section = {...newCourse.sections[i]}
		for (let j = 0; j < section.lessons.length; j++) {
			let lesson = {...section.lessons[j]}
			if (oldCourse.sections && oldCourse.sections.length > 0 && oldCourse.sections[i] && oldCourse.sections[i].lessons && oldCourse.sections[i].lessons.length > 0 && oldCourse.sections[i].lessons[j] && oldCourse.sections[i].lessons[j].courseSpecificData) {
				lesson.courseSpecificData = oldCourse.sections[i].lessons[j].courseSpecificData
			}
			section.lessons[j] = lesson
		}
		newCourse.sections[i] = section
	}
	await courseDocRef.set(newCourse)

	// COPY IMAGES AND CREATE THUMBNAILS
	await createCourseImages(newCourse, oldCourse.meta.settings.slug, courseDocRef, async () => {
		callback({
			result: 'success',
			docId: newCourse.id
		})
	})
}

const deleteCourse = async (courseId) => {
	let firebaseObj = await getFirebaseObj('deleteCourse')
	let firestore = firebaseObj.firestore

	const deleteResponse = await firestore.collection('courses').doc(courseId).delete()
	return deleteResponse
}



// ======== INTERNAL USAGE FUNCTIONS  =============


const checkCurriculumForNewCourse = async (curriculum) => {
	if (!curriculum) { return { result: 'failed', errorMessage: 'Curriculum is undefined' } }
	if (!curriculum.meta) { return { result: 'failed', errorMessage: 'Curriculum meta data is undefined. Please fill the meta data' } }
	if (!curriculum.meta.goals) { return { result: 'failed', errorMessage: 'Curriculum goals are undefined. Please add goals' } }
	if (curriculum.meta.goals && curriculum.meta.goals.length === 0) { return { result: 'failed', errorMessage: 'Curriculum does not have any goals. Please add goals' } }
	if (!curriculum.meta.studentGroup) { return { result: 'failed', errorMessage: 'Student groups are undefined. Please add student groups' } }
	if (curriculum.meta.studentGroup && curriculum.meta.studentGroup.length === 0) { return { result: 'failed', errorMessage: 'Curriculum does not have target student group. Please add target student groups' } }

	return {
		result: 'success'
	}
}

const updateNewCourseSections = async (newCourse, curriculumId) => {
	let lessonCount = 0
	let allLessonTemplates = await getAllLessonTemplatesOfCurriculum(curriculumId)

	for (let i = 0; i < newCourse.sections.length; i++ ) {
		let section = {...newCourse.sections[i]}
		lessonCount += section.lessons.length
		
		for (let j = 0; j < section.lessons.length; j++ ) {
			let lesson = {...section.lessons[j]}
			let lessonTemplate = allLessonTemplates.filter(lessonTemplate => lessonTemplate.key === lesson.lessonTemplateKey)[0]
			
			if (lessonTemplate) {
				if (lessonTemplate.title) { lesson.templateTitle = lessonTemplate.title }
				if (lessonTemplate.metaData) { lesson.templateMetaData = lessonTemplate.metaData }
				if (lessonTemplate.stats) { lesson.templateStats = lessonTemplate.stats }
			}
			section.lessons[j] = lesson
		}
		newCourse.sections[i] = section
	}

	return {
		newCourse,
		lessonCount
	}
}

const updateCourseContentWithCurriculum = async (course, curriculum) => {
	let newCourse = {...course}
	newCourse.meta.curriculum = {curriculumId : curriculum.id }
	newCourse.meta.dates.courseCreated = Date.now()
	newCourse.meta.dates.courseLastUpdated = Date.now()
	newCourse.courseName = curriculum.meta.intro.title ? curriculum.meta.intro.title : (curriculum.title ? curriculum.title : '')

	delete newCourse.label
	delete newCourse.title

	let updateSectionsResult = await updateNewCourseSections(newCourse, curriculum.id)
	newCourse = updateSectionsResult.newCourse

	newCourse.meta.stats = {
		sectionCount: newCourse.sections.length,
		lessonCount: updateSectionsResult.lessonCount		
	}
	return newCourse
}

const createCourseImages = async (newCourse, slugText, courseDocRef, callback) => {
	let firebaseObj = await getFirebaseObj('createCourseImages')
	let storage = firebaseObj.storage

	await convertImgURLToDataURLviaCanvas(newCourse.meta.intro.coverImageUrl, async (base64Image) => {
		let folderName = `CURRICULUM-IMAGES/${newCourse.meta.curriculum.curriculumId}/COURSES/${newCourse.id}`
		let fileName = `${slugText}-course-cover.png`
		let thumb600FileName =`${slugText}-course-cover-thumb-600.jpg`
		let thumb300FileName =`${slugText}-course-cover-thumb-300.jpg`
		
		let fileContentType = 'image/png'
		let tmpBase64Image = base64Image
		if (base64Image.indexOf('data:image/png;base64,') > -1) {
			fileContentType = 'image/png'
			tmpBase64Image = base64Image.replace('data:image/png;base64,', '')
		} else if (base64Image.indexOf('data:image/jpg;base64,') > -1) {
			fileContentType = 'image/jpg'
			tmpBase64Image = base64Image.replace('data:image/jpg;base64,', '')
		} else if (base64Image.indexOf('data:image/jpeg;base64,') > -1) {
			fileContentType = 'image/jpeg'
			tmpBase64Image = base64Image.replace('data:image/jpeg;base64,', '')
		}
		let blobFile = base64toBlob(tmpBase64Image, fileContentType)
		let thumbnailImage600 = await resizeImage(blobFile, 600, 480)
		let thumbnailImage300 = await resizeImage(blobFile, 300, 240)

		let fullSizeFileRef = storage.ref(`${folderName}/${fileName}`)
		let thumb600FileRef = storage.ref(`${folderName}/${thumb600FileName}`)
		let thumb300FileRef = storage.ref(`${folderName}/${thumb300FileName}`)
		
		try {
			await fullSizeFileRef.delete()
		} catch {}
		try {
			await thumb600FileRef.delete()
		} catch {}
		try {
			await thumb300FileRef.delete()
		} catch {}

		const uploadTask = fullSizeFileRef.put(blobFile)
		await uploadTask.on("state_changed", null, console.error, () => {
			storage
				.ref(folderName)
				.child(fileName)
				.getDownloadURL()
				.then(async (url_uploaded) => {
					const uploadTaskForThumb600 = thumb600FileRef.putString(thumbnailImage600, 'data_url')
					await uploadTaskForThumb600.on("state_changed", null, console.error, () => {
						storage
							.ref(folderName)
							.child(thumb600FileName)
							.getDownloadURL()
							.then(async (url_uploaded_thumb600) => {
								const uploadTaskForThumb300 = thumb300FileRef.putString(thumbnailImage300, 'data_url')
								await uploadTaskForThumb300.on("state_changed", null, console.error, () => {
									storage
										.ref(folderName)
										.child(thumb300FileName)
										.getDownloadURL()
										.then(async (url_uploaded_thumb300) => {
											newCourse.meta.intro.coverImageUrl = url_uploaded
											newCourse.meta.intro.coverImageFileName = fileName
											newCourse.meta.intro.coverImageThumb600Url = url_uploaded_thumb600
											newCourse.meta.intro.coverImageThumb600FileName = thumb600FileName
											newCourse.meta.intro.coverImageThumb300Url = url_uploaded_thumb300
											newCourse.meta.intro.coverImageThumb300FileName = thumb300FileName
											await courseDocRef.set(newCourse)
											callback()
										})
							})
						})
				})
			})
		})
	})
}

export { getCoursesOfStaff, getCourseById, getCoursesByCourseIdList, searchCourses, 
	createNewCourse, updateCourseData, syncCourseWithCurriculum, deleteCourse
}
