import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { AuthContext } from '../../../data/context/AuthProvider'
import { FormElementLabel, FormElementDesc, SimpleTextListManager,  H1 } from '../../../components'

let changeFromInside = false
function Users(props) {
	const [authData] = useContext(AuthContext)

	const [editors, setEditors] = useState(props.usersData.editors)
	const [viewers, setViewers] = useState(props.usersData.viewers)
	const [admins, setAdmins] = useState(props.usersData.admins)

	const [canEditUsers, setCanEditUsers] = useState(false)

	useEffect(() => {
		if (changeFromInside === true) {
			props.onUpdate({
				editors: (editors && editors.length > 0) ? editors : [],
				viewers: (viewers && viewers.length > 0) ? viewers : [],
				admins: (admins && admins.length > 0) ? admins : [],
			})
		}

		if (authData && authData.email && 
			( 
				(authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0 ) ) ||
				(admins && admins.indexOf(authData.email) >= 0) ||
				(authData.email === props.owner)
			)
		) {
			setCanEditUsers(true)
		}

	}, [editors, viewers, admins])

	return (
		<Grid container>
			{
				authData && authData.email &&
					<>
						<Grid item xs={12}>
							<H1>Kullanıcılar</H1>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'left', marginBottom: '30px'}}>
							<div>
								<FormElementLabel>Editörler</FormElementLabel>
								<FormElementDesc>Müfredatı düzenleme yetkisine sahip kullanıcıların email adreslerini ekleyebilirsiniz.</FormElementDesc>
								<SimpleTextListManager itemList={(editors && editors.length > 0) ? editors : []} editable={canEditUsers} onePerLine={true} showNumbers={true}
									onChange={(items) => { changeFromInside = true; setEditors(items) } }
								/>
							</div>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'left', marginBottom: '30px'}}>
							<div>
								<FormElementLabel>Görüntüleyenler</FormElementLabel>
								<FormElementDesc>Müfredatı görme yetkisine sahip kullanıcıların email adreslerini ekleyebilirsiniz.</FormElementDesc>
								<SimpleTextListManager itemList={(viewers && viewers.length > 0) ? viewers : []} editable={canEditUsers} onePerLine={true} showNumbers={true}
									onChange={(items) => { changeFromInside = true; setViewers(items) } }
								/>
							</div>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'left', marginBottom: '30px'}}>
							<div>
								<FormElementLabel>Yöneticiler</FormElementLabel>
								<FormElementDesc>Müfredatı düzenleme ve kullanıcılarınını belirleme yetkisine sahip kullanıcıların email adreslerini ekleyebilirsiniz.</FormElementDesc>
								<SimpleTextListManager itemList={(admins && admins.length > 0) ? admins : []} editable={canEditUsers} onePerLine={true} showNumbers={true}
									onChange={(items) => { changeFromInside = true; setAdmins(items) } }
								/>
							</div>
						</Grid>
					</>
			}
		</Grid>
	)
}

export default Users
