// import { fire } from '../../../utils/firebaseSettings'
import { getFirebaseObj } from '../../../utils/firebaseSettings'

const fetchGameInstance = async (gameId, _callback) => {
	let firebaseObj = await getFirebaseObj('fetchGameInstance')
	let fire = firebaseObj.fire

	fire.database().ref('lesson_instances').orderByChild('gameId').equalTo(gameId)
		.once('value', _callback)
}

const getGameInstanceData = async (gameId, _callback) => {
	fetchGameInstance(gameId, (snapshot) => {
		let game
		if (snapshot.val()) {			
			snapshot.forEach((child) => {
				game = child.val()
			})
		}
		return _callback(game)
	})
}

const getGameInstanceRef = async (gameKey) => {
	let firebaseObj = await getFirebaseObj('getGameInstanceRef')
	let fire = firebaseObj.fire

	let gameRef = fire.database().ref(`lesson_instances/${gameKey}`)
	return gameRef
}

const addGameInstanceAndReturnRef = async () => {
	let firebaseObj = await getFirebaseObj('addGameInstanceAndReturnRef')
	let fire = firebaseObj.fire

	let gameRef = fire.database().ref(`/lesson_instances`).push()
	return gameRef
}

const getPlayerRef = async (gameKey, playerKey) => {
	let firebaseObj = await getFirebaseObj('getPlayerRef')
	let fire = firebaseObj.fire

	let playerRef = fire.database().ref(`/lesson_instances/${gameKey}/players/${playerKey}`)
	return playerRef
}

const addPlayerAndReturnRef = async (gameKey) => {
	let firebaseObj = await getFirebaseObj('addPlayerAndReturnRef')
	let fire = firebaseObj.fire

	let playerRef = fire.database().ref(`/lesson_instances/${gameKey}/players`).push()
	return playerRef
}

const updateGameInstanceData = async (gameKey, gameUpdatedData, _callback) => {
	let firebaseObj = await getFirebaseObj('updateGameInstanceData')
	let fire = firebaseObj.fire

	fire.database().ref(`lesson_instances/${gameKey}`).update(gameUpdatedData, (error) => {
		_callback(error)
	})
}

const saveAnswerOnPlay = async (gameKey, playerKey, questionId, answer, _callback) => {
	let firebaseObj = await getFirebaseObj('saveAnswerOnPlay')
	let fire = firebaseObj.fire

	fire.database().ref('/lesson_instances/' + gameKey + '/players/' + playerKey + '/answers/' + questionId).set(answer, function (error) {
		_callback(error)
	})
}

const updateDrawingData = async (drawingData, gameKey, questionId) => {
	let firebaseObj = await getFirebaseObj('updateDrawingData')
	let fire = firebaseObj.fire

	fire.database().ref('/lesson_instances/' + gameKey + '/quiz/questions/' + parseInt(questionId - 1)).set(drawingData, function (error) { if (error) { } })
}

const clearDrawingData = async (drawingData, gameKey, questionId) => {
	let firebaseObj = await getFirebaseObj('clearDrawingData')
	let fire = firebaseObj.fire

	fire.database().ref('/lesson_instances/' + gameKey + '/quiz/questions/' + parseInt(questionId - 1)).set(drawingData, function (error) { if (error) { } })
}

const updateVideoPlayStatus = async (videoPlayData, lessonInstanceKey, questionId) => {
	let firebaseObj = await getFirebaseObj('updateVideoPlayStatus')
	let fire = firebaseObj.fire

	fire.database().ref('/lesson_instances/' + lessonInstanceKey + '/quiz/questions/' + parseInt(questionId - 1)).set(videoPlayData, function (error) { if (error) { } })
}

export { getGameInstanceData, getGameInstanceRef, addGameInstanceAndReturnRef, getPlayerRef, addPlayerAndReturnRef, updateGameInstanceData, saveAnswerOnPlay,
	updateDrawingData, clearDrawingData, updateVideoPlayStatus
}
