import React, { Component } from 'react'
import styled from 'styled-components'
import { CountdownAnimation } from '../../../components'

const NextQuestionMessage = styled.div`font-size: 60px; margin-top: 80px; font-weight: bold; width: 100%; text-align: center;`

let interval
class PhaseAwaitingQuestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            counter: this.startCounter(),
        }
        this.nextPhase = this.nextPhase.bind(this)
    }

    startCounter() {
        const question = this.props.game.quiz.questions[this.props.game.quiz.currentQuestion]
        if (question.cType === 'quiz' || question.cType === 'poll' || question.cType === 'form') { // Question
            let that = this
            let counter = 5
    
            interval = setInterval(function () {
                counter--
                that.setState({ counter: counter })
                if (counter === 0) {
                    that.nextPhase()
                    clearInterval(interval)
                }
            }, 1000)
        } else { // Content, go to next step immediately
            this.props.gameFunc.update({ phase: "show_content" });
        }        
        return 5
    }

    componentWillUnmount() {
        clearInterval(interval)
    }

    nextPhase() {
        this.props.gameFunc.update({ phase: "show_question" });
    }
    
    render() {
        return (
            <div>
                <NextQuestionMessage>Sıradaki soru geliyor...</NextQuestionMessage>
                <CountdownAnimation speed="slow" />
            </div>
        )
    }
}

export default PhaseAwaitingQuestion
