import moment from 'moment'

const getStatsOfCurriculum = async (curriculum) => {
	let totalLessonCount = 0
	let lessonsCountContainsLessonTemplate = 0
	if (curriculum && curriculum.sections && curriculum.sections.length > 0) {
		for (let i = 0; i < curriculum.sections.length; i++) {
			if (curriculum.sections[i] && curriculum.sections[i].lessons && curriculum.sections[i].lessons.length > 0) {
				totalLessonCount += curriculum.sections[i].lessons.length
				for (let j = 0; j < curriculum.sections[i].lessons.length; j++) {
					if (curriculum.sections[i].lessons[j].lessonTemplateKey && curriculum.sections[i].lessons[j].lessonTemplateKey.length > 0) {
						lessonsCountContainsLessonTemplate++
					}
				}
			}
		}
	}

	let curriculumStats = {
		sectionCount: (curriculum && curriculum.sections && curriculum.sections.length > 0) ? curriculum.sections.length : 0,
		totalLessonCount: totalLessonCount,
		lessonsCountContainsLessonTemplate: lessonsCountContainsLessonTemplate
	}
	return curriculumStats
}

const getCurriculumListStats = async (curriculums, courses) => {
	let iUsedForCourseCurriculumCount = 0
	let iNonUsedForCourseCurriculumCount = 0
	let lastCurriculumCreateDate = moment('2020-01-01', 'YYYY-MM-dd')
	let totalLessonCountInCurriculums = 0
	let totalLessonsContainLessonTemplate = 0
	for (let i = 0; i < curriculums.length; i++) {
		let coursesWithThisCurriculum = courses.filter (_course => _course.meta.curriculum.curriculumId === curriculums[i].id)
		curriculums[i].courseCount = coursesWithThisCurriculum.length
		curriculums[i].courses = coursesWithThisCurriculum
		if (coursesWithThisCurriculum.length > 0) {
			iUsedForCourseCurriculumCount++
		} else {
			iNonUsedForCourseCurriculumCount++
		}

		if (lastCurriculumCreateDate < moment(curriculums[i].meta.dates.curriculumCreated)) {
			lastCurriculumCreateDate = moment(curriculums[i].meta.dates.curriculumCreated)
		}

		let curriculumStats = await getStatsOfCurriculum(curriculums[i])
		totalLessonCountInCurriculums += curriculumStats.totalLessonCount
		totalLessonsContainLessonTemplate += curriculumStats.lessonsCountContainsLessonTemplate
	}

	let curriculumListStats = {
		all: curriculums.length,
		usedForCourse: iUsedForCourseCurriculumCount,
		nonUsedForCourse: iNonUsedForCourseCurriculumCount,
		lastCreateDate: lastCurriculumCreateDate.toDate(),
		allLessonsCount: totalLessonCountInCurriculums,
		lessonsOnlyContainLessonTemplateCount: totalLessonsContainLessonTemplate,
	}

	return curriculumListStats
}

export { getStatsOfCurriculum, getCurriculumListStats }
