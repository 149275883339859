import React from 'react'

function BaseInput(props) {
	return (
		<input
			type={props.type ? props.type : "text"}
			onChange={(e) => { if(props.onChange) { props.onChange(e.target.value) } } }
			onBlur={(e) => { if(props.onBlur) { props.onBlur(e) } } }
			value={props.value}
			style={{
				border: (props.border ? props.border : ((props.showAsRequired && props.value.length === 0) ? 'solid 2px red' : 'solid 1px gainsboro')),
				width: props.width ? props.width : '100%',
				maxWidth : props.maxWidth ? props.maxWidth : '100%',
				borderRadius: '4px',
				height: props.height ? props.height : '32px',
				fontSize: props.fontSize ? props.fontSize : '18px',
				paddingLeft: '10px',
				paddingRight: '10px',
				color: props.color ? props.color : 'black',
				WebkitBoxShadow: (props.WebkitBoxShadow ? props.WebkitBoxShadow :  'inset 0 0 3px rgba(30, 30, 30, 0.2)'),
				fontFamily: 'Nunito',
				textAlign: props.textAlign ? props.textAlign : 'left',
				step: props.step ? props.step : '',
				min: props.min ? props.min : '',
				max: props.max ? props.max : '',
				zIndex: 100,
				...props
			}}
			maxLength={props.maxLength}
		/>
	)
}

export { BaseInput }