import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { AuthContext } from '../../../data/context/AuthProvider'
import { deleteCourse, syncCourseWithCurriculum } from '../../../data/firebase/courseFirebase'
import { BaseInput, FormElementLabel, FormElementDesc, H1, LoadingEffect, SimpleTextListManager } from '../../../components'

const ScreenBlock = styled(Grid)`text-align: left; border: solid 1px gainsboro; border-radius: 6px; padding: 5px; margin-top: 20px; padding-left: 10px;`

let changeFromInside = false
function CourseSettings(props) {
	const [authData] = useContext(AuthContext)
	let history = useHistory()

	const [courseSpecificName, setCourseSpecificName] = useState(props.settingsData.courseSpecificName ? props.settingsData.courseSpecificName : '')
	const [slug, setSlug] = useState(props.settingsData.slug ? props.settingsData.slug.replace('-' + props.courseId, '') : '')
	const [courseSpecificTags, setCourseSpecificTags] = useState(props.settingsData.courseSpecificTags ? props.settingsData.courseSpecificTags : [])
	const [status, setStatus] = useState(props.settingsData.status ? props.settingsData.status : '')
	const [domainAllowance, setDomainAllowance] = useState(props.settingsData.domainAllowance ? props.settingsData.domainAllowance : '')
	const [allowedDomains, setAllowedDomains] =  useState(props.settingsData.allowedDomains ? props.settingsData.allowedDomains : [])
	const [minStudent, setMinStudent] = useState(props.settingsData.minStudent ? props.settingsData.minStudent : 0)
	const [maxStudent, setMaxStudent] = useState(props.settingsData.maxStudent ? props.settingsData.maxStudent : 0)
	const [contactPhone, setContactPhone] = useState(props.settingsData.contactPhone ? props.settingsData.contactPhone : '')
	const [classCategory, setClassCategory] = useState(props.settingsData.classCategory ? props.settingsData.classCategory : 'multiStudents')
	const [courseWhatsappGroup, setCourseWhatsappGroup] = useState(props.settingsData.courseWhatsappGroup ? props.settingsData.courseWhatsappGroup : '')
	const [courseRelatedSocialLinks, setCourseRelatedSocialLinks] = useState(props.settingsData.courseRelatedSocialLinks ? props.settingsData.courseRelatedSocialLinks : [])

	const [openDeleteCourseDialog, setOpenDeleteCourseDialog] = useState(false)
	const [openSyncCourseDialog, setOpenSyncCourseDialog] = useState(false)
	const [loading, setLoading] = useState(false)

	const updateSettingsData = () => {
		props.onUpdate({
			courseSpecificName: courseSpecificName,
			slug: slug + '-' + props.courseId,
			courseSpecificTags: courseSpecificTags,
			status: status ? status : 'draft',
			minStudent: minStudent,
			maxStudent: maxStudent,
			contactPhone: contactPhone,
			classCategory: classCategory,
			domainAllowance: domainAllowance,
			allowedDomains: allowedDomains,
			courseWhatsappGroup: courseWhatsappGroup,
			courseRelatedSocialLinks: courseRelatedSocialLinks
		})
	}

	useEffect(() => {
		setSlug(props.settingsData.slug ? props.settingsData.slug.replace('-' + props.courseId, '') : '')
		setStatus(props.settingsData.status ? props.settingsData.status : '')
	}, [props])

	useEffect(() => {
		if (changeFromInside === true) {
			updateSettingsData()
		}
	}, [courseSpecificName, slug, courseSpecificTags, status, minStudent, maxStudent, contactPhone, classCategory, domainAllowance, allowedDomains, courseWhatsappGroup, courseRelatedSocialLinks])

	return (
		<Grid container>
			<Grid item xs={12}>
				<H1>Ayarlar</H1>
			</Grid>
			<Grid item xs={12} style={{textAlign: 'left', marginBottom: '30px'}}>
				Kursun web sitesinde yayınlanmasına dair ayarlar
			</Grid>
			<ScreenBlock item xs={12}>
				<FormElementLabel>Kurs Adı</FormElementLabel>
				<FormElementDesc>Kursa müfredattakinden farklı bir isim vermek istiyorsanız, buradan yeni kurs adını girebilirsiniz. Müfredattaki ismin kullanılmasını istiyorsanız, bu alanı boş bırakın.</FormElementDesc>
				{
					props.editable ?
						<BaseInput value={courseSpecificName} maxLength="160" onChange={(newValue) => { changeFromInside = false; setCourseSpecificName(newValue)}} onBlur={(e) => { changeFromInside = true; updateSettingsData()}} />
					:
						<div style={{fontSize: '16px'}}>{courseSpecificName}</div>
				}
			</ScreenBlock>
			<ScreenBlock item xs={12} style={{marginTop: '20px'}}>
				<FormElementLabel>Kurs Etiketleri</FormElementLabel>
				<FormElementDesc>Müfredat etiketleri haricinde, sadece bu kursa özel etiketleri buradan ekleyebilirsiniz. Özellikle bir web sitesine, bir coğrafi bölgeye hitap etme gibi özel etiketler kursa özel kullanılabilir.</FormElementDesc>
				<SimpleTextListManager itemList={courseSpecificTags} onChange={(tagList) => {changeFromInside = true; setCourseSpecificTags(tagList)} } editable={props.editable} />
			</ScreenBlock>
			<ScreenBlock item xs={12} style={{marginTop: '20px'}}>
				<FormElementLabel>Slug</FormElementLabel>
				{
					props.editable ?
						<div style={{textAlign: 'right'}}>
							<BaseInput value={slug} maxLength="160" onChange={(newValue) => { changeFromInside = false; setSlug(newValue)}} onBlur={(e) => { changeFromInside = true; updateSettingsData()}} width='100%' />-{props.courseId}
						</div>
					:
						<div>
							{slug}-{props.courseId}
						</div>
				}
			</ScreenBlock>

			<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
				<FormElementLabel>Kurs Kodu</FormElementLabel>
				<div style={{marginLeft: '20px'}}>{props.courseId}</div>
			</ScreenBlock>

			<Grid item xs={false} sm={2}></Grid>

			<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
				<FormElementLabel>Müfredat Kodu</FormElementLabel>
				<div style={{marginLeft: '20px'}}>
					<a href={`/curriculum-designer/${props.curriculumId}`} target="_blank" rel="noopener noreferrer">{props.curriculumId}</a>
				</div>
			</ScreenBlock>


			<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
				<FormElementLabel>İrtibat Telefonu</FormElementLabel>
				<FormElementDesc>Bu kursu almak isteyen öğrenciler veya veliler, kurs hakkında önceden bilgi almak istelerse hangi numarayı arasınlar?</FormElementDesc>
				<BaseInput value={contactPhone} onChange={(newValue) => { changeFromInside = false; setContactPhone(newValue)}} onBlur={(e) => { changeFromInside = true; updateSettingsData()}} width='260px' />
			</ScreenBlock>
			<Grid item xs={false} sm={2}></Grid>
			<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
				<FormElementLabel>Sınıf Kategorisi</FormElementLabel>
				<FormElementDesc>Bu kursun nasıl bir sınıf türü için yayınlanacağı</FormElementDesc>				
				<Select value={classCategory} onChange={(e) => { changeFromInside = true; setClassCategory(e.target.value); } } style={{width: '260px'}}>
					<MenuItem value="multiStudents">Sınıf Halinde</MenuItem>
					<MenuItem value="privateLesson">Özel Ders</MenuItem>
				</Select>
			</ScreenBlock>

			{
				classCategory && classCategory === 'multiStudents'  &&
					<>
						<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
							<FormElementLabel>Minimum Öğrenci Sayısı</FormElementLabel>
							<FormElementDesc>Bu kursun açılabilmesi için olması gereken en az öğrenci sayısı. Minimum öğrenci sayısı belirlemek istemiyorsanız, 0 yazın</FormElementDesc>
							{
								props.editable ?
									<BaseInput value={minStudent} type="number" onChange={(newValue) => { changeFromInside = false; setMinStudent(newValue)}} onBlur={(e) => { changeFromInside = true; updateSettingsData()}} width='150px' />
								:
									<div>{minStudent}</div>
							}
							
						</ScreenBlock>
						<Grid item xs={false} sm={2}></Grid>

						<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
							<FormElementLabel>Maksimum Öğrenci Sayısı</FormElementLabel>
							<FormElementDesc>Bu kursa kabul edilecek en fazla öğrenci sayısı. Maksimum öğrenci sayısı belirlemek istemiyorsanız, 0 yazın</FormElementDesc>
							{
								props.editable ?
									<BaseInput value={maxStudent} type="number" onChange={(newValue) => { changeFromInside = false; setMaxStudent(newValue)}} onBlur={(e) => { changeFromInside = true; updateSettingsData()}} width='150px' />
								:
									<div>{maxStudent}</div>
							}
						</ScreenBlock>
					</>	
			}

			<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
				<FormElementLabel>Yayınlanma Durumu</FormElementLabel>
				{
					props.editable ?
						<RadioGroup aria-label="Publish Status" name="publishStatus" value={status} onChange={(e) => { changeFromInside = true; setStatus(e.target.checked ? e.target.value : ''); } }>
							<FormControlLabel value="draft" control={<Radio />} label="Draft" />
							<FormControlLabel value="published" control={<Radio />} label="Herkese Açık" />
							<FormControlLabel value="published-unlisted" control={<Radio />} label="Liste Dışı" />
							<FormControlLabel value="published-private" control={<Radio />} label="Gizli" />
						</RadioGroup>
					:
						<div>{status}</div>
				}

				{ status === 'draft' && <FormElementDesc>Çalışma modunda, hiç kimseye görüntülenmiyor</FormElementDesc> }
				{ status === 'published' && <FormElementDesc>Yayında ve herkese görüntüleniyor.</FormElementDesc> }
				{ status === 'published-unlisted' && <FormElementDesc>Yayında ancak sadece bu kursun sitedeki adresini bilenler detaylarını görebilir</FormElementDesc> }
				{ status === 'published-private' && <FormElementDesc>Yayında ancak sadece siz görebilirsiniz.</FormElementDesc> }
			</ScreenBlock>
			<Grid item xs={false} sm={2}></Grid>

			<ScreenBlock item xs={12} sm={5} style={{marginTop: '20px'}}>
				<FormElementLabel>Yayına İzin Verilen Domainler</FormElementLabel>
				<FormElementDesc>Bu kursun hangi web sitelerinde yayınlanmasını istiyorsanız, domain adreslerini ekleyiniz.</FormElementDesc>
				{
					props.editable ?
						<RadioGroup aria-label="Allowed Domains" name="domainAllowance" value={domainAllowance} onChange={(e) => { changeFromInside = true; setDomainAllowance(e.target.checked ? e.target.value : ''); } }>
							<FormControlLabel value="all-domains" control={<Radio />} label="Tüm Web Siteleri" />
							<FormControlLabel value="only-selected-domains" control={<Radio />} label="Sadece Belirli Domainler" />
						</RadioGroup>
					:
						<div>{domainAllowance}</div>
				}				

				{
					domainAllowance === 'only-selected-domains' &&
						<SimpleTextListManager itemList={(allowedDomains) ? allowedDomains : ['']} editable={true} onePerLine={true} showNumbers={true}
							onChange={(items) => { changeFromInside = true; setAllowedDomains(items) } }
						/>
				}
			</ScreenBlock>

			<ScreenBlock item xs={12} style={{marginTop: '20px'}}>
				<FormElementLabel>Kurs Whatsapp Grubu</FormElementLabel>
				<FormElementDesc>Bu kursa kayıt olanların davet edileceği whatsapp grubu</FormElementDesc>
				<BaseInput value={courseWhatsappGroup} onChange={(newValue) => { changeFromInside = false; setCourseWhatsappGroup(newValue)}} onBlur={(e) => { changeFromInside = true; updateSettingsData()}} width='100%' />

				<FormElementLabel style={{marginTop: '20px'}}>Kurs İle İlgili Duyuru Grupları</FormElementLabel>
				<FormElementDesc>Bu kursu inceleyenlerin davet edileceği whatsapp, telegram, facebook vs gibi duyuru grupları.</FormElementDesc>				
				
				<SimpleTextListManager itemList={(courseRelatedSocialLinks) ? courseRelatedSocialLinks : ['']} editable={true} onePerLine={true} showNumbers={true} textCase="normal"
					onChange={(items) => { changeFromInside = true; setCourseRelatedSocialLinks(items) } }
				/>
				
			</ScreenBlock>

			{
				props.editable &&
					<ScreenBlock item xs={12} style={{marginTop: '20px'}}>
						<div style={{color: 'navy'}}>Bu kursu yeniden kaynak müfredat içeriği ile güncelle. Bu işlem sonucunda daha önce yayınlanan tüm bilgiler güncellenir ve her zaman bu işleme izin verilmeyebilir.</div>
						<Button variant="contained" onClick={() => setOpenSyncCourseDialog(true)} color="primary">KURSU GÜNCELLE</Button>
					</ScreenBlock>
			}
			
			{
				props.editable &&
					<ScreenBlock item xs={12} style={{marginTop: '20px'}}>
						<div style={{color: 'red'}}>Bu kursu sil</div>
						<Button variant="contained" onClick={() => setOpenDeleteCourseDialog(true)} color="secondary">KURSU SİL</Button>
					</ScreenBlock>
			}
			
			{
				props.editable &&
					<Dialog open={openDeleteCourseDialog} onClose={() => setOpenDeleteCourseDialog(false)}>
						<DialogTitle id="alert-dialog-title">{"Bu kursu silmek istediğinizden emin misiniz?"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Devam ederseniz, kurs bilgileri tamamen silinecek. Kursu silmeniz sonucu, müfredat bilgileri silinmez. Sadece kurs bilgileri silinecektir.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpenDeleteCourseDialog(false)} color="primary">
								İPTAL
							</Button>
							<Button onClick={async () => { await deleteCourse(props.courseId); setOpenDeleteCourseDialog(false); history.push('/courses') }} color="primary" autoFocus>
								SİL
							</Button>
						</DialogActions>
					</Dialog>
			}
			
			{
				props.editable &&	
					<Dialog open={openSyncCourseDialog} onClose={() => setOpenSyncCourseDialog(false)}>
						<DialogTitle id="alert-dialog-title">{"Bu kursu güncellemek istiyor musunuz?"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Devam ederseniz, kurs bilgileri aktif müfredat içeriği ile eşitlenecek. Kurs yayına açıldıktan sonra bu işleme izin verilmeyebilir.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpenSyncCourseDialog(false)} color="primary">
								İPTAL
							</Button>
							<Button onClick={async () => { setOpenSyncCourseDialog(false); setLoading(true); await syncCourseWithCurriculum(authData.email, props.courseId, props.curriculumId, () => { setLoading(false) } );  }} color="primary" autoFocus>
								GÜNCELLE
							</Button>
						</DialogActions>
					</Dialog>
			}
			

			<LoadingEffect loading={loading} loadingText="GÜNCELLENİYOR" />
		</Grid>
	)
}

export default CourseSettings
