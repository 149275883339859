import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import CourseSettings from './CourseSettings'
import CoursePricing from './CoursePricing'
import { H1 } from '../../../components'

const levels = [
	{ id: '0', name: ''},
	{ id: '1', name: 'Başlangıç Seviyesi'},
	{ id: '2', name: 'Orta Seviye'},
	{ id: '3', name: 'İleri Seviye'},
]

const languages = [
	{ id: 'en', name: 'English'},
	{ id: 'es', name: 'Español'},
	{ id: 'fr', name: 'Française'},
	{ id: 'de', name: 'Deutsche'},
	{ id: 'tr', name: 'Türkçe'},
]

const LeftMenu = styled.div`margin-top: 20px; `
const MenuItem = styled.div`font-size: 16px; text-align: left; min-height: 28px;  margin: 10px; padding: 10px; border-radius: 6px; cursor: pointer;
	:hover { background-color: gainsboro; }
`

const MetaItemLabel = styled.div`text-align: left; color: maroon; font-size: 11px; margin-top: 10px; font-weight: bold;`
const MetaItem = styled.div`text-align: left; font-weight: bold; color: black; padding-left: 5px; margin-top: 5px;`
const TagItem = styled.div`margin-right: 10px; border: solid 1px gainsboro; border-radius: 6px; background-color: gainsboro; padding-top: 3px; padding-bottom: 3px; padding-right: 6px; padding-left: 6px; margin-top: 5px; float: left;`
const CourseDescription = styled.div`max-height: 240px; overflow: auto; border: solid 1px gainsboro; border-radius: 6px; padding: 5px;`

let changeFromInside = false

function CourseMetaData(props) {
	let history = useHistory()

	const [menu, setMenu] = useState((props.urlParams && props.urlParams.param3) ? props.urlParams.param3 : 'intro')
	
	useEffect(() => {
		if (props.urlParams && props.urlParams.param3) {
			setMenu(props.urlParams.param3)
		} else {
			history.push(`/courses/${props.course.id}/meta/intro`)
			setMenu('intro')
		}
	}, [])

	useEffect(() => {
		if (!props.urlParams.param3) {
			history.push(`/courses/${props.course.id}/meta/intro`)
			setMenu('intro')
		}
	}, [props.urlParams])

	useEffect(() => {
		if (changeFromInside) {
			history.push(`/courses/${props.course.id}/meta/${menu}`)
		}
	}, [menu])

	return (
		<Grid container>
			<Grid item xs={12} md={2} style={{ height: 'calc(100vh - 150px)', overflow: 'auto', borderRight: 'solid 1px gainsboro'}}>
				<LeftMenu>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('intro')}} style={{ 'backgroundColor': ( menu === 'intro' ? 'gainsboro' : 'white') }}>Tanıtım Sayfası Bilgileri</MenuItem>

					<MenuItem onClick={() => { changeFromInside = true; setMenu('goals')}} style={{ 'backgroundColor': ( menu === 'goals' ? 'gainsboro' : 'white') }}>Neler Öğrenilecek?</MenuItem>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('requirements')}} style={{ 'backgroundColor': ( menu === 'requirements' ? 'gainsboro' : 'white') }}>Gereksinimler</MenuItem>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('studentGroup')}} style={{ 'backgroundColor': ( menu === 'studentGroup' ? 'gainsboro' : 'white') }}>Hedef Öğrenciler</MenuItem>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('messages')}} style={{ 'backgroundColor': ( menu === 'messages' ? 'gainsboro' : 'white') }}>Karşılama ve Tebrik Mesajları</MenuItem>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('pricing')}} style={{ 'backgroundColor': ( menu === 'pricing' ? 'gainsboro' : 'white') }}>Fiyatlandırma</MenuItem>
					<MenuItem onClick={() => { changeFromInside = true; setMenu('settings')}} style={{ 'backgroundColor': ( menu === 'settings' ? 'gainsboro' : 'white') }}>Ayarlar</MenuItem>
				</LeftMenu>
			</Grid>
			<Grid item xs={12} md={1}></Grid>
			<Grid item xs={12} md={8}>
				{ menu && menu === 'intro' && props.course && props.course.meta &&
					<Grid container>
						<Grid item xs={12}>
							<H1>Kurs hakkında temel bilgiler</H1>
						</Grid>
						<Grid item xs={12} sm={6}>							
							<MetaItemLabel>Müfredat Başlığı:</MetaItemLabel>
							<MetaItem>{props.course.meta.intro.title}</MetaItem>
							
							<MetaItemLabel>Alt Başlık:</MetaItemLabel>
							<MetaItem>{props.course.meta.intro.subTitle}</MetaItem>
							
							<MetaItemLabel>Açıklaması:</MetaItemLabel>
							<MetaItem>
								<CourseDescription dangerouslySetInnerHTML={{ __html: props.course.meta.intro.description }}></CourseDescription>
							</MetaItem>
							
							<MetaItemLabel>Dili:</MetaItemLabel>							
							<MetaItem>{props.course.meta.intro.language ? languages.filter(language => language.id === props.course.meta.intro.language)[0].name : ''}</MetaItem>
							
							<MetaItemLabel>Seviyesi:</MetaItemLabel>
							<MetaItem>{props.course.meta.intro.level ? levels.filter(level => level.id === props.course.meta.intro.level)[0].name : ''}</MetaItem>

							<MetaItemLabel>Kategori:</MetaItemLabel>
							<MetaItem>{props.course.meta.intro.category ? props.course.meta.intro.category : ''}</MetaItem>

							<MetaItemLabel>Müfredat Etiketleri:</MetaItemLabel>
							<MetaItem>
								{
									props.course.meta.intro && props.course.meta.intro.tags && props.course.meta.intro.tags.map((tag, index) => (
										<TagItem key={index}>{tag}</TagItem>
									))
								}
							</MetaItem>
						</Grid>
						<Grid item xs={false} sm={1}></Grid>
						<Grid item xs={12} sm={5}>
							<MetaItem><img src={props.course.meta.intro.coverImageUrl} alt={props.course.meta.intro.title} style={{maxWidth: '400px', maxHeight: '400px'}} /></MetaItem>
						</Grid>
					</Grid>
				}
				{ menu && menu === 'goals' && props.course && props.course.meta &&
					<div>
						<H1>Öğrenciler, kursunuzda ne öğrenecek?</H1>
						{
							props.course.meta.goals.map((item, index) => (
								<MetaItem key={index}>{item.value}</MetaItem>
							))
						}	
					</div>
				}
				{ menu && menu === 'requirements' && props.course && props.course.meta &&
					<div>
						<H1>Kurs gereksinimleri veya ön koşulları var mı?</H1>
						{
							props.course.meta.requirements.map((item, index) => (
								<MetaItem key={index}>{item.value}</MetaItem>
							))
						}
					</div>
				}
				{ menu && menu === 'studentGroup' && props.course && props.course.meta &&
					<div>
						<H1>Hedef öğrencileriniz kimlerdir?</H1>
						{
							props.course.meta.studentGroup.map((item, index) => (
								<MetaItem key={index}>{item.value}</MetaItem>
							))
						}
					</div>
				}
				{ menu && menu === 'messages' && props.course && props.course.meta &&
					<div>
						<H1>Öğrenciler için karşılama mesajları</H1>
						
						<MetaItemLabel>Karşılama Mesajı:</MetaItemLabel>
						<MetaItem>{props.course.meta.messages ? props.course.meta.messages.welcome : ''}</MetaItem>
						
						<MetaItemLabel>Tebrik Mesajı:</MetaItemLabel>
						<MetaItem>{props.course.meta.messages ? props.course.meta.messages.congrats : ''}</MetaItem>
					</div>
				}
				{ menu && menu === 'pricing' && props.course && props.course.meta &&
					<CoursePricing
						courseId={props.course.id}
						lessonCount={props.course.meta.stats.lessonCount}
						pricingData={props.course.meta.pricing ?  props.course.meta.pricing : {}}
						onUpdatePricing={(pricingData) => props.onUpdateMeta({...props.course.meta, pricing: pricingData})}
						editable={props.editable}
					/>
				}

				{ menu && menu === 'settings' && props.course && props.course.meta &&
					<CourseSettings courseId={props.course.id} curriculumId={props.course.meta.curriculum.curriculumId}
						settingsData={props.course.meta.settings ? props.course.meta.settings : {}}
						onUpdate={(settingsData) => props.onUpdateMeta({...props.course.meta, settings: settingsData})}
						editable={props.editable}
					/> 
				}
			</Grid>
			<Grid item xs={12} md={1}></Grid>
		</Grid>
	)
}

export default CourseMetaData
