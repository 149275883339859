import React from 'react'
import { Collection, Input } from 'usetheform'
import { DraftEditor } from './DraftEditor'
import { limitTo } from './utils/limitTo'

export const WhatsHappeningBar = (props) => {
	return (
		<div>
			<Collection object name="editor" validators={[limitTo(props.maxChars)]}>
				<DraftEditor name="editorState" maxChars={props.maxChars} onChange={(editorData) => props.onChange(editorData) } />
				<Input type="hidden" name="plainText" />
			</Collection>
		</div>
	)
}
