import React, { useState, useEffect, useContext } from 'react'
import CurriculumDesigner from '../../PageBlocks/curriculum/CurriculumDesigner'
import { getCurriculumById, } from '../../data/firebase/curriculumFirebase'
import { Layout2 } from '../../components'
import { AuthContext } from '../../data/context/AuthProvider'

function Curriculum(props) {
	const [authData] = useContext(AuthContext)
	
	const [curriculumId] = useState((props && props.match && props.match.params && props.match.params.id) ? props.match.params.id :  null)
	const [selectedCurriculum, setSelectedCurriculum] = useState({})

	useEffect(async () => {
		if (curriculumId && authData.email) {
			const tempSelected = await getCurriculumById(authData.email, curriculumId)
			setSelectedCurriculum(tempSelected)
		}
	}, [curriculumId, authData.email])

	return (
		<Layout2 withAuth={true}>
			{
				selectedCurriculum && selectedCurriculum.id && 
					<div>
						<CurriculumDesigner curriculum={selectedCurriculum} urlParams={props.match.params} />
					</div>
			}
		</Layout2>
	)
}

export default Curriculum
