import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { FormElementLabel, Toggle } from '../../../../../components'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const pointOptions = [
	{ id: 0, label: '0' },
	{ id: 1000, label: '1000' },
	{ id: 2000, label: '2000' },
]
let changeFromInside = false

function QuestionOptions(props) {
	const [timeLimit, setTimeLimit] = useState(props.value.timeLimit ? parseInt(props.value.timeLimit) : 20)
	const [point, setPoint] = useState(props.value.point ? props.value.point : 1000)

	const [firstLoadDone, setFirstLoadDone] = useState(false)

	useEffect(() => {
		setTimeLimit((typeof props.value.timeLimit === undefined || props.value.timeLimit === null) ? 20 : parseInt(props.value.timeLimit))
		setPoint(props.value.point)
		setFirstLoadDone(true)
	}, [props])

	useEffect(() => {
		if (firstLoadDone && changeFromInside) {
			let tempQOptions = {
				timeLimit: parseInt(timeLimit),
				point
			}
			if (props.onUpdate) {
				props.onUpdate(tempQOptions)
			}
		}
	}, [timeLimit, point])

	return(
		<Grid container>
			<Grid item xs={12} sm={4}>
				<FormControl style={{width : '100%' }}>
					<InputLabel htmlFor="time-limit">Süre (saniye)</InputLabel>
					<Select value={timeLimit}  onChange={(e) => {changeFromInside = true; setTimeLimit(e.target.value) } } inputProps={{ id: 'time-limit', }} >
						<MenuItem value="-1">Süresiz</MenuItem>
						<MenuItem value="5">5 saniye</MenuItem>
						<MenuItem value="10">10 saniye</MenuItem>
						<MenuItem value="20">20 saniye</MenuItem>
						<MenuItem value="30">30 saniye</MenuItem>
						<MenuItem value="45">45 saniye</MenuItem>
						<MenuItem value="60">1 dakika</MenuItem>
						<MenuItem value="90">1.5 dakika</MenuItem>
						<MenuItem value="120">2 dakika</MenuItem>
						<MenuItem value="180">3 dakika</MenuItem>
						<MenuItem value="240">4 dakika</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={false} sm={2}></Grid>
			{
				props.cType === 'quiz' &&
					<>
						<Grid item xs={6} sm={1}>
							<FormElementLabel>Puan</FormElementLabel>
						</Grid>
						<Grid item xs={6} sm={4}>
							<Toggle options={pointOptions} onChange={(newValue) => {changeFromInside = true; setPoint(newValue) } } value={point} />
						</Grid>
						<Grid item xs={false} sm={1}></Grid>
					</>
			}
		</Grid>
	)			
}

export default QuestionOptions
