import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import 'moment/locale/tr'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { getBrandData } from '../../utils/appUtil'
import { AuthContext } from '../../data/context/AuthProvider'

const CourseCard = styled(Grid)`border: solid 1px gainsboro; min-height: 220px; border-radius: 6px; padding: 5px`
const CourseCardImageArea = styled.div`height: 120px; width: 100%;`
const CourseCardImage = styled.img`height: 116px; max-width: 100%; border-radius: 6px 6px 6px 6px; margin-top: 3px; margin-bottom: 3px;`
const CourseLink = styled(Link)`height: 30px; font-size: 16px; font-weight: bold; overflow: hidden; color: black;`
const LabelPart = styled(Grid)``
const DataPart = styled(Grid)`font-weight: bold; padding-left: 5px; border-bottom: solid 1px gainsboro; font-size: 14px;`


function CourseListItem(props) {
    const [authData] = useContext(AuthContext)
    moment.locale('tr')

    const [title, setTitle] = useState('')
    const [coverImage, setCoverImage] = useState('https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3')

    useEffect(() => {
        if (props.course) {
            if (props.course.meta && props.course.meta.intro) {
                setCoverImage(props.course.meta.intro.coverImageThumb300Url)
            } else {
                setCoverImage('https://firebasestorage.googleapis.com/v0/b/yesil-okul-web-app.appspot.com/o/APP-IMAGES%2Fno-image-yet-300x240.jpg?alt=media&token=d027b674-994b-4d1e-b3de-64ac7085edc3')
            }
        }
        let tempCourseName = props.course.meta && props.course.meta.settings.courseSpecificName ? props.course.meta.settings.courseSpecificName : (props.course.meta && props.course.meta.intro.title ? props.course.meta.intro.title : props.course.title)
        setTitle(tempCourseName)
    }, [props.course])

    return (
        <CourseCard container>
            <Grid item xs={4}>
                <CourseLink to={`/courses/${props.course.id}`}>
                    <CourseCardImageArea>
                        <CourseCardImage src={coverImage} />
                    </CourseCardImageArea>
                    <div style={{fontSize: '16px'}}>
                        {title}
                    </div>
                </CourseLink>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={7} style={{textAlign: 'left', fontSize: '12px'}}>
                <Grid container>
                    <LabelPart item xs={5}>
                        Ders Sayısı - Ücret
                    </LabelPart>
                    <DataPart item xs={7}>
                        {
                            props.course.meta &&
                                <div>
                                    {props.course.meta.stats.lessonCount} Ders
                                    &nbsp;*&nbsp;
                                    {(props.course.meta.pricing && props.course.meta.pricing.basePrice) ? props.course.meta.pricing.basePrice : ''} {props.course.meta.pricing ? props.course.meta.pricing.currency : ''}
                                    &nbsp;=&nbsp;
                                    {(props.course.meta.pricing && props.course.meta.pricing.basePrice) ? props.course.meta.stats.lessonCount * props.course.meta.pricing.basePrice : ''} {props.course.meta.pricing ? props.course.meta.pricing.currency : ''}
                                </div>
                        }
                    </DataPart>
                    <LabelPart item xs={5}>
                        Kayıt / Sepet
                    </LabelPart>
                    <DataPart item xs={7} style={{fontSize: '16px'}}>
                        {(props.course.students && props.course.students.registered) ? props.course.students.registered.length : '0' }
                        /
                        {(props.course.students && props.course.students.inShoppingCart) ? props.course.students.inShoppingCart.length : '0' }
                    </DataPart>
                    <LabelPart item xs={5}>
                        Kurs Tarih Aralığı
                    </LabelPart>
                    <DataPart item xs={7} style={{fontSize: '12px'}}>
                        {
                            props.course.meta &&
                                <div>
                                    {moment(props.course.meta.stats && props.course.meta.stats.firstLessonDate && props.course.meta.stats.firstLessonDate.toDate()).format('DD MMMM YYYY hh:mm')}
                                    <br />
                                    {moment(props.course.meta.stats && props.course.meta.stats.lastLessonDate && props.course.meta.stats.lastLessonDate.toDate()).format('DD MMMM YYYY hh:mm')}
                                </div>
                        }
                    </DataPart>

                    <LabelPart item xs={5}>
                        Web Sayfası
                    </LabelPart>
                    <DataPart item xs={7}>
                        {
                            props.course.meta &&
                                <a href={`${getBrandData().publicWebsite}/${props.course.meta.intro.language}/course/${props.course.meta.settings.slug}`} target="_blank" rel="noopener noreferrer" style={{fontSize: '9px', lineHeight: '9px'}}>
                                    <OpenInNewIcon style={{fontSize: '9px'}} />&nbsp;{props.course.courseName}
                                </a>
                        }
                    </DataPart>

                    <LabelPart item xs={5}>
                        Son Güncelleme
                    </LabelPart>
                    <DataPart item xs={7} style={{fontSize: '10px'}}>
                        {
                            props.course.meta &&
                                <div>
                                    {moment(props.course.meta && props.course.meta.dates && props.course.meta.dates.courseLastUpdated).format('DD MMMM YYYY hh:mm')}
                                </div>
                        }
                    </DataPart>
                    {
                        (authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 )) && 
                            <>
                                <LabelPart item xs={5}>
                                    Owner
                                </LabelPart>
                                <DataPart item xs={7} style={{fontSize: '9px'}}>
                                    {
                                        props.course.meta &&
                                            <a href={`${getBrandData().publicWebsite}/${props.course.meta.intro.language}/course/list/tag/cowner/${props.course.owner}`} target="_blank" rel="noopener noreferrer" style={{fontSize: '9px', lineHeight: '9px'}}>
                                                {props.course.owner}
                                            </a>
                                    }
                                </DataPart>
                                <LabelPart item xs={5}>
                                    Firestore
                                </LabelPart>
                                <DataPart item xs={7} style={{fontSize: '9px'}}>
                                    <a href={`https://console.firebase.google.com/u/0/project/yesil-okul-web-app/firestore/data/~2Fcourses~2F${props.course.id}`} target="_blank" rel="noopener noreferrer">
                                        <OpenInNewIcon style={{fontSize: '14px'}} />
                                    </a>
                                </DataPart>
                            </>
                    }
                </Grid>
            </Grid>
        </CourseCard>
	)
}

export default CourseListItem
